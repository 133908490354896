@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/spacing.scss';

.highlighted-brands {
	height: 517px;
	margin-bottom: spacing.$spacing-10x;
	& .highlighted-brands-title {
		font-size: fonts.$font-size-h3;
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		color: colors.$color-primary;
		text-align: center;
		margin-bottom: spacing.$spacing-x;
	}
	& .highlighted-brands-text {
		display: flex;
		width: 610px;
		text-align: left;
		margin: auto;
		font-size: fonts.$font-size-h5;
		line-height: 28.8px;
		font-family: fonts.$tertiary-font-family;
		font-weight: fonts.$font-weight-semibold;
		color: colors.$color-black;
	}
	& .divider {
		height: 2px;
		width: 88.5%;
		margin-top: spacing.$spacing-3x;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		background: colors.$color-gray;
		box-shadow: shadows.$shadow-lower;
		z-index: 10;
	}
	& .brands-carousel-container {
		position: relative;
		z-index: 0;
		width: 90%;
		margin: auto;
		top: 35px;
		& .swiper {
			height: auto;
			.swiper-wrapper {
				height: 370px;
				.swiper-slide {
					padding: spacing.$spacing-x;
					& .brand-card-container {
						max-width: 410px;
					}
				}
			}
		}
		& .nav-buttons {
			display: flex;
			justify-content: space-between;

			& .swiper-button-next {
				background: colors.$color-gray;
				border-radius: border-radius.$radius-biggest;
				border: none;
				bottom: 160px;
				color: inherit;
				cursor: pointer;
				font: inherit;
				height: 26px;
				outline: inherit;
				position: relative;
				width: 26px;
				left: 35px;
				align-self: flex-end;
				&::after {
					font-size: fonts.$font-size-smaller;
					color: colors.$color-black;
					font-weight: fonts.$font-weight-bold;
				}
				&:hover {
					filter: drop-shadow(shadows.$shadow-low);
				}
				&:active {
					box-shadow: shadows.$shadow-high-inner;
				}
			}
			& .swiper-button-prev {
				align-items: center;
				border-radius: border-radius.$radius-biggest;
				border: none;
				color: inherit;
				cursor: pointer;
				font: inherit;
				height: 26px;
				outline: inherit;
				width: 26px;
				position: relative;
				bottom: 160px;
				margin-left: -40px;
				&::after {
					font-size: fonts.$font-size-smaller;
					color: colors.$color-black;
					font-weight: fonts.$font-weight-bold;
				}

				&:hover {
					filter: drop-shadow(shadows.$shadow-low);
				}

				&:active {
					box-shadow: shadows.$shadow-high-inner;
				}
			}
		}
	}
}

@media (max-width: 1430px) {
	.highlighted-brands {
		& .divider {
			width: 87%;
		}
	}
}
@media (max-width: 1280px) {
	.highlighted-brands {
		& .divider {
			width: 89%;
		}
	}
}

@media (max-width: 992px) {
	.highlighted-brands {
		& .divider {
			width: 79%;
		}
	}
}
@media (max-width: 900px) {
	.highlighted-brands {
		& .divider {
			width: 81%;
		}
	}
}
@media (max-width: 744px) {
	.highlighted-brands {
		& .divider {
			width: 86%;
		}
	}
}
@media (max-width: 660px) {
	.highlighted-brands {
		& .divider {
			width: 90%;
		}
	}
}
@media (max-width: 570px) {
	.highlighted-brands {
		& .divider {
			width: 84%;
		}
	}
}
@media (max-width: 540px) {
	.highlighted-brands {
		& .divider {
			width: 87%;
		}
	}
}
@media (max-width: 490px) {
	.highlighted-brands {
		& .divider {
			width: 74%;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.highlighted-brands {
		height: 459px;
		margin-bottom: spacing.$spacing-3x;
		& .highlighted-brands-title {
			font-size: fonts.$font-size-big;
		}
		& .highlighted-brands-text {
			font-size: fonts.$font-size-small;
			max-width: 392px;
			text-align: center;
			line-height: 19.2px;
		}
		& .brands-carousel-container {
			position: relative;
			z-index: 0;
			width: 90%;
			margin: auto;
			top: 0px;
			& .swiper-custom-pagination-highlighted-brand {
				text-align: center;
				display: block;
				position: relative;
				margin-right: auto;
				margin-top: -25px;
				z-index: 10;
			}
			& .nav-buttons {
				display: flex;
				justify-content: space-between;

				& .swiper-button-next {
					left: 20px;
				}
				& .swiper-button-prev {
					margin-left: -30px;
				}
			}
			& .swiper {
				height: auto;
				.swiper-wrapper {
					height: 360px;
					.swiper-slide {
						& .brand-card-container {
							max-width: 288px;
							max-height: 264px;
							& .image {
								height: 114px;
							}
							& .info {
								display: flex;
								flex-direction: column;
								padding: spacing.$spacing-2x;
								gap: 16px;
								height: 150px;
								& .brand-highlighted-description {
									line-height: 16px;
									font-size: fonts.$font-size-smaller;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 500px) and (max-width: breakpoints.$mobile-breakpoint) {
	.highlighted-brands {
		height: 697px;
		margin-bottom: spacing.$spacing-7x;
		& .highlighted-brands-title {
			font-size: fonts.$font-size-bigger-mobile;
			margin-bottom: spacing.$spacing-x;
			line-height: 25.6px;
		}
		& .highlighted-brands-text {
			display: flex;
			width: 368px;
			text-align: center;
			margin: auto;
			font-size: fonts.$font-size-base-mobile;
			line-height: 19.2px;
			font-family: fonts.$tertiary-font-family;
			font-weight: fonts.$font-weight-semibold;
		}
		& .divider {
			margin-top: spacing.$spacing-1_25x;
		}
		& .brands-carousel-container {
			position: relative;
			z-index: 0;
			width: 90%;
			margin: auto;
			top: 23px;
			& .nav-buttons {
				& .swiper-button-next {
					left: 20px;
				}
				& .swiper-button-prev {
					margin-left: -30px;
				}
			}
			& .nav-buttons {
				& .swiper-button-next {
					left: 20px;
				}
				& .swiper-button-prev {
					margin-left: -30px;
				}
			}
			& .swiper-custom-pagination-highlighted-brand {
				text-align: center;
				display: block;
				position: relative;
				margin-left: auto;
				margin-right: auto;
				margin-top: spacing.$spacing-1_25x;
			}

			& .control-mobile-for-highlighted-brand {
				position: inherit;
				display: flex;
				justify-content: space-between;
				width: 100%;
				& .bi-chevron-left {
					left: 20px;
					position: inherit;
					font-size: fonts.$font-size-big;
					top: -40px;
					color: white;
					&::before {
						color: colors.$color-gray-slate !important;
						font-weight: fonts.$font-weight-bold !important;
					}
				}
				& .bi-chevron-right {
					width: 24px;
					height: 24px;
					font-size: fonts.$font-size-big;
					right: 20px;
					position: inherit;
					top: -40px;
					&::before {
						color: colors.$color-gray-slate !important;
						font-weight: fonts.$font-weight-bold !important;
					}
				}
			}
			& .swiper {
				height: 578px;
				.swiper-wrapper {
					height: auto;
					padding-top: spacing.$spacing-1_5x;
					.swiper-slide {
						height: 264px;
						top: 0px;
						margin-bottom: spacing.$spacing-3x;
						& .brand-card-container {
							max-width: 288px;
							max-height: 264px;
							& .image {
								height: 114px;
							}
							& .info {
								display: flex;
								flex-direction: column;
								padding: spacing.$spacing-2x;
								gap: 16px;
								height: 150px;
								& .brand-highlighted-name {
									font-size: fonts.$font-size-bigger-mobile;
									line-height: 21.79px;
								}
								& .brand-highlighted-description {
									line-height: 16px;
									font-size: fonts.$font-size-small-mobile;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 499px) {
	.highlighted-brands {
		height: 711px;
		& .highlighted-brands-title {
			font-size: fonts.$font-size-bigger-mobile;
			margin-bottom: spacing.$spacing-x;
			line-height: 25.6px;
		}
		& .highlighted-brands-text {
			display: flex;
			width: 90%;
			text-align: center;
			margin: auto;
			font-size: fonts.$font-size-base-mobile;
			line-height: 19.2px;
			font-family: fonts.$tertiary-font-family;
			font-weight: fonts.$font-weight-semibold;
		}
		& .brands-carousel-container {
			position: relative;
			width: 90%;
			margin: auto;
			top: 35px;
			& .nav-buttons {
				& .swiper-button-next {
					left: 20px;
				}
				& .swiper-button-prev {
					margin-left: -30px;
				}
			}

			& .swiper-custom-pagination-highlighted-brand {
				text-align: center;
				display: block;
				position: relative;
				margin-left: auto;
				margin-right: auto;
				margin-top: spacing.$spacing-1_25x;
			}

			& .control-mobile-for-highlighted-brand {
				position: inherit;
				display: flex;
				justify-content: space-between;
				width: 100%;
				& .bi-chevron-left {
					left: 20px;
					position: inherit;
					font-size: fonts.$font-size-big;
					top: -40px;
					color: white;
					&::before {
						color: colors.$color-gray-slate !important;
						font-weight: fonts.$font-weight-bold !important;
					}
				}
				& .bi-chevron-right {
					width: 24px;
					height: 24px;
					font-size: fonts.$font-size-big;
					right: 20px;
					position: inherit;
					top: -40px;
					&::before {
						color: colors.$color-gray-slate !important;
						font-weight: fonts.$font-weight-bold !important;
					}
				}
			}
			& .swiper {
				padding-top: spacing.$spacing-1_25x;
				.swiper-wrapper {
					height: auto;
					.swiper-slide {
						height: 264px;
						top: 6px;
						margin-bottom: spacing.$spacing-3x;
						& .brand-card-container {
							max-width: 288px;
							max-height: 264px;
							& .image {
								height: 114px;
							}
							& .info {
								padding: spacing.$spacing-2x;
								gap: 16px;
								height: 150px;
								& .brand-highlighted-name {
									font-size: fonts.$font-size-bigger-mobile;
									line-height: 21.79px;
								}
								& .brand-highlighted-description {
									line-height: 16px;
									font-size: fonts.$font-size-small-mobile;
								}
							}
						}
					}
				}
			}
		}
	}
}
