@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/opacity.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';
.sort-products-container {
	.sort-button-container {
		z-index: 200;
	}
	.icon {
		font-size: fonts.$font-size-base;
	}
	display: flex;
	margin-left: auto;
	width: 100%;
	margin-right: spacing.$spacing-2x;
	& .sort-by-options-container {
		position: absolute;
		display: flex;
		width: 209px;
		flex-direction: column;
		z-index: 190;
		top: spacing.$spacing-x;
		background: colors.$color-background-white;
		border-radius: border-radius.$radius-default;
		box-shadow: shadows.$shadow-medium;
		height: fit-content;
		& .sort-option {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-small;
			line-height: spacing.$spacing-2x;
			display: flex;
			align-items: center;
			letter-spacing: 0.0075em;
			color: #000000;
			cursor: pointer;
			width: 100%;
			height: 28px;
			padding: spacing.$spacing-half-x spacing.$spacing-2x;
			margin: spacing.$spacing-x spacing.$spacing-half-x 0px spacing.$spacing-half-x;
			&:last-child {
				margin-bottom: spacing.$spacing-x;
			}
			&:first-child {
				margin-top: spacing.$spacing-3x;
			}
			& .selected-icon {
				margin-left: auto;
			}
			&.sort-selected {
				width: 97%;
				display: flex;
				flex-direction: row;
				height: spacing.$spacing-3x;
				background: colors.$color-background-gray;
				border-radius: border-radius.$radius-big;
				height: 28px;
			}
			&:not(.disabled):hover {
				width: 97%;
				display: flex;
				flex-direction: row;
				height: spacing.$spacing-3x;
				background: colors.$color-background-gray;
				border-radius: border-radius.$radius-big;
				height: 28px;
			}
			&.disabled {
				color: colors.$color-text-gray-slate-light;
				font-size: fonts.$font-size-smaller;
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.sort-by-btn {
		display: flex;
		justify-content: center;
		width: fit-content;
	}
	.sort-products-container {
		margin-right: 0px;
		&.sort-products-container {
			& .sort-by-options-container {
				right: 2%;
				top: spacing.$spacing-3x;
				& .sort-option {
					&:first-child {
						margin-top: spacing.$spacing-x !important;
					}
				}
			}
		}
	}
}
@media (min-width: 0px) and (max-width: 499px) {
	.sort-products-container {
		margin-right: 0;
		& .sort-by-options-container {
			right: 4%;
			top: spacing.$spacing-3_5x !important;
		}
		& .sort-option {
			&:first-child {
				margin-top: 3px !important;
			}
		}
	}
}
@media (min-width: 500px) and (max-width: 559px) {
	.sort-products-container {
		& .sort-by-options-container {
			right: 3.5%;
		}
	}
}

@media (min-width: 560px) and (max-width: 629px) {
	.sort-products-container {
		& .sort-by-options-container {
			right: 3%;
		}
	}
}

@media (min-width: 630px) and (max-width: breakpoints.$mobile-breakpoint) {
	.sort-products-container {
		& .sort-by-options-container {
			right: 2.8%;
		}
	}
}
