$font-weight-bold: bold;
$font-weight-regular: regular;
$font-weight-semibold: semi bold;

$font-size-h1: 64px;
$font-size-h2: 48px;
$font-size-h3: 32px;
$font-size-h4: 24px;
$font-size-h5: 20px;
$font-size-h6: 18px;
$font-size-base: 14px;
$font-size-big: 16px;
$font-size-bigger: 18px;
$font-size-small: 12px;
$font-size-smaller: 10px;

$font-size-h1-mobile: 48px;
$font-size-h2-mobile: 38px;
$font-size-h3-mobile: 28px;
$font-size-h4-mobile: 22px;
$font-size-h5-mobile: 18px;
$font-size-h6-mobile: 16px;
$font-size-base-mobile: 12px;
$font-size-big-mobile: 14px;
$font-size-bigger-mobile: 16px;
$font-size-small-mobile: 10px;
$font-size-smaller-mobile: 9px;

$line-size-h1: 64px;
$line-size-h2: 48px;
$line-size-h3: 32px;
$line-size-h4: 24px;
$line-size-h5: 20px;
$line-size-h6: 18px;
$line-size-base: 14px;
$line-size-big: 16px;
$line-size-bigger: 18px;
$line-size-small: 12px;
$line-size-smaller: 10px;

$line-size-h1-mobile: 48px;
$line-size-h2-mobile: 38px;
$line-size-h3-mobile: 28px;
$line-size-h4-mobile: 22px;
$line-size-h5-mobile: 18px;
$line-size-h6-mobile: 16px;
$line-size-base-mobile: 12px;
$line-size-big-mobile: 14px;
$line-size-bigger-mobile: 16px;
$line-size-small-mobile: 10px;
$line-size-smaller-mobile: 9px;

$letter-spacing-std: 0.12px;

$text-underline: underline;
