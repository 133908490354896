@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/border-radius.scss';

.p-skeleton-product-list-infinite-scroll {
	&.first-group {
		margin-top: spacing.$spacing-2x;
	}

	& .p-skeleton-product-card {
		height: 339px;
		width: 100%;
		position: relative;

		& .p-skeleton-product-card-title {
			position: absolute;
			left: 16px;
			bottom: 16px;
			height: 21px;
			width: 50%;
			background-color: colors.$color-gray-medium;
			border-radius: border-radius.$radius-medium;
		}
	}
	&.expanded {
		display: grid;
		grid-template-columns: repeat(3, minmax(auto, 1fr));
		grid-template-rows: repeat(3, minmax(auto, 1fr));
		width: 100%;
		row-gap: 40px;
		column-gap: 24px;
		padding: 12px;
	}
	&.collapsed {
		display: grid;
		grid-template-columns: repeat(4, minmax(auto, 1fr));
		grid-template-rows: repeat(3, minmax(auto, 1fr));
		width: 100%;
		row-gap: 40px;
		column-gap: 24px;
		padding: 12px;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.p-skeleton-product-list-infinite-scroll {
		&.collapsed {
			grid-template-columns: repeat(3, minmax(auto, 1fr));
			padding: 12px 12px 12px 6px;
			row-gap: 28px;
			column-gap: 16px;
		}
		&.expanded {
			grid-template-columns: repeat(3, minmax(auto, 1fr));
			padding: 12px 12px 12px 6px;
			row-gap: 28px;
			column-gap: 16px;
		}
	}
}
@media (min-width: breakpoints.$large-screen-breakpoint) {
	.p-skeleton-product-list-infinite-scroll {
		&.collapsed {
			grid-template-columns: repeat(5, minmax(auto, 1fr));
			padding: 12px 12px 12px 6px;
		}
		&.expanded {
			grid-template-columns: repeat(4, minmax(auto, 1fr));
			padding: 12px 12px 12px 6px;
		}
	}
}
@media (max-width: 1080px) {
	.p-skeleton-product-list-infinite-scroll {
		&.collapsed {
			grid-template-columns: repeat(3, minmax(auto, 1fr));
			padding: 12px 12px 12px 6px;
		}
	}
}
@media (min-width: 500px) and (max-width: breakpoints.$mobile-breakpoint) {
	.p-skeleton-product-list-infinite-scroll {
		&.expanded {
			grid-template-columns: repeat(3, minmax(auto, 1fr)) !important;
			padding: 12px 12px 12px 6px;
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.p-skeleton-product-list-infinite-scroll {
		grid-template-columns: repeat(2, minmax(auto, 1fr)) !important;
		padding: 12px 12px 12px 6px;
		&.expanded {
			row-gap: 16px;
		}
	}
}
@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1200px) {
	.p-skeleton-product-list-infinite-scroll {
		&.collapsed {
			grid-template-columns: repeat(3, minmax(auto, 1fr));
			padding: 12px 12px 12px 6px;
		}
		&.expanded {
			grid-template-columns: repeat(2, minmax(auto, 1fr));
			padding: 12px 12px 12px 6px;
			& .p-skeleton-product-card {
				width: 98.83%;
				max-width: 320px;
			}
		}
	}
}
@media ((min-width: 1500px) and (max-width: 1807px)) or ((min-width: 1200px) and (max-width: 1454px)) or ((min-width: 993px) and (max-width: 1088px)) {
	.p-skeleton-product-list-infinite-scroll {
		&.collapsed {
			display: grid;
			grid-template-columns: repeat(4, minmax(auto, 1fr));
			grid-template-rows: repeat(3, minmax(auto, 1fr));
			width: 100%;
			row-gap: 40px;
			column-gap: 24px;
			padding: 12px;
		}
	}
}
