@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
#popup-content {
	margin: auto;
	width: 613px;
	height: fit-content;
	& .open {
		border: none;
		border-radius: border-radius.$radius-medium;
	}
	& .score-container .score-collapsed {
		height: 78px;
		display: flex;
		align-items: center;
		& .score-img {
			margin-left: 86px;
		}
		& .score-label {
			margin-left: 11px;
			font-family: fonts.$tertiary-font-family;
			font-size: fonts.$font-size-big;
		}
		& .score-circle {
			margin-right: 45px;
		}
	}
}
.score-wrapper {
	overflow-y: scroll;
	max-height: 100vh;
	&::-webkit-scrollbar {
		background: transparent;
		width: 4px;
	}
}
#close-popup {
	position: relative;
	top: 34px;
	left: 95%;
	cursor: pointer;
	z-index: 10;
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	#popup-content {
		margin-top: auto !important;
		width: 100% !important;
		height: 100% !important;
		overflow: scroll;
		max-width: 390px;
		& .score-container {
			width: 100%;
			.score-collapsed {
				height: 64px;

				& .score-img {
					margin-left: 50px !important;
				}
				& .score-label {
					margin-left: 10px !important;
					margin-right: 64px;
					font-family: fonts.$secondary-font-family;
					font-size: fonts.$font-size-small;
				}
				& .score-circle {
					margin-right: 10px !important;
				}
			}
		}
	}
	#close-popup {
		left: 93%;
		top: 30px;
	}
	.popup-content {
		margin-top: 0px !important;
		overflow: scroll;
		& ::-webkit-scrollbar {
			display: none;
		}
	}
}
