@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/opacity.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';

.bob-error-container {
	display: flex;
	flex-direction: column;
	padding: spacing.$spacing-7x;
	.bob-error-title {
		margin-bottom: 0px;
		text-align: center;
		font-family: fonts.$tertiary-font-family;
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-h4;
		line-height: 38.4px;
	}
	.bob-error-body {
		margin-top: spacing.$spacing-4x;
		margin-bottom: 0px;
		text-align: center;
		font-family: fonts.$primary-font-family;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-bigger;
		line-height: 28.8px;
		color: colors.$color-gray-slate;
		a {
			color: colors.$color-gray-slate;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.bob-error-container {
		padding: spacing.$spacing-x;
		.bob-error-title {
			font-size: fonts.$font-size-h5;
			line-height: 32px;
		}
		.bob-error-body {
			margin-top: spacing.$spacing-3x;
			font-size: fonts.$font-size-big;
			line-height: 28.8px;
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.bob-error-container {
		padding: spacing.$spacing-2x;
		.bob-error-title {
			font-size: fonts.$font-size-base-mobile;
			line-height: 19.2px;
		}
		.bob-error-body {
			margin-top: spacing.$spacing-3x;
			font-size: fonts.$font-size-base-mobile;
			line-height: 19.2px;
		}
	}
}
