@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
.header {
	grid-area: header;
	text-align: justify;
	position: relative;
	z-index: 101;
}
#iribbon-container {
	background: colors.$color-gray-darker;
	color: colors.$color-white;
	font-size: 13px;
	font-weight: normal;
	line-height: 18px;
	min-height: 35px;
	position: relative;
}
#iribbon-container nav ul {
	list-style: outside none none;
	margin: 0;
	padding: 1em 0;
}
#iribbon-container nav ul li {
	list-style: outside none none;
	margin: 0;
	padding: 1em 0;
}
#iribbon-container ul,
#iribbon-container ol {
	margin: 0 0 20px 20px;
}
#iribbon-container a {
	color: colors.$color-white;
	font-weight: normal;
	opacity: unset;
	padding: 1px 0 0 0;
}
#iribbon-container p {
	font-family: Arial, Helvetica, sans-serif;
}

#iribbon-title {
	background: url(../../../assets/images/iribbon-logo.gif) no-repeat center top;
	border-radius: 0;
	border: none;
	bottom: 0;
	box-shadow: none;
	cursor: pointer;
	display: block;
	height: 16px;
	left: 50%;
	margin: 10px auto 10px -46px;
	padding-top: 0;
	position: absolute;
	text-indent: -999em;
	transition: none;
	width: 92px;
}
#iribbon-detail {
	margin: 0 auto;
	max-width: 1180px;
	overflow: hidden;
	padding: 0 5px 2em;
	transition: max-height 0.8s ease;
}
#iribbon-detail.ribbon-hide {
	display: none;
	max-height: 0px;
}
#iribbon-detail.ribbon-show {
	display: block;
	max-height: 400px;
}

#iribbon-detail {
	padding-bottom: 2em;
}
#iribbon-detail.ribbon-hide {
	display: none;
	max-height: 0px;
}
#iribbon-detail.ribbon-show {
	display: block;
	max-height: 400px;
}
#iribbon-detail p {
	margin: 0;
	padding: 0;
}

#iribbon-detail ul {
	border-bottom: none;
	display: inline-block;
	list-style: outside none none;
	margin: 0;
	padding: 0;
}
#iribbon-detail ul li {
	display: inline-block;
	line-height: 1em;
	list-style: outside none none;
	margin: 0;
	padding: 0 8px 0 0;
}
#iribbon-detail ul li:last-child {
	border: 0 none;
}
#iribbon-detail ul li a {
	border-left: 1px solid #a5acaf;
	padding-left: 10px;
	text-decoration: fonts.$text-underline;
	text-transform: uppercase;
}
#iribbon-detail ul li.iribbon-mainlink {
	border: 0 none;
	padding-left: 0;
}
#iribbon-detail ul li.iribbon-mainlink a {
	border: none;
	padding-left: 0px;
	text-transform: none;
}

#iribbon-left {
	float: left;
	padding: 25px 15px 5px 15px;
	width: 100%;
}
#iribbon-left p {
	margin-bottom: 10px;
}
#iribbon-left {
	float: left;
	margin: 40px 0;
	max-width: 590px;
	padding: 0 15px;
	width: 50%;
}

#iribbon-right {
	float: left;
	padding: 25px 5px 15px 15px;
	width: 100%;
}
#iribbon-right {
	border-left: 1px solid #a5acaf;
	float: left;
	float: right;
	margin: 40px 0;
	max-width: 590px;
	padding: 2% 15px 0px 15px;
	width: 50%;
}

#iribbon-title.active {
	background-position: center bottom;
}
