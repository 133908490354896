@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/spacing.scss';

@keyframes scrolling {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}

.brand-bussiness-profile-container {
	display: flex;
	flex-direction: column;
	padding-top: 41px;

	& .back-container {
		display: flex;
		cursor: pointer;

		& .back-page-text {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-base;
			line-height: 22px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
			padding-left: 6px;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	& .share-container {
		position: absolute;
		z-index: 2;
		right: 16px;
		top: 16px;
		border-radius: border-radius.$radius-circle;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: shadows.$shadow-medium;
		cursor: pointer;
	}

	& .contact-container {
		margin-top: spacing.$spacing-7x;

		& .separator {
			width: 100%;
			height: 1px;
			background-color: colors.$color-background-gray;
		}

		& .brand-contact-title-container {
			& .brand-contact-title {
				font-family: fonts.$secondary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-big;
				line-height: 28px;
				letter-spacing: 0.0075em;
				color: colors.$color-primary;
			}
		}
	}

	.container-offerings {
		background-color: colors.$color-gray-light;
		padding: spacing.$spacing-2x spacing.$spacing-x spacing.$spacing-3x spacing.$spacing-x;
		margin-top: spacing.$spacing-2x;
		margin-left: -8px;

		.brand-info-title {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-bigger;
			line-height: 28px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-primary;
		}

		.container-options-offerings {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			margin-top: spacing.$spacing-x;

			.offering {
				display: flex;
				flex-direction: column;

				.title {
					font-family: fonts.$tertiary-font-family;
					font-size: fonts.$font-size-big;
					font-weight: fonts.$font-weight-semibold;
					line-height: 28.8px;
					letter-spacing: 0.0075em;
					text-align: left;
				}

				.options {
					margin-block-start: 0px;
					list-style-type: disc;
					padding-inline-start: 26px;
					margin-block-end: 0px;

					li {
						font-family: fonts.$primary-font-family;
						font-size: fonts.$font-size-big;
						font-weight: fonts.$font-weight-regular;
						line-height: 25.6px;
						letter-spacing: 0.0075em;
						text-align: left;
					}
				}
			}
		}
	}

	.clients-container {
		margin-top: spacing.$spacing-7x;

		.title {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-bigger;
			line-height: 28px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-primary;
		}

		.images-container {
			display: flex;
			gap: 32px;
			overflow: hidden;
			flex-wrap: wrap;
			justify-content: space-evenly;

			.image-wrapper {
				aspect-ratio: 4 / 3;

				img {
					max-width: 200px;
					height: 100%;
					width: 100%;
					object-fit: contain;
				}
			}
		}
	}

	.text-container {
		position: relative;
		display: flex;
		max-width: 90vw;
		flex-direction: column;
		margin: spacing.$spacing-7x auto 0px auto;
		background: colors.$color-gray-light;
		padding: spacing.$spacing-x spacing.$spacing-2x;

		&:before {
			content: '';
			background-image: url('../../assets/images/quotes_init.png');
			height: 50px;
			width: 65px;
			position: absolute;
			top: 48px;
			left: 24px;
			background-repeat: no-repeat;
			aspect-ratio: 16/9;
		}

		&:after {
			content: '';
			background-image: url('../../assets/images/quotes_end.png');
			height: 50px;
			width: 65px;
			position: absolute;
			bottom: 55px;
			right: 35px;
			background-repeat: no-repeat;
			aspect-ratio: 16/9;
		}

		.title {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-bigger;
			line-height: 28px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-primary;
			margin-bottom: 20px;
		}

		.slider-container {
			height: auto;
			margin: auto;
			max-width: 100%;
			position: relative;

			.swiper-slide {
				background: transparent;
				// height: 245px;
				display: flex;
				flex-direction: column;
				text-align: left;
			}
			.swiper-content {
				padding: 0 5% spacing.$spacing-3x 5%;
				.description,
				.author {
					font-family: fonts.$tertiary-font-family;
					font-size: fonts.$font-size-big;
					font-weight: fonts.$font-weight-regular;
					line-height: 28.8px;
					letter-spacing: 0.0075em;
				}
				.author {
					margin-top: spacing.$spacing-1_5x;
				}
			}

			& .swiper-button-prev {
				align-items: center;
				background: colors.$color-gray;
				border-radius: border-radius.$radius-biggest;
				border: none;
				color: inherit;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				font: inherit;
				gap: 4px;
				height: 26px;
				justify-content: center;
				margin-left: -56px;
				outline: inherit;
				padding: spacing.$spacing-half-x;
				width: 26px;
				&::before {
					content: '\ea64';
					font-family: remixicon !important;
					font-size: fonts.$font-size-base;
					color: colors.$color-black;
					font-weight: fonts.$font-weight-bold;
					position: absolute;
				}
				&::after {
					content: '';
				}

				&:hover {
					filter: drop-shadow(shadows.$shadow-low);
				}

				&:active {
					box-shadow: shadows.$shadow-high-inner;
				}
			}

			& .swiper-button-next {
				align-items: center;
				background: colors.$color-gray;
				border-radius: border-radius.$radius-biggest;
				border: none;
				color: inherit;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				font: inherit;
				gap: 4px;
				height: 26px;
				justify-content: center;
				margin-right: -56px;
				outline: inherit;
				padding: spacing.$spacing-half-x;
				width: 26px;

				&::before {
					content: '\ea6e';
					font-family: remixicon !important;
					font-size: fonts.$font-size-big;
					color: colors.$color-black;
					font-weight: fonts.$font-weight-bold;
					position: absolute;
				}

				&::after {
					content: '';
				}

				&:hover {
					filter: drop-shadow(shadows.$shadow-low);
				}

				&:active {
					box-shadow: shadows.$shadow-high-inner;
				}
			}

			.swiper-custom-pagination-for-brand {
				text-align: center;
				display: block;
				margin-top: 30px;
				position: relative;
				width: 80%;
				height: 24px;
				z-index: 999;

				.swiper-pagination-bullet {
					background: #bec2c5;
					opacity: 1;
				}

				.swiper-pagination-bullet-active {
					background: #5c666f;
					opacity: 1;
				}
			}

			.control-mobile-for-brand {
				position: static;
				display: flex;
				width: 100%;
				margin-top: -37px;
				justify-content: space-between;

				.bi-chevron-left {
					width: 24px;
					height: 24px;
					font-size: fonts.$font-size-big;
					display: flex;
					align-items: center;
					left: spacing.$spacing-2x;
					position: inherit;

					&::before {
						color: colors.$color-black !important;
						font-weight: fonts.$font-weight-bold !important;
					}
				}

				.bi-chevron-right {
					width: 24px;
					height: 24px;
					font-size: fonts.$font-size-big;
					display: flex;
					align-items: center;
					right: 0;
					position: inherit;

					&::before {
						color: colors.$color-black !important;
						font-weight: fonts.$font-weight-bold !important;
					}
				}
			}
		}
	}
}

@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: breakpoints.$desktop-breakpoint) {
	.container-offerings {
		.container-options-offerings {
			row-gap: spacing.$spacing-4x;
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.brand-bussiness-profile-container {
		padding-top: spacing.$spacing-3x;
		margin: 0px spacing.$spacing-2x 94px spacing.$spacing-2x;

		.brand-products {
			height: spacing.$spacing-5x;

			.brand-products-link {
				margin-left: spacing.$spacing-5x;
			}

			.products-carousel-container {
				margin-left: calc(7vw - spacing.$spacing-2x);
				width: 86vw !important;

				.product-card {
					width: 193px;
					max-height: 240px;

					& .product-image {
						margin-top: 0px;
						width: 150px;
						height: 120px;

						& > img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						& .error-place-holder {
							margin: auto;
							width: 86px;
							height: 86px;
						}
					}

					& .labels-container {
						margin-top: 0px;

						& > .expo-label:only-child {
							margin-top: spacing.$spacing-4x !important;
						}
					}

					& .product-favorite {
						right: 4px;
					}

					& .product-info {
						margin: spacing.$spacing-2x spacing.$spacing-1_5x spacing.$spacing-x spacing.$spacing-2x;
					}
				}

				.swiper-button-prev {
					margin-left: -32px;
				}

				.swiper-button-next {
					margin-right: -42px;
				}
			}
		}

		& .contact-container {
			margin-top: spacing.$spacing-7x;
			width: 98%;

			& .contact-information-container {
				& .contact-information-item-container {
					padding: spacing.$spacing-half-x 0px spacing.$spacing-half-x spacing.$spacing-4x;
				}
			}
		}
		.text-container {
			.slider-container {
				.swiper-content {
					padding: 0 0 spacing.$spacing-3x 0;
				}
			}
		}
	}

	.container-offerings {
		.container-options-offerings {
			row-gap: spacing.$spacing-4x;
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.brand-bussiness-profile-container {
		padding-top: spacing.$spacing-4x;
		margin: 0 spacing.$spacing-2x;

		& .brand-profile {
			& .brand-profile-name {
				padding-right: spacing.$spacing-2x;

				& .brand-name {
					font-size: fonts.$font-size-h4;
					line-height: 33px;
				}
			}

			& .brand-information {
				flex-direction: column;
				justify-content: center;
				margin-top: spacing.$spacing-2x;

				& .brand-image {
					& .image-container {
						margin: auto;
						width: 227px;
						padding: spacing.$spacing-1_5x;
					}
				}
			}

			& .brand-information {
				& .brand-attributes-container {
					padding-left: 0px;
					margin: spacing.$spacing-4x 0px 0px 0px;
					overflow: hidden;

					& > :first-child {
						width: fit-content;
					}

					& .brand-attributes {
						& .attributes-container {
							padding-bottom: 20px;
							grid-template-columns: repeat(auto-fit, minmax(133px, 1fr));
							background-size: 90px 109px;

							& > div {
								height: 28px;
								margin-top: 2px;
							}
						}
					}
				}
			}

			& .brand-details {
				flex-direction: column;
				margin: spacing.$spacing-5x spacing.$spacing-2x 0px 0px;
				width: 100%;

				& .contact-information-container {
					margin-left: 0;

					& .cards-container {
						margin-top: spacing.$spacing-x;
					}
				}

				& .mission-container {
					&.no-card {
						flex-direction: column;
						gap: 0px;
					}

					& .brand-mission {
						font-size: fonts.$font-size-small;
						line-height: 19px;
						padding-bottom: 18px;
					}

					& .brand-story {
						font-size: fonts.$font-size-small;
						line-height: 19px;
						padding-bottom: 18px;
					}

					& .brand-info-title {
						font-size: fonts.$font-size-big;
						line-height: 26px;
					}
				}
			}
		}

		& .share-container {
			right: 8px;
			top: 8px;
		}

		& .brand-products {
			height: 350px;

			& .brand-products-link {
				font-size: fonts.$font-size-big !important;

				& .ri-arrow-right-line {
					font-size: fonts.$font-size-h5 !important;
				}
			}

			& .products-carousel-container {
				margin-left: 0 !important;

				& .swiper-custom-pagination-for-brand {
					text-align: center;
					display: block;
					margin-top: 30px;
					position: relative;
					width: 80%;

					z-index: 999;

					.swiper-pagination-bullet {
						background: #bec2c5;
						opacity: 1;
					}

					.swiper-pagination-bullet-active {
						background: #5c666f;
						opacity: 1;
					}
				}

				& .control-mobile-for-brand {
					position: relative;
					display: flex;
					width: 100%;
					margin-top: -33px;
					justify-content: space-between;

					.bi-chevron-left {
						width: 24px;
						height: 24px;
						font-size: fonts.$font-size-big;
						display: flex;
						align-items: center;
						left: spacing.$spacing-2x;
						position: inherit;

						&::before {
							color: colors.$color-black !important;
							font-weight: fonts.$font-weight-bold !important;
						}
					}

					.bi-chevron-right {
						width: 24px;
						height: 24px;
						font-size: fonts.$font-size-big;
						display: flex;
						align-items: center;
						right: 0;
						position: inherit;

						&::before {
							color: colors.$color-black !important;
							font-weight: fonts.$font-weight-bold !important;
						}
					}
				}

				.product-card {
					& .labels-container {
						& > .expo-label:only-child {
							margin-top: 20px;

							&:not(.newtopia) {
								width: 63px;
							}
						}

						.how-good-btn {
							line-height: 1px;

							span {
								line-height: 1px;
								display: flex;
								align-items: center;
								justify-content: center;
							}

							i {
								line-height: 1px;
								margin-top: 0;
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		& .contact-container {
			margin-top: spacing.$spacing-5x;

			&.short-products-wrapper {
				margin-top: 0px;
			}
		}

		.text-container {
			margin-top: spacing.$spacing-5x;
			padding: spacing.$spacing-x;
			.slider-container {
				.swiper-content {
					padding: spacing.$spacing-x;
				}
				.swiper-custom-pagination-for-brand {
					margin-top: spacing.$spacing-x;
				}
			}
		}
	}
}

@media (min-width: 700px) and (max-width: breakpoints.$mobile-breakpoint) {
	.container-offerings {
		margin-top: spacing.$spacing-2x;

		.container-options-offerings {
			justify-content: space-evenly;
			row-gap: spacing.$spacing-2x;
		}
	}

	.brand-bussiness-profile-container {
		.text-container {
			.slider-container {
				.swiper-custom-pagination-for-brand {
					width: 40%;
				}
				.control-mobile-for-brand {
					width: 50%;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 699px) {
	.container-offerings {
		margin-top: spacing.$spacing-2x;

		.container-options-offerings {
			justify-content: flex-start;
			row-gap: spacing.$spacing-2x;
			column-gap: spacing.$spacing-4x;
		}
	}
}

@media (min-width: 601px) and (max-width: breakpoints.$mobile-breakpoint) {
	.brand-bussiness-profile-container {
		& .contact-container {
			& .contact-information-container {
				gap: 8px;

				& .contact-information-item-container {
					padding: spacing.$spacing-x 0px spacing.$spacing-x spacing.$spacing-4x;
					row-gap: 0;
				}
			}
		}
	}

	@media (max-width: 710px) {
		.brand-bussiness-profile-container {
			& .contact-container {
				& .contact-information-container {
					width: 92%;
				}
			}
		}
	}
}

@media (min-width: 800px) and (max-width: 1024px) {
	.brand-bussiness-profile-container {
		& .contact-container {
			& .contact-information-container {
				max-width: unset;
				width: 94%;
			}
		}
	}

	@media (max-width: 820px) {
		.brand-bussiness-profile-container {
			& .contact-container {
				& .contact-information-container {
					max-width: unset;
					width: 92%;
				}
			}
		}
	}
}
