@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/spacing.scss';

.brand-profile {
	margin-bottom: auto;

	.brand-profile-name {
		margin-top: spacing.$spacing-3x;
		display: flex;
		width: 100%;

		& .brand-name {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h1-mobile;
		}
	}

	.brand-information {
		display: flex;
		margin-top: spacing.$spacing-4x;

		& .brand-image {
			flex: 1;

			& .image-container {
				width: 259px;
				padding: spacing.$spacing-2x;
				box-shadow: shadows.$shadow-brand-image;
				border-radius: border-radius.$radius-brand-image;
				height: 233px;
				position: relative;

				& > img {
					object-fit: contain;
					width: 100%;
					height: 201px;
				}
			}
		}

		& .brand-attributes-container {
			flex: 4;
			padding-left: 42px;
			display: flex;
			flex-wrap: wrap;

			& > :first-child {
				margin: 0px spacing.$spacing-x 0px 0px;
				width: fit-content;
			}

			& .brand-attributes {
				flex: 1 0 100%;

				& .attributes-container {
					display: grid;
					align-items: center;
					grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
					background-image: linear-gradient(
						180deg,
						colors.$color-background-white,
						50%,
						colors.$color-background-secondary-lightest 50%
					);
					background-repeat: repeat;
					background-size: 90px 101px;
					row-gap: 24px;

					& > div {
						height: 45px;
						min-width: 134px;
						margin-top: -11px;
					}
				}
			}
		}
	}

	.brand-details {
		margin-top: spacing.$spacing-7x;
		display: flex;
		flex-wrap: wrap;
		width: 60%;

		& .mission-container {
			flex: 3;
			display: flex;
			flex-direction: column;

			&.no-card {
				flex-direction: row;
				gap: 56px;
			}

			& .mission-item {
				width: 100%;
			}

			& .brand-mission {
				font-family: fonts.$primary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				letter-spacing: fonts.$letter-spacing-std;
				color: colors.$color-text-black;
				display: flex;
				align-items: center;
				text-align: left;
			}

			& .brand-story {
				font-family: fonts.$primary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				letter-spacing: fonts.$letter-spacing-std;
				color: colors.$color-text-black;
				display: flex;
				align-items: center;
				text-align: left;
			}
		}

		& .brand-info-title {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-bigger;
			line-height: 28px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-primary;
		}

		& .contact-information-container {
			flex: 2;
			display: flex;
			flex-direction: column;
			margin-left: 90px;

			& .cards-container {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
		}
	}

	.container-slider-certifications {
		margin-left: -6%;
		margin-right: 0;
		overflow: hidden;
		margin-top: spacing.$spacing-7x;
		.slider-certifications {
			background: colors.$certifications-gradient;
			height: 60px;
			width: 100%;
			display: flex;
			align-items: center;
			padding: 0 spacing.$spacing-x;
			overflow: hidden;
			.group {
				display: flex;
				gap: 40px;
				padding-right: 20px;
				will-change: transform;
				animation: scrolling 80s linear infinite;
				flex: 0 0 100%;
				.attribute {
					flex-shrink: 0;
					background: colors.$color-tag-certification;
					border-radius: 24px;
					padding: 0 spacing.$spacing-2x;
					font-family: fonts.$tertiary-font-family;
					font-size: fonts.$font-size-base;
					font-weight: fonts.$font-weight-regular;
					line-height: 19.2px;
					letter-spacing: 0.0075em;
					color: white;
					display: flex;
					align-items: center;
					height: 28px;
				}
			}
		}
	}

	.demo-link-container {
		display: flex;
		flex-direction: column;
		margin-top: spacing.$spacing-4x;
		.item {
			display: flex;
			align-items: center;
			gap: 8px;
			i {
				color: colors.$color-brand-links;
				font-size: 24px;
			}
			.attribute-link {
				font-family: fonts.$primary-font-family;
				font-size: fonts.$font-size-base;
				font-weight: fonts.$font-weight-regular;
				line-height: 22.4px;
				letter-spacing: 0.0075em;
				color: black;
				text-decoration: underline;
			}
		}
		&.mobile {
			display: none;
		}
	}
}

@media (min-width: 1394px) {
	.brand-profile {
		& .brand-information {
			& .brand-attributes-container {
				& .brand-attributes {
					& .attributes-container {
						padding-bottom: 25px;

						& > div {
							height: 30px;
							min-width: 134px;
							margin-top: -1px;
						}
					}
				}
			}
		}

		& .brand-details {
			display: flex;
			flex-wrap: wrap;
		}
	}
}

@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: breakpoints.$desktop-breakpoint) {
	.brand-profile {
		margin-bottom: 0px;

		& .brand-profile-name {
			& .brand-name {
				font-size: fonts.$font-size-bigger;
				line-height: 25px;
			}
		}

		& .brand-information {
			& .brand-attributes-container {
				padding-left: spacing.$spacing-3x;
				margin-right: 55px;

				& > :nth-child(2) {
					flex: 0 0 fit-content;

					&:hover {
						flex: 0 0 fit-content;
					}
				}

				& .brand-attributes {
					& .attributes-container {
						grid-template-columns: repeat(3, minmax(100px, 1fr));
						background-size: 90px 110px;

						& > :nth-child(1),
						& > :nth-child(2),
						& > :nth-child(3) {
							margin-top: 0px;
						}

						& > :nth-child(4),
						& > :nth-child(5),
						& > :nth-child(6) {
							margin-top: -10px;
							margin-bottom: 0px;
						}

						& > :nth-child(7),
						& > :nth-child(8),
						& > :nth-child(9) {
							margin-top: -15px;
						}

						& > :nth-child(10),
						& > :nth-child(11),
						& > :nth-child(12) {
							margin-top: -12px;
							margin-bottom: spacing.$spacing-1_25x;
						}
					}
				}
			}
		}

		& .brand-details {
			flex-direction: column;
			margin-right: 75px;

			& .contact-information-container {
				margin-left: 0;

				& .cards-container {
					flex-direction: column;
				}
			}

			& .mission-container {
				& .brand-mission {
					padding-bottom: spacing.$spacing-4x;
				}

				& .brand-story {
					padding-bottom: spacing.$spacing-4x;
				}

				& .brand-info-title {
					font-size: fonts.$font-size-big;
					line-height: 26px;
				}
			}
		}

		.container-slider-certifications {
			.slider-certifications {
				height: 52px;
				.group {
					.attribute {
						font-size: fonts.$font-size-small;
						line-height: 19.2px;
						letter-spacing: 0.0075em;
						height: 24px;
					}
				}
			}
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.brand-profile {
		margin-bottom: 0px;
		& .brand-profile-name {
			& .brand-name {
				font-size: fonts.$font-size-bigger;
				line-height: 25px;
			}
		}

		& .brand-information {
			width: 98%;
			padding-left: spacing.$spacing-1_5x;

			& .brand-image {
				& .image-container {
					display: flex;
					align-items: center;
					width: 227px;
					padding: spacing.$spacing-1_5x;
					& > img {
						width: 100%;
					}
				}
			}

			& .brand-attributes-container {
				padding-left: spacing.$spacing-3x;
				margin-right: 55px;
				width: 90%;

				& > :nth-child(2) {
					flex: 0 0 fit-content;

					&:hover {
						flex: 0 0 fit-content;
					}
				}

				& .brand-attributes {
					& .attributes-container {
						background-size: 90px 110px;
						grid-template-columns: repeat(3, minmax(150px, 1fr));
						row-gap: 30px;

						& > :nth-child(1),
						& > :nth-child(2),
						& > :nth-child(3) {
							margin-top: 0px;
						}

						& > :nth-child(4),
						& > :nth-child(5),
						& > :nth-child(6) {
							margin-top: -13px;
							margin-bottom: 0px;
						}

						& > :nth-child(7),
						& > :nth-child(8),
						& > :nth-child(9) {
							margin-top: -23px;
							margin-bottom: 0px;
						}

						& > :nth-child(10),
						& > :nth-child(11),
						& > :nth-child(12) {
							margin-top: -18px;
							margin-bottom: 9px;
						}

						& > div {
							min-width: 160px;
						}
					}
				}
			}
		}

		& .brand-details {
			flex-direction: column;
			margin-right: 75px;
			width: 92%;
			margin-top: spacing.$spacing-7x;

			& .contact-information-container {
				margin-left: 0;

				& .cards-container {
					flex-direction: column;
				}
			}

			& .mission-container {
				&.no-card {
					flex-direction: column;
					gap: 0px;
				}

				& .brand-mission {
					padding-bottom: spacing.$spacing-4x;
				}

				& .brand-story {
					padding-bottom: spacing.$spacing-4x;
				}

				& .brand-info-title {
					font-size: fonts.$font-size-big;
					line-height: 26px;
				}
			}
		}

		.container-slider-certifications {
			margin-left: -16px;
			margin-right: 0;
			margin-top: spacing.$spacing-7x;
			.slider-certifications {
				height: 52px;
				.group {
					gap: spacing.$spacing-2x;
					.attribute {
						font-size: fonts.$font-size-small;
						line-height: 19.2px;
						letter-spacing: 0.0075em;
						height: 24px;
					}
				}
			}
		}

		.demo-link-container {
			&:not(.mobile) {
				display: none;
			}
			&.mobile {
				display: flex;
				margin-top: spacing.$spacing-4x;
				padding-left: 12px;
			}
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.brand-profile {
		& .brand-profile-name {
			padding-right: spacing.$spacing-2x;

			& .brand-name {
				font-size: fonts.$font-size-h4;
				line-height: 33px;
			}
		}

		& .brand-information {
			flex-direction: column;
			justify-content: center;
			margin-top: spacing.$spacing-2x;

			& .brand-image {
				& .image-container {
					margin: auto;
					width: 227px;
					padding: spacing.$spacing-1_5x;
				}
			}
			& .brand-attributes-container {
				padding-left: 0px;
				margin: spacing.$spacing-4x 0px 0px 0px;
				overflow: hidden;

				& > :first-child {
					width: fit-content;
				}

				& .brand-attributes {
					& .attributes-container {
						padding-bottom: 20px;
						grid-template-columns: repeat(auto-fit, minmax(133px, 1fr));
						background-size: 90px 109px;

						& > div {
							height: 28px;
							margin-top: 2px;
						}
					}
				}
			}
		}

		& .brand-details {
			flex-direction: column;
			margin: spacing.$spacing-5x spacing.$spacing-2x 0px 0px;

			& .contact-information-container {
				margin-left: 0;

				& .cards-container {
					margin-top: spacing.$spacing-x;
				}
			}

			& .mission-container {
				&.no-card {
					flex-direction: column;
					gap: 0px;
				}

				& .brand-mission {
					font-size: fonts.$font-size-small;
					line-height: 19px;
					padding-bottom: 18px;
				}

				& .brand-story {
					font-size: fonts.$font-size-small;
					line-height: 19px;
					padding-bottom: 18px;
				}

				& .brand-info-title {
					font-size: fonts.$font-size-big;
					line-height: 26px;
				}
			}
		}

		.container-slider-certifications {
			margin-left: -16px;
			margin-right: 0;
			margin-top: spacing.$spacing-5x;
			.slider-certifications {
				height: 52px;
				.group {
					gap: spacing.$spacing-x;
					align-items: center;
					img {
						height: 14px;
						width: 14px;
					}
					.attribute {
						font-size: fonts.$font-size-smaller;
						line-height: 19.2px;
						letter-spacing: 0.0075em;
						height: 24px;
					}
				}
			}
		}

		.demo-link-container {
			&:not(.mobile) {
				display: none;
			}
			&.mobile {
				display: flex;
				margin-top: spacing.$spacing-5x;
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 392px) {
	.brand-profile {
		& .brand-information {
			& .brand-attributes-container {
				& .brand-attributes {
					& .attributes-container {
						padding-bottom: 25px;

						& > div {
							height: 28px;
							min-width: 134px;
							margin-top: 2px;
						}
					}
				}
			}
		}

		& .brand-details {
			display: flex;
			flex-wrap: wrap;
		}
	}
}
