@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/fonts.scss';
.marketing-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	margin: 8px auto auto 2%;
	& .marketing-title {
		font-family: fonts.$primary-font-family;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-base;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
		font-style: normal;
		line-height: 22px;
		margin-bottom: 8px;
	}
	& .download-assets {
		font-family: fonts.$primary-font-family;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-base;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
		font-style: normal;
		line-height: 22px;
		cursor: pointer;
		display: flex;
		align-items: center;
		& .marketing-title {
			border-bottom: 1.5px solid colors.$color-text-black;
		}
		& > .icon {
			margin: auto auto 7px 7px;
			font-size: fonts.$font-size-big;
		}
	}
}
