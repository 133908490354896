@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';

.product-card {
	display: flex;
	flex-direction: column;
	width: 98.83%;
	height: 339px;
	background: colors.$color-background-white;
	border-radius: border-radius.$radius-medium;
	cursor: pointer;
	filter: drop-shadow(shadows.$shadow-medium);
	min-width: 217px;
	max-width: 320px;
	position: relative;
	text-decoration: none;
	color: unset;
	&:hover {
		color: unset;
		text-decoration: none;
	}

	@keyframes loading-skeleton1 {
		0% {
			background-color: colors.$color-background-gray;
		}

		50% {
			background-color: colors.$color-background-light-gray;
		}

		100% {
			background-color: colors.$color-background-gray;
		}
	}

	.p-card-skeleton {
		background-color: colors.$color-background-gray;
		display: inline-flex;
		line-height: 1;
		position: relative;
		overflow: hidden;
		z-index: 1;
		animation-name: loading-skeleton1;
		animation-duration: 2s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		width: 100%;
		height: 100%;
	}

	& .product-image {
		margin: spacing.$spacing-2x auto spacing.$spacing-x auto;
		width: 100%;
		height: 200px;
		padding: spacing.$spacing-2x spacing.$spacing-2x 0 spacing.$spacing-2x;
		display: flex;
		justify-content: center;
		text-align: center;

		& .error-place-holder {
			margin: auto;
			width: 152px;
			height: 152px;
		}

		& img {
			height: auto;
		}
	}

	& .product-favorite {
		padding: spacing.$spacing-2x spacing.$spacing-2x 0 0;
		position: absolute;
		right: 0px;
		z-index: 2;

		i {
			font-size: fonts.$font-size-h4;
		}

		.red {
			color: colors.$color-error;
		}
	}

	& .product-info {
		margin: spacing.$spacing-x spacing.$spacing-1_5x spacing.$spacing-1_5x spacing.$spacing-2x;
		width: fit-content;
		display: flex;
		flex-direction: column;
		text-decoration: none;
		text-align: left;
		& .brand-name-container {
			width: 100%;
			overflow: hidden;
			& .brand {
				font-family: fonts.$primary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-small;
				line-height: 19px;
				color: colors.$color-background-slate;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
				white-space: pre-wrap;
			}
		}

		& .name-wrapper {
			height: 46px;
			overflow: hidden;
			& .name {
				font-family: fonts.$secondary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				color: colors.$color-text-black;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				white-space: pre-wrap;
			}
		}
	}

	& .product-score {
		font-family: fonts.$secondary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-small;
		line-height: 14px;
		color: colors.$color-text-black;
		margin: 0.42px -5px 0px -6px;
	}

	& .world-icon {
		margin: 0px 0px;
		font-size: fonts.$font-size-base;
	}
	& .labels-container {
		display: flex;
		flex-wrap: wrap;
		margin: auto spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-2x;
		& > :nth-child(1) {
			margin-right: 2%;
		}
		& > :nth-child(2) {
			&:not(.newtopia) {
				width: 73px;
			}
		}
		& > .expo-label:only-child {
			&:not(.newtopia) {
				width: fit-content;
				padding: 0 spacing.$spacing-x;
			}
		}
		&.brand-page-wrapper {
			margin-top: -16px !important;
			& > :nth-child(2) {
				margin-left: 0px;
			}
			& > .expo-label:only-child {
				margin-top: spacing.$spacing-4x;
			}
		}
		&.expo-west-wrapper {
			& > :nth-child(1) {
				margin-right: 2%;
			}
		}
	}
}
//Stack vertically hg scores and expo labels
@media ((min-width: 1500px)) or ((min-width: 1200px) and (max-width: 1499px)) or ((min-width: 993px) and (max-width: 1199px)) {
	.product-card {
		height: 367px;
		& .labels-container {
			margin: auto 5% auto 5%;
			& > .expo-label:only-child {
				margin-top: 22px;
			}
			&.show-hg-wrapper {
				margin: 2px auto auto 5%;
			}
			& .button {
				&.how-good-btn {
					margin-bottom: 30px;
				}
			}
			& .button + .expo-label {
				margin-top: -21px;
				margin-bottom: spacing.$spacing-2x;
			}
		}
	}
}

@media screen and (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.product-card {
		& .product-favorite {
			i {
				font-size: fonts.$font-size-h5;
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.product-card {
		& .product-favorite {
			i {
				font-size: fonts.$font-size-h5;
			}
		}
		& .product-score {
			font-size: fonts.$font-size-small-mobile;
			margin-left: -25px;
			margin-right: -25px;
		}

		& .world-icon {
			font-size: fonts.$font-size-small-mobile;
			margin-bottom: 0;
			margin-left: -5px;
		}
	}
}
