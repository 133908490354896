@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/spacing.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/shadows.scss';

.home-swiper-container {
	display: grid;
	& > div {
		padding: 0px 0px spacing.$spacing-3x 0px;
		width: 100%;
	}
	& .swiper-slide {
		background-color: transparent;
		& div {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 88%;
			height: 215px;
			max-width: 1280px;
			&.newtopia {
				background: url('../../assets/images/home-page-slider/desktop/newtopia.svg') no-repeat;
				background-size: 100% auto;
				background-position: center;
				border-radius: border-radius.$radius-default;
			}
			&.atoz {
				background: url('../../assets/images/home-page-slider/desktop/A2Z.svg') no-repeat;
				background-size: 100% auto;
				background-position: center;
				border-radius: border-radius.$radius-default;
			}
			&.expo-west {
				background: url('../../assets/images/home-page-slider/desktop/expoWest.svg') no-repeat;
				background-size: 100% auto;
				background-position: center;
				border-radius: border-radius.$radius-default;
			}
			&.general {
				background: url('../../assets/images/home-page-slider/desktop/general.svg') no-repeat;
				background-size: 100% auto;
				background-position: center;
				border-radius: border-radius.$radius-default;
			}
			&.private-label {
				background: url('../../assets/images/home-page-slider/desktop/privateLabel.svg') no-repeat;
				background-size: 100% auto;
				background-position: center;
				border-radius: border-radius.$radius-default;
			}
		}
	}
	& .swiper-button-next,
	& .swiper-button-prev {
		align-items: center;
		align-self: stretch;
		background-color: colors.$color-background-gray;
		border-radius: border-radius.$radius-circle;
		border: none;
		color: colors.$color-text-black;
		cursor: pointer;
		display: flex;
		font: inherit;
		height: 38px;
		justify-content: center;
		outline: inherit;
		padding: 0;
		top: 300px;
		width: 38px;
		&:hover {
			filter: drop-shadow(shadows.$shadow-low);
		}
		&:active {
			box-shadow: shadows.$shadow-high-inner;
		}
	}

	& .swiper-button-next {
		right: 2.5%;
	}

	& .swiper-button-prev {
		left: 2.5%;
	}

	& .swiper-button-next:after,
	& .swiper-button-prev:after {
		display: flex;
		justify-content: center;
		align-items: center;
		align-self: stretch;
		width: 38px;
		height: 38px;
		font-size: fonts.$font-size-small;
		color: colors.$color-text-black;
		content: '';
	}

	& .swiper-button-next:after {
		background-image: url('../../assets/images/arrow-right-s.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 22px 22px;
	}

	& .swiper-button-prev:after {
		background-image: url('../../assets/images/arrow-left-s.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 22px 22px;
	}

	& .swiper-pagination-bullets {
		display: block;
		position: relative;
		margin-top: spacing.$spacing-1-5x;
		& .swiper-pagination-bullet {
			width: 12px;
			height: 12px;
			background: colors.$color-background-gray;
			opacity: 1;
			&:hover {
				background: colors.$color-gray-medium;
			}
		}

		& .swiper-pagination-bullet-active {
			background: #8d949a;
		}
	}
}
@media (max-width: breakpoints.$tablet-breakpoint) {
	.home-swiper-container {
		display: grid;
		height: auto;
		& > div {
			padding: 0px 0px spacing.$spacing-3x 0px;
			width: 100%;
		}
		& .swiper-slide {
			& > div {
				height: 109px;
				max-width: 87%;
				width: 100%;
				&.newtopia {
					background: url('../../assets/images/home-page-slider/tablet/newtopia.svg') no-repeat;
					background-size: 100% auto;
					background-position: center;
				}
				&.atoz {
					background: url('../../assets/images/home-page-slider/tablet/A2Z.svg') no-repeat;
					background-size: 100% auto;
					background-position: center;
				}
				&.expo-west {
					background: url('../../assets/images/home-page-slider/tablet/expoWest.svg') no-repeat;
					background-size: 100% auto;
					background-position: center;
				}
				&.general {
					background: url('../../assets/images/home-page-slider/tablet/general.svg') no-repeat;
					background-size: 100% auto;
					background-position: center;
				}
				&.private-label {
					background: url('../../assets/images/home-page-slider/tablet/privateLabel.svg') no-repeat;
					background-size: 100% auto;
					background-position: center;
				}
			}
		}
		& .swiper-button-next,
		& .swiper-button-prev {
			top: 240px;
			width: 26px;
			height: 26px;
		}
		& .swiper-button-next:after,
		& .swiper-button-prev:after {
			width: 26px;
			height: 26px;
		}
		& .swiper-button-next {
			right: 2%;
		}

		& .swiper-button-prev {
			left: 2%;
		}
		& .swiper-pagination {
			position: relative;
			margin-top: spacing.$spacing-2x;
		}
	}
}
@media (max-width: 821px) {
	.home-swiper-container {
		display: grid;
		height: auto;
		& > div {
			padding: 0px 0px spacing.$spacing-3x 0px;
			width: 100%;
		}
		& .swiper-button-next {
			right: 2%;
		}

		& .swiper-button-prev {
			right: 2%;
		}
	}
}
@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1250px) {
	.home-swiper-container {
		& .swiper-slide {
			& > div {
				width: 87%;
			}
		}
	}
}
@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1080px) {
	.home-swiper-container {
		& .swiper-slide {
			& > div {
				width: 86%;
			}
		}
	}
}
@media (min-width: 400px) and (max-width: breakpoints.$mobile-breakpoint) {
	.home-swiper-container {
		width: 98vw;
		display: block;
		& > div {
			width: auto;
		}
		& .swiper-slide {
			& > div {
				height: 109px;
				max-width: 652px;
				width: 90vw;
				margin: auto;
				&.newtopia {
					background: url('../../assets/images/home-page-slider/large-mobile/newtopia.svg')
						no-repeat;
					background-size: 100% 100%;
					background-position: center;
				}
				&.atoz {
					background: url('../../assets/images/home-page-slider/large-mobile/A2Z.svg') no-repeat;
					background-size: 100% 100%;
					background-position: center;
				}
				&.expo-west {
					background: url('../../assets/images/home-page-slider/large-mobile/expoWest.svg')
						no-repeat;
					background-size: 100% 100%;
					background-position: center;
				}
				&.general {
					background: url('../../assets/images/home-page-slider/large-mobile/general.svg') no-repeat;
					background-size: 100% 100%;
					background-position: center;
				}
				&.private-label {
					background: url('../../assets/images/home-page-slider/large-mobile/privateLabel.svg')
						no-repeat;
					background-size: 100% 100%;
					background-position: center;
				}
			}
		}
		& .swiper-button-next,
		& .swiper-button-prev {
			top: 255px;
			background-color: transparent;
		}
		& .swiper-button-next:after,
		& .swiper-button-prev:after {
			background-color: transparent;
		}
		& .swiper-button-next {
			right: 5%;
		}

		& .swiper-button-prev {
			left: 3%;
		}
		& .swiper-pagination-bullets {
			& .swiper-pagination-bullet {
				width: 8px;
				height: 8px;
			}
		}
	}
	@media (max-width: 546px) {
		.home-swiper-container {
			margin-top: -20px;
			& .swiper-button-next,
			& .swiper-button-prev {
				top: 220px;
			}
			& .swiper-pagination-bullets {
				top: -15px;
			}
		}
	}
}
@media (min-width: 0px) and (max-width: 399px) {
	.home-swiper-container {
		width: 98vw;
		display: block;
		& > div {
			width: auto;
		}
		& .swiper-slide {
			& > div {
				height: 109px;
				width: 100%;
				max-width: 91%;
				margin: auto;
				&.newtopia {
					background: url('../../assets/images/home-page-slider/mobile/newtopia.svg') no-repeat;
					background-size: cover;
					background-position: center;
				}
				&.atoz {
					background: url('../../assets/images/home-page-slider/mobile/A2Z.svg') no-repeat;
					background-size: cover;
					background-position: center;
				}
				&.expo-west {
					background: url('../../assets/images/home-page-slider/mobile/expoWest.svg') no-repeat;
					background-size: cover;
					background-position: center;
				}
				&.general {
					background: url('../../assets/images/home-page-slider/mobile/general.svg') no-repeat;
					background-size: cover;
					background-position: center;
				}
				&.private-label {
					background: url('../../assets/images/home-page-slider/mobile/privateLabel.svg') no-repeat;
					background-size: cover;
					background-position: center;
				}
			}
		}
		& .swiper-button-next,
		& .swiper-button-prev {
			top: 255px;
			background-color: transparent;
		}
		& .swiper-button-next:after,
		& .swiper-button-prev:after {
			background-color: transparent;
		}
		& .swiper-button-next {
			right: 4%;
		}

		& .swiper-button-prev {
			left: 2%;
		}
		& .swiper-pagination-bullets {
			& .swiper-pagination-bullet {
				width: 8px;
				height: 8px;
			}
		}
	}
}
