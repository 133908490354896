//Refers to titles, body

$font-weight-bold: 'bold';
$font-weight-regular: 'regular';
$font-weight-semibold: 'semi bold';

$font-size-h1: 64;
$font-size-h2: 48;
$font-size-h3: 32;
$font-size-h4: 24;
$font-size-h5: 20;
$font-size-h6: 18;
$font-size-base: 14;
$font-size-big: 16;
$font-size-bigger: 18;
$font-size-small: 12;
$font-size-smaller: 10;

$font-size-h1-mobile: 48;
$font-size-h2-mobile: 38;
$font-size-h3-mobile: 28;
$font-size-h4-mobile: 22;
$font-size-h5-mobile: 18;
$font-size-h6-mobile: 16;
$font-size-base-mobile: 12;
$font-size-big-mobile: 14;
$font-size-bigger-mobile: 16;
$font-size-small-mobile: 10;
$font-size-smaller-mobile: 9;

$letter-spacing-std: '0.12px';

$text-underline: 'underline';

$tooltip-margin-x: 40px;
$tooltip-margin-y: 10px;

$tooltip-arrow-size: 8px;
