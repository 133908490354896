@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/opacity.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/shadows.scss';
.iiris-profile {
	padding-top: 3.8rem;
	background-color: #e2e8f0;
	display: flex;
	min-height: 100vh;
}

.main-body {
	padding: spacing.$spacing-2x;
}

.card {
	margin-top: spacing.$spacing-4x;
	background: colors.$color-background-white;
	box-shadow: shadows.$shadow-medium;
	border-radius: border-radius.$radius-medium;

	.card-body {
		flex: 1 1 auto;
		min-height: 1px;
		padding: spacing.$spacing-3x;

		.card-title {
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h4;
			line-height: 33px;
			letter-spacing: 0.0075em;
			font-family: fonts.$secondary-font-family;

			color: colors.$color-black;
		}
	}
}

.gutters-sm {
	margin-right: (spacing.$spacing-x * -1);
	margin-left: (spacing.$spacing-x * -1);
}

.gutters-sm > .col,
.gutters-sm > [class*='col-'] {
	padding-right: spacing.$spacing-x;
	padding-left: spacing.$spacing-x;
}

.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}

.bg-gray-300 {
	background-color: #e2e8f0;
}

.h-100 {
	height: 100% !important;
}

.shadow-none {
	box-shadow: none !important;
}

.progress {
	height: 5px;
}

.wrapper {
	display: flex;
	width: 100%;
	align-items: stretch;
}

#sidebar {
	width: 60px;
	background: linear-gradient(271deg, #17a2b8, #4d7ebbfa);
	color: colors.$color-white;
	transition: width 2s;
}

#sidebar a span,
#sidebar .active a:hover span {
	display: none;
	opacity: opacity.$opacity-0;
	overflow: hidden;
}

#sidebar:hover {
	width: 200px;
	max-width: 200px;
}

#sidebar a:hover span {
	display: inline-block;
	text-decoration: none;
	font-size: fonts.$font-size-small;
	margin-left: spacing.$spacing-x;
	color: colors.$color-white;
	transition: opacity 0.25s, transform 0.25s, visibility 0.25s;
	opacity: opacity.$opacity-100;
}

#sidebar.active {
	margin-left: -250px;
}

.btn-info {
	color: colors.$color-white;
}

#sidebar .sidebar-header {
	padding: spacing.$spacing-3x;
	background: #17a2b8;
}

#sidebar ul.components {
	padding: spacing.$spacing-3x 0px;
	border-bottom: 1px solid #47748b;
}

#sidebar ul p {
	color: colors.$color-white;
	padding: spacing.$spacing-x;
}

#sidebar ul li {
	position: relative;
}

#sidebar ul li a {
	padding: spacing.$spacing-1_5x;
	font-size: 1.1em;
	text-align: center;
	display: flex;
	flex-flow: row;
	justify-content: center;
	text-decoration: none;
}

#sidebar ul li a svg {
	color: colors.$color-white;
}

#sidebar ul .active a svg {
	color: #17a2b8;
}

a[data-toggle='collapse'] {
	position: relative;
}

.dropdown-toggle::after {
	display: block;
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
}

ul ul a {
	font-size: 0.9em !important;
	padding-left: spacing.$spacing-4x !important;
	background: #17a2b8;
}

ul.CTAs {
	padding: spacing.$spacing-2x;
}

a.download {
	background: colors.$color-white;
	color: #17a2b8;
}

a.article,
a.article:hover {
	background: #17a2b8 !important;
	color: colors.$color-white !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
	width: 100%;
	padding: spacing.$spacing-3x;
	min-height: 100vh;
	transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
	#sidebar.active {
		margin-left: 0px;
	}

	#sidebarCollapse span {
		display: none;
	}
}

.profile-container {
	margin-top: spacing.$spacing-x;
	margin-bottom: spacing.$spacing-2x;

	.row-old {
		display: flex !important;
	}

	label {
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-big;
		line-height: fonts.$font-size-bigger;
	}

	p {
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-base;
		line-height: 22px;
		/* identical to box height, or 160% */

		letter-spacing: 0.0075em;

		/* Neutral Colors/Slate */

		color: #5c666f;
	}
}

.page-container {
	max-width: 100%;
	padding: 0px;
	background: colors.$color-white;
}

#sidebar ul li.active .curved-corner-bottomright {
	height: 10px;
	width: 10px;
	right: 0;
	top: -10px;
	overflow: hidden;
	position: absolute;
}

#sidebar ul li.active .curved-corner-topleft {
	height: 10px;
	width: 10px;
	right: 0;
	overflow: hidden;
	position: absolute;
	transform: rotateY(180deg);
}

#sidebar ul li.not-active:hover .curved-corner-bottomright {
	height: 18px;
	width: 28px;
	left: 0px;
	top: -18px;
	overflow: hidden;
	position: absolute;
	transform: rotateY(180deg);
}

#sidebar ul li.not-active:hover .curved-corner-topleft {
	transform: rotateY(360deg);
	height: 18px;
	width: spacing.$spacing-2x;
	left: 0px;
	overflow: hidden;
	position: absolute;
}

.edit-btn {
	position: absolute;
	top: spacing.$spacing-3x;
	right: spacing.$spacing-3x;
	height: 26px;
	line-height: 26px;
	font-size: fonts.$font-size-big;
	font-weight: fonts.$font-weight-semibold;
	color: #8d949a;
	font-style: normal;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	display: flex;
	& .gear-icon {
		font-size: fonts.$font-size-h4;
		margin-right: spacing.$spacing-half-x;
	}
}

.save-btn {
	justify-content: end;
	background: colors.$color-first-tier;
	font-weight: fonts.$font-weight-semibold;
	font-size: fonts.$font-size-big;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.0075em;
	border: none;
	padding: spacing.$spacing-half-x spacing.$spacing-4x;
	color: colors.$color-white;
	box-shadow: shadows.$shadow-low;
	border-radius: border-radius.$radius-biggest;
	margin-left: auto;
	margin-top: spacing.$spacing-3x;
}

.edit-btn-grp svg {
	width: 25px;
	height: 25px;
	margin-left: spacing.$spacing-1_5x;
	cursor: pointer;
}

.rounded-circle {
	margin-top: -100px;
	border-radius: border-radius.$radius-circle;
	box-shadow: 1px 1px 10px 0px #5c5a5a8c;
	border: 2px solid colors.$color-white;
}

.edit-pen-btn {
	display: flex;
	justify-content: end;
	position: absolute;
	right: 30px;
	bottom: 1px;
}

.upload-container {
	padding: 0 spacing.$spacing-4x;
}

.upload-container h3 {
	color: #06bad7;
}

.file-drop-target p {
	position: relative;
	z-index: 1;
}

.file-drop-target:hover:after {
	-webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
	transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

.file-drop-target input {
	position: absolute;
}

.file-drop-target p {
	text-align: center;
	font-weight: 100;
	font-size: 17px;
	line-height: 220px;
}

.iiris-btn-grp {
	display: flex;
	justify-content: space-around;
	gap: 10px;
	margin-top: spacing.$spacing-half-x;
}

.iiris-btn-grp .iiris-btn-block + .iiris-btn-block {
	margin-top: 0 !important;
}

.ReactCrop {
	min-width: 200px;
	min-height: 200px;
}

.bg-img {
	height: 100px;
	overflow: hidden;
	margin: spacing.$spacing-x spacing.$spacing-2x 0;
	background: linear-gradient(90deg, #4d81bbb5, #17a2b8);
	min-height: 110px;
	background-clip: border-box;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
	border-radius: border-radius.$radius-small;
	position: relative;
}

.bg-img img {
	width: 100%;
	opacity: opacity.$opacity-30;
	height: 100%;
}

.bg-img .edit-pen-btn {
	right: 10px;
	bottom: 10px;
}

.center-drag {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.center-drag a {
	text-decoration: none;
	text-transform: uppercase;
	font-size: fonts.$font-size-small;
}

.center-drag a span {
	padding: spacing.$spacing-2x;
	transition: 0.5s;
	position: relative;
}

.center-drag a span:nth-child(odd) {
	color: colors.$color-white;
	background: #17a2b8;
}

.center-drag a span:nth-child(even) {
	color: colors.$color-white;
	background: #4d7ebbfa;
}

.center-drag a span:nth-child(odd):before {
	content: attr(data-attr);
	position: absolute;
	top: 0;
	left: 0;
	background: #4d7ebbfa;
	padding: spacing.$spacing-2x;
	transition: 0.5s;
	transform-origin: top;
	transform: rotateX(90deg) translateY(-50%);
}

.center-drag a:hover span:nth-child(odd):before {
	transform: rotateX(0deg) translateY(0%);
}

.center-drag a span:nth-child(even):before {
	content: attr(data-attr);
	position: absolute;
	top: 0;
	left: 0;
	background: #17a2b8;
	padding: spacing.$spacing-2x;
	transition: 0.5s;
	transform-origin: bottom;
	transform: rotateX(90deg) translateY(50%);
}

.center-drag a:hover span:nth-child(even):before {
	transform: rotateX(0deg) translateY(0%);
}

.center-drag a span:nth-child(odd):after {
	content: attr(data-attr);
	padding: spacing.$spacing-2x;
	position: absolute;
	top: 0;
	left: 0;
	background: #17a2b8;
	transform-origin: bottom;
	transform: rotateX(0deg) translateY(0%);
	transition: 0.5s;
}

.center-drag a:hover span:nth-child(odd):after {
	transform: rotateX(90deg) translateY(50%);
}

.center-drag a span:nth-child(even):after {
	content: attr(data-attr);
	position: absolute;
	top: 0;
	left: 0;
	background: #4d7ebbfa;
	padding: spacing.$spacing-2x;
	transition: 0.5s;
	transform-origin: top;
	transform: rotateX(0deg) translateY(0%);
}

.center-drag a:hover span:nth-child(even):after {
	transform: rotateX(90deg) translateY(-50%);
}

.iiris-profile-loader {
	width: 100%;
	margin: auto;
}

.loader {
	margin: auto;
	width: 50%;
	padding: spacing.$spacing-x;
}

#profile-img {
	position: relative;
}

#account-details {
	font-weight: fonts.$font-weight-bold;
	color: #505454;
}

.page-container {
	.profile-detail {
		padding: spacing.$spacing-4x spacing.$spacing-10x spacing.$spacing-6x;
		display: flex;
		background: colors.$app-background-gradient;

		.icon {
			justify-content: center;
			display: flex;
			align-items: center;

			i {
				padding: spacing.$spacing-1_5x;
				border-radius: border-radius.$radius-semi-circle;
				width: 57px;
				height: 57px;
				align-items: center;
				justify-content: center;
				display: flex;
				background: colors.$color-background-white;
			}

			.ri-user-line:before {
				font-size: 21px;
				color: #008fbe;
			}
		}

		.detail {
			margin-left: spacing.$spacing-7x;
			color: colors.$color-white;

			.name {
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-bigger;
				line-height: 25px;
				letter-spacing: 0.0075em;
			}

			.text {
				font-weight: fonts.$font-weight-semibold;
				font-size: fonts.$font-size-base;
				line-height: 22px;
			}
		}
	}
}

.page-container {
	&.ingredient-background {
		background: colors.$page-container-template-background;
	}
	.tabs-container {
		&.ingredient-background {
			background: colors.$profile-template-background;
		}
		border-radius: border-radius.$radius-bigger;
		padding: spacing.$spacing-9x spacing.$spacing-8x;
		background: colors.$color-background-white;
		margin-top: (spacing.$spacing-4x * -1);
		min-height: 100vh;
		@media (max-width: breakpoints.$mobile-breakpoint) {
			padding: spacing.$spacing-6x 0px;
		}
	}

	#root {
		width: 100vw;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.container {
		width: 400px;
		height: 250px;
		background-color: #eeeded;
		border: 1px solid rgba(0, 0, 0, 0.3);
	}

	.tab-list {
		height: spacing.$spacing-5x;
		display: flex;
		list-style: none;
		font-size: fonts.$font-size-bigger;
		padding: 0;
		margin: 0;
		gap: spacing.$spacing-5x;
	}

	.tabs {
		font-family: fonts.$secondary-font-family;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		cursor: pointer;
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-base;
		line-height: 22px;
		border-bottom: 2px solid colors.$color-gray;
		color: colors.$color-gray-slate-light;
		margin: -2px;
		i {
			padding: spacing.$spacing-x;
			font-size: fonts.$font-size-h5;
		}
	}

	.active-tabs {
		color: colors.$color-first-tier;
		border-bottom: 2px solid colors.$color-first-tier;
	}

	.active-tabs::before {
		content: '';
		display: block;
		position: absolute;
		top: -4px;
		left: 50%;
		transform: translateX(-50%);
		width: calc(100% + 2px);
		height: 4px;
		background: #5893f1;
	}

	.content {
		display: none;
	}

	.active-content {
		display: block;
		input,
		select {
			margin-bottom: spacing.$spacing-2x;
		}
	}
	& .hide-select-icon {
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.page-container {
		& .profile-detail {
			padding: spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-6x;
			& .detail {
				margin-left: spacing.$spacing-2x;
			}
		}
	}
	.profile-container {
		margin: spacing.$spacing-x spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-2x;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.page-container {
		& .profile-detail {
			padding: spacing.$spacing-4x spacing.$spacing-10x spacing.$spacing-6x;
		}
		.tabs-container {
			padding: spacing.$spacing-6x spacing.$spacing-2x;
		}
	}
}
@media (min-width: 501px) {
	.tab-list {
		width: fit-content;
		border-bottom: 2px solid colors.$color-gray;
		.tabs {
			i {
				padding: spacing.$spacing-x spacing.$spacing-x spacing.$spacing-x 0;
			}
		}
	}
	.profile-container {
		flex-direction: column;
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.profile-container {	
			.row-old {
				flex-direction: column;
			}
		}
	.page-container {
		& .tabs-container {
			.tab-list {
				margin-left: spacing.$spacing-2x;
			}
		}
	}
}
@media (min-width: 0px) and (max-width: 500px) {
	.page-container {
		& .tabs-container {
			padding: spacing.$spacing-3x 0px;
			.tab-list {
				gap: 12px;
				height: 44px;
				justify-content: space-between;
				margin: auto;
				width: 96%;
				padding: 0px spacing.$spacing-half-x;
				.tabs {
					display: grid;
					font-size: fonts.$font-size-small;
					line-height: 0;
					padding-bottom: spacing.$spacing-x;
					border-bottom: 0px;
					i {
						text-align: center;
					}
				}
				.active-tabs {
					border-bottom: 2px solid;
				}
			}
		}
	}
	.profile-container {
		& .edit-btn {
			font-size: fonts.$font-size-smaller;
			line-height: 16px;
			font-family: fonts.$primary-font-family;
			letter-spacing: 0.075px;
			& .gear-icon {
				font-size: fonts.$font-size-h6-mobile;
			}
		}
	
		
			.row-old {
				flex-direction: column;
			}
		& .card {
			border-radius: 16px;
			box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
			& .card-body {
				padding: spacing.$spacing-1_5x spacing.$spacing-2x;
				& .card-title {
					font-size: fonts.$font-size-bigger;
					line-height: normal;
					letter-spacing: 0.135px;
				}
			}
		}
		label {
			font-family: fonts.$tertiary-font-family;
			font-size: fonts.$font-size-big;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			line-height: 26px;
			letter-spacing: 0.12px;
			margin-bottom: auto;
		}
	}
}
#profile-chart-icon {
	&::after {
		content: 'Engagement Dashboard';
		cursor: pointer;
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-base;
		line-height: 22px;
		margin-left: spacing.$spacing-x;
	}
}
@media (max-width: 500px) {
	#profile-chart-icon {
		padding: spacing.$spacing-x 0px;
		&::after {
			content: 'Engagement';
			cursor: pointer;
			font-family: fonts.$secondary-font-family;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-base;
			line-height: 22px;
			display: block;
			margin-top: 14px;
			margin-left: unset;
		}
	}
}
@media (max-width: breakpoints.$tablet-breakpoint) {
	.page-container {
		& .tabs {
			font-family: fonts.$primary-font-family;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-base-mobile;
		}
		& .active-tabs {
			font-family: fonts.$tertiary-font-family;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-base-mobile;
			& #profile-chart-icon {
				&::after {
					font-family: fonts.$tertiary-font-family;
					font-weight: fonts.$font-weight-semibold;
					font-size: fonts.$font-size-base-mobile;
				}
			}
		}
		#profile-chart-icon {
			&::after {
				font-family: fonts.$primary-font-family;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-base-mobile;
			}
		}
	}
}
