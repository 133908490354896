@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/spacing.scss';
.whats-new-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	gap: 4px;
	padding-left: spacing.$spacing-1_25x;
	& .selected-element-container {
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 4px;
		margin-top: spacing.$spacing-x;
		margin-left: -4px;
		& > i {
			color: colors.$color-gray-slate;
			font-size: fonts.$font-size-h5;
			cursor: pointer;
		}
		& .current-category {
			color: colors.$color-text-black;
			font-family: fonts.$secondary-font-family;
			font-size: fonts.$font-size-base;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			line-height: 22.4px;
			letter-spacing: 0.105px;
			text-decoration: underline;
		}
	}
	& .filter-elements {
		display: flex;
		flex-direction: column;
		& .check-container {
			margin-left: 0px;
		}
		& .whats-new-filter-content {
			display: flex;
			align-items: center;
			& > input[type='checkbox'] {
				margin-left: 0px;
			}
			& > i {
				cursor: pointer;
				color: colors.$color-text-gray-slate-light;
				font-size: fonts.$font-size-big;
				margin: -13px 0px 0px spacing.$spacing-half-x;
				&.active {
					color: colors.$color-primary;
				}
			}
		}
	}
}
