@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';

.social-tab {
	padding-top: 24px;
	margin-bottom: 16px;
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.social-tab {
		padding-top: spacing.$spacing-2x;
		margin-bottom: spacing.$spacing-2x;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.social-tab {
		padding-top: spacing.$spacing-2x;
		margin-bottom: spacing.$spacing-2x;
	}
}
