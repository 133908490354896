@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/breakpoints.scss';
.category-navbar {
	display: flex;
	width: 100%;
	height: 55px;
	box-sizing: border-box;
	background-color: colors.$color-background-white;
	border-bottom: 2px solid #eeeeee;
	box-shadow: shadows.$shadow-medium;
	justify-content: space-between;
	align-items: flex-start;
	align-self: stretch;
	padding: spacing.$spacing-1_5x 6%;
	position: relative;
	z-index: 99;
	gap: 40px;
	.category-item {
		color: colors.$color-text-black;
		text-align: center;
		font-family: fonts.$tertiary-font-family;
		font-style: normal;
		font-size: fonts.$font-size-bigger;
		font-weight: fonts.$font-weight-semibold;
		line-height: 28.8px;
		letter-spacing: 0.135px;
		cursor: pointer;
		position: relative;
		padding-bottom: spacing.$spacing-1_5x;
		&.active:before {
			width: 100%;
			left: 0;
		}
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 3px;
			bottom: -3px;
			left: 50%;
			background-color: colors.$color-black;
			transition: width 0.4s ease, left 0.4s ease;
		}
		&.event-text {
			color: colors.$newtopia-highlight-navbar;
			font-weight: fonts.$font-weight-bold;
			font-family: fonts.$secondary-font-family;
			&:before {
				content: '';
				position: absolute;
				width: 0;
				height: 3px;
				bottom: -3px;
				left: 50%;
				background-color: colors.$newtopia-highlight-navbar;
				transition: width 0.4s ease, left 0.4s ease;
			}
			&:hover:before,
			&.active:before {
				background-color: colors.$newtopia-highlight-navbar;
				width: 100%;
				left: 0;
			}
		}
		&:hover,
		:active,
		&.active {
			font-weight: fonts.$font-weight-bold;
			font-family: fonts.$secondary-font-family;
			padding-bottom: spacing.$spacing-1_5x;
		}
		&:hover:before {
			width: 100%;
			left: 0;
		}
	}
}

@media (max-width: 1439px) {
	.category-navbar {
		justify-content: center;
		gap: 16px;
		& .category-item {
			font-size: fonts.$font-size-base;
		}
	}
}

@media (max-width: breakpoints.$tablet-breakpoint) {
	.category-navbar {
		height: 44px;
		gap: 12px;
		border-bottom: none;
		padding: spacing.$spacing-1_5x 10px;
		justify-content: space-around;
		& .category-item {
			font-size: fonts.$font-size-base-mobile;
			line-height: 19px;
			letter-spacing: 0.0075em;
		}
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.category-navbar {
		display: none;
	}
}
