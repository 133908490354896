@use './variables.scss';
@use '../../styles/tokens/breakpoints.scss';
h1 {
	font-weight: variables.$font-weight-bold;
	font-size: variables.$font-size-h1;
	letter-spacing: variables.$letter-spacing-std;
}

h2 {
	font-weight: variables.$font-weight-bold;
	font-size: variables.$font-size-h2;
	letter-spacing: variables.$letter-spacing-std;
}

h3 {
	font-weight: variables.$font-weight-bold;
	font-size: variables.$font-size-h3;
	letter-spacing: variables.$letter-spacing-std;
}

h4 {
	font-weight: variables.$font-weight-bold;
	font-size: variables.$font-size-h4;
	letter-spacing: variables.$letter-spacing-std;
}

h5 {
	font-weight: variables.$font-weight-bold;
	font-size: variables.$font-size-h5;
	letter-spacing: variables.$letter-spacing-std;
}

h6 {
	font-weight: variables.$font-weight-bold;
	font-size: variables.$font-size-h6;
	letter-spacing: variables.$letter-spacing-std;
}

p {
	font-weight: variables.$font-weight-regular;
	font-size: variables.$font-size-base;
	letter-spacing: variables.$letter-spacing-std;

	&.semibold {
		font-weight: variables.$font-weight-semibold;
	}

	&.bold {
		font-weight: variables.$font-weight-bold;
	}

	&.link {
		text-decoration: variables.$text-underline;
	}

	&.big {
		font-size: variables.$font-size-big;
		line-height: variables.$line-size-big * 1.6;
	}

	&.bigger {
		font-size: variables.$font-size-bigger;
		line-height: variables.$line-size-bigger * 1.6;
	}

	&.small {
		font-size: variables.$font-size-small;
		line-height: variables.$line-size-small * 1.6;
	}
	&.smaller {
		font-size: variables.$font-size-smaller;
		line-height: variables.$line-size-smaller * 1.6;
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	h1 {
		font-weight: variables.$font-weight-bold;
		font-size: variables.$font-size-h1-mobile;
		letter-spacing: variables.$letter-spacing-std;
	}

	h2 {
		font-weight: variables.$font-weight-bold;
		font-size: variables.$font-size-h2-mobile;
		letter-spacing: variables.$letter-spacing-std;
	}

	h3 {
		font-weight: variables.$font-weight-bold;
		font-size: variables.$font-size-h3-mobile;
		letter-spacing: variables.$letter-spacing-std;
	}

	h4 {
		font-weight: variables.$font-weight-bold;
		font-size: variables.$font-size-h4-mobile;
		letter-spacing: variables.$letter-spacing-std;
	}

	h5 {
		font-weight: variables.$font-weight-bold;
		font-size: variables.$font-size-h5-mobile;
		letter-spacing: variables.$letter-spacing-std;
	}

	h6 {
		font-weight: variables.$font-weight-bold;
		font-size: variables.$font-size-h6-mobile;
		letter-spacing: variables.$letter-spacing-std;
	}

	p {
		font-weight: variables.$font-weight-regular;
		font-size: variables.$font-size-base-mobile;
		line-height: variables.$line-size-base-mobile * 1.6;
		letter-spacing: variables.$letter-spacing-std;
		&.big {
			font-size: variables.$font-size-big-mobile;
			line-height: variables.$line-size-big-mobile * 1.6;
		}

		&.bigger {
			font-size: variables.$font-size-bigger-mobile;
			line-height: variables.$line-size-bigger-mobile * 1.6;
		}

		&.small {
			font-size: variables.$font-size-small-mobile;
			line-height: variables.$line-size-small-mobile * 1.6;
		}
		&.smaller {
			font-size: variables.$font-size-smaller-mobile;
			line-height: variables.$line-size-smaller-mobile * 1.6;
		}
	}
}
