@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/shadows.scss';
.expo-label {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	width: 100%;
	height: 20px;
	border-radius: border-radius.$radius-medium;
	&.expoeast {
		font-family: fonts.$tertiary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-small;
		line-height: 19px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-white;
		background: colors.$gradient-blue;
		& > span {
			&:has(i) {
				padding-right: spacing.$spacing-x;
			}
			& > i {
				position: absolute;
				font-size: fonts.$font-size-base;
			}
		}
	}
	&.expowest,
	&.expowest2024 {
		font-family: fonts.$tertiary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-small;
		line-height: 19.2px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-white;
		background: colors.$gradient-expo-west;
		& > span {
			&:has(i) {
				padding-right: spacing.$spacing-x;
			}
			& > i {
				position: absolute;
				font-size: fonts.$font-size-base;
			}
		}
	}
	&.large {
		font-family: fonts.$tertiary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-small;
		line-height: 19.2px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-white;
		box-shadow: shadows.$shadow-low;
		padding: 0 spacing.$spacing-x;
		width: fit-content;
		height: 28px;
		margin-top: 0px;
		& > span {
			&:has(i) {
				align-items: center;
				padding: spacing.$spacing-half-x spacing.$spacing-x spacing.$spacing-half-x 7.5px;
			}
			& > i {
				position: relative;
				font-size: fonts.$font-size-h6-mobile;
				margin-left: 2px;
				font-weight: 500;
				top: 0.5px;
			}
		}
		&:hover {
			& > span {
				&:has(i) {
					& > i {
						margin-left: spacing.$spacing-half-x;
					}
				}
			}
		}
		&.expoeast {
			&.animated {
				animation: gradient 6s infinite;
				animation-delay: 1s, 5s;
			}
		}
		&.expowest,
		&.expowest2024 {
			&.animated {
				animation: west-gradient 450ms infinite linear;
			}
		}
		&.newtopia {
			&.animated {
				transition: background-color 0.1s;
				background-image: colors.$animated-newtopia-label;
				background-size: 300% 100%;
				animation: newtopia-animated 11s infinite linear;
				animation-delay: 5s;
				background-position: 100% 0;
				cursor: default;
			}
		}
	}
	&.inside-carousel {
		height: 16px;
		font-family: fonts.$tertiary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-small-mobile;
		line-height: 16px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-white;
		&.expoeast {
			background: colors.$gradient-blue;
		}
		&.expowest {
			background: colors.$gradient-expo-west;
		}
		&.newtopia {
			font-size: fonts.$font-size-small-mobile;
			background: colors.$newtopia-event-tag-gradient;
		}
	}
	&.newtopia {
		font-family: fonts.$tertiary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-small;
		line-height: 19px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
		background: colors.$newtopia-event-tag-gradient;
		width: fit-content;
		padding: 0 spacing.$spacing-x;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.expo-label {
		height: 16px;
		&.expoeast {
			font-size: fonts.$font-size-small-mobile;
			line-height: 16px;
			& > span {
				& > i {
					font-size: fonts.$font-size-base-mobile;
				}
			}
		}
		&.expowest {
			font-size: fonts.$font-size-small-mobile;
			line-height: 16px;
			& > span {
				& > i {
					font-size: fonts.$font-size-base-mobile;
				}
			}
		}
		&.newtopia {
			font-size: fonts.$font-size-small-mobile;
			line-height: 16px;
			& > span {
				& > i {
					font-size: fonts.$font-size-base-mobile;
				}
			}
		}
		&.large {
			font-size: fonts.$font-size-base-mobile;
			line-height: 19.2px;
			height: 23px;
			margin-top: 2px;
			& > span {
				&:has(i) {
					padding: 0px spacing.$spacing-x 0px 7.5px;
				}
				& > i {
					font-size: fonts.$font-size-h6-mobile;
					margin-left: 0px;
					top: 1px;
				}
			}
			&:hover {
				& > span {
					&:has(i) {
						& > i {
							margin-left: 0px;
						}
					}
				}
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.expo-label {
		&.expoeast {
			font-size: fonts.$font-size-small-mobile;
			line-height: 16px;
			width: 63px;
		}
		&.expowest {
			font-size: fonts.$font-size-small;
			line-height: 19.2px;
			width: 63px;
		}
		&.newtopia:not(.large) {
			font-size: fonts.$font-size-small-mobile;
			line-height: 16px;
		}
		&.large {
			& > span {
				&:has(i) {
					padding: 0px spacing.$spacing-x 0px 7.5px;
				}
				& > i {
					top: 1px;
					margin-left: 0px;
					font-size: fonts.$font-size-h6-mobile;
				}
			}
			&:hover {
				& > span {
					&:has(i) {
						& > i {
							margin-left: 0px;
						}
					}
				}
			}
		}
	}
}


@media (min-width:breakpoints.$tablet-breakpoint) and (max-width: breakpoints.$desktop-breakpoint) {
	.expo-label {
		&.large {
			height: 28px;
		}
	}
}