@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';

.label-container {
	label {
		font-size: fonts.$font-size-base;
		line-height: 22.4px;
		font-weight: fonts.$font-weight-semibold;
		font-family: fonts.$tertiary-font-family;
		margin: 0px;

		i {
			font-size: fonts.$font-size-big;
			color: colors.$color-text-gray-slate-light;
			margin-left: 2px;
		}
		.tooltip-wrapper {
			.tooltip-content.left {
				min-width: 300px;
				text-wrap: balance;
			}
			.tooltip-content.right {
				min-width: 300px;
				text-wrap: balance;
			}
		}
	}
}
