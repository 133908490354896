@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
.menu-name {
	color: colors.$color-text-black;
	cursor: pointer;
	font-family: fonts.$primary-font-family;
	font-size: fonts.$font-size-big;
	font-style: normal;
	font-weight: fonts.$font-weight-regular;
	letter-spacing: 0.44px;
	line-height: 24px;
	margin-bottom: 18px;
}
.check-box-children {
	display: none;
	margin-left: 18px;
}
.carrot-icon::before {
	content: url(../../../assets/images/polygonClose.svg);
	width: 14px;
	height: 11px;
	order: 1;
	flex-grow: 0;
	margin-left: -9.5px;
	display: flex;
}
.carrot-icon-open::before {
	content: url(../../../assets/images/polygonOpen.svg);
	width: 14px;
	height: 11px;
	order: 1;
	flex-grow: 0;
	margin-left: -9.5px;
}
.drop-menu-item {
	display: flex;
}
.foward-arrow {
	margin: -6px 14.7px 14.7px auto;
}
.filter-panel-item {
	margin-left: 0px;
}
