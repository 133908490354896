@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';
@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/shadows.scss';

.share-menu-container {
	.icon-container {
		cursor: pointer;
		i {
			font-size: fonts.$font-size-h5;
		}
	}
	.share-menu-options {
		position: absolute;
		top: calc(100% + 4px);
		left: 0;
		background-color: white;
		box-shadow: shadows.$shadow-medium;
		padding: spacing.$spacing-x spacing.$spacing-half-x;
		border-radius: border-radius.$radius-mid;
		min-width: 162px;
		display: flex;
		flex-direction: column;
		gap: 4px;
		.menu-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			gap: 20px;
			cursor: pointer;
			padding: spacing.$spacing-x;
			height: 28px;
			&:hover {
				background: colors.$color-gray;
				border-radius: border-radius.$radius-big;
			}
			.icon {
				color: colors.$color-primary;
				font-size: 22px;
			}
			.text {
				font-family: fonts.$primary-font-family;
				font-size: fonts.$font-size-small;
				font-weight: fonts.$font-weight-regular;
				line-height: 19.2px;
				letter-spacing: 0.0075em;
			}
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.share-menu-container {
		.share-menu-options {
			right: 0;
			left: auto;
		}
	}
}
