@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/spacing.scss';
.products-wrapper {
	grid-template-columns: auto 1fr;
	display: grid;
}
.search-wrapper {
	margin-top: 67px;
}
.product-list {
	display: flex;
	justify-content: center;
	margin-top: auto;
	&.expanded {
		& > div {
			display: grid;
			grid-template-columns: repeat(3, minmax(auto, 1fr));
			grid-template-rows: repeat(3, minmax(auto, 1fr));
			width: 100%;
			row-gap: 40px;
			column-gap: 24px;
			padding: 12px;
		}
	}
	&.collapsed {
		& > div {
			display: grid;
			grid-template-columns: repeat(4, minmax(auto, 1fr));
			grid-template-rows: repeat(3, minmax(auto, 1fr));
			width: 100%;
			row-gap: 40px;
			column-gap: 24px;
			padding: 12px;
		}
	}
}
.loading {
	text-align: center;
	background-color: colors.$color-transparent;
}
.no-products {
	text-align: center;
}
.category-title-container {
	display: flex;
	flex-direction: column;
	margin-top: spacing.$spacing-4x;
	& .category-title {
		font-family: fonts.$secondary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-h5;
		line-height: 27px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.product-list {
		margin-left: 12px;
		&.collapsed {
			& > div {
				grid-template-columns: repeat(3, minmax(auto, 1fr));
				padding: 12px 12px 12px 6px;
				row-gap: 28px;
				column-gap: 16px;
				& .product-card {
					height: 254px;
					min-width: 189px;
					& .product-image {
						height: 150px;
						margin: spacing.$spacing-x auto;
						& .error-place-holder {
							height: 86px;
							width: 86px;
							margin: auto;
						}
					}
					& .name-wrapper {
						height: 36px;
						& .name {
							font-size: fonts.$font-size-base-mobile;
							line-height: 19px;
						}
					}
					& .product-info {
						& .brand-name-container {
							& .brand {
								font-size: fonts.$font-size-small-mobile;
								line-height: 16px;
							}
						}
					}
					& .labels-container {
						margin: auto spacing.$spacing-x spacing.$spacing-x spacing.$spacing-2x;
						& :nth-child(2) {
							height: 16px;
							&:not(.newtopia) {
								width: 63px;
							}
						}
						& > .expo-label:only-child {
							height: 16px;
							&:not(.newtopia) {
								width: fit-content;
								padding: 0 spacing.$spacing-x;
							}
						}

						.how-good-btn {
							line-height: 1px;
							span {
								line-height: 1px;
								display: flex;
								align-items: center;
								justify-content: center;
							}
							i {
								line-height: 1px;
								margin-top: 0;
							}
						}
					}
				}
			}
		}
		&.expanded {
			& > div {
				grid-template-columns: repeat(3, minmax(auto, 1fr));
				padding: 12px 12px 12px 6px;
				row-gap: 28px;
				column-gap: 16px;
				& .product-card {
					height: 254px;
					min-width: 189px;
					& .product-image {
						height: 150px;

						margin: spacing.$spacing-x auto spacing.$spacing-x auto;
						& .error-place-holder {
							height: 86px;
							width: 86px;
							margin: auto;
						}
					}
					& .name-wrapper {
						height: 36px;
						& .name {
							font-size: fonts.$font-size-base-mobile;
							line-height: 19px;
						}
					}
					& .product-info {
						& .brand-name-container {
							& .brand {
								font-size: fonts.$font-size-small-mobile;
								line-height: 16px;
							}
						}
					}
					& .labels-container {
						margin: auto spacing.$spacing-x spacing.$spacing-x spacing.$spacing-2x;
						& :nth-child(2) {
							&:not(.newtopia) {
								width: 63px;
							}
							height: 16px;
						}
						& > .expo-label:only-child {
							&:not(.newtopia) {
								width: 63px;
							}
							height: 16px;
						}
					}
				}
			}
		}
		@media (max-width: 900px) {
			& > div {
				grid-template-columns: repeat(1, minmax(auto, 1fr));
			}
		}
	}
	.category-title-container {
		& .category-title {
			margin-left: spacing.$spacing-2x;
		}
	}
}

@media (min-width: breakpoints.$large-screen-breakpoint) {
	.product-list {
		&.collapsed {
			& > div {
				grid-template-columns: repeat(5, minmax(auto, 1fr));
				padding: 12px 12px 12px 6px;
			}
		}
		&.expanded {
			& > div {
				grid-template-columns: repeat(4, minmax(auto, 1fr));
				padding: 12px 12px 12px 6px;
			}
		}
	}
}
@media (max-width: 1080px) {
	.product-list {
		&.collapsed {
			& > div {
				grid-template-columns: repeat(3, minmax(auto, 1fr));
				padding: 12px 12px 12px 6px;
			}
		}
	}
}
@media (min-width: 500px) and (max-width: breakpoints.$mobile-breakpoint) {
	.product-list {
		&.expanded {
			& > div {
				grid-template-columns: repeat(3, minmax(auto, 1fr)) !important;
				padding: 12px 12px 12px 6px;
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.category-title-container {
		margin-top: spacing.$spacing-2x;
		& .category-title {
			margin-left: spacing.$spacing-2x;
			font-size: fonts.$font-size-big-mobile;
			line-height: 22px;
		}
	}
	.products-wrapper {
		margin-top: 0px;
		display: block;
		.product-list {
			margin-left: 0;
			margin-top: auto;
			& > div {
				grid-template-columns: repeat(2, minmax(auto, 1fr));
				row-gap: spacing.$spacing-2x;
				column-gap: spacing.$spacing-3x;
				padding: spacing.$spacing-2x;
				.product-card {
					width: 100%;
					min-width: 130px;
					max-width: 220px;
					max-height: 244px;
					min-height: 240px;
					& .product-image {
						height: 120px;
						margin: spacing.$spacing-x auto;
						padding: 0px;
						& .error-place-holder {
							width: 86px;
							height: 86px;
							margin: auto;
							padding: 0px;
						}
					}
					& .product-favorite {
						padding: spacing.$spacing-x spacing.$spacing-x 0px 0px;
					}
					& .product-info {
						margin: auto spacing.$spacing-x spacing.$spacing-x spacing.$spacing-x;
						& .brand {
							font-size: fonts.$font-size-smaller;
							line-height: 16px;
						}
						& .name-wrapper {
							height: 36px;
							& .name {
								font-size: fonts.$font-size-base-mobile;
								line-height: 19px;
							}
						}
					}
					& .labels-container {
						margin: auto spacing.$spacing-x spacing.$spacing-x spacing.$spacing-x;
						& .how-good-btn {
							max-width: 106px;
							width: auto;
							height: 16px;
							font-size: fonts.$font-size-hg-btn-inside-caurosel;
							line-height: 1px;
							& .world-icon {
								font-size: calc(fonts.$font-size-small-mobile + 1px);
								margin: 0px -4px 0px -5px;
							}
							& .product-score {
								font-size: fonts.$font-size-small-mobile;
								margin: 0px -8px 0px -4px;
								line-height: 1px;
							}
						}
						> :nth-child(2) {
							&:not(.newtopia) {
								width: 63px;
							}
							height: 16px;
						}
						> .expo-label:only-child {
							&:not(.newtopia) {
								width: fit-content;
								padding: 0 spacing.$spacing-x;
							}
							height: 16px;
							margin-top: 20px;
						}
					}
				}
			}
		}
	}
}

@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1200px) {
	.product-list {
		&.collapsed {
			& > div {
				grid-template-columns: repeat(3, minmax(auto, 1fr));
				padding: 12px 12px 12px 6px;
			}
		}
		&.expanded {
			& > div {
				grid-template-columns: repeat(2, minmax(auto, 1fr));
				padding: 12px 12px 12px 6px;
			}
		}
	}
}
@media ((min-width: 1500px) and (max-width: 1807px)) or ((min-width: 1200px) and (max-width: 1454px)) or ((min-width: 993px) and (max-width: 1088px)) {
	.product-list {
		&.collapsed {
			& > div {
				display: grid;
				grid-template-columns: repeat(4, minmax(auto, 1fr));
				grid-template-rows: repeat(3, minmax(auto, 1fr));
				width: 100%;
				row-gap: 40px;
				column-gap: 24px;
				padding: 12px;
				& .product-card {
					height: 367px;
					& .labels-container {
						margin: auto 5% auto 5%;
						& > .expo-label:only-child {
							margin-top: 22px;
						}
						&.show-hg-wrapper {
							margin: 2px auto auto 5%;
						}
						& .button {
							&.how-good-btn {
								margin-bottom: 30px;
							}
						}
						& .button + .expo-label {
							margin-top: -21px;
							margin-bottom: spacing.$spacing-2x;
						}
					}
				}
			}
		}
	}
}

/** Safari */
@media (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) and (stroke-color: transparent) {
		@media screen and ((min-width: 977px) and (max-width: 992px)) {
			.product-list {
				&.collapsed {
					& > div {
						grid-template-columns: repeat(3, minmax(auto, 1fr));
						padding: 12px 12px 12px 6px;
					}
				}
				&.expanded {
					& > div {
						grid-template-columns: repeat(2, minmax(auto, 1fr));
						padding: 12px 12px 12px 6px;
					}
				}
			}
		}
		@media screen and ((min-width: 720px) and (max-width: 742px)) {
			.product-list {
				margin-left: 0;
				margin-top: auto;
				& > div {
					grid-template-columns: repeat(2, minmax(auto, 1fr));
					row-gap: spacing.$spacing-2x;
					column-gap: spacing.$spacing-3x;
					padding: spacing.$spacing-2x;
					.product-card {
						width: 100%;
						min-width: 130px;
						max-width: 200px;
						max-height: 240px;
						min-height: 220px;
						& .product-image {
							width: 87%;
							height: 120px;
							margin: spacing.$spacing-x auto spacing.$spacing-x spacing.$spacing-x;
							padding: 0px;
							& .error-place-holder {
								width: 86px;
								height: 86px;
								margin: auto;
								padding: 0px;
							}
						}
						& .product-favorite {
							padding: spacing.$spacing-x spacing.$spacing-x 0px 0px;
						}
						& .product-info {
							margin: auto spacing.$spacing-x spacing.$spacing-x spacing.$spacing-x;
							& .brand {
								font-size: fonts.$font-size-smaller;
								line-height: 16px;
							}
							& .name-wrapper {
								height: 36px;
								& .name {
									font-size: fonts.$font-size-base-mobile;
									line-height: 19px;
								}
							}
						}
						& .labels-container {
							margin: auto spacing.$spacing-x spacing.$spacing-x spacing.$spacing-x;
							& .how-good-btn {
								width: 106px;
								height: 16px;
								font-size: fonts.$font-size-hg-btn-inside-caurosel;
								& .world-icon {
									font-size: calc(fonts.$font-size-small-mobile + 1px);
								}
								& .product-score {
									font-size: fonts.$font-size-small-mobile;
								}
							}
							> :nth-child(2) {
								width: 63px;
								height: 16px;
							}
							> .expo-label:only-child {
								width: 63px;
								height: 16px;
								margin-top: 20px;
							}
						}
					}
				}
			}
		}
	}
}
