@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.check-container {
	margin-left: spacing.$spacing-x;
	display: flex;
	align-items: center;
	margin-bottom: spacing.$spacing-1_5x;
	& .check-icon {
		cursor: pointer;
	}
	&:hover {
		& > label {
			&:not(.selected) {
				text-decoration: underline;
			}
			font-family: fonts.$tertiary-font-family;
			font-weight: fonts.$font-weight-semibold;
		}
	}
	& .check-label {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-base;
		line-height: 24px;
		letter-spacing: 0.44px;
		color: colors.$color-text-black;
		word-wrap: break-word;
		margin-bottom: 0px;
		cursor: pointer;
		margin-left: spacing.$spacing-half-x;
		&.selected {
			font-family: fonts.$secondary-font-family;
			font-weight: fonts.$font-weight-bold;
		}
		&.parent-label {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-base;
			line-height: 20px;
			display: flex;
			align-items: center;
			letter-spacing: 0.44px;
			color: colors.$color-text-black;
			margin-bottom: 0px;
			cursor: pointer;
			&.selected {
				font-family: fonts.$secondary-font-family;
				font-weight: fonts.$font-weight-bold;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	& input[type='checkbox'] {
		display: none;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.check-container {
		& input[type='checkbox'] {
			margin-bottom: spacing.$spacing-1_5x;
		}
		& .check-label {
			margin-top: 2px;
		}
	}
}
