@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/breakpoints.scss';
.onboard-brand-card-container {
	& .brand-onboard-card-title-container {
		margin-top: spacing.$spacing-2x;
		&.left-card-title {
			margin-right: spacing.$spacing-3x;
		}
		&.right-card-title {
			margin-left: 4.4%;
		}
		& .brand-onboard-card-title {
			font-family: fonts.$tertiary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-h5;
			line-height: 32px;
			letter-spacing: 0.0075em;
			color: colors.$color-primary-dark;
		}
		& .brand-title-separator {
			margin-top: spacing.$spacing-x;
			width: 100%;
			height: 2px;
			background-color: colors.$color-background-gray;
		}
	}
	& .onboard-brand-card-header-container {
		display: flex;
		&.no-image-wrapper {
			margin-top: spacing.$spacing-2x;
		}
		&.image-wrapper {
			margin-top: spacing.$spacing-3_5x;
		}
	}
	& .onboard-brand-card-content-container {
		margin-top: spacing.$spacing-2x;
	}
	& .onboard-brand-card-underline {
		width: 100%;
		display: flex;
		margin-top: spacing.$spacing-3x;
		&.bottom-left {
			justify-content: flex-start;
			transform: translateX(-48px);
		}
		&.bottom-right {
			justify-content: flex-end;
			transform: translateX(48px);
		}
		&.top-left {
			justify-content: flex-start;
			transform: translateX(-48px);
			margin-top: spacing.$spacing-4x;
		}
		&.top-right {
			justify-content: flex-end;
			transform: translateX(48px);
		}
		& .onboard-brand-card-underline-dot {
			height: 10px;
			width: 24px;
			background-color: colors.$color-secondary-light;
			&:not(:last-child) {
				margin-right: spacing.$spacing-2x;
			}
			&:first-child {
				width: 12px;
			}
			&:last-child {
				width: 12px;
			}
		}
	}
	&.placeholder {
		opacity: 0;
	}
	&.negative-margin-wrapper {
		margin-top: -40px;
		word-break: break-word;
	}
	&.m2-negative-margin-wrapper {
		margin-top: -30px;
	}
	&.need-changes-negative-margin-wrapper {
		margin-top: -34.7px;
	}
}
@media (min-width: 1140px) and (max-width: 1500px) {
	.onboard-brand-card-container {
		& .brand-onboard-card-title-container {
			&.left-card-title {
				width: 100%;
				margin-right: 0px;
			}
			&.right-card-title {
				margin-left: 0px;
			}
		}
		& .onboard-brand-card-header-container {
			&.no-image-wrapper {
				margin-top: spacing.$spacing-x;
			}
		}
		& .onboard-brand-card-content-container {
			margin-top: spacing.$spacing-x;
		}
		& .onboard-brand-card-underline {
			margin-top: spacing.$spacing-2x;
			& .onboard-brand-card-underline-dot {
				height: 10px;
				width: 24px;
				background-color: colors.$color-secondary-light;
				&:not(:last-child) {
					margin-right: spacing.$spacing-x;
				}
				&:first-child {
					width: 12px;
				}
				&:last-child {
					width: 12spx;
				}
			}
			&.bottom-left {
				transform: translateX(-35px);
			}
			&.bottom-right {
				transform: translateX(35px);
			}
			&.top-left {
				margin-top: 19px;
				transform: translateX(-35px);
			}
			&.top-right {
				transform: translateX(35px);
			}
		}
		&.spins-img-wrapper {
			& .onboard-brand-card-header-container {
				&.image-wrapper {
					margin-top: spacing.$spacing-9x;
				}
			}
		}
	}
}
@media (max-width: 1248px) {
	.onboard-brand-card-container {
		& .brand-onboard-card-title-container {
			&.left-card-title {
				margin-right: 0px;
			}
			& .brand-onboard-card-title {
				font-size: fonts.$font-size-base;
				line-height: 22.4px;
			}
			&.right-card-title {
				margin-left: 0px;
			}
		}
		& .onboard-brand-card-header-container {
			&.no-image-wrapper {
				margin-top: spacing.$spacing-x;
			}
		}
		& .onboard-brand-card-content-container {
			margin-top: spacing.$spacing-x;
		}
		& .onboard-brand-card-underline {
			margin-top: spacing.$spacing-2x;
			& .onboard-brand-card-underline-dot {
				height: 6px;
				width: 12px;
				background-color: colors.$color-secondary-light;
				&:not(:last-child) {
					margin-right: spacing.$spacing-x;
				}
				&:first-child {
					width: 6px;
				}
				&:last-child {
					width: 6px;
				}
			}
			&.bottom-left {
				transform: translateX(-36px);
			}
			&.bottom-right {
				transform: translateX(35px);
			}
			&.top-left {
				margin-top: 19px;
				transform: translateX(-35px);
			}
			&.top-right {
				transform: translateX(35px);
			}
		}
		&.spins-img-wrapper {
			& .onboard-brand-card-header-container {
				&.image-wrapper {
					margin-top: spacing.$spacing-9x;
				}
			}
		}
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.onboard-brand-card-container {
		& .brand-onboard-card-title-container {
			&.left-card-title {
				width: 349px;
			}
			&.right-card-title {
				width: 352px;
			}
		}
		&.mobile-negative-margin-wrapper {
			margin-top: -16px;
		}
		& .onboard-brand-card-header-container {
			display: flex;
			flex-direction: column;
		}
		&.mobile-spins--negative-margin-wrapper {
			margin-top: -30px;
		}
	}
}
@media (max-width: 500px) {
	.onboard-brand-card-container {
		& .brand-onboard-card-title-container {
			&.left-card-title {
				width: 228px;
				margin-top: spacing.$spacing-1_5x;
			}
			&.right-card-title {
				width: 231px;
			}
		}
	}
}
