@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/breakpoints.scss';

.save-template-sticky-container {
	position: fixed;
	z-index: 100;
	bottom: 0;
	width: 100%;
	background: colors.$save-template-sticky-background;
	margin-left: -50px;
	box-shadow: shadows.$save-sticky-template-shadow;
	border-radius: border-radius.$radius-medium border-radius.$radius-medium 0px 0px;
	height: 71px;
	display: flex;
	align-items: center;
	padding: 0px spacing.$spacing-10x;
	opacity: 1;
	transition: opacity 0.3s ease-out;
	&.hidden {
		opacity: 0;
	}
	& .save-btn-container {
		margin-left: auto;
	}
	& .selected-template-container {
		& .current-template-text {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h5;
			line-height: 27px;
			letter-spacing: 0.0075em;
			color: colors.$color-primary;
			&::before {
				content: 'Selected Template:';
				font-family: fonts.$tertiary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-semibold;
				font-size: fonts.$font-size-h5;
				line-height: 27px;
				letter-spacing: 0.0075em;
				color: colors.$color-text-black;
				margin-right: spacing.$spacing-x;
			}
		}
	}
}
@media (max-width: 1200px) {
	.save-template-sticky-container {
		padding: 0px spacing.$spacing-10x 0 spacing.$spacing-7x;
	}
}
@media (max-width: breakpoints.$tablet-breakpoint) {
	.save-template-sticky-container {
		margin-left: -26px;
		padding: 0px spacing.$spacing-7x 0 spacing.$spacing-3x;
		& .selected-template-container {
			& .current-template-text {
				font-size: fonts.$font-size-big;
				line-height: 28px;
				&::before {
					font-size: fonts.$font-size-big;
					line-height: 28px;
				}
			}
		}
	}
}
@media (max-width: 820px) {
	.save-template-sticky-container {
		padding: 0px spacing.$spacing-3x 0 spacing.$spacing-3x;
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.save-template-sticky-container {
		flex-direction: column;
		padding: spacing.$spacing-x 0px spacing.$spacing-2x 0px;
		height: 76px;
		margin-left: -16px;
		& .selected-template-container {
			& .current-template-text {
				font-size: fonts.$font-size-small;
				line-height: 19px;
				&::before {
					content: 'Template:';
					font-size: fonts.$font-size-small;
					line-height: 19px;
					margin-right: spacing.$spacing-half-x;
				}
			}
		}
		& .save-btn-container {
			margin-left: unset;
		}
	}
}
