@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/spacing.scss';
.new-filter-container {
	display: flex;
	align-items: center;
	cursor: pointer;
	gap: 4px;
	margin-top: spacing.$spacing-x;
	& .filter-name {
		color: colors.$color-text-black;
		font-family: fonts.$tertiary-font-family;
		font-size: fonts.$font-size-base;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		line-height: 22.4px;
		letter-spacing: 0.105px;
	}
	& > i {
		font-size: fonts.$font-size-h5;
		color: colors.$color-gray-slate;
	}
	&:hover {
		& .filter-name {
			text-decoration: underline;
		}
		& > i {
			transform: translateX(4px);
		}
	}
}
