/* The grid container */
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';

.grid-container {
	display: grid;
	grid-template-areas:
		'header header header header header header'
		'nav nav nav nav nav nav'
		'main-content main-content main-content main-content main-content main-content'
		'footer footer footer footer footer footer';
}

.main-content {
	grid-area: main-content;
	min-height: 80vh;
	margin: 0% 4% 3% 5.5%;
	& .col {
		margin: 0px !important;
		padding: 0px !important;
	}

	& .row {
		margin: 0px !important;
		padding: 0px !important;
	}
}
.main-content-homepage {
	grid-area: main-content;
	min-height: 80vh;
	margin: 0px;
	overflow: auto;
}

.landing-content {
	height: calc(100vh);
}

.complete-brand-details {
	background: colors.$color-primary;
}

.standalone-content {
	height: auto;
	width: 100%;
}

.secondary-layout {
	grid-area: main-content;
	min-height: 80vh;
	margin: 0 1.5% 0 1.5%;
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.secondary-layout {
		margin: 0;
		overflow: hidden;
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.main-content {
		margin: 0;
		width: 100vw;
	}
}
