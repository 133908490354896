@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/border-radius.scss';
.about-brand-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: auto;
	width: 110.3%;
	margin: spacing.$spacing-7x auto spacing.$spacing-7x -6%;
	overflow: hidden;
	background: colors.$color-background-secondary-lightest;

	& .about-brand-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: auto;
		margin: spacing.$spacing-4x auto spacing.$spacing-4x 6%;

		& .brand-information-container {
			width: 100%;
			display: flex;
			align-items: flex-start;
			flex-direction: column;

			& .brand-name-container {
				margin-top: spacing.$spacing-3x;
				margin-bottom: spacing.$spacing-3x;
				& .brand-name {
					font-family: fonts.$secondary-font-family;
					font-weight: fonts.$font-weight-bold;
					font-style: normal;
					font-size: fonts.$font-size-h4;
					line-height: 24.51px;
					letter-spacing: 0.0075em;
					color: colors.$color-text-black;
				}
			}
			& .brand-info {
				display: flex;
				width: 90%;
				& .brand-image {
					& .image-container {
						background-color: colors.$color-background-white;
						width: 288px;
						padding: spacing.$spacing-2x;
						border-radius: border-radius.$radius-brand-image;
						height: 274px;
						& > img {
							object-fit: contain;
							width: 100%;
							height: 242px;
						}
					}
				}
				& .brand-story-container {
					padding-right: spacing.$spacing-x;
					margin-left: spacing.$spacing-3x;
					& .brand-story {
						max-width: 954px;
						font-family: fonts.$primary-font-family;
						font-style: normal;
						font-weight: fonts.$font-weight-regular;
						font-size: fonts.$font-size-base;
						line-height: 22.4px;
						letter-spacing: 0.0075em;
						color: colors.$color-text-black;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
						margin-bottom: spacing.$spacing-2x;
					}
					& .about-brand-link {
						font-family: fonts.$secondary-font-family;
						font-style: normal;
						font-weight: fonts.$font-weight-bold;
						font-size: fonts.$font-size-big;
						line-height: 25.6px;
						letter-spacing: fonts.$letter-spacing-std;
						color: colors.$color-primary;
						cursor: pointer;
						text-decoration: none;
						display: flex;
						height: 23px;
						&:hover {
							text-decoration: none;
						}
						& .icon-arrow {
							position: relative;
							top: 2px;
							left: 3px;
							font-size: fonts.$font-size-arrow-big;
							font-weight: fonts.$font-weight-regular;
						}
					}
				}
			}
		}
	}

	& .about-brand-title {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-bigger;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-primary;
		line-height: 25px;
		font-style: normal;
		margin-right: auto;
	}
}

@media (min-width: 0px) and (max-width: 500px) {
	.about-brand-container {
		width: 106%;
		margin-bottom: 120px;
		height: 530px;
		background-color: colors.$color-secondary-lightest;
		padding: spacing.$spacing-2x spacing.$spacing-5x spacing.$spacing-4x spacing.$spacing-7x;

		& .about-brand-content {
			margin: 0px 0px 0px 0px;
			width: auto;
			& .about-brand-title {
				font-size: fonts.$font-size-bigger-mobile;
			}
			& .brand-information-container {
				width: auto;
				& .brand-name-container {
					margin-top: spacing.$spacing-2x;
					margin-bottom: spacing.$spacing-2x;
					& .brand-name {
						font-size: fonts.$font-size-h4-mobile;
						line-height: 30px;
					}
				}

				& .brand-info {
					display: block;
					width: 100%;
					& .brand-image {
						& .image-container {
							width: 259px;
							height: 235px;
							margin: auto;
							& > img {
								height: 213px;
							}
						}
					}
					& .brand-story-container {
						width: auto;
						margin-top: spacing.$spacing-2x;
						margin-left: 0px;
						padding-right: 0px;
						& .brand-story {
							-webkit-line-clamp: 6;
							font-size: fonts.$font-size-base-mobile;
							line-height: 19.2px;
						}
						& .about-brand-link {
							font-size: fonts.$font-size-base;
							height: 23px;
							display: flex;
							& .icon-arrow {
								position: sticky;
								font-size: fonts.$font-size-h4;
								margin-left: 3px;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 500px) and (max-width: breakpoints.$mobile-breakpoint) {
	.about-brand-container {
		padding-left: spacing.$spacing-2x;
		width: auto;
		& .about-brand-content {
			padding-left: 20px;
			& .about-brand-title {
				font-size: fonts.$font-size-bigger;
			}
			& .brand-information-container {
				& .brand-info {
					& .brand-image {
						& .image-container {
							width: 227px;
							height: 216px;
							margin: auto;
							& > img {
								height: 184px;
							}
						}
					}
				}
				& .brand-story-container {
					padding-right: 0px !important;
					& .brand-story {
						-webkit-line-clamp: 5 !important;
						font-size: fonts.$font-size-small !important;
					}
					& .about-brand-link {
						font-size: fonts.$font-size-base !important;
						align-items: center;
						display: block !important;
						&:hover {
							text-decoration: none;
						}
						& .icon-arrow {
							align-items: center;
							font-size: fonts.$font-size-h5 !important;
							margin-left: 3px;
						}
					}
				}
				& .brand-name-container {
					& .brand-name {
						font-size: fonts.$font-size-h4-mobile;
					}
				}
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.about-brand-container {
		padding: 0 spacing.$spacing-2x;
		width: auto;
		& .about-brand-content {
			& .brand-information-container {
				& .brand-name-container {
					& .brand-name {
						font-size: fonts.$font-size-h5;
					}
				}
				& .brand-info {
					width: 94%;
					& .brand-image {
						& .image-container {
							width: 227px;
							height: 216px;
							margin: auto;
							& > img {
								height: 184px;
							}
						}
					}
					& .brand-story-container {
						& .brand-story {
							-webkit-line-clamp: 4;
							font-size: fonts.$font-size-small;
						}
						& .about-brand-link {
							height: 23px;
							display: flex;
							align-items: center;
							font-size: fonts.$font-size-base;
							&:hover {
								text-decoration: none;
							}
							& .icon-arrow {
								position: sticky;
								font-size: fonts.$font-size-arrow-small;
								margin-left: 3px;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 601px) {
	.mobile-contact-information-container {
		display: none;
	}
}

@media (max-width: 600px) {
	.about-brand-container {
		& .about-brand-link {
			margin-bottom: spacing.$spacing-2x;
		}
	}

	.mobile-contact-information-container {
		width: 100%;
		padding: 0px spacing.$spacing-1_5x 0px 0px;

		& .contact-title-container {
			margin-bottom: spacing.$spacing-1_25x;

			& .title {
				font-family: fonts.$secondary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-bigger-mobile;
				line-height: 26px;
				letter-spacing: 0.0075em;
				color: colors.$color-primary;
			}
		}
	}
}
