@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';
.error-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 161px auto 200px auto;
	& > div {
		height: 100%;
		text-align: center;
		& .error-img {
			width: 571px;
		}
		& .error-title {
			margin-top: 83px;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-h4;
			line-height: 38px;
			font-family: fonts.$tertiary-font-family;
		}
		& .error-content {
			margin-top: 80px;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-h4;
			line-height: 18px;
		}
		& .error-btn-continer {
			display: flex;
			justify-content: center;
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.error-container {
		padding-bottom: 120px;
		& div {
			& .error-img {
				width: 202px;
				height: 130px;
			}
			& .error-title-container {
				display: flex;
				text-align: left;
				margin: auto auto auto spacing.$spacing-2x;
				& .error-title {
					margin-top: spacing.$spacing-4x;
					font-weight: fonts.$font-weight-semibold;
					font-size: fonts.$font-size-base-mobile;
					line-height: 18px;
				}
			}
			& .error-content {
				margin-top: 12px;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-base-mobile;
				line-height: 18px;
			}
		}
	}
}
