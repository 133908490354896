@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/breakpoints.scss';
.company-details-loading-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	& .loading-title-container {
		display: flex;
		margin: spacing.$spacing-3x auto auto auto;
		text-align: center;
		& .title {
			color: colors.$color-text-black;
			font-family: fonts.$secondary-font-family;
			font-size: fonts.$font-size-h4;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			letter-spacing: fonts.$letter-spacing-std;
			line-height: 60px;
		}
	}
	& .congrats-container {
		display: flex;
		margin: spacing.$spacing-2x auto auto auto;
		width: 450px;
		& .congrats-text {
			color: colors.$color-gray-slate;
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-big;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			letter-spacing: fonts.$letter-spacing-std;
			line-height: 26px;
		}
	}
	& .checkmarks-container {
		display: flex;
		flex-direction: column;
		margin: spacing.$spacing-7x auto auto 76px;
		width: 482px;
		gap: 32px;
		& .check-mark-element {
			display: flex;
			& .ri-checkbox-circle-line {
				color: colors.$color-primary;
				font-size: fonts.$font-size-h4;
			}
			& .check-mark-text-container {
				display: flex;
				margin: spacing.$spacing-half-x auto auto spacing.$spacing-x;
				& .checkmark-title {
					color: colors.$color-text-black;
					font-family: fonts.$secondary-font-family;
					font-size: fonts.$font-size-big;
					font-style: normal;
					font-weight: fonts.$font-weight-bold;
					letter-spacing: fonts.$letter-spacing-std;
					line-height: 26px;
				}
				& .checkmark-text {
					color: colors.$color-text-black;
					font-family: fonts.$primary-font-family;
					font-size: fonts.$font-size-big;
					font-style: normal;
					font-weight: fonts.$font-weight-regular;
					letter-spacing: fonts.$letter-spacing-std;
					line-height: 26px;
				}
			}
		}
	}
	& .progress-container {
		display: flex;
		width: 421px;
		margin: 120px auto 58px auto;
		justify-content: center;
		& > progress {
			-webkit-appearance: none;
			appearance: none;
			width: 100%;
			height: 34px;
			border-radius: border-radius.$radius-semi-circle;
			background-color: colors.$color-gray;
			&::-webkit-progress-bar {
				background-color: colors.$color-gray;
				border-radius: border-radius.$radius-biggest;
			}
			&::-webkit-progress-value {
				border-radius: border-radius.$radius-biggest 0px 0px border-radius.$radius-biggest;
				background: colors.$gradient-beacon;
			}
		}
		& .fade-out {
			animation: progressFadeOut 300ms forwards;
		}
		& .progress-rounded {
			&::-webkit-progress-bar {
				border-radius: border-radius.$radius-biggest;
			}
			&::-webkit-progress-value {
				border-radius: border-radius.$radius-biggest;
			}
		}
		@keyframes progressFadeOut {
			0% {
				opacity: 1;
			}
			100% {
				opacity: 0;
			}
		}
	}
	& .btn-fade-in {
		animation: loading-company-btn-fadeIn 600ms forwards;
		@keyframes loading-company-btn-fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.company-details-loading-container {
		& .checkmarks-container {
			width: 470px;
		}
	}
}
@media (min-width: 391px) and (max-width: breakpoints.$mobile-breakpoint) {
	.company-details-loading-container {
		min-width: 390px;
		margin-top: spacing.$spacing-3x;
		& .loading-title-container {
			margin: spacing.$spacing-4x auto auto auto;
		}
		& .congrats-container {
			margin: spacing.$spacing-half-x auto auto auto;
			width: 288px;
			& .congrats-text {
				font-size: fonts.$font-size-big-mobile;
				line-height: 22.4px;
				letter-spacing: 0.105px;
			}
		}
		& .checkmarks-container {
			margin: spacing.$spacing-1_5x auto auto auto;
			width: 288px;
			gap: 16px;
			& .check-mark-element {
				& .ri-checkbox-circle-line {
					font-size: fonts.$font-size-h5;
					margin-top: spacing.$spacing-x;
				}
				& .check-mark-text-container {
					margin: auto auto auto spacing.$spacing-x;
					flex-wrap: wrap;
					width: 258px;
					& .checkmark-title {
						font-size: fonts.$font-size-big-mobile;
						line-height: 22.4px;
						letter-spacing: 0.105px;
					}
					& .checkmark-text {
						font-size: fonts.$font-size-big-mobile;
						line-height: 22.4px;
						letter-spacing: 0.105px;
					}
				}
			}
		}
		& .progress-container {
			width: 85%;
			margin: 58px auto spacing.$spacing-9x auto;
		}
	}
}

@media (min-width: 0px) and (max-width: 391px) {
	.company-details-loading-container {
		margin-top: spacing.$spacing-3x;
		& .loading-title-container {
			margin: spacing.$spacing-4x auto auto auto;
		}
		& .congrats-container {
			margin: spacing.$spacing-half-x auto auto auto;
			width: 288px;
			& .congrats-text {
				font-size: fonts.$font-size-big-mobile;
				line-height: 22.4px;
				letter-spacing: 0.105px;
			}
		}
		& .checkmarks-container {
			margin: spacing.$spacing-1_5x auto auto auto;
			width: 288px;
			gap: 16px;
			& .check-mark-element {
				& .ri-checkbox-circle-line {
					font-size: fonts.$font-size-h5;
					margin-top: spacing.$spacing-x;
				}
				& .check-mark-text-container {
					margin: auto auto auto spacing.$spacing-x;
					flex-wrap: wrap;
					width: 258px;
					& .checkmark-title {
						font-size: fonts.$font-size-big-mobile;
						line-height: 22.4px;
						letter-spacing: 0.105px;
					}
					& .checkmark-text {
						font-size: fonts.$font-size-big-mobile;
						line-height: 22.4px;
						letter-spacing: 0.105px;
					}
				}
			}
		}
		& .progress-container {
			width: 288px;
			margin: 58px auto spacing.$spacing-9x auto;
		}
		& .separator {
			width: 390px;
		}
	}
}
