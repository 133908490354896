/* Style the nav */
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/shadows.scss';
.nav-container {
	grid-area: nav;
	background: colors.$app-background-gradient;
	padding: 10px 80px 10px 5%;
	text-align: center;
	display: flex;
	max-height: 60px;
	justify-content: space-between;
	position: relative;
	z-index: 100;
	& .btn-group {
		&.event-wrapper {
			width: 55%;
		}
		display: flex;
		align-items: center;
		.showfloor-map {
			color: colors.$color-text-white;
			margin-right: spacing.$spacing-2x;
			display: flex;
			align-items: center;
			margin-left: auto;
			margin-top: 3px;
			i {
				font-size: fonts.$font-size-h4-mobile;
				margin-right: 4px;
			}
			&:hover {
				text-decoration: none;
			}
			& > span {
				letter-spacing: 0.12px;
				font-size: fonts.$font-size-big;
				font-family: fonts.$primary-font-family;
				font-style: normal;
				line-height: 25.6px;
				font-weight: fonts.$font-weight-regular;
				&:hover {
					text-decoration: underline;
				}
			}
			&.no-text-wrapper {
				margin-right: spacing.$spacing-x;
				margin-top: 0px;
			}
		}
		& .favorites {
			margin-right: spacing.$spacing-2x;
			text-decoration: none;
			display: flex;
			align-items: center;
			color: colors.$color-text-white;
			i {
				font-size: fonts.$font-size-h4-mobile;
				margin-right: spacing.$spacing-half-x;
			}
			span {
				font-family: fonts.$primary-font-family;
				font-size: fonts.$font-size-big;
				line-height: 26px;
				letter-spacing: fonts.$letter-spacing-std;
				&:hover {
					text-decoration: underline;
				}
			}
			&.no-event {
				margin-left: auto;
			}
		}
		& .search-icon {
			display: none;
		}
		& > a {
			text-decoration: none;
		}
	}
	& .bacon-name {
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-h4;
		line-height: 24px;
		padding-top: 10px;
		color: white !important;
		font-family: 'Ubuntu', sans-serif !important;
	}
	& .bacon-icon {
		align-items: center;
		display: flex;
		margin-right: spacing.$spacing-4x;
		& img {
			width: 258px;
			height: 37px;
		}
		.bacon-icon {
			display: inline-block;
		}
		.bacon-icon-mob {
			display: none;
		}
	}
	& .product-link {
		text-decoration: none;
	}
	& .search-container {
		left: 25.2%;
		position: absolute;
		z-index: 999;
		width: 100%;
		max-width: 700px;
	}
	& .search-anonymous-wrapper {
		max-width: 700px;
	}
}
@media (max-width: 1520px) {
	.nav-container {
		& .search-container {
			max-width: 585px;
		}
		& .search-anonymous-wrapper {
			max-width: 700px;
		}
	}
}
@media (max-width: 1380px) {
	.nav-container {
		& .search-container {
			max-width: 550px;
		}
	}
}
@media (max-width: 1340px) {
	.nav-container {
		& .search-container {
			max-width: 530px;
			left: 25.5%;
		}
	}
}
@media (max-width: 1310px) {
	.nav-container {
		& .search-container {
			max-width: 500px;
			left: 26%;
		}
	}
}
@media (max-width: 1276px) {
	.nav-container {
		& .search-container {
			max-width: 590px;
			left: 27.5%;
		}
	}
}
@media (max-width: 1190px) {
	.nav-container {
		& .search-container {
			max-width: 485px;
			left: 30%;
		}
	}
}
@media (max-width: 1084px) {
	.nav-container {
		& .search-container {
			max-width: 450px;
			left: 32%;
		}
	}
}
@media (max-width: 1035px) {
	.nav-container {
		& .search-container {
			max-width: 400px;
			left: 32.5%;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.nav-container {
		box-shadow: shadows.$shadow-high;
		& .btn-group {
			width: auto;
			&.event-wrapper {
				width: auto;
			}
			& .search-icon {
				display: none;
			}
		}
		padding: 10px 10px 10px 16px;
		& .bacon-icon {
			margin-right: spacing.$spacing-x;
			& img {
				width: 122px;
				height: 16px;
			}
		}
		& .favorites {
			&.favorites-text-hidden {
				margin-right: spacing.$spacing-half-x;
			}
		}
		& .search-container {
			width: 100%;
			max-width: 450px;
			left: 19.5%;
			&.search-anonymous-wrapper {
				display: none;
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.nav-container {
		background: colors.$app-background-gradient;
		padding: spacing.$spacing-1_25x;
		height: 50px;
		box-shadow: shadows.$shadow-high;
		& .btn-group {
			width: auto;
			margin-right: spacing.$spacing-half-x;
			& .favorites {
				margin-right: 6px;
				span {
					display: none;
				}
			}
			.showfloor-map {
				margin-right: spacing.$spacing-half-x;
				span {
					display: none;
				}
			}
			& .search-icon {
				display: flex;
				align-items: center;
				width: 20px;
				height: 20px;
				margin-right: spacing.$spacing-1_5x;
				color: colors.$color-text-white;
				font-size: fonts.$font-size-h4-mobile;
			}
			&.event-wrapper {
				width: auto;
			}
		}
		.product-link {
			a {
				width: 42px;
			}
		}
		.bacon-icon-mob {
			display: inline-block;
		}
		& .bacon-icon {
			width: 71px;
			& img {
				width: 136px;
				height: 19px;
			}
		}
		& .bacon-name {
			color: white;
			font-weight: normal;
			font-family: 'Ubuntu', sans-serif;
		}
		& .btn-group {
			& .favorites {
				& i {
					margin: auto -16px auto auto;
				}
			}
		}
		& .search-container {
			overflow-x: hidden;
			padding: 0px spacing.$spacing-2x 2px spacing.$spacing-2x;
			width: 100%;
			max-width: unset;
			left: 0;
		}
	}
}
@media (min-width: 1281px) and (max-width: 1450px) {
	.nav-container {
		& .btn-group {
			&.event-wrapper {
				width: 75%;
			}
		}
	}
}
@media (max-width: 1437px) {
	.nav-container {
		&.anonymous-content {
			padding: 10px 4% 10px 5%;
		}
	}
}
@media (max-width: 1290px) {
	.nav-container {
		&.anonymous-content {
			padding: 10px 2% 10px 5%;
		}
	}
}
@media (max-width: 1240px) {
	.nav-container {
		&.anonymous-content {
			padding: 10px 1% 10px 5%;
		}
	}
}
