@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/breakpoints.scss';
.bubble {
	align-items: center;
	background: none;
	border-radius: border-radius.$radius-big;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font: inherit;
	gap: 8px;
	height: auto;
	justify-content: center;
	margin: 8px 0 8px 8px;
	outline: inherit;
	padding: 4px 16px;
	width: fit-content;
	& > * {
		width: max-content;
	}
	&.regular {
		background: colors.$color-background-secondary-lightest;
		& .name {
			font-family: fonts.$tertiary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-small;
			line-height: 19px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-gray-darkest;
		}
		.close {
			align-items: center;
			background: #99d2e5;
			border-radius: border-radius.$radius-circle;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			gap: 10px;
			height: 16px;
			justify-content: center;
			order: 1;
			padding: 1px;
			width: 16px;
			.icon {
				font-size: fonts.$font-size-small;
				color: colors.$color-white;
			}
		}
		&:active {
			background: colors.$color-secondary-light;
			&:hover {
				box-shadow: shadows.$shadow-lower;
			}
			& .close {
				background: colors.$color-secondary;
			}
		}
		&:hover {
			box-shadow: shadows.$shadow-lower;
		}
		&.disabled {
			background: colors.$color-background-gray;
			& .name {
				color: colors.$color-text-gray-slate-light;
			}
			& .close {
				background: colors.$color-gray-medium;
			}
			&:hover {
				box-shadow: none;
			}
		}
	}
	&.outline {
		background: colors.$color-background-white;
		border: 1.5px solid colors.$color-primary;
		& .name {
			font-family: fonts.$tertiary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-small;
			line-height: 19px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-primary;
		}
		.close {
			align-items: center;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			gap: 10px;
			height: 16px;
			justify-content: center;
			order: 1;
			padding: 1px;
			width: 16px;
			.icon {
				font-size: fonts.$font-size-big;
				color: colors.$color-primary;
			}
		}
		&:hover {
			background: colors.$color-secondary-lightest;
			box-shadow: shadows.$shadow-lower;
		}
		&:active {
			background: colors.$color-primary;
			border: 1.5px solid colors.$color-primary;
			& .name {
				color: colors.$color-text-white;
			}
			& .close {
				.icon {
					color: colors.$color-text-white;
				}
			}
			&:hover {
				box-shadow: shadows.$shadow-lower;
			}
		}
		&.disabled {
			background: colors.$color-background-white;
			border: 2px solid colors.$color-text-gray-slate-light;
			cursor: none;
			& .name {
				color: colors.$color-text-gray-slate-light;
			}
			& .close {
				.icon {
					color: colors.$color-text-gray-slate-light;
				}
			}
			&:hover {
				box-shadow: none;
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.bubble {
		&.regular {
			padding: 4px 12px;
			& .name {
				font-size: fonts.$font-size-smaller;
				line-height: 16px;
			}
		}
		&.outline {
			padding: 4px 12px;
			& .name {
				font-size: fonts.$font-size-smaller;
				line-height: 16px;
			}
			.close {
				.icon {
					font-size: fonts.$font-size-base;
				}
			}
		}
	}
}
