@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
$footer-font-family: fonts.$primary-font-family, 'proxima-nova', 'Helvetica Neue', Helvetica, Arial,
	sans-serif;
.footer {
	grid-area: footer;
	height: 100px;
	position: relative;
	z-index: 102;
}
.l-footer-min {
	font-family: $footer-font-family;
	padding: 0;
}
.l-footer {
	font-family: $footer-font-family;
	width: 100%;
	position: relative;
	z-index: 102;
}
.l-corporate-footer {
	color: colors.$color-white;
	width: 100%;
}

.informa-redesign .l-corporate-footer .l-corporate-footer-logo-copyrights-wrapper {
	padding: 0;
}

.informa-redesign .l-corporate-footer .l-corporate-footer-logo-copyrights-wrapper {
	padding: 0 25px;
}
.l-corporate-footer .l-corporate-footer-logo-copyrights-wrapper {
	background-color: colors.$color-gray-darker;
	width: 100%;
}

.informa-redesign
	.l-corporate-footer
	.l-corporate-footer-logo-copyrights-wrapper
	.l-corporate-footer-logo-copyrights {
	padding: 14px 0;
}
.l-corporate-footer
	.l-corporate-footer-logo-copyrights-wrapper
	.l-corporate-footer-logo-copyrights {
	display: table;
	margin: 0 auto;
	max-width: 82.625rem;
	padding: 14px 0;
	width: 100%;
}
.l-corporate-footer
	.l-corporate-footer-logo-copyrights-wrapper
	.l-corporate-footer-logo-copyrights
	.l-corporate-footer-logo {
	display: table-cell;
	vertical-align: middle;
	width: 49%;
}
.l-corporate-footer
	.l-corporate-footer-logo-copyrights-wrapper
	.l-corporate-footer-logo-copyrights
	.l-coporate-footer-copyright {
	display: table-cell;
	font-size: 0.875rem;
	font-size: fonts.$font-size-base;
	line-height: 24px;
	text-align: right;
	vertical-align: middle;
	width: 49%;
}

.informa-redesign .l-corporate-footer .l-corporate-footer-links-wrapper {
	padding: 0;
}

.informa-redesign .l-corporate-footer .l-corporate-footer-links-wrapper {
	padding: 0 25px;
}
.l-corporate-footer .l-corporate-footer-links-wrapper {
	background-color: colors.$color-background-black;
	width: 100%;
}

.l-corporate-footer {
	color: colors.$color-white;
	width: 100%;
}
.l-footer-min {
	font-family: $footer-font-family;
	padding: 0;
}

ul {
	display: inline-block;
	list-style-type: none;
	margin-block-end: 1em;
	margin-block-start: 1em;
	margin-inline-end: 0px;
	margin-inline-start: 0px;
	padding-inline-start: 40px;
	text-decoration: none;
}
.l-corporate-footer .l-corporate-footer-links-wrapper .l-coporate-footer-links ul li {
	display: inline-block;
	line-height: 1rem;
	margin: 0;
	padding: 0 8px 0 0;
}
.l-corporate-footer .l-corporate-footer-links-wrapper .l-coporate-footer-links ul li a {
	border-right: 1px solid #a5acaf;
	color: colors.$color-white;
	font-size: 0.875rem;
	line-height: 24px;
	padding-right: 10px;
	text-decoration: none;
}

.l-corporate-footer .l-corporate-footer-links-wrapper .l-coporate-footer-links ul li:last-child a {
	border: none;
	padding-right: 0;
}
.l-coporate-footer-links {
	margin: 0 auto;
	max-width: 87rem;
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.footer {
		height: auto;
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow: hidden;
	}
	.l-corporate-footer {
		.l-corporate-footer-logo-copyrights-wrapper {
			.l-corporate-footer-logo-copyrights {
				display: block !important;
				text-align: center;
				padding: 42px 59px 37px 59px !important;
			}
		}
	}
	.l-corporate-footer-logo {
		width: 100%;
		display: block !important;
		img {
			width: 201px;
			height: auto;
		}
	}
	.l-coporate-footer-copyright {
		display: block !important;
		text-align: center !important;
		margin-top: 38px;
		width: 100% !important;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.l-corporate-footer-logo {
		width: 35%;
		padding-left: 29px;
	}

	.l-coporate-footer-copyright {
		padding-right: 15px;
	}

	.beacon-footer-section,
	.beacon-footer-sub {
		//padding: 0 29px;
		padding-bottom: 40px !important;
	}
}
