@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';

@keyframes prepcards {
	0% {
		left: 0px;
	}
	20% {
		left: -105px;
	}
	33% {
		left: -105px;
	}
	53% {
		left: -160px;
	}
	66% {
		left: -160px;
	}
	87% {
		left: 0px;
	}
	100% {
		left: 0px;
	}
}
@keyframes prepcards-mobile {
	0% {
		left: 0px;
	}
	20% {
		left: -40px;
	}
	33% {
		left: -40px;
	}
	53% {
		left: -60px;
	}
	66% {
		left: -60px;
	}
	87% {
		left: 0px;
	}
	100% {
		left: 0px;
	}
}

@keyframes mapindicator {
	0% {
		transform: rotate(363deg) translateX(3px);
	}
	30% {
		transform: rotate(355deg) translateX(-8px);
	}
	50% {
		transform: rotate(355deg) translateX(-8px);
	}
	80% {
		transform: rotate(363deg) translateX(3px);
	}
	100% {
		transform: rotate(363deg) translateX(3px);
	}
}

@keyframes mapindicator-mobile {
	0% {
		transform: rotate(363deg) translateX(0px);
	}
	30% {
		transform: rotate(355deg) translateX(-4px);
	}
	50% {
		transform: rotate(355deg) translateX(-4px);
	}
	80% {
		transform: rotate(363deg) translateX(0px);
	}
	100% {
		transform: rotate(363deg) translateX(0px);
	}
}

.prep-container {
	width: 100%;
	height: 560px;
	background: colors.$color-background-white;
	border-radius: border-radius.$radius-medium;
	overflow: hidden;
	.prep-header {
		padding: spacing.$spacing-3x;
		text-align: center;
		font-size: fonts.$font-size-h5;
		background: colors.$gradient-blue;
		color: colors.$color-white;
		font-family: fonts.$tertiary-font-family;
		letter-spacing: 0.0075em;
		line-height: 27px;
	}

	.prep-main-body {
		padding: spacing.$spacing-3x;

		.prep-step {
			width: 94%;
			font-size: fonts.$font-size-h5;
			font-family: fonts.$primary-font-family;
			letter-spacing: 0.25px;
			line-height: 32px;
			color: colors.$color-primary-dark;
			font-weight: fonts.$font-weight-semibold;
			font-style: normal;
			span {
				font-family: fonts.$secondary-font-family;
				font-weight: bold;
			}
			a {
				outline: none;
				color: colors.$color-primary;
				font-family: fonts.$secondary-font-family;
			}
			& .link-show-floor {
				display: flex;
				align-items: center;
				text-decoration: none;
			}
			& .show-map {
				text-decoration: underline;
				margin-left: spacing.$spacing-half-x;
			}
		}

		.prep-body {
			height: 271px;
			width: 100%;
			margin-top: spacing.$spacing-4x;
			overflow: hidden;
			.prep-cards {
				position: relative;
				margin-top: spacing.$spacing-3x;
				display: flex;
				width: fit-content;
				gap: 8px;
				animation-name: prepcards;
				animation-direction: normal;
				animation-duration: 6s;
				animation-timing-function: ease-in-out;
				animation-iteration-count: infinite;
			}
			.prep-map {
				display: flex;
				justify-content: center;
				.mapindicator {
					position: absolute;
					margin-top: 45px;
					margin-left: -65px;
					animation-name: mapindicator;
					animation-duration: 4s;
					animation-timing-function: ease-in-out;
					animation-iteration-count: infinite;
				}
			}
		}

		.prep-buttons {
			float: right;
			margin-top: spacing.$spacing-2x;
		}

		.prep-cursor {
			position: absolute;
			margin-top: -237px;
			margin-left: 269px;
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.score-wrapper {
		width: 100% !important;
	}
	.prep-container {
		margin-top: 110px;
		height: 334px !important;
		.prep-header {
			font-size: fonts.$font-size-base;
			padding: 20px;
		}

		.prep-main-body {
			.prep-step {
				font-size: fonts.$font-size-small;
				width: 92%;
				line-height: 19px;
			}
			.prep-body {
				margin-top: 0;
				height: 150px;
				.prep-cards {
					animation-name: prepcards-mobile;
					svg {
						width: 74px;
						height: 101px;
					}
				}
				.prep-map {
					svg {
						width: 163px;
						height: 142px;
					}

					.mapindicator {
						animation-name: mapindicator-mobile;

						width: 44px;
						margin-top: 34px;
						margin-left: -33px;
					}
				}
			}
			.prep-buttons {
				margin-top: 0;
			}
			.prep-cursor {
				margin-top: -109px;
				margin-left: 183px;
				svg {
					width: 19px;
				}
			}
		}
	}
}
