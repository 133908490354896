@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.accordion-container {
	font-weight: fonts.$font-weight-regular;
	font-size: fonts.$font-size-small;
	line-height: 19px;
	color: colors.$color-text-black;
	letter-spacing: fonts.$letter-spacing-std;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	margin: 96px auto auto auto;
}

.accordion-item {
	&.clickable {
		cursor: pointer;
	}
	& .accordion-title {
		display: flex;
		flex-direction: row;

		& .accordion-title-icon {
			margin: auto 0 auto auto;
			cursor: pointer;
			margin-right: 2%;
			font-size: fonts.$font-size-h5;
			& .ri-lock-line {
				color: colors.$color-gray-slate;
			}
		}

		& .accordion-title-text {
			font-family: fonts.$primary-font-family;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-big;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
			font-style: normal;
			line-height: 27px;
			margin-left: 2%;
		}

		& .title-open {
			font-family: fonts.$secondary-font-family;
			font-weight: fonts.$font-weight-bold;
			color: colors.$color-primary;
			line-height: 26px;
		}

		& .accordion-title-comingsoon {
			border-radius: border-radius.$radius-big;
			padding: 4px 16px;
			background: colors.$color-third-light;
			font-size: fonts.$font-size-base;
			line-height: 18px;
			color: colors.$color-primary;
			font-weight: fonts.$font-weight-semibold;
			margin-left: 20px;
		}
		& .accordion-title-likely-new-product {
			font-family: fonts.$tertiary-font-family;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-small;
			line-height: 19.2px;
			font-style: normal;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-primary;
			border-radius: border-radius.$radius-big;
			padding: 4px 16px;
			background: colors.$color-third-light;
			margin-left: spacing.$spacing-x;
		}
	}

	& .separator {
		width: 100%;
		height: 1px;
		background-color: colors.$color-background-gray;
		margin: 16px 0px 16px 0px;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.accordion-container {
		padding: 0 16px;
	}
}
@media (min-width: 332px) and (max-width: 359px) {
	.accordion-container {
		padding: 0 spacing.$spacing-2x;
	}
	.accordion-item {
		& .accordion-title {
			& .accordion-title-text {
				margin-left: 0%;
			}
			& .accordion-title-likely-new-product {
				margin-left: spacing.$spacing-half-x;
				padding: spacing.$spacing-half-x spacing.$spacing-half-x;
				font-size: fonts.$font-size-small-mobile;
				width: 40%;
				text-align: center;
			}
		}
	}
}
@media (min-width: 0px) and (max-width: 332px) {
	.accordion-container {
		padding: 0 spacing.$spacing-2x;
	}
	.accordion-item {
		& .accordion-title {
			& .accordion-title-text {
				margin-left: 0%;
			}
			& .accordion-title-likely-new-product {
				margin-left: spacing.$spacing-half-x;
				padding: spacing.$spacing-half-x spacing.$spacing-half-x;
				font-size: fonts.$font-size-small-mobile;
				width: 37%;
				text-align: center;
			}
		}
	}
}
@media (min-width: 359px) and (max-width: breakpoints.$mobile-breakpoint) {
	.accordion-container {
		padding: 0 spacing.$spacing-2x;
	}
	.accordion-item {
		& .accordion-title {
			& .accordion-title-text {
				margin-left: 0%;
			}
			& .accordion-title-likely-new-product {
				margin-left: spacing.$spacing-half-x;
			}
		}
	}
}
