@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';

.not-for-sale-container {
	display: flex;
	width: 100lvw;
	margin: 50px auto 56px -6%;
	background: colors.$not-labeled-us;

	& .not-for-sale-title {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-bigger;
		color: colors.$color-primary-dark;
		line-height: 25px;
		margin-bottom: 15px;
		& .us-flag {
			height: 18px;
			display: inline-flex;
			margin-left: spacing.$spacing-x;
			margin-bottom: 5px;
		}
	}

	& .not-for-sale-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 65%;
		margin: spacing.$spacing-4x spacing.$spacing-10x spacing.$spacing-4x 5.5%;
	}
	& .not-for-sale-text {
		font-size: fonts.$font-size-big;
		font-weight: 400;
		line-height: 25.6px;
	}
	& .not-for-sale-link {
		margin: auto;
		margin-top: spacing.$spacing-2x;
		width: 100%;
		a {
			justify-content: center;
			width: max-content;
			& .action-link {
				width: 100%;
				color: colors.$color-primary-dark;
			}
			i {
				font-size: fonts.$font-size-bigger;
				color: colors.$color-primary-dark;
				margin-left: 6px;
				font-weight: fonts.$font-weight-regular;
				position: relative;
				top: 0px;
			}
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.not-for-sale-container {
		margin: 50px auto 56px 0%;
		& .not-for-sale-content {
			width: 100%;
			gap: 12px;
			margin: spacing.$spacing-2x 0px spacing.$spacing-2x 3%;

			& .not-for-sale-title {
				margin-bottom: 0px;
				font-size: fonts.$font-size-big;
				& .us-flag {
					height: 16px;
				}
			}
			& .not-for-sale-link {
				a {
					font-size: fonts.$font-size-small;
					font-weight: fonts.$font-weight-bold;
				}
				span {
					font-size: fonts.$font-size-small;
				}
				& i {
					position: static;
					font-size: fonts.$font-size-small-mobile;
					font-weight: fonts.$font-weight-bold;
					margin-left: 6px;
				}
				margin-top: 0px;
			}
			& .not-for-sale-text {
				font-size: fonts.$font-size-base;
				line-height: 22.4px;
			}
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.not-for-sale-container {
		padding-left: spacing.$spacing-2x;
		padding-right: spacing.$spacing-2x;
		margin: 50px auto 56px 0%;
		& .not-for-sale-content {
			width: 100%;
			gap: 16px;
			margin: spacing.$spacing-2x 0 spacing.$spacing-2x 2.5%;
			& .not-for-sale-title {
				margin-bottom: 0px;
				font-size: fonts.$font-size-big;
				& .us-flag {
					height: 16px;
				}
			}
			& .not-for-sale-link {
				& span {
					font-size: fonts.$font-size-small;
				}
				& i {
					font-size: fonts.$font-size-small-mobile;
					font-weight: fonts.$font-weight-bold;
					position: static;
					margin-left: 6px;
				}
				margin-top: 0px;
			}
			& .not-for-sale-text {
				font-size: fonts.$font-size-small;
				line-height: 19.2px;
			}
		}
	}
}
