@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/opacity.scss';
@use '../../../styles/tokens/spacing.scss';

.carousel-bubble-wrapper {
	margin: 0;
	.swiper-button-next:after,
	.swiper-button-prev:after {
		font-size: fonts.$font-size-small;
		color: colors.$color-text-black;
		padding: spacing.$spacing-x spacing.$spacing-1_5x;
		border-radius: border-radius.$radius-circle;
		background-color: #eaeaea;
		margin-top: spacing.$spacing-half-x;
	}
	.swiper-button-next.swiper-button-disabled,
	.swiper-button-prev.swiper-button-disabled {
		opacity: opacity.$opacity-80;
	}
}
.carousel-wrapper {
	width: 270px;
	margin: (spacing.$spacing-x * -1) auto spacing.$spacing-1_5x spacing.$spacing-half-x;
	& .carousel-item {
		width: 100%;
		display: flex;
	}
	& .slick-slide {
		width: auto !important;
		display: flex !important;
	}
}
.slick-prev:before,
.slick-next:before {
	color: colors.$color-black;
}
.slick-prev {
	margin: spacing.$spacing-half-x 0px 0px spacing.$spacing-x;
	color: colors.$color-black;
	display: none !important;
}

.slick-next {
	margin: spacing.$spacing-half-x 0px 0px;
	color: colors.$color-black;
	display: none !important;
}
.category-warapper {
	width: 900px;
}
.show-arrow {
	.slick-prev {
		margin: spacing.$spacing-half-x 0px 0px spacing.$spacing-x;
		color: colors.$color-black;
		display: block !important;
	}
}
.show-nex {
	.slick-next {
		margin: spacing.$spacing-half-x 0px 0px;
		color: colors.$color-black;
		display: block !important;
	}
}
.arrow {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 65%;
	display: block;
	width: 20px;
	height: 20px;
	padding: 0;
	transform: translate(0, -50%);
	cursor: pointer;
	color: colors.$color-transparent;
	border: none;
	outline: none;
	background: colors.$color-transparent;
}
.prev {
	left: (spacing.$spacing-3x * -1);
}
.next {
	right: (spacing.$spacing-3x * -1);
}
.hide-next {
	display: none;
}
.custom-slick {
	width: 100%;
}

.bubbles-contianer .swiper {
	width: 100%;
	height: 100%;
	margin: 0px spacing.$spacing-x;
}

.bubbles-contianer .swiper-slide {
	width: fit-content !important;
}

.bubbles-contianer .filter-carousel-wraper {
	display: flex;
	max-width: 70vw;
	align-items: center;
	width: 100%;
	&.no-arrow-wrapper {
		margin-left: -16px;
	}
}

.bubbles-contianer .swiper-button-prev,
.bubbles-contianer .swiper-button-next {
	display: none;
}

.bubbles-contianer .bi-chevron-right,
.bubbles-contianer .bi-chevron-left {
	cursor: pointer;
}

.bubbles-contianer .bi-chevron-left::before {
	color: colors.$color-text-gray-slate-light !important;
	margin: spacing.$spacing-x 10px spacing.$spacing-1_5x 0px !important;
	width: 5.19px;
	height: 8.49px;
}

.bubbles-contianer .bi-chevron-right::before {
	color: colors.$color-text-gray-slate-light !important;
	margin: spacing.$spacing-x 10px spacing.$spacing-1_5x 2px !important;
	width: 5.19px;
	height: 8.49px;
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.carousel-bubble-wrapper {
		.swiper-button-next:after,
		.swiper-button-prev:after {
			display: none !important;
		}
	}
	.bubbles-contianer .bi-chevron-left::before {
		margin: 12px 8px 22px 0px !important;
		width: 2px !important;
		height: 4px !important;
	}

	.bubbles-contianer .bi-chevron-right::before {
		width: 2px !important;
		height: 4px !important;
		margin: 12px 18px 24px 0px !important;
	}
	.bubbles-contianer .filter-carousel-wraper {
		max-width: 100vw;
		&.left-wrapper-container {
			max-width: 100vw;
		}
	}
	.bubbles-contianer {
		& .swiper {
			margin: 0px auto;
		}
		& .filter-carousel-wraper {
			& .bi-chevron-left {
				&.left-wrapper {
					margin-right: 6px;
				}
			}
			&.no-arrow-wrapper {
				margin-left: 0px;
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.bubbles-contianer {
		& .filter-carousel-wraper {
			max-width: 97vw;
		}
		& .swiper {
			margin: 0px spacing.$spacing-x;
		}
		& .filter-carousel-wraper {
			&.no-arrow-wrapper {
				margin-left: -6px;
			}
		}
	}
}
@media (min-width: 1300px) and (max-width: 1426px) {
	.category-warapper {
		width: 1100px;
	}
}
@media (min-width: 1630px) {
	.bubbles-contianer {
		& .filter-carousel-wraper {
			max-width: unset;
		}
	}
}
