@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/spacing.scss';
.brand-card-container {
	border-radius: border-radius.$radius-default;
	background: colors.$color-background-white;
	border-radius: border-radius.$radius-medium;
	filter: drop-shadow(shadows.$shadow-medium);
	height: 335px;
	grid-auto-rows: 1fr;
	width: 94%;
	& .image {
		height: 172px;
		max-width: 410px;
		padding: spacing.$spacing-x spacing.$spacing-2x;
		& img {
			width: 100%;
			height: 100%;
		}
	}
	& .info {
		display: flex;
		flex-direction: column;
		padding: spacing.$spacing-3x spacing.$spacing-4x spacing.$spacing-3x spacing.$spacing-4x;
		gap: 12px;
		height: 163.22px;
		& .brand-highlighted-name {
			font-family: fonts.$secondary-font-family;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h5;
			text-align: left;
			margin: 0px;
			color: colors.$color-black;
		}
		& .brand-highlighted-description {
			height: 36.22px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-small;
			text-align: left;
			margin: 0px;
			margin-bottom: -2px;
			color: colors.$color-black;
		}
		& .button {
			margin: 0px;
			width: 128px;
			height: 28px;
			padding: 4px 18px 4px 18px;
			font-size: fonts.$font-size-small;
			border: 1px solid colors.$color-primary;
			gap: 2px;
			text-align: center;
			line-height: 19.2px;
			& .ri-arrow-right-s-line {
				font-size: fonts.$font-size-bigger;
			}
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.brand-card-container {
		& .info {
			& .brand-highlighted-name {
				font-size: fonts.$font-size-big;
			}
			& .button {
				font-size: fonts.$font-size-small;
				& .ri-arrow-right-s-line {
					font-size: 18px;
				}
			}
		}
	}
}
