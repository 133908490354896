@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/spacing.scss';
@use '../../styles/tokens/breakpoints.scss';
@keyframes shake {
	0% {
		transform: translate(0, 0) rotate(0);
	}
	16% {
		transform: translate(5px, 0) rotate(30deg);
	}
	32% {
		transform: translate(0, 0) rotate(0);
	}
	48% {
		transform: translate(5px, 0) rotate(30deg);
	}
	64% {
		transform: translate(0, 0) rotate(0);
	}
	80% {
		transform: translate(5px, 0) rotate(30deg);
	}
	96% {
		transform: translate(0, 0) rotate(0);
	}
	100% {
		transform: translate(0, 0) rotate(0);
	}
}
@keyframes reverse-shake {
	0% {
		transform: translate(0, 0) rotate(0);
	}
	16% {
		transform: translate(-5px, 0) rotate(-30deg);
	}
	32% {
		transform: translate(0, 0) rotate(0);
	}
	48% {
		transform: translate(-5px, 0) rotate(-30deg);
	}
	64% {
		transform: translate(0, 0) rotate(0);
	}
	80% {
		transform: translate(-5px, 0) rotate(-30deg);
	}
	96% {
		transform: translate(0, 0) rotate(0);
	}
	100% {
		transform: translate(0, 0) rotate(0);
	}
}
.what-is-new-container {
	height: 873px;
	background: url('../../assets/images/home-page/home-page-wave.png');
	display: flex;
	flex-direction: column;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center;
	& .title-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-top: spacing.$spacing-7x;
		& .what-is-new-title {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h3;
			line-height: 44px;
			color: colors.$color-primary;
		}
	}
	& .what-is-new-content-container {
		display: grid;
		width: 100%;
		height: 100%;
		margin-top: spacing.$spacing-4x;
		padding: 0px spacing.$spacing-5x 0px spacing.$spacing-5x;
		grid-template-columns: 1fr 2fr 1fr;
		& .new-to-market-container {
			padding-top: 210px;
			& .rounded-button {
				margin: 0px;
				& > i {
					font-size: fonts.$font-size-big;
				}
				&:hover {
					& > i {
						transform: translate(4px);
					}
				}
			}
			& .new-to-title-container {
				& .new-to-title {
					font-family: fonts.$secondary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-bold;
					font-size: fonts.$font-size-h4;
					line-height: 33px;
					letter-spacing: 0.0075em;
					color: colors.$color-primary-dark;
				}
			}
			& .new-to-content-container {
				margin-top: spacing.$spacing-2x;
				width: 357px;
				& .new-to-content {
					font-family: fonts.$tertiary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-semibold;
					font-size: fonts.$font-size-big;
					line-height: 29px;
					letter-spacing: 0.0075em;
					color: colors.$color-text-black;
				}
			}
			& .new-to-button-container {
				margin-top: spacing.$spacing-3x;
			}
		}
		& .images-container {
			background: url('../../assets/images/home-page/images-container-background.png') no-repeat;
			background-size: 100% 100%;
			background-position: center;
			display: flex;
			justify-content: center;
			max-width: 560px;
			height: 683px;
			width: 100%;
			margin: 0px auto;
			padding: 36px;
			& .animate {
				animation: shake 2s forwards;
			}
			& #vista-hermosa {
				position: absolute;
				width: 395px;
				height: 476px;
				object-fit: cover;
			}
			& #byheart {
				position: absolute;
				width: 226px;
				height: 253px;
				object-fit: cover;
				margin-left: 303px;
				margin-top: -26px;
				&.animate {
					animation: reverse-shake 2s forwards;
				}
			}
			& #davidstea {
				position: absolute;
				width: 226px;
				height: 329px;
				object-fit: cover;
				margin-left: -325px;
				margin-top: 222px;
			}
			& #benvoleo {
				width: 190px;
				height: 270px;
				position: absolute;
				object-fit: cover;
				margin-left: -339px;
				margin-top: -26px;
			}
			& #ritter {
				width: 240px;
				height: 240px;
				position: absolute;
				object-fit: cover;
				z-index: 3;
				margin-top: 364px;
				margin-left: 25px;
			}
			& #tostrose {
				width: 210px;
				height: 396px;
				position: absolute;
				object-fit: cover;
				margin-left: 343px;
				margin-top: 134px;
				z-index: 1;
				&.animate {
					animation: reverse-shake 2s forwards;
				}
			}
		}
		& .new-to-beacon-container {
			padding-top: 210px;
			& .rounded-button {
				margin: 0px;
				& > i {
					font-size: fonts.$font-size-big;
				}
				&:hover {
					& > i {
						transform: translate(4px);
					}
				}
			}
			& .new-to-title-container {
				& .new-to-title {
					font-family: fonts.$secondary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-bold;
					font-size: fonts.$font-size-h4;
					line-height: 33px;
					letter-spacing: 0.0075em;
					color: colors.$color-primary-dark;
				}
			}
			& .new-to-content-container {
				width: 348px;
				margin-top: spacing.$spacing-2x;
				& .new-to-content {
					font-family: fonts.$tertiary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-semibold;
					font-size: fonts.$font-size-big;
					line-height: 29px;
					letter-spacing: 0.0075em;
					color: colors.$color-text-black;
				}
			}
			& .new-to-button-container {
				margin-top: spacing.$spacing-3x;
			}
		}
	}
}
@media screen and (min-width: 1260px) and (max-width: 1400px) {
	.what-is-new-container {
		& .what-is-new-content-container {
			& .new-to-beacon-container {
				transform: translateX(40px);
			}
		}
	}
}
@media screen and (min-width: 1260px) and (max-width: 1290px) {
	.what-is-new-container {
		& .what-is-new-content-container {
			& .images-container {
				& #vista-hermosa {
					width: 356px;
					height: 428px;
				}
				& #byheart {
					width: 203px;
					height: 227px;
					margin-left: 271px;
					margin-top: -21px;
				}
				& #davidstea {
					width: 203px;
					height: 296px;
					margin-left: -301px;
					margin-top: 198px;
				}
				& #benvoleo {
					width: 171px;
					height: 243px;
					margin-left: -311px;
					margin-top: -21px;
				}
				& #ritter {
					width: 216px;
					height: 216px;
					margin-top: 327px;
					margin-left: 6px;
				}
				& #tostrose {
					width: 189px;
					height: 356px;
					margin-left: 285px;
					margin-top: 124px;
				}
			}
		}
	}
}
@media screen and (min-width: 800px) and (max-width: 1259px) {
	.what-is-new-container {
		height: 574px;
		& .title-container {
			margin-top: spacing.$spacing-3x;
			& .what-is-new-title {
				font-size: fonts.$font-size-h5;
				line-height: 27px;
				letter-spacing: 0.0075em;
			}
		}
		& .what-is-new-content-container {
			grid-template-columns: 1fr 1fr;
			margin-top: spacing.$spacing-3x;
			& .new-to-beacon-container {
				margin-top: 184px;
			}
			& .new-to-market-container,
			& .new-to-beacon-container {
				grid-column: 1;
				grid-row: 1;
				padding-top: 0px;
				& .new-to-title-container {
					& .new-to-title {
						font-size: fonts.$font-size-bigger;
						line-height: 24.51px;
					}
				}
				& .new-to-content-container {
					width: 100%;
					max-width: 400px;
				}
				& .new-to-content-container {
					margin-top: spacing.$spacing-x;
					& .new-to-content {
						line-height: 28.8px;
					}
				}
				& .new-to-button-container {
					margin-top: spacing.$spacing-x;
				}
			}
			& .images-container {
				background-size: 60% 50%;
				background-position: top;
				& #vista-hermosa {
					margin-top: -90px;
					transform: rotate(10deg);
					scale: 0.7;
				}
				& #byheart {
					margin-left: 219px;
					margin-top: -64px;
					scale: 0.7;
				}
				& #davidstea {
					margin-left: -235px;
					margin-top: 81px;
					scale: 0.7;
				}
				& #benvoleo {
					margin-left: -215px;
					margin-top: -77px;
					scale: 0.7;
				}
				& #ritter {
					transform: rotate(4deg);
					margin-top: 180px;
					margin-left: 8px;
					scale: 0.7;
				}
				& #tostrose {
					margin-left: 241px;
					margin-top: 22px;
					scale: 0.7;
				}
			}
		}
	}
}
@media screen and (min-width: 600px) and (max-width: 799px) {
	.what-is-new-container {
		height: 574px;
		& .title-container {
			margin-top: spacing.$spacing-3x;
			& .what-is-new-title {
				font-size: fonts.$font-size-h5;
				line-height: 27px;
				letter-spacing: 0.0075em;
			}
		}
		& .what-is-new-content-container {
			grid-template-columns: 1fr 1fr;
			margin-top: spacing.$spacing-2x;
			& .new-to-beacon-container {
				margin-top: 184px;
			}
			& .new-to-market-container,
			& .new-to-beacon-container {
				grid-column: 1;
				grid-row: 1;
				padding-top: 0px;
				& .new-to-title-container {
					& .new-to-title {
						font-size: fonts.$font-size-bigger;
						line-height: 24.51px;
					}
				}
				& .new-to-content-container {
					width: 100%;
					max-width: 296px;
				}
				& .new-to-content-container {
					margin-top: spacing.$spacing-x;
					& .new-to-content {
						line-height: 28.8px;
					}
				}
				& .new-to-button-container {
					margin-top: spacing.$spacing-x;
				}
			}
			& .images-container {
				grid-column: 2;
				padding: spacing.$spacing-10x;
				background-size: 220px 400px;
				background-position: top;
				& #vista-hermosa {
					width: 173px;
					height: 239px;
					margin-top: -20px;
					margin-left: -23px;
					transform: rotate(10deg);
				}
				& #byheart {
					width: 105px;
					height: 126px;
					margin-left: 140px;
					margin-top: -28px;
				}
				& #davidstea {
					width: 115px;
					height: 167px;
					margin-left: -183px;
					margin-top: 83px;
				}
				& #benvoleo {
					width: 80px;
					height: 141px;
					margin-left: -190px;
					margin-top: -33px;
				}
				& #ritter {
					width: 130px;
					height: 130px;
					margin-top: 163px;
					margin-left: 8px;
				}
				& #tostrose {
					width: 95px;
					height: 218px;
					margin-left: 160px;
					margin-top: 25px;
					transform: rotate(5deg);
				}
			}
		}
	}
	@media screen and (max-width: 660px) {
		.what-is-new-container {
			& .what-is-new-content-container {
				overflow: hidden;
				grid-template-columns: 320px auto;
			}
		}
	}
}
@media screen and (min-width: 0px) and (max-width: 599px) {
	.what-is-new-container {
		background: url('../../assets/images/home-page/home-page-wave-mobile.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
		height: 804px;
		& .title-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			text-align: center;
			margin-top: spacing.$spacing-3x;
			& .what-is-new-title {
				font-size: fonts.$font-size-bigger-mobile;
				line-height: 22px;
			}
		}
		& .what-is-new-content-container {
			padding: 0px spacing.$spacing-1_5x 0px spacing.$spacing-1_5x;
			margin-top: spacing.$spacing-3x;
			grid-template-columns: 1fr;
			grid-template-rows: 134px 330px 134px;
			& .images-container {
				height: auto;
				padding: 0;
				margin-top: spacing.$spacing-3x;
				background-size: 250px 250px;
				background-position: center;
				& #vista-hermosa {
					width: 198px;
					height: 273px;
					margin-top: 26px;
					margin-left: -23px;
					transform: rotate(8deg);
				}
				& #byheart {
					width: 121px;
					height: 144px;
					margin-left: 159px;
					margin-top: 4px;
				}
				& #davidstea {
					width: 132px;
					height: 191px;
					margin-left: -212px;
					margin-top: 155px;
				}
				& #benvoleo {
					width: 80px;
					height: 162px;
					margin-left: -220px;
					margin-top: 4px;
				}
				& #ritter {
					width: 149px;
					height: 149px;
					margin-top: 233px;
					margin-left: 8px;
					transform: rotate(4deg);
				}
				& #tostrose {
					width: 90px;
					height: 250px;
					margin-left: 190px;
					margin-top: 93px;
					transform: rotate(-2deg);
				}
			}
			& .new-to-market-container,
			& .new-to-beacon-container {
				padding: 0;
				& .new-to-title-container {
					display: flex;
					justify-content: center;
					align-items: center;
					& .new-to-title {
						font-size: fonts.$font-size-bigger-mobile;
						line-height: 22px;
					}
				}
				& .new-to-content-container {
					width: 100%;
					max-width: 296px;
					margin: auto;
				}
				& .new-to-content-container {
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: justify;
					margin-top: spacing.$spacing-x;
					& .new-to-content {
						font-size: fonts.$font-size-big-mobile;
						line-height: 22px;
					}
				}
				& .new-to-button-container {
					margin-top: spacing.$spacing-1_5x;
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: justify;
				}
			}
			& .new-to-beacon-container {
				margin-top: spacing.$spacing-9x;
			}
		}
	}
	@media (max-width: 450px) {
		.what-is-new-container {
			& .what-is-new-content-container {
				& .images-container {
					height: auto;
					padding: 0;
					margin-top: spacing.$spacing-3x;
					background-size: 250px 250px;
					background-position: center;
					& #vista-hermosa {
						width: 144px;
						height: 199px;
						margin-top: 26px;
						margin-left: -23px;
						transform: rotate(8deg);
					}
					& #byheart {
						width: 88px;
						height: 105px;
						margin-left: 104px;
						margin-top: 15px;
					}
					& #davidstea {
						width: 96px;
						height: 139px;
						margin-left: -146px;
						margin-top: 122px;
					}
					& #benvoleo {
						width: 70px;
						height: 118px;
						margin-left: -167px;
						margin-top: 16px;
					}
					& #ritter {
						width: 108px;
						height: 108px;
						margin-top: 180px;
						margin-left: 8px;
						transform: rotate(4deg);
					}
					& #tostrose {
						width: 75px;
						height: 182px;
						margin-left: 134px;
						margin-top: 75px;
						transform: rotate(-5deg);
					}
				}
				& .new-to-beacon-container {
					margin-top: auto;
				}
			}
		}
	}
}
