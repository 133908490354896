@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';
.bubbles-contianer {
	display: flex;
	flex-wrap: wrap;
	height: auto;
	margin: -4px 15px 0px 9px;
	width: auto;
	&.collapsed {
		margin: 2px 0px 0px 9px;
		& .filter-carousel-wraper {
			max-width: 89vw;
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.bubbles-contianer {
		margin: 0px 0px -8px spacing.$spacing-x;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.bubbles-contianer {
		&.collapsed {
			margin: 0px 0px 4px 9px;
			& .filter-carousel-wraper {
				max-width: 97vw;
			}
		}
	}
}
