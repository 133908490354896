@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';

.favorites-container {
	display: flex;
	justify-content: space-between;
	.title {
		color: colors.$color-text-black;
		font-family: fonts.$secondary-font-family;
		font-size: fonts.$font-size-h4;
		font-weight: fonts.$font-weight-bold;
		line-height: normal;
		font-style: normal;
		letter-spacing: 0.18px;
	}

	.exports {
		display: flex;

		button {
			background: colors.$color-background-white;
			padding: 6px 32px;
			border: 2px solid colors.$color-primary;
			box-shadow: shadows.$shadow-low;
			border-radius: border-radius.$radius-biggest;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-primary;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-big;
			line-height: 28px;

			&:first-child {
				margin-right: 32px;
			}
		}
	}

	.export-csv {
		background: none;
		align-items: center;
		border: none;
		color: colors.$color-first-tier;
		cursor: pointer;
		display: flex;
		font-family: fonts.$secondary-font-family;
		font-size: fonts.$font-size-small;
		font-weight: fonts.$font-weight-bold;
		line-height: 19px;
		outline: inherit;
		padding: 0;

		&.disabled {
			color: colors.$color-gray-slate-light;
			cursor: not-allowed;
		}

		& i {
			font-weight: 500;
			padding: spacing.$spacing-half-x;
			font-size: fonts.$font-size-bigger;
		}
	}
}

.favorite-filter-sort-container {
	display: flex;
	justify-content: space-between;
	margin-top: spacing.$spacing-3x;
	.favorite-sort-container {
		z-index: 1;
		position: relative;
		.sort-products-container {
			margin-right: 0;
			.sort-by-btn {
				z-index: 0;
			}
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.favorites-container {
		.title {
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			line-height: 27px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
			font-size: fonts.$font-size-h5;
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.favorites-container {
		display: block;
		.title {
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-h6-mobile;
			line-height: 28px;
			border-bottom: 2px solid colors.$color-gray;
			margin: auto;
			font-family: fonts.$secondary-font-family;
			display: flex;
			width: 100%;
			height: 30px;
		}

		.export-csv {
			justify-content: center;
			margin: 0px auto;
		}

		.exports {
			button {
				padding: 2px spacing.$spacing-3x;
				border: 1px solid #00748c;
				color: #00748c;
				font-weight: fonts.$font-weight-semibold;
				font-size: fonts.$font-size-base-mobile;
				line-height: 20px;

				&:first-child {
					margin-right: 8px;
				}
			}
		}
	}
	.favorite-filter-sort-container {
		.favorite-sort-container {
			margin-right: 12px;
		}
	}
	.product-card {
		width: 136px;
		height: auto;
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) {
	.favorites-container {
		border-bottom: 2px solid colors.$color-gray;
		padding-bottom: spacing.$spacing-x;
	}
}
