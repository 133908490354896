@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';

.loading-container {
	display: flex;
	gap: spacing.$spacing-7x;
	margin-top: spacing.$spacing-3x;

	@keyframes loading-skeleton {
		0% {
			background-color: colors.$color-background-gray;
		}

		50% {
			background-color: colors.$color-background-light-gray;
		}

		100% {
			background-color: colors.$color-background-gray;
		}
	}

	& .templates-skeleton {
		height: 405px;
		width: calc(100% - spacing.$spacing-7x);
		background-color: colors.$color-background-gray;
		display: inline-flex;
		line-height: 1;
		position: relative;
		overflow: hidden;
		z-index: 1;
		border-radius: border-radius.$radius-medium;
		animation-name: loading-skeleton;
		animation-direction: normal;
		animation-duration: 2s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
	}

	@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
		& .templates-skeleton {
			width: 100%;

			&:nth-child(2) {
				display: none;
			}
		}
	}
}
