@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';

.info-text-container {
	& .info-text {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-h5;
		line-height: 32px;
		letter-spacing: 0.0075em;
		color: colors.$color-text-black;
	}
}

@media (max-width: breakpoints.$tablet-breakpoint) {
	.info-text-container {
		& .info-text {
			font-size: fonts.$font-size-big;
			line-height: 25.6px;
		}
	}
}

@media (max-width: 500px) {
	.info-text-container {
		& .info-text {
			font-size: fonts.$font-size-base;
			line-height: 22.4px;
		}
	}
}
