@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';
$footer-font-family: fonts.$primary-font-family, 'proxima-nova', 'Helvetica Neue', Helvetica, Arial,
	sans-serif;

.beacon-footer {
	background: colors.$gradient-blue;

	.beacon-footer-section {
		display: flex;
		padding-top: 48px;
		max-width: 88.625rem;
		margin: 0 auto;

		.partner {
			width: 75%;
		}

		.service {
			width: 25%;
		}

		.label {
			font-family: $footer-font-family;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-big;
			line-height: 24px;
			color: colors.$color-white;
		}
	}

	.beacon-footer-sub {
		display: flex;
		padding: spacing.$spacing-4x 0;
		max-width: 75%;
		margin: 0 auto;

		.partners {
			width: 66%;

			.partners-label {
				font-family: $footer-font-family;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-big;
				line-height: 24px;
				color: colors.$color-white;
			}

			.partner-wrap {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 10%;
				border-right: 1px solid rgba(255, 255, 255, 0.2);

				.partner {
					font-family: $footer-font-family;
					font-weight: fonts.$font-weight-regular;
					font-size: fonts.$font-size-base;
					line-height: 24px;
					color: colors.$color-white;

					.block {
						display: block;
					}

					.partner-logo {
						height: 74px;
						display: flex;
						align-items: flex-end;
						margin: spacing.$spacing-x 0;
					}

					.partner-text {
						text-align: left;
					}

					.partner-text a {
						text-decoration-line: fonts.$text-underline;
						color: colors.$color-white;
					}

					.partner-label {
						height: 24px;
					}
				}
			}
		}

		.services {
			width: 36%;

			.services-wrap {
				width: fit-content;
				margin: auto;

				.services-label {
					font-family: $footer-font-family;
					font-weight: fonts.$font-weight-bold;
					font-size: fonts.$font-size-big;
					line-height: 24px;
					color: colors.$color-white;
				}

				.service {
					width: fit-content;

					.service-logo {
						height: 74px;
						display: flex;
						align-items: center;
						margin: spacing.$spacing-x auto;
					}

					.service-text {
						text-align: left;
					}

					.service-text a {
						font-family: $footer-font-family;
						font-style: normal;
						font-weight: fonts.$font-weight-regular;
						font-size: fonts.$font-size-base;
						line-height: 24px;
						text-decoration-line: fonts.$text-underline;
						color: colors.$color-white;
					}

					.service-label {
						height: 24px;
					}
				}
			}
		}
	}

	.footer-help-links {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-big;
		line-height: 24px;

		display: flex;
		justify-content: center;
		gap: 24px;
		padding-bottom: spacing.$spacing-4x;

		a {
			color: colors.$color-white;
		}
	}
}

.wave {
	background: url('../../assets/images/wave-background1.png') no-repeat bottom;
	background-size: 100% 100%;
	margin: auto;
	width: 100%;
	height: 332px;
	z-index: -1;
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.wave {
		background-size: unset;
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$tablet-breakpoint) {
	.beacon-footer {
		.beacon-footer-section {
			display: none !important;

			.partner {
				color: white !important;
			}
		}

		.beacon-footer-sub {
			display: block !important;
			width: 100%;
			min-width: 230px;

			.beacon-footer-section {
				display: block !important;
				padding-top: spacing.$spacing-8x;
			}

			.partners {
				width: fit-content;
				margin: auto;

				.partners-label {
					margin-bottom: spacing.$spacing-x;
				}

				.partner-wrap {
					display: block !important;
					padding: 0 0 spacing.$spacing-7x 0 !important;
					border-right: 0px;

					.partner {
						width: 100% !important;
						margin-top: spacing.$spacing-3x;

						.partner-logo {
							width: 225px;
						}

						.partner-text {
							margin-top: spacing.$spacing-half-x;

							a {
								color: white !important;
							}
						}
					}
				}
			}

			.services {
				border-left: none !important;
				width: 100% !important;
				border-top: 1px solid rgba(255, 255, 255, 0.2);
				padding-top: spacing.$spacing-7x;

				.beacon-footer-section {
					.service {
						float: none !important;
						padding: 0 !important;
						width: 100% !important;
						padding-bottom: spacing.$spacing-2x !important;
						color: white !important;
					}
				}

				.service {
					float: none !important;
					padding: 0 !important;
					width: 100% !important;

					.service-logo {
						height: auto !important;

						img {
							width: 225px !important;
							height: auto !important;
						}
					}

					.service-text {
						margin-top: spacing.$spacing-half-x;

						a {
							color: white !important;
						}
					}
				}
			}
		}

		.footer-help-links {
			display: block !important;
			text-align: center;
			margin-top: -8px;
			width: 250px;
			margin-left: auto;
			margin-right: auto;
			position: relative;

			& div {
				margin-top: spacing.$spacing-2x;
			}
		}
	}

	.wave {
		background-size: unset;
	}
}
