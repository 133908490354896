@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
.main-menu-name {
	cursor: pointer;
	font-family: fonts.$primary-font-family;
	font-style: normal;
	font-weight: fonts.$font-weight-regular;
	font-size: fonts.$font-size-big;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: 0.44px;
	color: colors.$color-text-black;
	margin-bottom: 18px;
}
.main-menu-name:hover {
	text-decoration: fonts.$text-underline;
}
.menu-active {
	& > span {
		font-family: fonts.$secondary-font-family;
	}
}
.main-menu-items {
	display: none;
}
.filter-active {
	display: block;
}
.filter-hidden {
	display: none;
}
.main-panel-name {
	font-family: fonts.$primary-font-family;
	font-style: normal;
	font-weight: fonts.$font-weight-regular;
	font-size: fonts.$font-size-base;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: 0.44px;
	color: colors.$color-text-black;
	height: 40px;
	margin-left: 16px;
	& > .icon {
		margin: auto 14.7px 14.7px auto;
		margin-left: auto;
		font-size: fonts.$font-size-h5;
	}
}
.mobile-aling {
	margin-left: 6px;
}
.scroll-check {
	max-height: calc(100vh - 250px);
	overflow-y: auto;
}
