@use '../../styles/tokens/spacing.scss';
@use '../../styles/tokens/breakpoints.scss';
.home-page-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	overflow: hidden;
	& .event-slider-container {
		width: 100%;
		margin-top: spacing.$spacing-3x;
	}
	& .what-is-new-section-container {
		width: 100%;
		margin-top: spacing.$spacing-8x;
	}
	& .browse-by-category-container {
		width: 100%;
		margin-top: 134px;
		margin-bottom: spacing.$spacing-12x;
	}
	& .highlighted-products-container {
		width: 100%;
	}
	& .highlighted-brands-container {
		width: 100%;
	}
}

@media (max-width: breakpoints.$tablet-breakpoint) {
	.home-page-container {
		& .event-slider-container {
			margin-top: spacing.$spacing-3x;
		}
		& .what-is-new-section-container {
			margin-top: spacing.$spacing-4x;
		}
		& .browse-by-category-container {
			margin-top: spacing.$spacing-4x;
			margin-bottom: spacing.$spacing-7x;
		}
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.home-page-container {
		& .what-is-new-section-container {
			margin-top: spacing.$spacing-3x;
		}
		& .browse-by-category-container {
			margin-top: spacing.$spacing-3x;
			margin-bottom: spacing.$spacing-5x;
		}
	}
}
