@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';

.product-certifications-form {
	margin-top: spacing.$spacing-2x;
	p {
		margin: 0px;
		margin-top: spacing.$spacing-1_5x;
		a {
			color: inherit;
			text-decoration: none;
		}
	}
}
