@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.score-container {
	background: colors.$color-background-white;
	box-shadow: shadows.$shadow-lower;
	border-radius: border-radius.$radius-default;
	min-height: 167px;

	width: 414px;
	position: relative;
	a {
		color: colors.$color-black;
	}
	.score-collapsed {
		width: 100%;
		transition: 0.5s;
		& .hg-content-container {
			& .hg-title-container {
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				margin-top: 2px;
				.score-img {
					margin-left: spacing.$spacing-half-x;

					img {
						width: 56px;
						height: auto;
					}
				}

				.hover {
					border: 2px solid #729987;
				}

				.score-label {
					font-family: fonts.$primary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-regular;
					font-size: fonts.$font-size-small;
					line-height: 19px;
					letter-spacing: fonts.$letter-spacing-std;
					color: colors.$color-text-black;
					border-radius: border-radius.$radius-medium;
					display: flex;
					margin: 0px 4px 0px 6px;
					height: fit-content;
					padding: 2px 12px 2px 12px;
					& > b {
						font-weight: fonts.$font-weight-bold;
						font-family: fonts.$secondary-font-family;
						margin-right: 2px;
						margin-left: 2px;
					}
					& > i {
						font-size: fonts.$font-size-base;
					}
					&.best {
						background-color: colors.$best-estimate;
					}
					&.good {
						background-color: colors.$good-estimate;
					}
					&.great {
						background-color: colors.$great-estimate;
					}
					&.standards {
						background-color: colors.$color-background-gray;
					}
				}

				.score-circle {
					padding: 20px;

					.score {
						border-radius: border-radius.$radius-small;
						width: 62px;
						height: 41px;
						align-items: center;
						display: flex;
						justify-content: center;
						font-weight: fonts.$font-weight-bold;
						font-size: fonts.$font-size-h4;
						line-height: 40px;
						letter-spacing: 0.325581px;
						text-transform: uppercase;
						color: colors.$color-text-black;
					}
				}
				.question-logo {
					position: absolute;
					right: 2px;
					top: -4px;
					& .question-icon {
						font-size: fonts.$font-size-h5;
						cursor: pointer;
						color: colors.$color-gray-slate;
					}
				}
			}
			& .hg-content {
				display: flex;
				width: 92%;
				margin-left: auto;
				margin-right: auto;
				border-top: 1px solid colors.$color-gray;
				&.hg-none-wrapper {
					display: block;
				}
				& .hg-text {
					& .hg-explanation {
						&.none-wrapper {
							display: flex;
							justify-content: center;
							align-items: center;
							text-align: center;
							width: 100%;

							& .hg-explanation-text {
								margin: 4px auto auto auto;
								font-family: fonts.$primary-font-family;
								font-style: normal;
								font-weight: fonts.$font-weight-regular;
								font-size: fonts.$font-size-smaller;
								line-height: 16px;
								letter-spacing: fonts.$letter-spacing-std;
								color: colors.$color-text-black;
								width: fit-content;
								& > b {
									font-weight: fonts.$font-weight-bold;
									font-family: fonts.$secondary-font-family;
									margin-right: 3px;
								}
							}
						}
						& .hg-explanation-text {
							margin: 4px 0px 0px 0px;
							font-family: fonts.$primary-font-family;
							font-style: normal;
							font-weight: fonts.$font-weight-regular;
							font-size: fonts.$font-size-smaller;
							line-height: 16px;
							letter-spacing: fonts.$letter-spacing-std;
							color: colors.$color-text-black;
							width: fit-content;
							& > b {
								font-weight: fonts.$font-weight-bold;
								font-family: fonts.$secondary-font-family;
								margin-right: 3px;
							}
						}
					}
					& .estimate-container {
						display: flex;
						width: 100%;
						justify-content: center;
						margin-top: spacing.$spacing-half-x;
						&.hg-none-estimate-wrapper {
							margin-top: spacing.$spacing-3x;
						}
						& .product-estimate {
							display: flex;
							flex-direction: column;
							margin-right: 12px;
							& .estimate-text {
								font-family: fonts.$primary-font-family;
								font-style: normal;
								font-weight: fonts.$font-weight-regular;
								font-size: fonts.$font-size-smaller;
								line-height: 16px;
								letter-spacing: fonts.$letter-spacing-std;
								color: colors.$color-gray-darker;
							}
							& .estimate-value-container {
								display: flex;
								width: 45px;
								height: 23px;
								background: colors.$estimate-color-background;
								border-radius: border-radius.$radius-small;
								justify-content: center;
								align-items: center;
								text-align: center;
								margin: 4px 0px 0px 21px;
								i {
									font-size: fonts.$font-size-big;
								}
								& .estimate-value {
									font-style: normal;
									font-weight: fonts.$font-weight-semibold;
									font-size: fonts.$font-size-base;
									line-height: 22px;
									letter-spacing: fonts.$letter-spacing-std;
									text-align: center;
									margin: 0px 0px 0px 4px;
								}
							}
						}
						& .category-average {
							display: flex;
							flex-direction: column;
							margin-left: 12px;
							& .average-text {
								font-family: fonts.$primary-font-family;
								font-style: normal;
								font-weight: fonts.$font-weight-regular;
								font-size: fonts.$font-size-smaller;
								line-height: 16px;
								letter-spacing: fonts.$letter-spacing-std;
								color: colors.$color-gray-darker;
							}
							& .average-value-container {
								display: flex;
								width: 45px;
								height: 23px;
								background: colors.$estimate-color-background;
								border-radius: border-radius.$radius-small;
								justify-content: center;
								align-items: center;
								text-align: center;
								margin: 4px 0px 0px 21px;
								i {
									font-size: fonts.$font-size-big;
								}
								& .average-value {
									font-style: normal;
									font-weight: fonts.$font-weight-semibold;
									font-size: fonts.$font-size-base;
									line-height: 22px;
									letter-spacing: fonts.$letter-spacing-std;
									text-align: center;
									margin: 0 0px 0px 4px;
								}
							}
						}
						& .versus-text {
							font-family: fonts.$secondary-font-family;
							font-style: normal;
							font-weight: fonts.$font-weight-bold;
							font-size: fonts.$font-size-smaller;
							line-height: 16px;
							letter-spacing: fonts.$letter-spacing-std;
							color: colors.$color-black;
							margin-bottom: 21px;
						}
					}
				}
			}
		}
	}

	.hover {
		border: 1px solid rgba(0, 0, 0, 1);
		border-radius: border-radius.$radius-small;

		& > div:first-of-type {
			transform: translateX(10px);
			transition: transform 330ms ease-in-out;
		}
	}

	.score-description {
		background-image: url('./../../../assets/images/howGood_background.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
		max-height: 0;
		transition: max-height 0.15s ease-out;
		overflow: hidden;
		display: flex;

		.score-desc-block {
			.header {
				font-weight: fonts.$font-weight-bold;
				padding: 20px 0;
			}

			.body {
				padding: 15px 33px;
				font-size: fonts.$font-size-big;
				line-height: 26px;
				letter-spacing: 0.25px;
				color: colors.$color-text-black;

				& b {
					font-family: fonts.$secondary-font-family;
				}
			}

			.key-metrics {
				display: flex;
				flex-wrap: wrap;
				margin: 20px 80px;

				.flex {
					display: flex !important;
					margin-top: 8px;

					.key-metric {
						font-weight: fonts.$font-weight-regular;
						font-size: fonts.$font-size-base;
						line-height: 24px;
						margin-left: 5px;
						letter-spacing: 0.25px;

						color: colors.$color-text-black;
					}

					.fa {
						color: white;
						object-fit: contain;
						background: #407b7f;
						width: 20px;
						border-radius: border-radius.$radius-circle;
						height: 20px;
						padding: 2px;
					}
				}

				.center {
					width: 30%;
				}

				.left {
					width: 70%;
				}
			}

			.scoring {
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-big;
				line-height: 26px;
				letter-spacing: 0.25px;
				color: colors.$color-text-black;
				padding: 0 33px;
				b {
					font-family: fonts.$secondary-font-family;
				}
				.score-impact {
					padding: 0 57px;
					margin-top: 30px;

					.bar-flex {
						display: flex;
						width: 100%;
						height: 16px;
						margin-top: -16px;
						.goodbar {
							width: 4px;
							height: 16px;
							background: colors.$color-how-good-bar;
							margin-left: 60%;
						}
						.greatbar {
							width: 4px;
							height: 16px;
							background: colors.$color-how-great-bar;
							margin-left: 13%;
						}
						.bestbar {
							width: 4px;
							height: 16px;
							background: colors.$color-how-best-bar;
							margin-left: 13%;
						}
					}
					.flex {
						display: flex;
						padding: 0 5px;
						position: relative;
						height: 30px;
						label {
							font-weight: fonts.$font-weight-bold;
							font-size: fonts.$font-size-base;
							line-height: 22px;
							letter-spacing: 0.25px;
						}

						.good {
							position: absolute;
							color: colors.$color-how-good-text;
							margin-left: 60%;
							p {
								margin-left: -23px;
								color: inherit;
								font-size: inherit;
								line-height: inherit;
								font-weight: inherit;
							}
						}
						.great {
							color: colors.$color-how-great-text;
							margin-left: 73%;
							position: absolute;
							p {
								margin-left: -19px;
								color: inherit;
								font-size: inherit;
								line-height: inherit;
								font-weight: inherit;
							}
						}
						.best {
							color: colors.$color-how-best-text;
							margin-left: 86%;
							position: absolute;
							p {
								margin-left: -9px;
								color: inherit;
								font-size: inherit;
								line-height: inherit;
								font-weight: inherit;
							}
						}
					}

					.score-bar {
						width: 100%;
						height: 16px;
						background: linear-gradient(89.98deg, #fef876 45.84%, #6bd6d6 72.92%, #c2e950 99.99%);
						border-radius: border-radius.$radius-default;
					}
				}
			}

			.footer {
				padding: 10px 33px 16px;
				display: block;
				height: auto;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-big;
				line-height: 26px;
				letter-spacing: 0.25px;

				a {
					padding-left: 5px;
					text-decoration: fonts.$text-underline;
					color: black;
					font-weight: fonts.$font-weight-bold;
					font-family: fonts.$secondary-font-family;
					cursor: pointer;
				}
			}

			.updated-info {
				font-size: fonts.$font-size-big;
				line-height: 22px;
				letter-spacing: 0.25px;
				padding: 0px 33px 32px;
				color: colors.$color-background-slate;
			}
		}
	}

	.active {
		max-height: 100%;
		transition: max-height 0.25s ease-in;
		border-top: 1px solid #e7e7e7;
	}

	.close_icon {
		position: absolute;
		top: 13px;
		right: 13px;
		font-size: fonts.$font-size-h4;
		line-height: fonts.$font-size-h4;
	}
	&.best {
		border: 2px solid colors.$best-estimate;
	}
	&.good {
		border: 2px solid colors.$good-estimate;
	}
	&.great {
		border: 2px solid colors.$great-estimate;
	}
	&.standards {
		border: 2px solid colors.$color-gray;
	}
}

.open {
	border: 1px solid rgba(0, 0, 0, 1);
}

.modalopen {
	border: 0px;
	box-shadow: shadows.$shadow-bigger;
	border-radius: border-radius.$radius-medium;
	font-size: fonts.$font-size-small !important;
	width: auto;
	&.score-container {
		& .score-collapsed {
			justify-content: center;
			& .score-img {
				margin-left: 0px !important;
				& > img {
					width: auto;
					height: 70px;
				}
			}
		}
	}
}

.modal-score-container {
	& .modal-content {
		width: auto;
		margin: auto;
	}
}
@media (min-width: 0px) and (max-width: 450px) {
	.score-container {
		width: 300px;
	}
}

@media (min-width: 0px) and (max-width: 345px) {
	.score-container {
		margin: auto;
		.score-collapsed {
			& .hg-content-container {
				& .hg-title-container {
					.score-label {
						font-size: 7px !important;
						i {
							font-size: 7px;
						}
					}
				}
				.hg-content {
					.hg-explanation-text {
						font-size: 7px !important;
					}
				}
			}
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.score-container {
		width: 300px;
		margin: auto;
		min-height: 183px;
		.score-collapsed {
			& .hg-content-container {
				& .hg-title-container {
					margin-top: spacing.$spacing-x;
					.score-img {
						margin-bottom: 4px;
					}
					.score-label {
						font-size: fonts.$font-size-smaller;
						line-height: 16px;
						margin: 0 4px 0 3px;
						letter-spacing: 0.02px;
						padding: 2px 6px 2px 6px;
						& > b {
							margin-left: 1px;
						}
					}
					.question-logo {
						right: 0;
						top: -11px;
						& .question-icon {
							font-size: fonts.$font-size-h5-mobile;
							cursor: pointer;
						}
					}
					& .score-img {
						& > img {
							width: 38px;
							height: auto;
						}
					}
				}
			}
		}
		.score-description {
			.score-desc-block {
				.key-metric {
					font-size: fonts.$font-size-base-mobile;
				}
				.key-metrics {
					display: block;
					margin: 20px 32px;

					.center {
						width: 100%;
					}
					.left {
						width: 100%;
					}
				}
				.body {
					padding: 10px 16px;
					font-size: fonts.$font-size-base-mobile;
					line-height: 20px;
					a:focus-visible {
						outline: none;
					}
				}
				.scoring {
					font-size: fonts.$font-size-base-mobile;
					line-height: 22px;
					padding: 0 16px;
					.score-impact {
						padding: 0 32px;
					}
				}
				.footer {
					font-size: fonts.$font-size-base-mobile;
					line-height: 22px;
					padding: 10px 16px 16px;
				}
				.updated-info {
					font-size: fonts.$font-size-small;
					line-height: 19.2px;
					letter-spacing: 0.09px;
					padding: 0px 16px 16px;
				}
			}
		}
	}
	.modalopen {
		&.score-container {
			& .score-collapsed {
				justify-content: center;
				& .score-img {
					& > img {
						width: auto;
						height: 50px;
					}
				}
			}
		}
	}

	.modal-score-container {
		& .modal-content {
			width: 100%;
		}
	}

	.open {
		margin: 0px 0.7px 0px 0.5px;
	}
}
