@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/opacity.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';

.faq-container {
	border-bottom: 1px solid colors.$color-gray;
	gap: 8px;
	padding: spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-4x;
	display: flex;
	flex-direction: column;
	.title-faq {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		p {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-bigger;
			margin: 0px;
		}
		i {
			align-self: center;
		}
		.active {
			font-family: fonts.$tertiary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			color: colors.$color-primary;
		}
	}
	.description-faq {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-base;
		a {
			color: inherit;
		}
	}
}

.faq-section {
	display: flex;
	flex-direction: column;
	gap: 32px;
	background-color: colors.$color-background-white;
	border-radius: border-radius.$radius-medium;
	margin-top: spacing.$spacing-7x;
	box-shadow: shadows.$shadow-medium;
	width: 95%;
	padding: spacing.$spacing-2x spacing.$spacing-4x spacing.$spacing-2x spacing.$spacing-4x;

	.faq-section-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.faq-section {
		margin-top: spacing.$spacing-x;
		gap: 16px;
		padding: spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-2x;
		width: 100%;
		.faq-section-container {
			gap: 16px;
			.form-title-container {
				padding: spacing.$spacing-x;
				display: flex;
				flex-direction: row;
			}
		}
	}
	.faq-container {
		padding: spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-4x;
		.title-faq {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			p {
				font-family: fonts.$primary-font-family;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-big;
				margin: 0px;
			}
			.active {
				font-size: fonts.$font-size-big;
			}
		}
		.description-faq {
			font-size: fonts.$font-size-small;
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.faq-section {
		margin-top: spacing.$spacing-x;
		width: 100%;
		.faq-section-container {
			margin-bottom: spacing.$spacing-x;
			gap: 16px;
			.form-title-container {
				padding: spacing.$spacing-x spacing.$spacing-2x;
				display: flex;
				flex-direction: row;
				margin-bottom: spacing.$spacing-x;
			}
		}
	}
	.faq-container {
		padding: spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-4x;
		.title-faq {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			p {
				font-family: fonts.$primary-font-family;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-base;
				margin: 0px;
			}
			.active {
				font-size: fonts.$font-size-base;
			}
		}
		.description-faq {
			font-size: fonts.$font-size-small;
		}
	}
}
