@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/breakpoints.scss';
.user-menu {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: spacing.$spacing-2x;
	width: max-content;
	height: auto;
	background: colors.$color-background-white;
	box-shadow: 2px 8px 20px rgba(0, 0, 0, 0.25);
	border-radius: 12px;
	z-index: 203;
	margin-top: 4.5px;
	animation: fadeIn 0.3s linear forwards;
	position: absolute;
	right: 0;
	gap: 16px;
}
.user-menu {
	overflow: hidden;
	& .user-menu-item {
		&:nth-child(1) {
			& .icon {
				font-size: fonts.$font-size-h3;
				margin-right: spacing.$spacing-half-x;
			}
			& .text {
				font-family: fonts.$tertiary-font-family;
				font-weight: fonts.$font-weight-semibold;
				font-size: fonts.$font-size-big;
				font-style: normal;
				line-height: 19px;
				color: colors.$color-text-black;
				margin-top: -6px;
			}
			& .sub-text {
				font-family: fonts.$primary-font-family;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-base;
				font-style: normal;
				line-height: 13px;
				color: colors.$color-gray-slate-light;
				margin-top: 0px;
			}
		}
		&:nth-child(2) {
			background-color: colors.$color-secondary-lighter;
			width: 120%;
			margin-left: -15px;
			height: 48px;
			& .text {
				text-align: center;
				width: 180px;
				font-family: fonts.$secondary-font-family;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-big;
				font-style: normal;
				line-height: 19px;
				color: colors.$color-primary;
				text-decoration: underline;
				margin-left: 15px;
			}
		}
	}
}
.user-menu {
	& .user-menu-item {
		&:not(:first-child) {
			margin-top: -6px;
			& .icon {
				margin-right: spacing.$spacing-x;
				font-size: fonts.$font-size-h3;
			}
			& .beacon-hub{
				width: 33px;
			}
			&:not(:nth-child(2)) {
				& .text {
					font-family: fonts.$primary-font-family;
					font-weight: fonts.$font-weight-regular;
					font-size: fonts.$font-size-big;
					font-style: normal;
					line-height: 13px;
					color: colors.$color-text-black;
				}
			}
		}
		&:last-child {
			margin-bottom: 4px;
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.user-menu {
		margin-top: spacing.$spacing-1_25x;
	}
}
@media (max-width: breakpoints.$tablet-breakpoint) {
	.user-menu {
		padding: spacing.$spacing-x spacing.$spacing-x 0px 14px;
		gap: 8px;
		& .user-menu-item {
			&:not(:first-child) {
				& .beacon-hub{
					width: 25px;
				}
				&:not(:nth-child(2)) {
					& .text {
						font-size: fonts.$font-size-small;
					}
				}
				& .icon {
					font-size: fonts.$font-size-h4;
				}
			}
			&:first-child {
				& .text {
					font-size: fonts.$font-size-small;
				}
				& .sub-text {
					font-size: fonts.$font-size-smaller;
				}
			}
			&:nth-child(2) {
				height: 36px;
				& .text {
					line-height: 16px;
					font-size: fonts.$font-size-small;
					width: 127px;
				}
			}
		}
	}
}
