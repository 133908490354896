@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/opacity.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.detailed-template-profile {
	.button-detail-container {
		display: flex;
		height: 42px;
		.spacing-separator {
			width: 272px;
		}
		.detail-button-profile {
			width: calc(100% - 272px);
			.show-btn {
				align-items: center;
				background: colors.$color-background-white;
				border-radius: border-radius.$radius-biggest;
				border: 1px solid colors.$color-gray;
				box-shadow: shadows.$shadow-medium;
				box-sizing: border-box;
				color: colors.$color-gray-slate;
				display: flex;
				flex-direction: row;
				flex-grow: 0;
				font-family: fonts.$tertiary-font-family;
				font-size: fonts.$font-size-base;
				font-style: normal;
				font-weight: fonts.$font-weight-semibold;
				gap: 8px;
				justify-content: center;
				letter-spacing: fonts.$letter-spacing-std;
				line-height: 26px;
				order: 0;
				padding: spacing.$spacing-x spacing.$spacing-4x;
				text-align: center;
				width: 70%;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

#details {
	margin-top: 40px;
	margin: 42px 82px 0px 82px;
	.options-ingredients-container {
		display: flex;
		height: 32px;
		.button-view-options {
			display: flex;
			.active {
				color: colors.$color-primary;
			}
		}
		.divider {
			border: 1px solid colors.$color-background-gray;
			margin: 0px spacing.$spacing-2x;
		}
		.menu-ingredients-container {
			display: flex;
			.button-panel-profile {
				width: 100%;
				display: flex;
				flex-direction: row;
				margin: auto;
				justify-content: center;
				height: 32px;
				gap: 16px;
				& > button {
					height: 32px;
					padding: 6px spacing.$spacing-2x 6px spacing.$spacing-2x;
					margin: auto;
					font-size: fonts.$font-size-small;

					&:not(.active) {
						&:hover {
							background-color: colors.$background-hover-ingredients-button;
							border: colors.$border-hover-ingredients-button;
							color: colors.$color-gray-slate-border-input;
						}
					}
				}

				& :first-child {
					margin-left: 0px;
				}
			}
		}
	}

	.row {
		.list-container {
			margin-top: spacing.$spacing-4x;
			.item-container {
				margin-bottom: spacing.$spacing-4x;
				.item-title {
					font-size: fonts.$font-size-base;
					font-family: fonts.$secondary-font-family;
					font-weight: fonts.$font-weight-bold;
					line-height: 22px;
				}
				.pills-container {
					gap: 8px;
					display: flex;
					flex-wrap: wrap;
					.pill {
						padding: 2px spacing.$spacing-2x 2px spacing.$spacing-2x;
						border-radius: border-radius.$radius-biggest;
						height: 27px;
						background-color: colors.$color-info-light;
						font-size: fonts.$font-size-base;
						font-family: fonts.$tertiary-font-family;
						font-weight: fonts.$font-weight-semibold;
						line-height: 22.4px;
						color: colors.$color-primary;
					}
				}
			}
		}
	}
}

.view-template-comparison-profile {
	margin-top: spacing.$spacing-3x;
	& .banned-ingredients {
		display: flex;
		flex-direction: row;
		width: 95%;
		flex-wrap: wrap;
		& .banned-ingredients-item {
			display: flex;
			flex-direction: column;
			width: 100%;
			flex-wrap: wrap;
			& .banned-ingredients-item-title {
				flex: 1;
				font-family: fonts.$secondary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				letter-spacing: 0.325581px;
				text-transform: capitalize;
				color: colors.$color-text-black;
			}
			& .banned-ingredients-list {
				flex: 1;
				display: flex;
				margin-top: 4px;
				margin-bottom: 42px;
				flex-wrap: wrap;
				& .banned-ingredient-item {
					align-items: flex-start;
					border-radius: border-radius.$radius-biggest;
					display: flex;
					flex-direction: row;
					flex-grow: 0;
					font-family: fonts.$tertiary-font-family;
					font-size: fonts.$font-size-base;
					font-style: normal;
					font-weight: fonts.$font-weight-semibold;
					gap: 8px;
					letter-spacing: 0.325581px;
					line-height: 22px;
					order: 0;
					padding: 2px spacing.$spacing-2x;
					text-transform: capitalize;
					margin-right: 6px;
					margin-top: spacing.$spacing-x;
					&.blue {
						color: colors.$color-primary;
						background: colors.$color-info-light;
					}
					&.green {
						color: colors.$color-second-tier;
						background: colors.$color-success-light;
					}
					&.yellow {
						color: colors.$color-third-tier-2;
						background: colors.$color-warning-light;
					}
				}
			}
		}
	}
	& .line {
		width: 90%;
		border: 0.5px solid rgba(0, 0, 0, 0.2);
		margin-left: 80px;
	}
}
.sentence-container-profile {
	display: flex;
	width: 517px;
	margin: 0px 0px spacing.$spacing-2x 0px;
	& .disclaimer-sentence {
		font-family: fonts.$tertiary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-small;
		line-height: 19px;
		letter-spacing: fonts.$letter-spacing-std;
		display: flex;
		align-items: center;
		color: colors.$color-gray-slate;
	}
}
.show-specific-profile {
	margin-bottom: 31px;
	margin-left: 0px;
	input[type='checkbox'] {
		opacity: opacity.$opacity-0;
		display: none;
	}

	&.blue input[type='checkbox'] + label::before {
		content: '';
		display: inline-block;
		width: 12px;
		height: 12px;
		background: colors.$color-background-white;
		outline: 2px solid colors.$color-primary;
		margin: 0px spacing.$spacing-x 0px 0px;
		padding-top: 2px;
	}
	&.blue input[type='checkbox']:checked + label::before {
		content: '';
		display: inline-block;
		width: 12px;
		height: 12px;
		outline: 2px solid colors.$color-primary;
		margin: 0px spacing.$spacing-x 0px 0px;
		padding-top: 2px;
		background: colors.$color-primary url(../../../assets/images/checkmark.png) no-repeat center;
	}
	& .check-label {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-big;
		line-height: 26px;
		align-items: center;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
	}
}
.table-container {
	height: 858px;
	width: 100%;
	overflow-y: scroll;
	& .table {
		margin-bottom: 0px;
		& .blue {
			background: colors.$color-info-light;
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-base;
			line-height: 22px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-primary;
			border-bottom: none;
		}
		& .green {
			background: #ecf7f2;
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-base;
			line-height: 22px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-second-tier;
		}
		& .yellow {
			background: colors.$color-warning-light;
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-base;
			line-height: 22px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-third-tier-2;
		}
		& thead th {
			border-bottom: none;
		}
		& th {
			border-top: none;
			padding-left: 30px;
		}
		& td {
			padding-left: 32px;
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-base;
			line-height: 22px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
			&:nth-child(1) {
				width: 523px;
			}
			&:nth-child(2) {
				width: 313px;
			}
			&:nth-child(3) {
				width: 314px;
			}
		}
		& .category-table-filter {
			display: flex;
			align-items: center;
			cursor: pointer;
			& .category-text {
				width: fit-content;
				max-width: 157px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			& .ri-arrow-up-s-line,
			& .ri-arrow-down-s-line {
				font-size: fonts.$font-size-h4;
				font-weight: fonts.$font-weight-regular;
			}
		}
	}
}
.detailed-template-profile {
	& .col {
		padding-left: 0;
		padding-right: 0;
	}
}
.detail-slider {
	width: 100%;
	margin-top: 22px;
}
.banned-list-profile {
	flex: 1;
	display: flex;
	margin-top: 4px;
	margin-bottom: 42px;
	flex-wrap: wrap;
	& .banned-ingredient-item {
		align-items: flex-start;
		border-radius: border-radius.$radius-biggest;
		display: flex;
		flex-direction: row;
		flex-grow: 0;
		font-family: fonts.$tertiary-font-family;
		font-size: fonts.$font-size-base;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		gap: 8px;
		letter-spacing: 0.325581px;
		line-height: 22px;
		order: 0;
		padding: 2px 16px;
		text-transform: capitalize;
		margin-right: 6px;
		margin-top: 8px;
		&.blue {
			color: colors.$color-primary;
			background: colors.$color-info-light;
		}
		&.green {
			color: colors.$color-second-tier;
			background: colors.$color-success-light;
		}
		&.yellow {
			color: colors.$color-third-tier-2;
			background: colors.$color-warning-light;
		}
	}
}
.show-all {
	display: flex;
	align-items: center;
	font-family: fonts.$primary-font-family;
	font-style: normal;
	font-weight: fonts.$font-weight-regular;
	font-size: fonts.$font-size-small;
	line-height: 16px;
	letter-spacing: fonts.$letter-spacing-std;
	color: colors.$color-text-black;
	order: 0;
	flex-grow: 0;
	justify-content: center;
	margin: -25px 2px 32px 0px;
	& .svg-wrapper {
		margin-left: 5.76px;
	}
}
.back-to-top-wrapper {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	flex-direction: column;
}
.back-to-top {
	background: colors.$color-secondary-lightest;
	border-radius: border-radius.$radius-circle;
	width: 32px;
	height: 32px;
	border: 1px solid #0172a9;
	display: flex;
	align-items: center;
	margin-left: auto;
	justify-content: center;
	margin-right: 2%;
}
.back-text {
	display: flex;
	align-items: center;
	margin-left: auto;
	justify-content: center;
	font-family: fonts.$tertiary-font-family;
	font-style: normal;
	font-weight: fonts.$font-weight-semibold;
	font-size: 8px;
	line-height: 10px;
	text-align: center;
	letter-spacing: fonts.$letter-spacing-std;
	color: colors.$color-primary;
	margin-top: 2px;
}
.custom-table {
	background-color: colors.$color-background-white;
	& tr {
		&:hover {
			background-color: rgba(0, 0, 0, 0.05);
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.rounded-button {
		width: auto;
		&.green,
		&.yellow {
			margin: 8px 12px;
		}
	}
	.detail-button-profile {
		margin-top: -10px;
		.show-btn {
			width: 100%;
		}
	}

	#details {
		margin: 0px spacing.$spacing-2x;
		.options-ingredients-container {
			display: flex;
			flex-direction: column;
			height: auto;
			.divider {
				display: none;
			}
			.menu-ingredients-container {
				display: flex;
				.detail-slider {
					margin-top: 0px;
					width: 100%;
					.swiper {
						height: 100%;
						margin-left: auto;
						margin-right: auto;
						.swiper-wrapper {
						}
						.swiper-slide {
							width: auto;
						}
					}
				}
			}
		}
	}
	.detail-slider {
		margin-top: spacing.$spacing-4x;
		& .swiper-wrapper {
			padding-bottom: 16px;
		}
		& .swiper-pagination {
			bottom: -6px;
		}
		& .swiper-pagination-bullets {
			display: block;
		}
		& .swiper-slide {
			width: auto;
		}
		& .swiper {
			& > div {
				&.swiper-wrapper {
					&.swiper-slide {
						width: auto;
					}
					& > div {
						background-color: transparent;
						&:nth-child(2) {
							margin-left: -16px;
						}
						&:nth-child(3) {
							margin-left: -6px;
							width: 100%;
						}
					}
				}
			}
		}
	}
	.detail-button-profile {
		& .show-btn {
			font-size: fonts.$font-size-small;
		}
	}
	.view-template-comparison-profile {
		& .table-striped tbody tr:nth-of-type(odd) {
			background-color: colors.$table-stripe-background;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	#details {
		.options-ingredients-container {
			display: flex;
			flex-direction: column;
			height: auto;
			.divider {
				display: none;
			}
			.menu-ingredients-container {
				display: flex;
				.detail-slider {
					margin-top: 0px;
					.swiper {
						z-index: 0;
						.swiper-slide {
							width: auto;
						}
					}
				}
			}
		}
	}

	.detailed-template-profile {
		.button-detail-container {
			display: flex;
			height: 42px;
			.spacing-separator {
				width: 0px;
			}
			.detail-button-profile {
				width: 100%;
			}
		}
	}
	.button-panel-profile {
		display: flex;
		flex-direction: row;
		height: auto;
		gap: 8px;
		& .rounded-button {
			margin: 0px;
		}
		& .square-button {
			margin-left: -8px;
		}
		& .separator {
			margin: 0px spacing.$spacing-2x 0px spacing.$spacing-x;
		}
	}
	.show-specific {
		margin-left: -8px !important;
	}
	.table-container {
		width: 100%;
		margin-left: 0px;
		& .table {
			width: 100%;
			& td {
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3) {
					width: 232px;
				}
			}
		}
	}
}
@media (min-width: 0) and (max-width: breakpoints.$mobile-breakpoint) {
	#details {
		margin-top: 24px;
	}
	.detailed-template-profile {
		.button-detail-container {
			.spacing-separator {
				width: 0px;
			}
			.detail-button-profile {
				margin: auto;
				width: 100%;
				.show-btn {
					padding: spacing.$spacing-half-x spacing.$spacing-4x spacing.$spacing-half-x
						spacing.$spacing-2x;
					font-size: fonts.$font-size-small;
					width: 288px;
				}
			}
		}
	}
	.table-container {
		margin-left: 0px;
		width: 100%;
		& .table {
			width: max-content;
			& td {
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3) {
					width: 232px;
				}
			}
		}
	}
	.sentence-container-profile {
		width: 100%;
	}
}
@media (min-width: 798px) and (max-width: breakpoints.$tablet-breakpoint) {
	.button-panel-profile {
		width: 100%;
		display: flex;
		flex-direction: row;
		height: auto;
		gap: 8px;
		& .rounded-button {
			margin: 0px;
		}
		& .square-button {
			margin-left: -8px;
		}
		& .separator {
			margin: 0px spacing.$spacing-2x 0px spacing.$spacing-x;
		}
	}
}
@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1200px) {
	.table-container {
		width: 100%;
		margin-left: 0px;
		& .table {
			width: 100%;
			& td {
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3) {
					width: 300px;
				}
			}
		}
	}
}
