@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
.tooltip-container {
	display: none;
	& .tooltip-description {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-small;
		line-height: 19.2px;
		letter-spacing: 0.44px;
		color: colors.$color-gray-slate;
		& > span {
			font-family: fonts.$secondary-font-family;
		}
	}
	& .learn-more-link {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-smaller;
		line-height: 16px;
		text-align: center;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-gray-slate;
		cursor: pointer;
		border-bottom: 0.7px solid colors.$color-gray-slate;
		text-decoration: none;
	}
}
.show-tooltip {
	display: flex;
	margin: -4px 0px 0px 12px;
	width: 93%;
	flex-wrap: wrap;
}
@media (max-width: breakpoints.$tablet-breakpoint) {
	.show-tooltip {
		width: 90%;
	}
}
