@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/opacity.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/breakpoints.scss';
.new-ingredients-template {
	display: flex;
	flex-direction: column;
	gap: 24px;
	& .template-profile-learn-more-container {
		& .action-link-contianer {
			display: flex;
			gap: 4px;
			& .action-link {
				font-style: normal;
				width: fit-content;
				letter-spacing: fonts.$letter-spacing-std;
			}
			& > i {
				font-size: 22px;
				margin-top: -4px;
				width: 22px;
				height: 22px;
			}
		}
	}
	.ingredients-options-container {
		background-color: colors.$color-secondary-lightest;
		display: flex;
		justify-content: space-between;
		border-radius: 12px;
		padding: 16px;
		position: relative;
		.ingredients-options-title {
			font-family: fonts.$secondary-font-family;
			font-weight: fonts.$font-weight-bold;
			font-size: 20px;
			margin: 0px;
			padding: 0px;
			height: 27px;
			font-weight: 700;
			line-height: 27.24px;
			color: colors.$color-primary;
		}
		.ingredients-edit {
			display: flex;
			cursor: pointer;
			.edit-buttons {
				width: 115px;
				height: 29px;
				display: flex;
				gap: 8px;
				color: colors.$color-gray-slate;
				p {
					margin: auto;
					padding: 0px;
					align-self: center;
					font-size: 16px;
				}
				i {
					margin: auto;
					font-size: 20px;
				}
			}
			.edit-buttons.save {
				display: flex;
				width: 133px;
				height: 29px;
				p {
					margin: auto;
					padding: 0px;
					align-self: center;
					border-bottom: 0.7px solid colors.$color-gray-slate;
				}
				i {
					margin: auto;
					font-size: 20px;
				}
			}
		}
	}
}

.edit-btn-template {
	position: absolute;
	top: 24px;
	right: 24px;
	line-height: 26px;
	font-size: fonts.$font-size-big;
	font-weight: fonts.$font-weight-semibold;
	color: #8d949a;
	font-style: normal;
	height: 26px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	display: flex;
	& .gear-icon {
		font-size: fonts.$font-size-h4;
		margin-right: spacing.$spacing-half-x;
	}
}
.actions {
	height: 36px;
	width: 100%;
	margin-top: spacing.$spacing-5x !important;
	margin-bottom: spacing.$spacing-2x;
	display: flex;
	gap: 12px;
	button {
		height: 28px;
		font-size: fonts.$font-size-small;
		margin: 0px;
	}
}

button.show {
	display: block;
}

button.hide {
	display: none;
}
.template-title-show {
	font-family: fonts.$secondary-font-family;
	font-style: normal;
	font-weight: fonts.$font-weight-bold;
	font-size: fonts.$font-size-h4;
	line-height: fonts.$font-size-h3;
	letter-spacing: fonts.$letter-spacing-std;
	color: colors.$color-black;
	margin: 0px; //edited-suppress-old
}

.template-title-show span.card-name {
	color: colors.$color-primary;
}

.Toastify {
	.toast-body {
		padding: 5px 5px 5px 28px;
		h5 {
			margin-bottom: 0;
		}
		.toat-text {
			font-size: 14px;
		}
	}
	.Toastify__progress-bar {
		height: 3px;
	}
	.Toastify__toast {
		border-radius: 8px;
	}
}
.new-template-cards-profile {
	width: 100% !important;
	max-width: 881px;
	margin: 0px auto 0px auto;
	justify-content: center;
	display: flex;
	position: relative;
	background-color: colors.$color-background-white;
}
@media (max-width: 768px) {
	.in-profile {
		padding: 40px 16px;
	}
	.template-title-show {
		font-family: fonts.$secondary-font-family;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 24.51px;
		letter-spacing: 0.75%;
		color: colors.$color-black;
	}

	.edit-btn-template {
		position: absolute;
		top: 12px;
		right: 8px;
		height: 16px;
		line-height: 16px;
		font-size: fonts.$font-size-small-mobile;
		font-weight: fonts.$font-weight-semibold;
		color: colors.$color-gray-slate-lighter;
		font-style: normal;
		cursor: pointer;
		& .gear-icon {
			font-size: fonts.$font-size-h6-mobile;
		}
	}
	.new-ingredients-template {
		.col {
			padding-left: 5px;
			padding-right: 5px;
		}

		.save-btn {
			font-size: 12px;
			line-height: 20px;
		}
		& .template-header {
			margin: auto auto auto auto;
		}
		& .detailed-template-profile {
			margin: auto spacing.$spacing-2x auto spacing.$spacing-2x;
		}
	}
	.Toastify {
		.toast-body {
			padding: 5px 5px 5px 9px;
			h5 {
				margin-bottom: 0;
				font-size: 18px;
			}
			.toat-text {
				font-size: 12px;
			}
		}
		.Toastify__toast-icon {
			width: 13.3px;
			height: 13.3px;
		}
		.Toastify__toast {
			padding: 0 8px;
			border-radius: 8px;
		}
		.Toastify__toast-container--top-right {
			top: 20px;
			right: 10px;
		}
		.Toastify__toast-container {
			left: auto;
		}
	}
	.new-template-cards-profile {
		width: 100%;
	}
}
@media (max-width: 743px) {
	.new-template-cards-profile {
		width: 93%;
		margin: spacing.$spacing-2x spacing.$spacing-2x 40px spacing.$spacing-2x;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.new-ingredients-template {
		.col-ingredients-profile {
			padding-bottom: spacing.$spacing-2x;
		}
		& .template-title-show {
			margin-top: spacing.$spacing-5x;
			line-height: normal;
			font-size: fonts.$font-size-h5;
			letter-spacing: 0.15px;
		}
		& .detailed-template-profile {
			margin: auto auto auto spacing.$spacing-2x;
		}
		& .new-template-cards-profile {
			margin-top: spacing.$spacing-3x;
		}
		.actions {
			margin-top: spacing.$spacing-2x;
			margin-bottom: spacing.$spacing-2x;
		}
	}
}

@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1009px) {
	.actions {
		margin-top: spacing.$spacing-6x;
		margin-bottom: spacing.$spacing-2x;
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.new-ingredients-template {
		.col-ingredients-profile {
			padding-bottom: spacing.$spacing-2x;
			.row-ingredients-profile {
				.new-template-cards-profile {
					margin: auto 0px;
					padding: 0px;
				}
			}
		}
		margin: 0px;
		.ingredients-options-container {
			background-color: colors.$color-secondary-lightest;
			display: flex;
			justify-content: space-between;
			border-radius: 12px;
			padding: spacing.$spacing-x;

			position: relative;
			.ingredients-options-title {
				font-size: fonts.$font-size-big;
				margin: 0px;
				padding: 0px;
				height: 27px;
				font-weight: 700;
				line-height: 27.24px;
				color: colors.$color-primary;
			}
			.ingredients-edit {
				.edit-buttons.save {
					display: flex;
					width: 110px;
					height: 29px;
					p {
						font-size: fonts.$font-size-small;
						margin: auto;
						margin-left: 0px;
						margin-right: 0px;
						padding: 0px;
						align-self: center;
						border-bottom: 0.7px solid colors.$color-gray-slate;
					}
					i {
						margin: auto;
						margin-left: 0px;
						margin-right: 0px;
						font-size: fonts.$font-size-h5;
					}
				}
				.edit-buttons.edit {
					width: 89px;
					height: 20px;
					display: flex;
					margin: auto;
					gap: 4px;
					i {
						position: relative;
						top: -3px;
						font-size: fonts.$font-size-bigger;
					}
					p {
						font-size: fonts.$font-size-small;
						line-height: 19.2px;
						margin: auto;
						padding: 0px;
						width: 67px;
					}
				}
			}
		}
		& .template-header {
			margin: auto auto auto auto;
			& .template-title-show {
				line-height: 25px;
				letter-spacing: 0.0075em;
				margin-top: 0px;
				display: flex;
				flex-direction: column;
				gap: 4px;
			}
			& .template-profile-learn-more-container {
				& .action-link-contianer {
					& > i {
						font-size: fonts.$font-size-h5-mobile;
						width: 18px;
						height: 18px;
						margin-top: -3px;
					}
				}
			}
		}
	}
}
