@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/fonts.scss';
.favorite-list {
	padding-bottom: 24px;
	.products-wrapper.favorite-products-wrapper {
		display: flex;
		.product-list {
			display: flex;
			justify-content: center;
			margin-top: spacing.$spacing-3x;
			width: 100%;
			> div {
				display: grid;
				grid-template-columns: repeat(3, minmax(auto, 1fr));
				row-gap: 40px;
				column-gap: 1%;
			}
		}
	}

	.loading {
		text-align: center;
		background-color: colors.$color-transparent;
	}
	.no-products {
		text-align: center;
	}
	.favorite-products-wrapper {
		margin-top: 0;
	}
}

@media (min-width: 1336px) {
	.favorite-list {
		.product-list {
			& > div {
				grid-template-columns: repeat(4, minmax(auto, 1fr)) !important;
			}
		}
	}
}

@media (min-width: 1536px) {
	.favorite-list {
		.product-list {
			& > div {
				grid-template-columns: repeat(5, minmax(auto, 1fr)) !important;
			}
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.favorite-list {
		justify-content: center;
		display: flex;
		.product-list-skeleton-container {
			width: 100%;
			max-width: 448px;
		}
		.products-wrapper.favorite-products-wrapper {
			.product-list {
				padding: 0px;
				margin: 0px;
				margin-top: 16px;

				> div {
					width: 100%;
					min-width: 280px;
					padding: 0px 4px;
					row-gap: 16px;
					grid-template-columns: repeat(2, minmax(auto, 1fr));
					justify-items: center;
					justify-content: space-between;
					column-gap: spacing.$spacing-x;

					> a {
						min-width: 136px !important;
						width: 95%;
					}
				}

				.product-card {
					height: auto;
					& .labels-container {
						margin-top: spacing.$spacing-half-x;
						& > .expo-label:only-child {
							margin-top: spacing.$spacing-2x;
						}
					}
				}
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.favorite-list {
		.products-wrapper.favorite-products-wrapper {
			.product-list {
				margin-left: 0px;
				> div {
					grid-template-columns: repeat(3, minmax(auto, 1fr));
					row-gap: 28px;
					column-gap: 16px;
					& .product-card {
						height: auto;
						min-width: 189px;
						& .product-image {
							height: 114px;
							width: 66%;
							margin: spacing.$spacing-x auto spacing.$spacing-x auto;
							& .error-place-holder {
								height: 86px;
								width: 86px;
								margin: auto;
							}
						}
						& .name-wrapper {
							height: 36px;
							& .name {
								font-size: fonts.$font-size-base-mobile;
								line-height: 19px;
							}
						}
						& .product-info {
							& .brand-name-container {
								& .brand {
									font-size: fonts.$font-size-small-mobile;
									line-height: 16px;
								}
							}
						}
						& .labels-container {
							margin: auto spacing.$spacing-x spacing.$spacing-x spacing.$spacing-2x;
							width: 100%;
							max-width: 100%;

							& :nth-child(2) {
								&:not(.newtopia) {
									width: 63px;
								}
								height: 16px;
							}
							& > .expo-label:only-child {
								&:not(.newtopia) {
									width: fit-content;
									padding: 0 spacing.$spacing-x;
								}
								height: 16px;
							}

							.how-good-btn {
								line-height: 1px;
								max-width: 230px;
								width: auto;
								span {
									width: 63px;
									line-height: 1px;
									display: flex;
									align-items: center;
									justify-content: center;
									font-size: 10px;
								}
								i {
									line-height: 1px;
									margin-top: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1085px) {
	.favorite-list {
		.products-wrapper.favorite-products-wrapper {
			& .product-list {
				& > div {
					grid-template-columns: repeat(2, minmax(auto, 1fr));
				}
			}
		}
		& .product-list {
			& > div {
				& .product-card {
					height: 367px;
					& .labels-container {
						& :nth-child(2) {
							&:not(.newtopia) {
								width: auto;
							}
							height: 16px;
						}
						& > .expo-label:only-child {
							&:not(.newtopia) {
								width: 100%;
							}
							height: 16px;
						}
						margin: auto 5% auto 5%;
						& > .expo-label:only-child {
							margin-top: 22px;
						}
						&.show-hg-wrapper {
							margin: 2px auto auto 5%;
						}
						& .button {
							width: auto;
							&.how-good-btn {
								width: auto;
								max-width: 100%;
								margin-bottom: 30px;
								gap: 10px;
								font-size: 12px;
								span {
									display: flex;
									align-items: center;
									justify-content: center;
								}
							}
						}
						& .button + .expo-label {
							margin-top: -21px;
							margin-bottom: spacing.$spacing-2x;
						}
					}
				}
			}
		}
	}
}
