@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/variables.scss';

.tooltip-wrapper {
	display: inline-block;
	position: relative;
	width: 100%;
	height: 100%;
}

.tooltip-content {
	position: absolute;
	width: fit-content;
	border-radius: border-radius.$radius-small;
	color: colors.$color-white;
	background: colors.$color-gray-slate;
	font-size: fonts.$font-size-small;
	font-family: fonts.$tertiary-font-family;
	line-height: 19px;
	letter-spacing: 0.0075em;
	z-index: 100;
	white-space: nowrap;
	text-align: center;
	left: 50%;
	transform: translateX(-50%);
	padding: 6px;
}

.tooltip-content::before {
	content: ' ';
	left: 50%;
	border: solid transparent;
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-width: variables.$tooltip-arrow-size;
	margin-left: calc(variables.$tooltip-arrow-size * -1);
}

.tooltip-content.top {
	top: calc(variables.$tooltip-margin-x * -1);
}

.tooltip-content.top::before {
	top: 100%;
	border-top-color: colors.$color-gray-slate;
}

/* Absolute positioning */
.tooltip-content.bottom {
	bottom: calc(variables.$tooltip-margin-x * -1);
}

/* CSS border triangles */
.tooltip-content.bottom::before {
	bottom: 100%;
	border-bottom-color: colors.$color-gray-slate;
}

.tooltip-content.right {
	left: calc(100% + variables.$tooltip-margin-y);
	top: 50%;
	transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.tooltip-content.right::before {
	height: variables.$tooltip-arrow-size;
	left: calc(variables.$tooltip-arrow-size * -1);
	top: 50%;
	transform: translateX(0) translateY(-50%);
	border-right-color: colors.$color-gray-slate;
}

/* Absolute positioning */
.tooltip-content.left {
	left: auto;
	right: calc(100% + variables.$tooltip-margin-y);
	top: 50%;
	transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.tooltip-content.left::before {
	left: auto;
	right: calc(variables.$tooltip-arrow-size * -2);
	top: 50%;
	transform: translateX(0) translateY(-50%);
	border-left-color: colors.$color-gray-slate;
}
