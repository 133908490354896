@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/spacing.scss';

.brand-products {
	margin-top: spacing.$spacing-4x;
	height: 230px;

	& .brand-products-link {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-bigger;
		color: colors.$color-primary;
		align-items: center;
		cursor: pointer;
		text-decoration: none;

		&:hover {
			text-decoration: none;

			div {
				margin-left: spacing.$spacing-half-x;
			}
		}

		& div {
			position: absolute;
			margin-top: -2px;
			display: inline-block;
			transition: margin-left 0.5s;
		}

		& .ri-arrow-right-line {
			margin-left: spacing.$spacing-half-x;
			font-size: fonts.$font-size-h4-mobile;
		}
	}

	& .products-carousel-container {
		position: absolute;
		width: 90vw;
		height: auto;
		margin: auto;
		margin-left: -12px;

		& .product-card {
			min-width: 130px;
			max-width: 200px;
			height: 240px;
			width: 193px;

			& .product-image {
				margin-top: spacing.$spacing-x;
				width: 120px;
				height: 120px;

				& img {
					width: 100%;
					height: auto;
				}

				& .error-place-holder {
					margin: auto;
					width: 86px;
					height: 86px;
				}
			}

			& .product-favorite {
				padding: spacing.$spacing-x spacing.$spacing-x 0 0;
			}

			& .product-info {
				margin: spacing.$spacing-x spacing.$spacing-x 0px spacing.$spacing-x;

				.brand {
					font-size: fonts.$font-size-small-mobile !important;
					line-height: 16px !important;
					color: #6c757d !important;
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					text-overflow: ellipsis;
				}

				.name-wrapper {
					height: 36px;

					.name {
						font-family: fonts.$secondary-font-family !important;
						font-size: fonts.$font-size-base-mobile !important;
						line-height: 19px !important;
						letter-spacing: 0.0075em !important;
						overflow: hidden;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						text-overflow: ellipsis;
					}
				}
			}

			& .how-good-btn {
				font-size: fonts.$font-size-hg-btn-inside-caurosel;
				margin-bottom: spacing.$spacing-1_5x;
				width: 106px;
				height: 16px;
				padding: 0px spacing.$spacing-half-x;
				line-height: 1px;

				& > i {
					font-size: fonts.$font-size-small-mobile;
					margin-top: 0px;
					margin-bottom: 0;
					line-height: 1px;
				}

				span {
					line-height: 1px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			& .product-score {
				font-size: fonts.$font-size-small-mobile;
				line-height: 14px;
				width: 29px;
				height: 16px;
				margin-right: -8px;
				margin-left: -10px;
				margin-top: 0;
			}

			& .labels-container {
				margin: spacing.$spacing-x spacing.$spacing-x spacing.$spacing-x spacing.$spacing-x;

				& > :first-child {
					margin-right: spacing.$spacing-1_5x;
				}

				& > :nth-child(2) {
					&:not(.newtopia) {
						width: 63px;
					}

					margin: -8px 0px 0px 0px;
				}

				&.brand-page-wrapper {
					& .expo-label:only-child {
						margin-top: 45px;

						&:not(.newtopia) {
							width: 88px;
						}
					}
				}
			}
		}

		& .swiper {
			padding: spacing.$spacing-2x;
			margin-left: -8px !important;
			margin-right: -16px !important;

			& .swiper-slide {
				background: transparent;
			}
		}

		& .swiper-button-prev {
			align-items: center;
			background: colors.$color-gray;
			border-radius: border-radius.$radius-biggest;
			border: none;
			color: inherit;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			font: inherit;
			gap: 4px;
			height: 26px;
			justify-content: center;
			margin-left: -40px;
			outline: inherit;
			padding: spacing.$spacing-half-x;
			width: 26px;

			&::after {
				font-size: fonts.$font-size-smaller;
				color: colors.$color-black;
				font-weight: fonts.$font-weight-bold;
			}

			&:hover {
				filter: drop-shadow(shadows.$shadow-low);
			}

			&:active {
				box-shadow: shadows.$shadow-high-inner;
			}
		}

		& .swiper-button-next {
			align-items: center;
			background: colors.$color-gray;
			border-radius: border-radius.$radius-biggest;
			border: none;
			color: inherit;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			font: inherit;
			gap: 4px;
			height: 26px;
			justify-content: center;
			margin-right: -49px;
			outline: inherit;
			padding: spacing.$spacing-half-x;
			width: 26px;

			&::after {
				font-size: fonts.$font-size-smaller;
				color: colors.$color-black;
				font-weight: fonts.$font-weight-bold;
			}

			&:hover {
				filter: drop-shadow(shadows.$shadow-low);
			}

			&:active {
				box-shadow: shadows.$shadow-high-inner;
			}
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.brand-products {
		height: spacing.$spacing-5x;

		.brand-products-link {
			margin-left: spacing.$spacing-5x;
		}

		.products-carousel-container {
			margin-left: calc(7vw - spacing.$spacing-2x);
			width: 86vw !important;

			.product-card {
				width: 193px;
				max-height: 240px;

				& .product-image {
					margin-top: 0px;
					width: 150px;
					height: 120px;

					& > img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}

					& .error-place-holder {
						margin: auto;
						width: 86px;
						height: 86px;
					}
				}

				& .labels-container {
					margin-top: 0px;

					& > .expo-label:only-child {
						margin-top: spacing.$spacing-4x !important;
					}
				}

				& .product-favorite {
					right: 4px;
				}

				& .product-info {
					margin: spacing.$spacing-2x spacing.$spacing-1_5x spacing.$spacing-x spacing.$spacing-2x;
				}
			}

			.swiper-button-prev {
				margin-left: -32px;
			}

			.swiper-button-next {
				margin-right: -42px;
			}
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.brand-products {
		height: 350px;

		& .brand-products-link {
			font-size: fonts.$font-size-big !important;

			& .ri-arrow-right-line {
				font-size: fonts.$font-size-h5 !important;
			}
		}

		& .products-carousel-container {
			margin-left: 0 !important;

			& .swiper-custom-pagination-for-brand {
				text-align: center;
				display: block;
				margin-top: 30px;
				position: relative;
				width: 80%;

				z-index: 999;

				.swiper-pagination-bullet {
					background: #bec2c5;
					opacity: 1;
				}

				.swiper-pagination-bullet-active {
					background: #5c666f;
					opacity: 1;
				}
			}

			& .control-mobile-for-brand {
				position: relative;
				display: flex;
				width: 100%;
				margin-top: -33px;
				justify-content: space-between;

				.bi-chevron-left {
					width: 24px;
					height: 24px;
					font-size: fonts.$font-size-big;
					display: flex;
					align-items: center;
					left: spacing.$spacing-2x;
					position: inherit;

					&::before {
						color: colors.$color-black !important;
						font-weight: fonts.$font-weight-bold !important;
					}
				}

				.bi-chevron-right {
					width: 24px;
					height: 24px;
					font-size: fonts.$font-size-big;
					display: flex;
					align-items: center;
					right: 0;
					position: inherit;

					&::before {
						color: colors.$color-black !important;
						font-weight: fonts.$font-weight-bold !important;
					}
				}
			}

			.product-card {
				& .labels-container {
					& > .expo-label:only-child {
						margin-top: 20px;

						&:not(.newtopia) {
							width: 63px;
						}
					}

					.how-good-btn {
						line-height: 1px;

						span {
							line-height: 1px;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						i {
							line-height: 1px;
							margin-top: 0;
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}
