@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';
.detail-header {
	max-width: 1476px;
	margin: auto;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.product-detail {
	& .go-back-btn {
		margin-top: 40px;
		margin-bottom: 23px;
	}
}
.detail-container {
	display: grid;
	grid-template-columns: repeat(2, minmax(auto, 1fr));
	margin-top: 40px;
	.product-image-container {
		padding: 0 2% 2% 0%;
		.product-image {
			border-radius: border-radius.$radius-medium;
			width: 529px;
			background-color: colors.$color-background-white;
			display: block;
			align-items: center;
			justify-content: center;
		}
	}
}
.page-title {
	margin: 13px 0px 25px;
}
h4 {
	font-family: fonts.$primary-font-family;
	font-style: normal;
	font-size: 34px;
	line-height: 42px;
	font-weight: fonts.$font-weight-bold;
	letter-spacing: 0.25px;
	color: rgba(0, 0, 0, 0.87);
}
@media (min-width: breakpoints.$large-screen-breakpoint) {
	.detail-container {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.product-back {
		padding: 0 16px;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.detail-container {
		overflow: hidden;
		padding: 0 16px;
		& .product-image {
			width: 300px !important;
			& .mySwiper2 {
				height: 300px;
				margin: 0;
				& .swiper-button-prev {
					display: none;
				}
				& .swiper-button-next {
					display: none;
				}
			}
			& .product-carousel-container {
				& .product-favorite {
					top: 8px;
					right: 8px;
				}
			}
			& .myswiper2-dumb {
				position: relative;
				width: 100%;
				& .mySwiper {
					width: 90%;
					margin-top: 10px;

					& img {
						width: 79px;
						height: 79px;
					}
					& .swiper-button-next {
						right: -10px;
					}
					&.swiper-button-prev {
						left: -10px;
					}
				}
				& .bi-chevron-left {
					position: absolute;
					top: 30px;
					left: -5px;
				}
				& .bi::before {
					color: black;
				}
				& .bi-chevron-right {
					position: absolute;
					top: 30px;
					right: 0;
				}
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.detail-container {
		overflow: hidden;
		width: 100%;
		margin-top: spacing.$spacing-3x !important;
		grid-template-columns: repeat(1, minmax(auto, 1fr));
		.product-image-container {
			padding: 0 2% 0% 2%;
		}
	}
}
