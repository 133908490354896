@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';

.main-page-container {
	background: colors.$login-background-color;
	background-size: 600% 600%;
	animation: LoginBackgroundAnimation 10s ease infinite;
	display: flex;
	height: 100%;
	width: 100%;
	font-family: fonts.$primary-font-family;
}

@keyframes LoginBackgroundAnimation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.main-page-content {
	background: colors.$login-main-card-background;
	height: 100%;
	justify-content: center;
	margin: 0 auto;
	width: 100%;
	text-align: center;
	max-width: 1080px;
	& .logo {
		margin: spacing.$spacing-15x 0px spacing.$spacing-6x 0px;
		& .beacon-letters {
			object-fit: contain;
			width: 648px;
			height: 89px;
		}
		& .beacon-letters-mobile {
			display: none;
			margin: 0 auto;
			object-fit: contain;
			width: 240px;
			height: 76px;
		}
	}
	& .welcome-text {
		font-family: fonts.$secondary-font-family;
		color: colors.$color-primary-dark;
		display: flex;
		font-size: fonts.$font-size-h3;
		font-style: normal;
		font-weight: fonts.$font-weight-bold;
		justify-content: center;
		line-height: 43.58px;
		margin-bottom: spacing.$spacing-4x;
		text-align: center;
	}
	& .login-text {
		font-family: fonts.$primary-font-family;
		align-items: center;
		color: colors.$color-text-black;
		display: flex;
		font-size: fonts.$font-size-h4;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		justify-content: center;
		line-height: 32.68px;
		margin-bottom: spacing.$spacing-7x;
		text-align: center;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.main-page-content {
		border-bottom-left-radius: border-radius.$radius-bigger;
		border-bottom-right-radius: border-radius.$radius-bigger;
		height: fit-content;
		padding-bottom: spacing.$spacing-10x;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.main-page-content {
		border-bottom-left-radius: border-radius.$radius-bigger;
		border-bottom-right-radius: border-radius.$radius-bigger;
		height: fit-content;
		padding-bottom: spacing.$spacing-4x;
		& .logo {
			margin: spacing.$spacing-4x 0px spacing.$spacing-5x 0px;
			& .beacon-letters {
				display: none;
			}
			& .beacon-letters-mobile {
				display: block;
			}
		}
		& .welcome-text {
			margin-bottom: spacing.$spacing-2x;
			font-size: fonts.$font-size-bigger;
			line-height: 24.5px;
		}
		& .login-text {
			font-size: fonts.$font-size-base;
			margin-bottom: spacing.$spacing-5x;
			line-height: 22.4px;
		}
	}
}
