@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.product-information {
	& .label-container {
		display: flex;
		flex-wrap: wrap;
		& .product-brand {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-big;
			line-height: 26px;
			color: colors.$color-text-black;
			letter-spacing: fonts.$letter-spacing-std;
			cursor: pointer;
			margin: auto spacing.$spacing-2x 1px 0px;
			&:hover {
				text-decoration: underline;
			}
		}
		& > div {
			display: flex;
			flex-wrap: wrap;
			& > :nth-child(1) {
				margin-right: spacing.$spacing-x;
				width: fit-content;
				height: 29px;
			}
		}
	}
	& .product-name {
		font-family: fonts.$secondary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-bold;
		line-height: 44px;
		letter-spacing: 0.25px;
		color: rgba(0, 0, 0, 0.87);
		text-align: initial;
		font-size: fonts.$font-size-h3;
	}
	& .overview {
		display: flex;
		width: 100%;
		align-items: flex-start;
		& img {
			width: 230px;
			height: 299px;
		}

		& .details {
			& .product-description {
				margin-bottom: spacing.$spacing-2x;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-base;
				line-height: 19px;
				letter-spacing: 0.0075em;
				color: colors.$color-text-black;
			}
			.product-sub-detail {
				display: flex;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-big;
				line-height: 22px;
				letter-spacing: fonts.$letter-spacing-std;
				color: colors.$color-text-gray-slate-light;
				margin-bottom: spacing.$spacing-3x;
				& div {
					margin-right: 36px;
				}
			}
			& .product-howgood-market {
				display: flex;
				flex-wrap: wrap;
				gap: 40px;
			}
			& .links {
				margin-top: 32px;
				display: flex;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-small;
				line-height: 19px;
				align-items: center;
				letter-spacing: 0.0075em;
				color: colors.$color-text-black;
				gap: 32px;
				& div {
					border: 2px solid #99dbeb;
					padding: 8px 25px;
					border-radius: border-radius.$radius-small;
				}
				cursor: pointer;
			}
			& .market_ready {
				cursor: pointer;
				& img {
					width: 145px;
					height: auto;
				}
			}
			& .product-name {
				font-weight: fonts.$font-weight-bold;
			}
			& .product-image {
				display: none;
			}
			& p {
				font-family: fonts.$primary-font-family;
				font-style: normal;
				font-weight: 300;
				font-size: fonts.$font-size-bigger;
				line-height: 28px;
				letter-spacing: 0.25px;
				color: colors.$color-text-black;
			}
			& span:not(.booth-text) span:not(.expo-label) {
				font-family: fonts.$primary-font-family;
				font-style: normal;
				font-size: fonts.$font-size-h3-mobile;
				line-height: 33px;
				display: flex;
				align-items: center;
				margin: 20px 0 5px 0;
				&::before {
					font-weight: fonts.$font-weight-bold;
					margin-right: 5px;
				}
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.product-information {
		.overview {
			margin: auto;
			padding-right: 0px !important;
			& p {
				width: 90% !important;
				height: 100%;
				text-align: left;
			}

			& h4 {
				letter-spacing: 0px !important;
				text-align: left;
			}

			& .details {
				margin: 0;
				width: 100%;

				.links {
					margin-top: 16px !important;
				}

				.product-howgood-market {
					display: block !important;
				}

				.market_ready {
					display: block;
					width: 100%;
					margin-top: 16px;
				}

				.product-sub-detail {
					margin-bottom: 4px;
					font-size: fonts.$font-size-smaller;
					line-height: 16px;
				}

				.product-image {
					display: block;
					filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25));
					border-radius: 10px;
					width: 370px;
					height: auto;
					box-shadow: none;
					margin: 10% auto;

					img {
						display: block;
						margin: 0 auto;
						height: 330px;
					}
				}

				.product-brand {
					font-family: fonts.$primary-font-family;
					font-weight: fonts.$font-weight-regular;
					line-height: 29px;
				}

				.product-name {
					font-size: fonts.$font-size-bigger;
					font-weight: fonts.$font-weight-bold;
					line-height: 26px;
					color: rgba(0, 0, 0, 0.87);
					margin-bottom: 4px;
				}
				.product-description {
					font-size: fonts.$font-size-small;
					margin-bottom: spacing.$spacing-2x;
				}
			}
		}
		& .label-container {
			& .product-brand {
				margin: auto spacing.$spacing-2x 1px 0px;
			}
			& > div {
				& > :nth-child(1) {
					height: 29px;
					margin-right: spacing.$spacing-x;
				}
				& > :nth-child(2) {
					width: fit-content;
					&:hover {
						width: fit-content;
					}
				}
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.product-information {
		.overview {
			flex-direction: column;
			padding: 0 !important;
			& p {
				width: 100% !important;
				height: 100% !important;
				text-align: left;
			}
			& h4 {
				letter-spacing: 0px;
				text-align: left;
			}
			& .details {
				margin: 0;
				width: 100%;
				.links {
					margin-top: 16px !important;
					justify-content: center;
				}
				.product-howgood-market {
					gap: 15px !important;
				}
				.market_ready {
					margin: auto;
				}
				.product-sub-detail {
					display: block !important;
					font-size: fonts.$font-size-base;
					margin-bottom: 16px;
					div {
						margin-top: 8px;
					}
				}
				.product-image {
					display: block;
					filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
					border-radius: border-radius.$radius-small;
					width: 270px;
					height: auto;
					box-shadow: none;
					margin: 10% auto;
					img {
						display: block;
						margin: 10px auto;
						height: 250px;
					}
				}
				.product-brand {
					font-family: fonts.$primary-font-family;
					font-weight: fonts.$font-weight-regular;
					font-size: fonts.$font-size-big-mobile;
					line-height: 22px;
				}
				.product-name {
					font-family: fonts.$secondary-font-family;
					font-size: fonts.$font-size-h6;
					font-weight: fonts.$font-weight-bold;
					line-height: 26px;
					color: rgba(0, 0, 0, 0.87);
					margin-top: spacing.$spacing-x;
				}
				.product-description {
					font-size: fonts.$font-size-small;
					margin-bottom: spacing.$spacing-2x;
				}
			}
		}
		& .label-container {
			& .product-brand {
				margin: 3px spacing.$spacing-2x 1px 0px;
			}
			& > div {
				& > :nth-child(1) {
					height: 23px;
					margin-right: spacing.$spacing-x;
				}
			}
		}
	}
}
