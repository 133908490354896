@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
.make-changes-container {
	& .make-changes-header-text-container {
		& .make-changes-header-text {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-h5;
			line-height: 32px;
			letter-spacing: 0.0075em;
			color: colors.$color-text-black;
		}
		& > a {
			text-decoration: underline;
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h5;
			line-height: 32px;
			letter-spacing: 0.0075em;
			color: colors.$color-text-black;
		}
	}
	& .need-changes-timeline-content-container {
		margin: spacing.$spacing-6x 0px spacing.$spacing-5x 0px;
		width: 100%;
		
		 .timeline-container.need-changes{
			.timeline-step{
				width: 100%;
				justify-content: center;
			}
		}
	}
	& .number {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: 39.47px;
		line-height: 53.75px;
		letter-spacing: 0.0075em;
	}
	& .circle-check {
		font-size: 47.5px;
	}
	& .card-text-header {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-h5;
		line-height: 32px;
		letter-spacing: 0.0075em;
		color: colors.$color-primary;
		&.card-mt-2 {
			display: flex;
			margin-top: spacing.$spacing-2x;
		}
	}
	& .card-link-header {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-h5;
		line-height: 32px;
		letter-spacing: 0.0075em;
		color: colors.$color-primary;
		text-decoration: underline;
	}
	& .card-text-content {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-h5;
		line-height: 32px;
		letter-spacing: 0.0075em;
		color: colors.$color-text-black;
	}
	& .spins-img {
		margin-left: spacing.$spacing-3x;
		width: 167px;
		height: 141px;
	}
	& .favorites-img {
		width: 248px;
		height: 197px;
		margin-left: spacing.$spacing-2x;
	}
	& .first-card-header-container {
		margin-left: spacing.$spacing-2x;
	}
	& .first-card-content-container {
		margin-left: spacing.$spacing-3x;
	}
	& .second-card-header-container {
		width: 229px;
	}
	& .second-card-content-container {
		margin-left: spacing.$spacing-3x;
	}
}
@media (max-width: 1248px) {
	.make-changes-container {
		& .make-changes-header-text-container {
			& .make-changes-header-text {
				font-size: fonts.$font-size-base;
				line-height: 22.4px;
			}
			& > a {
				font-size: fonts.$font-size-base;
				line-height: 22.4px;
			}
		}
		& .number {
			font-size: 23.4px;
			line-height: 32px;
		}
		& .circle-check {
			font-size: 33.7px;
		}
		& .card-text-header {
			font-size: fonts.$font-size-base;
			line-height: 22px;
		}
		& .card-link-header {
			font-size: fonts.$font-size-base;
			line-height: 22px;
		}
		& .card-text-content {
			font-size: fonts.$font-size-small;
			line-height: 19.2px;
			display: flex;
		}
		& .spins-img {
			margin-top: -8px;
			margin-left: 0px;
			width: 146.98px;
			height: 124px;
		}
		& .favorites-img {
			margin-top: spacing.$spacing-1_5x;
			width: 146.62px;
			height: 116.58px;
			margin-left: 0px;
		}
		& .first-card-content-container {
			margin-left: 0px;
			max-width: 474px;
		}
		& .first-card-header-container {
			margin-top: -8px;
		}
		& .second-card-content-container {
			margin-left: 0px;
		}
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.make-changes-container {
		& .need-changes-timeline-content-container {
			margin: spacing.$spacing-2x spacing.$spacing-5x spacing.$spacing-2x 0px;
		}
		& .spins-img {
			margin-top: -28px;
			margin-left: 0px;
			width: 99.2px;
			height: 83.69px;
		}
		& .upload-img {
			width: 99.5px;
			height: 103.46px;
		}
		& .favorites-img {
			width: 146.62px;
			height: 116.58px;
			margin-top: 0px;
		}
		& .first-card-header-container {
			width: 100%;
			margin-left: 0px;
			margin-top: spacing.$spacing-x;
		}
		& .first-card-content-container {
			width: 100%;
		}
		& .second-card-header-container {
			width: 100%;
		}
		& .second-card-content-container {
			width: 100%;
		}
		& .card-text-header {
			&.card-mt-2 {
				margin-top: spacing.$spacing-x;
			}
		}
	}
}
@media (max-width: 500px) {
	.make-changes-container {
		& .make-changes-header-text-container {
			& > a {
				word-break: break-all;
			}
		}
		& .second-card-header-container {
			width: 217px;
		}
		& .second-card-content-container {
			width: 226px;
		}
	}
}
