@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/spacing.scss';
.highlighted-products {
	background: colors.$gradient-highlighted;
	padding-top: 45px;
	height: 575px;
	overflow: hidden;
	margin-bottom: 56px;
	& .highlighted-title {
		& .sustainability-icon {
			width: 48px;
			height: 46px;
			margin-bottom: 18px;
		}
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-h3;
		line-height: 44px;
		position: relative;
		letter-spacing: 0.0075em;
		color: colors.$color-primary;
		text-align: center;
		margin-bottom: 20px;
	}
	& .highlighted-text {
		& .bold-text {
			display: inline;
			font-family: fonts.$tertiary-font-family;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-bigger;
			line-height: 28.8px;
		}
		font-family: fonts.$primary-font-family;
		font-size: fonts.$font-size-bigger;
		font-weight: fonts.$font-weight-regular;
		line-height: 28.8px;
		text-align: left;
		width: 560px;
		margin: auto auto spacing.$spacing-1_25x auto;
	}
	& .divider {
		height: 2px;
		width: 88.5%;
		margin-top: 25px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		background: colors.$gradient-blue-2;
		box-shadow: shadows.$shadow-low;
		z-index: 150;
	}

	.products-carousel-container {
		height: auto;
		padding: spacing.$spacing-5x;
		& .product-card {
			min-width: 130px;
			max-width: 200px;
			height: 240px;
			width: 193px;
			& .product-image {
				margin-top: spacing.$spacing-x;
				width: auto;
				height: 120px;
				& img {
					z-index: -1;
					top: -12px;
					position: relative;
					margin: auto;
					width: 100%;
					max-height: 130px;
				}

				& .error-place-holder {
					margin: auto;
					width: 86px;
					height: 86px;
				}
			}

			& .product-favorite {
				padding: spacing.$spacing-x spacing.$spacing-x 0 0;
				i {
					font-size: 20px;
				}
			}

			& .product-info {
				margin: spacing.$spacing-x spacing.$spacing-x 0px spacing.$spacing-x;
				.brand {
					font-size: fonts.$font-size-small-mobile;
					line-height: 16px;
					color: #6c757d;
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					text-overflow: ellipsis;
				}

				.name-wrapper {
					height: 36px;
					.name {
						font-family: fonts.$secondary-font-family;
						font-size: fonts.$font-size-base-mobile;
						line-height: 19px;
						letter-spacing: 0.0075em;
						overflow: hidden;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						text-overflow: ellipsis;
					}
				}
			}

			& .how-good-btn {
				font-size: 8px;
				margin-bottom: spacing.$spacing-1_5x;
				width: 106px;
				height: 16px;
				padding: 0px spacing.$spacing-half-x;
				line-height: 1px;
				& > i {
					font-size: fonts.$font-size-small-mobile;
					margin-top: 0px;
					margin-bottom: 0;
					line-height: 1px;
				}
				span {
					line-height: 1px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			& .product-score {
				font-size: fonts.$font-size-small-mobile;
				line-height: 14px;
				width: 29px;
				height: 16px;
				margin-right: -8px;
				margin-left: -10px;
				margin-top: 0;
			}
			& .labels-container {
				margin: spacing.$spacing-x spacing.$spacing-x spacing.$spacing-x spacing.$spacing-x;
				& > :first-child {
					margin-right: spacing.$spacing-1_5x;
				}
				& > :nth-child(2) {
					width: auto;
					margin: -8px 0px 0px 0px;
				}
				&.brand-page-wrapper {
					& .expo-label:only-child {
						margin-top: 45px;
						width: auto;
					}
				}
			}
		}

		& .swiper {
			height: 260px;
			width: 95%;
			padding: spacing.$spacing-1_25x;
			& .swiper-slide {
				background: transparent;
			}
		}
		& .nav-buttons {
			display: flex;
			justify-content: space-between;

			& .swiper-button-next {
				background: colors.$color-gray;
				border-radius: border-radius.$radius-biggest;
				border: none;
				bottom: 120px;
				color: inherit;
				cursor: pointer;
				font: inherit;
				height: 26px;
				outline: inherit;
				position: relative;
				width: 26px;
				left: 0px;
				align-self: flex-end;
				&::after {
					font-size: fonts.$font-size-smaller;
					color: colors.$color-black;
					font-weight: fonts.$font-weight-bold;
				}
				&:hover {
					filter: drop-shadow(shadows.$shadow-low);
				}
				&:active {
					box-shadow: shadows.$shadow-high-inner;
				}
			}
			& .swiper-button-prev {
				align-items: center;
				border-radius: border-radius.$radius-biggest;
				border: none;
				color: inherit;
				cursor: pointer;
				font: inherit;
				height: 26px;
				outline: inherit;
				width: 26px;
				position: relative;
				bottom: 120px;
				margin-left: -10px;
				&::after {
					font-size: fonts.$font-size-smaller;
					color: colors.$color-black;
					font-weight: fonts.$font-weight-bold;
				}

				&:hover {
					filter: drop-shadow(shadows.$shadow-low);
				}

				&:active {
					box-shadow: shadows.$shadow-high-inner;
				}
			}
		}
	}
}

@media (max-width: 1430px) {
	.highlighted-products {
		& .divider {
			width: 87%;
		}
	}
}
@media (max-width: 1280px) {
	.highlighted-products {
		& .divider {
			width: 83%;
		}
	}
}
@media (max-width: 1100px) {
	.highlighted-products {
		& .divider {
			width: 85%;
		}
	}
}
@media (max-width: 992px) {
	.highlighted-products {
		& .divider {
			width: 79%;
		}
	}
}
@media (max-width: 900px) {
	.highlighted-products {
		& .divider {
			width: 81%;
		}
	}
}
@media (max-width: 744px) {
	.highlighted-products {
		& .divider {
			width: 76%;
		}
	}
}
@media (max-width: 660px) {
	.highlighted-products {
		& .divider {
			width: 80%;
		}
	}
}
@media (max-width: 570px) {
	.highlighted-products {
		& .divider {
			width: 84%;
		}
	}
}
@media (max-width: 540px) {
	.highlighted-products {
		& .divider {
			width: 87%;
		}
	}
}
@media (max-width: 490px) {
	.highlighted-products {
		& .divider {
			width: 90%;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.highlighted-products {
		height: 417px;
		padding-top: 30px;
		& .highlighted-title {
			& .sustainability-icon {
				width: 31px;
				height: 30px;
				margin-bottom: 0px;
				margin-right: 2px;
			}
			font-family: fonts.$secondary-font-family;
			font-size: 16px;
			margin-bottom: 0px;
		}
		& .highlighted-text {
			width: 50%;
			font-size: fonts.$font-size-small;
			font-family: fonts.$tertiary-font-family;
			font-weight: fonts.$font-weight-semibold;
			text-align: center;
			line-height: 22.4px;
			& .bold-text {
				display: inline;
				font-family: fonts.$secondary-font-family;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-small;
				line-height: 22.4px;
			}
		}
		& .divider {
			margin-top: 0px;
			box-shadow: shadows.$shadow-lower;
		}
		.products-carousel-container {
			padding-top: 10px;
			& .swiper {
				padding-bottom: 30px;
				height: 280px;
				& .swiper-pagination-bullets {
					display: block;
					position: relative;
					margin-top: spacing.$spacing-x;
					bottom: -0px;
				}
			}
			& .product-card {
				height: 240px;
			}
			& .nav-buttons {
				display: flex;
				justify-content: space-between;
				& .swiper-button-next {
					bottom: 130px;
					left: 10px;
				}
				& .swiper-button-prev {
					margin-left: -20px;
					bottom: 130px;
				}
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.swiper-pagination-bullets {
		margin-top: 21px;
		bottom: -5px;
	}
	.highlighted-products {
		height: 675px;
		padding-top: 15px;
		& .highlighted-title {
			font-family: fonts.$secondary-font-family;
			font-size: fonts.$font-size-bigger-mobile;
			margin-bottom: 5px;

			& .sustainability-icon {
				width: 27px;
				height: 26px;
				margin-bottom: 0px;
				margin-right: 2px;
			}
		}
		& .highlighted-text {
			width: 95%;
			font-family: fonts.$tertiary-font-family;
			font-size: fonts.$font-size-base-mobile;
			font-weight: fonts.$font-weight-semibold;
			text-align: center;
			line-height: 19.2px;
			& .bold-text {
				line-height: 19.2px;
				display: inline;
				font-family: fonts.$secondary-font-family;
				font-size: fonts.$font-size-base-mobile;
			}
		}
		& .divider {
			margin-top: 0px;
			box-shadow: shadows.$shadow-lower;
		}
		.products-carousel-container {
			margin: 0px;
			height: 400px;
			padding: 0px;
			padding-top: 5px;
			& .swiper-custom-pagination-highlighted {
				text-align: center;
				display: block;
				position: relative;
				width: 80%;
				margin-left: auto;
				margin-right: auto;
				margin-top: 14px;
			}

			& .control-mobile-for-highlighted {
				position: inherit;
				display: flex;
				width: 90%;
				margin: -42px auto 0;
				justify-content: space-between;
				.bi-chevron-left {
					left: spacing.$spacing-3x;
					position: inherit;
					top: 3px;
					&::before {
						color: colors.$color-gray-slate !important;
						font-weight: fonts.$font-weight-bold !important;
					}
				}
				.bi-chevron-right {
					width: 24px;
					height: 24px;
					font-size: fonts.$font-size-big;
					right: spacing.$spacing-2x;
					position: inherit;
					top: 20px;
					&::before {
						color: colors.$color-gray-slate !important;
						font-weight: fonts.$font-weight-bold !important;
					}
				}
			}
			& .product-card {
				min-width: 136px;
				max-width: 200px;
				height: 240px;
				width: 193px;

				& .product-favorite {
					padding: spacing.$spacing-x spacing.$spacing-x 0 0;
				}
			}
			& .swiper {
				height: auto;
				.swiper-wrapper {
					.swiper-slide:nth-child(n + 11):nth-child(-n + 20) {
						top: -10px;
					}
				}
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 342px) {
	.highlighted-products {
		height: 690px;
		& .products-carousel-container {
			& .swiper-custom-pagination-highlighted {
				top: -17px;
			}

			& .control-mobile-for-highlighted {
				.bi-chevron-left {
					position: relative;
					top: -4px;
				}

				.bi-chevron-right {
					position: relative;
					top: -4px;
				}
			}
		}
	}
}
