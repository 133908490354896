@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles//tokens//spacing.scss';
@use '../../../styles/tokens/fonts.scss';
.detail-breadcrumb-container {
	margin-top: spacing.$spacing-3x;
	& .breadcrumb-text {
		color: colors.$color-text-black;
		font-family: fonts.$primary-font-family;
		font-size: fonts.$font-size-small;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		line-height: 19.2px;
		letter-spacing: 0.09px;
	}
	& .has-more {
		&::after {
			cursor: auto;
			content: '/';
			margin: 0px spacing.$spacing-half-x 0px spacing.$spacing-half-x;
		}
	}
	& .last {
		color: colors.$color-text-gray-slate-light;
	}
}
@media (max-width: breakpoints.$tablet-breakpoint) {
	.detail-breadcrumb-container {
		display: none;
	}
}
