@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';

.action-link-contianer {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}

	& .action-link {
		cursor: pointer;

		&.regular {
			height: 23px;
			width: 73px;
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-base;
			font-weight: fonts.$font-weight-regular;
			line-height: 22px;
		}

		&.big {
			height: 26px;
			width: 83px;
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-big;
			font-weight: fonts.$font-weight-regular;
			line-height: 25px;
		}

		&.bigger {
			height: 29px;
			width: 94px;
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-bigger;
			font-weight: fonts.$font-weight-regular;
			line-height: 28px;
		}

		&.small {
			height: 20px;
			width: 63px;
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-small;
			font-weight: fonts.$font-weight-regular;
			line-height: 19px;
		}

		&.smaller {
			height: 16px;
			width: 52px;
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-smaller;
			font-weight: fonts.$font-weight-regular;
			line-height: 16px;
		}

		&.dark {
			color: colors.$color-text-black;

			&.underline {
				border-bottom: 1px solid colors.$color-black;
			}

			&:hover {
				border-bottom: 1px solid colors.$color-black;
			}
		}

		&.light-blue {
			color: colors.$color-primary;

			&.underline {
				border-bottom: 1px solid colors.$color-primary;
			}

			&:hover {
				border-bottom: 1px solid colors.$color-primary;
			}
		}

		&.bold {
			font-family: fonts.$secondary-font-family;
			font-weight: fonts.$font-weight-bold;
			line-height: 28.8px;
		}

		&.semi-bold {
			font-family: fonts.$tertiary-font-family;
			font-weight: fonts.$font-weight-semibold;
			line-height: 28.8px;
		}
	}

	& i {
		height: 14px;
		width: 14px;

		&.light-blue {
			color: colors.$color-primary;
		}

		&.dark {
			color: colors.$color-text-black;
		}
	}
}
