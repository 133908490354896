@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/spacing.scss';
.go-back-btn {
	background: none;
	border: none;
	color: colors.$color-black;
	cursor: pointer;
	font-family: fonts.$primary-font-family;
	font-size: fonts.$font-size-base;
	font-style: normal;
	font-weight: fonts.$font-weight-regular;
	letter-spacing: fonts.$letter-spacing-std;
	line-height: 22.4px;
	outline: inherit;
	padding: 0;
	width: fit-content;
	& > div {
		cursor: pointer;
		& > span {
			&:hover {
				text-decoration: underline;
			}
		}
	}
	& .fa-arrow-left::before {
		margin-right: spacing.$spacing-half-x;
	}
}
