@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/opacity.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.modal-open {
	padding: 0 !important;
}

.modal-body {
	padding: 0;
}

.modal-backdrop {
	&.show {
		opacity: opacity.$opacity-80;
	}
}

.product-carousel-container {
	position: relative;

	.product-favorite {
		position: absolute;
		z-index: 2;
		right: 32px;
		top: 16px;
		border-radius: border-radius.$radius-circle;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: shadows.$shadow-medium;
		cursor: pointer;

		.fa-heart {
			font-size: fonts.$font-size-h5;
		}

		.red {
			color: #f23e4a;
		}
	}

	.dual-container-actions {
		position: absolute;
		z-index: 2;
		right: 32px;
		top: 16px;
		border-radius: border-radius.$radius-small;
		padding: spacing.$spacing-half-x spacing.$spacing-x;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: shadows.$shadow-medium;
		gap: 8px;

		.ri-heart-line,
		.ri-heart-fill {
			height: 22px;
			cursor: pointer;
			font-size: fonts.$font-size-h5;
		}

		.red {
			color: #f23e4a;
		}
	}

	.tooltip-content-center {
		top: 25%;
		text-align: center;
		position: relative;
	}

	.swiper-custom-pagination {
		text-align: center;
	}

	& .myswiper2-dumb {
		width: 100%;
		margin-top: 5px;
		position: relative;

		& .bi-chevron-left {
			position: absolute;
			top: 28px;
			left: -5px;
			cursor: pointer;
		}

		& .bi::before {
			color: black !important;
		}

		& .bi-chevron-right {
			position: absolute;
			top: 28px;
			right: 0;
			cursor: pointer;
		}
		& .bi-chevron-right::before {
			content: '';
			background-image: url('../../../assets/images/arrow-right-s.svg');
			background-position: center;
			width: 16px;
			height: 16px;
			background-size: 16px 16px;
		}
		& .bi-chevron-left::before {
			content: '';
			background-image: url('../../../assets/images/arrow-left-s.svg');
			background-position: center;
			width: 16px;
			height: 16px;
			background-size: 16px 16px;
		}
	}
}

.swiper-slide {
	text-align: center;
	font-size: fonts.$font-size-bigger;
	background: #fff;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper {
	height: 100%;
	margin-left: auto;
	margin-right: auto;
}

.swiper-slide {
	background-size: cover;
	background-position: center;
}

.mySwiper2 {
	height: 529px;
	border-radius: border-radius.$radius-medium;
	border: 2px solid #eaeaea;
	margin: 0px 16px 24px 0px;
	box-shadow: shadows.$shadow-lower;

	.swiper-button-next:after,
	.swiper-button-prev:after {
		display: flex;
		justify-content: center;
		align-items: center;
		align-self: stretch;
		background-color: colors.$color-background-gray;
		width: 26px;
		height: 26px;
		font-size: fonts.$font-size-small;
		color: colors.$color-text-black;
		padding: 6.5px 9.5px;
		border-radius: border-radius.$radius-circle;
		content: '';
	}
	& .swiper-button-next:after {
		background-image: url('../../../assets/images/arrow-right-s.svg');
		background-position: center;
		background-size: 18px 18px;
	}
	& .swiper-button-prev:after {
		background-image: url('../../../assets/images/arrow-left-s.svg');
		background-position: center;
		background-size: 18px 18px;
	}

	& .single-no-image-place-holder {
		width: 75%;
		height: 70%;
		align-items: center;
		display: flex;
		margin: 15% auto;
		justify-content: center;
		object-fit: contain;
	}
}

.swiper-pagination-bullets {
	display: none;
}

.mySwiper {
	height: 100px;
	box-sizing: border-box;
	width: 90%;

	.swiper-button-prev {
		left: -10px;
	}

	.swiper-button-next {
		right: -10px;
	}

	.swiper-button-prev:after {
		font-size: fonts.$font-size-small;
		color: colors.$color-text-black;
		background-color: none;
	}

	.swiper-button-next:after {
		font-size: fonts.$font-size-small;
		color: colors.$color-text-black;
		background-color: none;
		right: 0;
	}
}

.mySwiper .swiper-slide {
	height: 100%;
}

.mySwiper .swiper-slide img {
	border: 1px solid #eaeaea;
	border-radius: border-radius.$radius-default;
	box-shadow: shadows.$shadow-lower;
}

.mySwiper .swiper-slide-thumb-active {
	opacity: 1;
	& > img {
		&:not(.no-image-place-holder) {
			width: 75%;
			background-size: cover;
			color: white;
			padding: 2px;
			background-image: linear-gradient(
					90deg,
					#0071a8 0%,
					#0071a8 0.17%,
					#0075ab 4.49%,
					#0082b5 11.52%,
					#0096c5 20.34%,
					#00add8 28.42%,
					#3fb17e 67.9%,
					#9aca3c 100%
				),
				linear-gradient(0deg, #ffffff, #ffffff);
		}
	}
}

.swiper-slide img {
	display: block;
	width: 75%;
	height: auto;
	object-fit: contain;
}

.mySwiper2 {
	& .swiper-slide {
		& .no-image-place-holder {
			width: 50%;
			height: auto;
		}
	}
}

.myswiper2-dumb {
	& .swiper {
		& .swiper-wrapper {
			& .swiper-slide {
				& .no-image-place-holder {
					width: 75%;
					height: auto;
					object-fit: contain;
				}
			}
		}
	}
}

.desktop-modal-container {
	.react-transform-component {
		justify-content: center;

		img {
			max-width: 100%;
		}
	}

	.modal-content {
		background: transparent;
		border: none !important;
		left: -100px !important;
	}

	.slick-list {
		max-width: 45vw;
		margin: 0 auto !important;
		background-color: colors.$color-background-white;
		border-radius: border-radius.$radius-medium;
	}

	.modal_item {
		display: flex !important;
		justify-content: space-around;
		height: 90vh;
		width: 100% !important;
	}

	.modal_item img {
		border-radius: border-radius.$radius-medium;
		width: 72%;
		height: auto;
		object-fit: scale-down;
	}

	.modal-dialog {
		max-width: 95% !important;
		height: 91%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.preview_image {
		max-width: 100px;
		max-height: 100px;
	}

	.slick-thumb {
		display: flex !important;
		right: 18vw;
		flex-direction: column;
		top: -10px;
		max-width: 110px !important;
		max-height: 90vh !important;
	}

	.slick-thumb::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	.slick-thumb::-webkit-scrollbar-track {
		border-radius: border-radius.$radius-default;
		background: rgba(0, 0, 0, 0.1);
	}

	.slick-thumb::-webkit-scrollbar-thumb {
		border-radius: border-radius.$radius-default;
		background: rgba(0, 0, 0, 0.2);
	}

	.slick-thumb::-webkit-scrollbar-thumb:hover {
		background: rgba(0, 0, 0, 0.4);
	}

	.slick-thumb::-webkit-scrollbar-thumb:active {
		background: rgba(0, 0, 0, 0.9);
	}

	.slick-thumb li {
		min-width: 100px;
		min-height: 100px;
		margin: 10px !important;
	}

	.slick-thumb li img {
		border-radius: border-radius.$radius-default;
	}

	.slick-active a img {
		border: 2px solid;
		border-image-source: linear-gradient(
			90deg,
			#0071a8 0%,
			#0071a8 0.17%,
			#0075ab 4.49%,
			#0082b5 11.52%,
			#0096c5 20.34%,
			#00add8 28.42%,
			#3fb17e 67.9%,
			#9aca3c 100%
		) !important;
		background: linear-gradient(
			90deg,
			#0071a8 0%,
			#0071a8 0.17%,
			#0075ab 4.49%,
			#0082b5 11.52%,
			#0096c5 20.34%,
			#00add8 28.42%,
			#3fb17e 67.9%,
			#9aca3c 100%
		) !important;
	}

	.close_icon {
		position: absolute;
		top: 0px;
		right: 11vw;
		font-size: fonts.$font-size-h4;
		width: 40px;
		height: 40px;
		text-align: center;
		align-items: center;
		background-color: colors.$color-background-white;
		border-radius: border-radius.$radius-circle !important;
		color: colors.$color-text-black;
		cursor: pointer;
	}

	.slick-slider {
		position: relative;
	}

	.bi-chevron-left {
		width: 40px;
		height: 40px;
		background-color: #eaeaea;
		border-radius: border-radius.$radius-circle;
		font-size: fonts.$font-size-big;
		padding: 0px 4px 0px 4px;
		position: absolute;
		top: 45%;
		left: 30%;
		cursor: pointer;
	}

	.bi-chevron-right {
		width: 40px;
		height: 40px;
		background-color: #eaeaea;
		border-radius: border-radius.$radius-circle;
		font-size: fonts.$font-size-big;
		padding: 0px 6px 0px 6px;
		position: absolute;
		top: 45%;
		left: 67.2%;
		cursor: pointer;
	}

	.bi-chevron-left::before {
		content: '';
		background-image: url('../../../assets/images/arrow-left-s.svg');
		background-position: center;
		background-size: 24px 24px;
		width: 24px;
		height: 20px;
	}

	.bi-chevron-right::before {
		content: '';
		background-image: url('../../../assets/images/arrow-right-s.svg');
		background-position: center;
		background-size: 24px 24px;
		width: 24px;
		height: 20px;
	}

	.modal_item .iiz div {
		display: flex;
		height: 100%;
		flex-direction: row;
		justify-content: center;
	}

	.iiz__btn {
		display: none;
	}

	.slick-list {
		height: calc(45vw + 10px);
	}

	.slick-list * {
		height: inherit;
	}

	@media only screen and (max-width: 1660px) {
		.slick-thumb {
			right: 16vw !important;
		}

		.close_icon {
			right: 9vw;
		}
	}

	@media only screen and (max-width: 1148px) {
		.slick-thumb {
			right: 14vw !important;
		}

		.close_icon {
			right: 5vw;
		}

		.bi-chevron-right {
			left: 65.2%;
		}
	}

	@media only screen and (max-width: 1000px) {
		.slick-thumb {
			right: 10vw !important;
		}
	}
}

.mobile-modal-container {
	.modal-dialog {
		max-width: 100vw;
		height: 91%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.close_icon-mobile {
		position: fixed;
		top: 16px;
		right: 26px;
		font-size: fonts.$font-size-h4;
		width: 40px;
		height: 40px;
		text-align: center;
		align-items: center;
		background-color: rgb(255, 255, 255);
		border-radius: 100% !important;
		color: colors.$color-text-black;
		cursor: pointer;
	}

	.modal-content {
		background: transparent;
		border: none !important;
		left: 0 !important;
		// height: 100%;
	}

	.slick-list {
		max-width: calc(100vw - 32px);
		// height: 85vw;
		margin: 0 auto !important;
		background-color: colors.$color-background-white;
		border-radius: border-radius.$radius-medium;
	}

	.slick-dots {
		bottom: -50px;

		li button:before {
			// opacity: .75;
			color: #bec2c5;
		}

		li.slick-active {
			button:before {
				color: #5c666f;
				opacity: 1;
			}
		}
	}

	.bi-chevron-left::before {
		content: '';
		background-image: url('../../../assets/images/arrow-left-s.svg');
		background-position: center;
		background-size: 24px 24px;
		width: 24px;
		height: 20px;
	}

	.bi-chevron-right::before {
		content: '';
		background-image: url('../../../assets/images/arrow-right-s.svg');
		background-position: center;
		background-size: 24px 24px;
		width: 24px;
		height: 20px;
	}

	.control-mobile {
		position: relative;
		display: flex;
		width: 100%;
		margin-top: -33px;
		justify-content: space-between;

		.bi-chevron-left {
			width: 40px;
			height: 40px;
			background-color: #eaeaea;
			border-radius: border-radius.$radius-circle;
			font-size: fonts.$font-size-big;
			padding: 0px 4px 0px 4px;
			top: 45%;
			cursor: pointer;
			position: inherit;
			left: 16px;
			bottom: 0;
		}

		.bi-chevron-right {
			width: 40px;
			height: 40px;
			background-color: #eaeaea;
			border-radius: border-radius.$radius-circle;
			font-size: fonts.$font-size-big;
			padding: 0px 6px 0px 6px;
			top: 45%;
			cursor: pointer;
			position: inherit;
			right: 16px;
			left: auto;
		}
	}

	.swiper-custom-pagination-for-modal {
		text-align: center;
		display: block;
		margin-top: 30px;

		.swiper-pagination-bullet {
			background: #bec2c5;
			opacity: 1;
		}

		.swiper-pagination-bullet-active {
			background: #5c666f;
			opacity: 1;
		}
	}

	.swiper-button-next {
		display: none;
	}

	.swiper-button-prev {
		display: none;
	}

	.mySwiper3 {
		border-radius: 16px;
		margin-right: 17px;
		margin-left: 17px;

		img {
			width: auto;
			height: auto;
		}

		.iiz__hint {
			bottom: -4px;
			right: 23px;
			visibility: hidden;
		}

		.react-transform-component {
			justify-content: center;

			img {
				max-width: 100%;
			}
		}
	}

	.iiz__hint_visible:before {
		content: '';
		background-image: url('../../../assets/images/zoom.gif');
		width: 40px;
		height: 40px;
		display: block;
	}

	.iiz__hint_visible {
		bottom: 26px;
		right: 42px;
		position: absolute;
		z-index: 1000;
	}
}

.spinner {
	border: 2px solid rgba(0, 0, 0, 0.1);
	border-left-color: colors.$spinner-color;
	border-radius: border-radius.$radius-semi-circle;
	width: 16px;
	height: 16px;
	animation: spin 1s linear infinite;
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.slick-dots li button:before {
		font-size: fonts.$font-size-small-mobile !important;
	}

	.product-carousel-container {
		.dual-container-actions {
			right: 28px;
			top: 12px;
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.product-carousel-container {
		.dual-container-actions {
			right: 12px;
			top: 12px;
		}
	}
}
