@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/spacing.scss';

.brand-profile-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 41px;
	margin-bottom: -50px;

	& .back-container {
		display: flex;
		cursor: pointer;

		& .back-page-text {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-base;
			line-height: 22px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
			padding-left: 6px;

			&:hover {
				text-decoration: underline;
			}
		}
	}
	& .share-container {
		position: absolute;
		z-index: 2;
		right: 16px;
		top: 16px;
		border-radius: border-radius.$radius-circle;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: shadows.$shadow-medium;
		cursor: pointer;
	}
	& .contact-container {
		margin-top: 112px;
		& .separator {
			width: 100%;
			height: 1px;
			background-color: colors.$color-background-gray;
		}
		& .brand-contact-title-container {
			& .brand-contact-title {
				font-family: fonts.$secondary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-big;
				line-height: 28px;
				letter-spacing: 0.0075em;
				color: colors.$color-primary;
			}
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.brand-profile-container {
		padding-top: spacing.$spacing-3x;
		margin: 0px spacing.$spacing-2x 94px spacing.$spacing-2x;
		width: 98%;
		& .contact-container {
			margin-top: 300px;
			width: 98%;
			& .contact-information-container {
				& .contact-information-item-container {
					padding: spacing.$spacing-half-x 0px spacing.$spacing-half-x spacing.$spacing-4x;
				}
			}
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.brand-profile-container {
		padding-top: spacing.$spacing-4x;
		margin: 0px auto -196px spacing.$spacing-2x;
		width: 92%;
		& .share-container {
			right: 8px;
			top: 8px;
		}
		& .contact-container {
			margin-top: 305px;
			&.short-products-wrapper {
				margin-top: 0px;
			}
		}
	}
}

@media (min-width: 601px) and (max-width: breakpoints.$mobile-breakpoint) {
	.brand-profile-container {
		& .contact-container {
			& .contact-information-container {
				gap: 8px;
				& .contact-information-item-container {
					padding: spacing.$spacing-x 0px spacing.$spacing-x spacing.$spacing-4x;
					row-gap: 0;
				}
			}
		}
	}
	@media (max-width: 710px) {
		.brand-profile-container {
			& .contact-container {
				& .contact-information-container {
					width: 92%;
				}
			}
		}
	}
}
@media (min-width: 800px) and (max-width: 1024px) {
	.brand-profile-container {
		& .contact-container {
			& .contact-information-container {
				max-width: unset;
				width: 94%;
			}
		}
	}
	@media (max-width: 820px) {
		.brand-profile-container {
			& .contact-container {
				& .contact-information-container {
					max-width: unset;
					width: 92%;
				}
			}
		}
	}
}
