@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.stepper-container {
	display: flex;
	width: 100%;
	height: auto;
	box-sizing: border-box;
	& .content-container {
		background: colors.$stepper-body-background;
		border-radius: 64px 64px 0px 0px;
		margin-top: -45px;
		padding: 25px 25px 25px 25px;
		position: relative;
		height: auto;
		width: 100%;
	}

	.stepper-body {
		padding: 25px 60px 25px 25px;
		position: relative;
		height: auto;
		width: 100%;
	}
	.stepper-footer {
		font-family: fonts.$tertiary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-big;
		line-height: 26px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
		height: auto;
		margin: 32px 0px 101px 105px;
		& .buttons-section {
			margin-top: 46px;
			display: flex;
			width: 100%;
			& .previous-btn {
				width: 39px;
				height: 26px;
				font-family: fonts.$secondary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-big;
				line-height: 26px;
				display: flex;
				align-items: center;
				text-align: center;
				letter-spacing: fonts.$letter-spacing-std;
				color: colors.$color-text-gray-slate-light;
				cursor: pointer;
				margin-left: 189px;
			}
			& .next-btn {
				align-items: center;
				background: colors.$color-primary;
				border-radius: border-radius.$radius-biggest;
				box-shadow: shadows.$shadow-low;
				color: colors.$color-text-white;
				display: flex;
				flex-direction: row;
				flex-grow: 0;
				font-family: fonts.$tertiary-font-family;
				font-size: fonts.$font-size-big;
				font-style: normal;
				font-weight: fonts.$font-weight-semibold;
				height: 38px;
				justify-content: center;
				letter-spacing: fonts.$letter-spacing-std;
				line-height: 26px;
				order: 0;
				padding: 6px 32px;
				text-align: center;
				width: 193px;
				left: 1058px;
				border: none;
				margin-left: auto;
				margin-right: 189px;
			}
			& .next-btn:hover {
				background: colors.$color-primary-dark;
			}
		}
		& .stepper-footer-tittle {
			margin-left: 1.5%;
		}
		&.welcome-wrapper {
			margin-top: 80px;
			& .buttons-section {
				display: flex;
				width: 160px;
				align-items: start;
				justify-content: start;
				text-align: start;
				position: relative;
				bottom: 155px;
				margin-left: 5.6%;
				& .welcome-btn {
					width: 154px;
					height: 38px;
					margin: 0px;
				}
			}
		}
	}
}

.stepper-container {
	display: flex;
	width: 100%;
	height: auto;
	box-sizing: border-box;
	& .col {
		padding: 0px !important;
	}
	& .stepper-head {
		position: relative;
		box-sizing: border-box;
		background: colors.$stepper-header-background;
		width: 100%;
		height: 200px;
		padding-top: 50px;
		overflow-x: hidden;
		& .stepper-header-wrapper {
			margin: 0 -240px 0 -20%;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			z-index: 1;
			width: 138%;
			& .step-indicatior {
				position: relative;
				text-align: center;
				flex: 1 1 auto;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					height: 3px;
					width: 100%;
					right: 50%;
					transform: translateY(-50%);
					z-index: -1;
					background: linear-gradient(to left, #99dbeb 50%, #008fbe 50%);
					background-size: 200% 100%;
					background-position: right bottom;
					transition: background-position 200ms ease-in-out;
				}
				&:first-child::before {
					content: none;
				}
				& .stepper-label {
					color: colors.$color-primary;
					position: absolute;
					top: 120%;
					left: 50%;
					transform: translateX(-50%);
				}
				& .indicatior-info {
					background: linear-gradient(90deg, #0172a9, #03aed5);
					color: colors.$color-text-white;
					font-family: fonts.$secondary-font-family;
					font-size: fonts.$font-size-base;
					font-style: normal;
					font-weight: fonts.$font-weight-bold;
					height: 24px;
					letter-spacing: fonts.$letter-spacing-std;
					line-height: 24px;
					text-align: center;
					width: 24px;
					border-radius: border-radius.$radius-circle;
					display: inline-flex;
					transform-origin: center;
					align-items: center;
					justify-content: center;
				}
			}
			& .step-enabled:before,
			& .step-active:before {
				background-position: left bottom;
			}
			& .step-enabled,
			& .step-active {
				transition: background-color 250ms ease;
			}
			& .step-disabled .indicatior-info {
				background: colors.$color-secondary-light;
			}
			& .step-enabled {
				& .indicatior-info {
					background-color: #4db193;
					color: white;
					animation: spring-down fonts.$font-weight-regular ms ease-in-out forwards;
				}
			}
		}
	}
}
@media (min-width: 1150px) and (max-width: 1300px) {
	.stepper-container {
		& .stepper-head {
			& .stepper-header-wrapper {
				width: auto;
			}
		}
		& .stepper-footer {
			&.welcome-wrapper {
				& .buttons-section {
					bottom: 130px;
					margin-left: 50px;
				}
			}
		}
	}
}
@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1150px) {
	.stepper-container {
		& .stepper-head {
			& .stepper-header-wrapper {
				width: auto;
			}
		}
		& .stepper-footer {
			&.welcome-wrapper {
				& .buttons-section {
					bottom: 100px;
					margin-left: 3.6%;
				}
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.stepper-container {
		& .content-container {
			padding: 1px spacing.$spacing-2x 0px spacing.$spacing-2x;
			border-radius: 24px 24px 0px 0px;
			height: 100%;
			& .template-header {
				margin: auto;
			}
		}
		.stepper-footer {
			margin-left: spacing.$spacing-2x;
			& .stepper-footer-tittle {
				margin-left: 0px;
				font-size: fonts.$font-size-base-mobile;
				line-height: 20px;
				letter-spacing: 0.09;
			}
			& .buttons-section {
				& .next-btn {
					margin-right: 3%;
					font-size: fonts.$font-size-small;
					line-height: 20px;
					width: fit-content;
				}
				& .previous-btn {
					margin-left: 0px;
					margin-top: spacing.$spacing-half-x;
					font-size: fonts.$font-size-small;
					line-height: 20px;
					width: fit-content;
				}
			}
			&.welcome-wrapper {
				display: flex;
				margin-top: 40px;
				& .buttons-section {
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin: auto;
					width: 95%;
					bottom: 0px;
					margin-left: 0px;
					& .welcome-btn {
						width: 128px;
						height: 28px;
						padding: 4px 20px;
						justify-content: center;
						align-items: center;
						& > i {
							margin-left: 2px;
							font-size: fonts.$font-size-h5-mobile;
							font-weight: 500;
						}
					}
				}
				& .previous-btn {
					margin-left: 0px;
					margin-top: spacing.$spacing-half-x;
					font-size: fonts.$font-size-small;
					line-height: 20px;
					width: fit-content;
				}
			}
		}
		& .stepper-body {
			padding: 0px;
			border-radius: 24px 24px 0px 0px;
		}
	}
	.stepper-container {
		overflow-x: hidden;
		& .stepper-head {
			padding-bottom: 45px;
			& .stepper-header-wrapper {
				margin: auto auto auto 0px;
				align-items: flex-start;
				width: 105%;
				& .step-indicatior {
					&:first-child {
						text-align: start;
						margin-left: spacing.$spacing-5x;
						& .stepper-label {
							left: 5%;
						}
					}
					&::before {
						height: 2px;
						width: 150%;
					}
					& .indicatior-info {
						width: 18px;
						height: 18px;
						margin-bottom: 6px;
						font-size: 12px;
						& > svg {
							width: 8px;
							height: 8px;
						}
					}
					& .step-active {
						width: 18px;
						height: 18px;
						margin-bottom: 6px;
						font-size: 8px;
						& > svg {
							width: 6px;
							height: 6px;
						}
					}
					& .stepper-label {
						font-size: 12px;
						font-family: fonts.$tertiary-font-family;
						font-size: fonts.$font-size-small;
						font-weight: fonts.$font-weight-semibold;
					}
				}
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.stepper-container {
		& .stepper-footer {
			margin: 19px auto 100px 19px;
			&.welcome-wrapper {
				& .buttons-section {
					bottom: 100px;
					margin-left: 0px;
				}
				& .previous-btn {
					margin-left: spacing.$spacing-x;
				}
			}
			& .stepper-footer-tittle {
				margin-left: 0px;
				line-height: 28.8px;
			}
			& .buttons-section {
				& .next-btn {
					margin-right: 3%;
					width: fit-content;
					font-size: fonts.$font-size-base;
					line-height: 22.4px;
				}
				& .previous-btn {
					margin-left: 0px;
					width: fit-content;
				}
			}
		}
		& .stepper-body {
			padding: 25px 0px 25px 0px;
		}
	}
	.stepper-container {
		overflow-x: hidden;
		& .stepper-head {
			& .stepper-header-wrapper {
				margin: 0px 0px 0px -10.5%;
				width: 120%;
			}
		}
	}
}
@media (max-width: 1246px) {
	.stepper-container {
		.stepper-footer {
			& .buttons-section {
				& .next-btn {
					&.welcome-btn {
						display: flex;
						bottom: 20px;
					}
				}
			}
		}
	}
}
@media (min-width: 1500px) {
	.stepper-container {
		& .stepper-footer {
			&.welcome-wrapper {
				& .buttons-section {
					margin-left: 6.5%;
				}
			}
		}
	}
}
@media (min-width: 1600px) {
	.stepper-container {
		& .stepper-footer {
			&.welcome-wrapper {
				& .buttons-section {
					margin-left: 6.7%;
				}
			}
		}
	}
}
@media (min-width: 1800px) {
	.stepper-container {
		& .stepper-footer {
			&.welcome-wrapper {
				& .buttons-section {
					margin-left: 7.5%;
				}
			}
		}
	}
}
@media (min-width: 410px) and (max-width: breakpoints.$mobile-breakpoint) {
	.stepper-container {
		& .stepper-head {
			& .stepper-header-wrapper {
				width: 115%;
			}
		}
	}
}
@media (min-width: 600px) and (max-width: breakpoints.$mobile-breakpoint) {
	.stepper-container {
		& .stepper-head {
			& .stepper-header-wrapper {
				width: 119%;
			}
		}
	}
}
@media (min-width: 350px) and (max-width: 410px) {
	.stepper-container {
		& .stepper-head {
			& .stepper-header-wrapper {
				width: 110%;
			}
		}
	}
}
