@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/opacity.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/spacing.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/shadows.scss';

.overlay.open-warning {
	width: 100%;
	position: fixed;
	height: 100%;
	z-index: 10000;
	top: 0;
	background-color: colors.$color-overlay-dark;
	overflow-x: hidden;
	display: flex;
}

.new-page-container {
	.ingredients-details-container {
		height: 500px;
	}
	> .new-profile-container {
		display: flex;
		flex-direction: row;
		margin: 0px;

		.overlay {
			width: 0px;
			position: fixed;
			height: 150vh;
			z-index: 2;
			left: 0;
			background-color: colors.$color-overlay-dark;
			overflow-x: hidden;
			animation: none;

			top: 0px;
		}
		.overlay.open {
			width: 100%;
			margin: 0px;
			border: 0px;

			.menu-container {
				position: absolute;
				height: 1500px;
				top: 87px;
				animation: leftToRight ease 1s;
				-moz-animation: leftToRigth 0.5s ease-out;
				-webkit-animation: leftToRigth 0.5s ease-out;
			}
		}

		.menu-container.default {
			width: 272px;
			height: auto;
			background-color: colors.$color-background-white;
			.menu-hide {
				display: none;
			}
			.menu-default {
				padding: spacing.$spacing-4x spacing.$spacing-1_25x spacing.$spacing-4x
					spacing.$spacing-1_25x;
				background-color: colors.$color-background-light-gray;
			}
		}
		.main-content.profile {
			width: calc(100% - 272px);
			padding: 0px spacing.$spacing-1_5x spacing.$spacing-1_5x spacing.$spacing-1_5x;
			margin: 0px;
			min-height: 50vh;
			.content {
				.menu-button {
					display: none;
				}
				.title-content {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-top: spacing.$spacing-4x;
					& h2 {
						font-size: fonts.$font-size-h3;
						color: colors.$color-gray-slate;
						line-height: 51.2px;
						margin: 0px;
						margin-left: spacing.$spacing-1_5x;
					}
					& i {
						font-size: 30px;
						color: colors.$color-secondary;
					}
				}
			}
			.profile-card {
				background-color: colors.$color-background-white;
				border-radius: border-radius.$radius-medium;
				padding: spacing.$spacing-2x spacing.$spacing-4x spacing.$spacing-4x spacing.$spacing-4x;
				box-shadow: shadows.$shadow-medium;
				width: 95%;
				margin-top: spacing.$spacing-2x;
				.profile-title {
					font-family: fonts.$secondary-font-family;
					font-weight: fonts.$font-weight-bold;
					font-size: fonts.$font-size-h4;
					line-height: 32.68px;
					margin: 0px;
				}
			}
			.ingredient-card {
				background-color: colors.$color-background-white;
				border-radius: border-radius.$radius-medium;
				padding: spacing.$spacing-2x spacing.$spacing-4x spacing.$spacing-3x spacing.$spacing-4x;
				box-shadow: shadows.$shadow-medium;
				width: 95%;
				margin-top: spacing.$spacing-2x;
			}
			.favorite-card {
				background-color: colors.$color-background-white;
				border-radius: border-radius.$radius-medium;
				width: 95%;
				box-shadow: shadows.$shadow-medium;
				padding: spacing.$spacing-2x spacing.$spacing-4x spacing.$spacing-4x spacing.$spacing-4x;
				margin-top: spacing.$spacing-2x;
				.profile-title {
					font-family: fonts.$secondary-font-family;
					font-weight: fonts.$font-weight-bold;
					font-size: fonts.$font-size-h4;
					line-height: 32.68px;
				}
			}
			.brand-info-card {
				background-color: colors.$color-background-white;
				border-radius: border-radius.$radius-medium;
				padding: spacing.$spacing-2x spacing.$spacing-4x spacing.$spacing-4x spacing.$spacing-4x;
				box-shadow: shadows.$shadow-medium;
				margin-top: spacing.$spacing-7x;
				width: 95%;
				.title-container {
					display: flex;
					align-items: center;
					h3 {
						margin: 0px;
						font-size: fonts.$font-size-h4;
						font-family: fonts.$secondary-font-family;
						font-weight: fonts.$font-weight-bold;
						line-height: 32.68px;
					}
				}
				.app-status {
					max-width: 705px;
					:first-child {
						font-family: fonts.$tertiary-font-family;
						font-weight: fonts.$font-weight-semibold;
						font-size: fonts.$font-size-h4;

						line-height: 28.8px;
						font-size: fonts.$font-size-bigger;
					}
					:nth-child(2) {
						font-family: fonts.$primary-font-family;
						font-weight: fonts.$font-weight-regular;
						font-size: fonts.$font-size-h4;
						line-height: 28.8px;
						font-size: fonts.$font-size-bigger;
					}
				}
				.divider {
					border-bottom: 2px solid colors.$color-background-gray;
					margin-top: spacing.$spacing-x;
				}
				.questions {
					margin-top: spacing.$spacing-7x;
					margin-bottom: spacing.$spacing-3x;
					background-color: colors.$color-secondary-lightest;
					display: flex;
					align-items: center;
					width: fit-content;
					border-radius: 12px;
					padding: spacing.$spacing-x spacing.$spacing-2x;
					i {
						font-size: fonts.$font-size-h4;
						color: colors.$color-primary;
						margin-right: 4px;
					}
					.info-questions-container {
						span {
							font-family: fonts.$primary-font-family;
							font-weight: fonts.$font-weight-regular;
							font-size: fonts.$font-size-bigger;
						}
						:nth-child(2) {
							font-size: fonts.$font-size-bigger;
							font-family: fonts.$secondary-font-family;
							font-weight: fonts.$font-weight-bold;
							a {
								color: colors.$color-black;
							}
						}
					}
				}
				.description-form {
					font-family: fonts.$primary-font-family;
					font-size: fonts.$font-size-bigger;
					font-weight: fonts.$font-weight-regular;
					line-height: 28.8px;
					letter-spacing: 0.0075em;
					color: colors.$color-gray-slate;
					margin-top: spacing.$spacing-2x;
				}
			}
			.count-products {
				position: relative;
				top: 16px;
				padding-bottom: spacing.$spacing-x;
				font-family: fonts.$primary-font-family;
				font-size: fonts.$font-size-small;
				font-weight: fonts.$font-weight-regular;
				line-height: 19.2px;
				letter-spacing: 0.0075em;
				color: colors.$color-gray-slate;
			}
			.product-info-card {
				background-color: colors.$color-background-white;
				border-radius: border-radius.$radius-medium;
				width: 95%;
				box-shadow: shadows.$shadow-medium;
				padding: spacing.$spacing-2x spacing.$spacing-4x spacing.$spacing-4x spacing.$spacing-4x;
				margin-bottom: spacing.$spacing-4x;
				.product-main-description-container {
					display: flex;
					gap: 32px;
					justify-content: space-between;
					.product-description-container {
						display: flex;
						flex-direction: column;
						gap: 8px;
						flex: 1;
						.options-container {
							display: flex;
							flex-wrap: unset;
							gap: 8px;
							cursor: pointer;
							i {
								align-self: center;
								color: colors.$color-gray-slate;
								font-size: fonts.$font-size-bigger;
							}
							span {
								font-family: fonts.$tertiary-font-family;
								font-size: fonts.$font-size-big;
								font-weight: fonts.$font-weight-semibold;
								line-height: 28.8px;
								letter-spacing: 0.0075em;
								color: colors.$color-gray-slate;
								align-self: center;
							}
							button {
								margin: 0px;
								border-radius: border-radius.$radius-big;
								border: 1.5px solid colors.$color-tertiary-dark;
								padding: 0px spacing.$spacing-2x;
								box-shadow: none;
								color: colors.$color-tertiary-dark;
								font-size: fonts.$font-size-small;
							}
							.rounded-button.primary {
								background-color: colors.$color-tertiary-dark;
								color: colors.$color-text-white;
							}
						}
						.product-description {
							max-width: 705px;
							display: flex;
							flex-direction: column;
							gap: spacing.$spacing-x;
							h3 {
								font-size: fonts.$font-size-h4;
								font-family: fonts.$secondary-font-family;
								font-weight: fonts.$font-weight-bold;
								margin: 0px;
							}
							span {
								font-size: fonts.$font-size-bigger;
								font-family: fonts.$primary-font-family;
								font-weight: fonts.$font-weight-regular;
								color: colors.$color-gray-slate;
							}
							.questions {
								margin-bottom: spacing.$spacing-1_5x;
								background-color: colors.$color-secondary-lightest;
								display: flex;
								align-items: center;
								max-width: 572px;
								border-radius: 12px;
								padding: spacing.$spacing-x spacing.$spacing-2x;
								i {
									font-size: fonts.$font-size-h4;
									color: colors.$color-primary;
									margin-right: 3px;
								}
								span {
									font-family: fonts.$primary-font-family;
									font-weight: fonts.$font-weight-regular;
									font-size: fonts.$font-size-bigger;
								}
								:nth-child(3) {
									font-size: fonts.$font-size-bigger;
									font-family: fonts.$secondary-font-family;
									font-weight: fonts.$font-weight-bold;
									a {
										color: colors.$color-black;
										text-decoration: underline;
									}
								}
							}
						}
					}
					.product-image {
						flex: 0 0 259px;
						width: 259px;
						height: 235px;
						border-radius: border-radius.$radius-brand-image;
						box-shadow: shadows.$shadow-brand-image;

						.image-container {
							position: relative;
							img {
								border-radius: border-radius.$radius-brand-image;
								max-height: 230px;
								width: 100%;
								margin: auto;
							}
							i {
								position: absolute;
								right: 18px;
								top: 18px;
								color: colors.$color-gray-slate-border-input;
								font-size: fonts.$font-size-h5;
							}
						}
					}
				}
				.product-main-description-container-mobile {
					display: none;
				}

				.questions-tablet {
					display: none;
				}
				.button-show {
					cursor: pointer;
					background-color: colors.$color-text-white;
					border: none;
					display: flex;
					gap: 4px;
					margin: auto;
					padding: spacing.$spacing-4x 0px 0px 0px;
					color: colors.$color-gray-slate;
					font-size: fonts.$font-size-base;
					display: flex;
					justify-content: center;
					align-items: center;
					i {
						font-size: 22px;
					}
				}
			}
			.brand-onboard-page-container {
				width: 97%;
				> .title-container {
					margin-top: 0px !important;
				}
			}
		}
	}
	#target {
		margin-top: spacing.$spacing-5x;
	}
}

@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1260px) {
	.new-page-container {
		.new-profile-container {
			.main-content {
				.content {
					.favorite-card {
						width: 100%;
					}
				}
			}
			.main-content.profile {
				.product-info-card {
					.product-main-description-container {
						.product-description-container {
							.product-description {
								.questions {
									display: none;
								}
							}
						}
					}
					.questions-tablet {
						margin-top: spacing.$spacing-2x;
						margin-bottom: spacing.$spacing-1_5x;
						background-color: colors.$color-secondary-lightest;
						display: flex;
						align-items: center;
						max-width: 572px;
						border-radius: 12px;
						padding: spacing.$spacing-x spacing.$spacing-2x;
						i {
							font-size: fonts.$font-size-h4;
							color: colors.$color-primary;
							margin-right: 3px;
						}
						span {
							font-family: fonts.$primary-font-family;
							font-weight: fonts.$font-weight-regular;
							font-size: fonts.$font-size-bigger;
						}
						:nth-child(3) {
							font-size: fonts.$font-size-bigger;
							font-family: fonts.$secondary-font-family;
							font-weight: fonts.$font-weight-bold;
							a {
								color: colors.$color-black;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1050px) {
	.new-page-container {
		.new-profile-container {
			.main-content {
				.content {
					.ingredient-card {
						padding: spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-3x spacing.$spacing-2x;
						width: 100%;
						margin-top: spacing.$spacing-x;
					}
					.favorite-card {
						width: 100%;
						padding: spacing.$spacing-2x spacing.$spacing-4x spacing.$spacing-4x spacing.$spacing-4x;
						margin-top: spacing.$spacing-x;
					}
				}
			}
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.new-page-container {
		.new-profile-container {
			height: 100%;
			.overlay.open {
				position: absolute;
				height: 250%;
				border: 0px;
				.overlay-close {
					min-height: 100%;
					width: calc(100% - 310px);
					margin-left: 310px;
				}
				.menu-container.tablet {
					display: flex;
					animation: leftToRigth 0.5s ease-out;
					-moz-animation: leftToRigth 0.5s ease-out;
					-webkit-animation: leftToRigth 0.5s ease-out;
					.menu-hide-container {
						height: 100%;
						width: 310px;
						.menu-hide {
							display: flex;
							flex-direction: column;
							background-color: colors.$color-background-white;
							border-radius: 0px border-radius.$radius-medium 0px 0px;
							height: 140%;
							padding-left: spacing.$spacing-2x;
							padding-right: spacing.$spacing-2x;
							.menu-header {
								width: 278px;
								position: relative;
								top: 26px;
								height: 20px;
								display: flex;
								justify-content: space-between;
								align-items: center;
								p {
									position: relative;
									font-size: fonts.$font-size-small;
									line-height: 19.2px;
									margin: 0px;
								}
								.hide-buttons {
									font-weight: 400;
									display: flex;
									.menu-button {
										width: auto;
										height: auto;
										font-size: fonts.$font-size-small;
										margin: 0px;
										padding: 0px;
										text-decoration: underline;
										color: colors.$color-gray-slate-light;
									}
									button {
										width: 16px;
										height: 16px;
										i {
											font-size: fonts.$font-size-small;
											color: colors.$color-gray-slate-light;
										}
										&:hover {
											background: none;
											box-shadow: none;
										}
										&:active {
											background: none;
											box-shadow: none;
										}
									}
								}
							}
							.divider {
								width: 100%;
								padding: 0px;
								border: 2px solid colors.$color-gray;
								margin: 0px;
								top: 29px;
								position: relative;
							}
						}
					}
				}
			}
			.main-content.profile {
				width: 100%;
			}
			.main-content {
				.content {
					.title-content {
						display: flex;
						height: 52px;
						& h2 {
							font-size: fonts.$font-size-h5 !important;
							line-height: 27.24px !important;
							margin: 0px;
							margin-left: spacing.$spacing-x !important;
						}
						& i {
							font-size: fonts.$font-size-h4 !important ;
							color: colors.$color-secondary;
						}
					}
					.header-card {
						display: flex;
						align-items: center;
						.menu-button {
							display: block;
							font-family: fonts.$primary-font-family;
							font-weight: fonts.$font-weight-regular;
							padding: spacing.$spacing-half-x spacing.$spacing-1_5x spacing.$spacing-half-x
								spacing.$spacing-1_5x;
							border-radius: 14px;
							border: 1px solid colors.$color-gray-slate-light;
							box-shadow: none;
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							width: 118px;
							height: 28px;
							color: colors.$color-black;
							margin: 0;
							i {
								margin-right: spacing.$spacing-half-x;
							}
						}
						.count-products {
							margin: 0px;
							top: 0px;
							padding: 0px;
						}
					}

					.profile-card {
						width: 100%;
						padding: spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-3x spacing.$spacing-2x;
						margin-top: spacing.$spacing-x;
						.profile-title {
							font-size: fonts.$font-size-h5;
							line-height: 27.24px;
							margin: 0px;
						}
					}
					.ingredient-card {
						border-radius: border-radius.$radius-medium;
						padding: 0px spacing.$spacing-2x spacing.$spacing-3x spacing.$spacing-2x;
						width: 100%;
						box-shadow: shadows.$shadow-medium;
						margin-top: spacing.$spacing-x;
					}
					.favorite-card {
						border-radius: border-radius.$radius-medium;
						width: 100%;
						box-shadow: shadows.$shadow-medium;
						padding: spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-3x spacing.$spacing-2x;
						margin-top: spacing.$spacing-x;
						.profile-title {
							font-size: fonts.$font-size-h4;
							line-height: 32.68px;
							margin: 0px;
						}
					}
					.brand-info-card {
						padding: spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-3x spacing.$spacing-2x;
						box-shadow: shadows.$shadow-medium;
						width: 100%;
						margin-top: spacing.$spacing-x;
						.title-container {
							padding-top: 16px;
							button {
								margin: 0px;
								font-size: fonts.$font-size-small;
							}
							h3 {
								margin: 0px;
								font-size: fonts.$font-size-h5;
							}
						}
						.app-status {
							max-width: 664px;
							:first-child {
								font-family: fonts.$tertiary-font-family;
								font-size: fonts.$font-size-base;
							}
							:nth-child(2) {
								font-family: fonts.$primary-font-family;
								font-size: fonts.$font-size-base;
							}
						}
						.divider {
							border-bottom: 2px solid colors.$color-background-gray;
							margin-top: spacing.$spacing-x;
						}
						.questions {
							margin-top: spacing.$spacing-5x;
							i {
								font-size: fonts.$font-size-big;
								color: colors.$color-primary;
							}
							.info-questions-container {
								span {
									font-family: fonts.$primary-font-family;
									font-size: fonts.$font-size-big;
								}
								:nth-child(3) {
									font-size: fonts.$font-size-big;
									a {
										color: colors.$color-black;
									}
								}
							}
						}
						.description-form {
							font-size: fonts.$font-size-big;
						}
					}
					.product-info-card {
						width: 100%;
						margin-top: spacing.$spacing-x;
						.product-main-description-container {
							.product-description-container {
								.product-description {
									h3 {
										font-size: fonts.$font-size-h5;
									}
									span {
										font-size: fonts.$font-size-base;
									}
									.questions {
										display: none;
									}
								}
							}
						}
						.questions-tablet {
							margin-top: spacing.$spacing-2x;
							margin-bottom: spacing.$spacing-1_5x;
							background-color: colors.$color-secondary-lightest;
							display: flex;
							align-items: center;
							max-width: 572px;
							border-radius: 12px;
							padding: spacing.$spacing-x spacing.$spacing-2x;
							i {
								font-size: fonts.$font-size-big;
								color: colors.$color-primary;
								margin-right: 3px;
							}
							span {
								font-family: fonts.$primary-font-family;
								font-weight: fonts.$font-weight-regular;
								font-size: fonts.$font-size-big;
							}
							:nth-child(3) {
								font-size: fonts.$font-size-big;
								font-family: fonts.$secondary-font-family;
								font-weight: fonts.$font-weight-bold;
								a {
									color: colors.$color-black;
									text-decoration: underline;
								}
							}
						}
					}
					.brand-onboard-page-container {
						width: 100%;
						margin-top: spacing.$spacing-x;
					}
				}
			}
		}
	}
	#target {
		margin-top: spacing.$spacing-4x !important;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.new-page-container {
		.new-profile-container {
			height: 100%;
			top: 30px;
			.overlay.open {
				position: absolute;
				height: 250%;
				border: 0px;
				z-index: 400;
				background-color: colors.$color-background-light-gray;
				top: 87px;
				display: block;
			}
			.menu-container.mobile {
				top: 0px !important;
				width: 100%;

				.menu-hide-container {
					height: 100%;
					width: 100%;
					.menu-hide {
						display: flex;
						flex-direction: column;
						background-color: colors.$color-background-light-gray;
						border-radius: 0px border-radius.$radius-medium 0px 0px;
						height: 140%;
						padding: spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-4x spacing.$spacing-2x;
						.menu-header {
							width: 100%;
							position: relative;
							display: flex;
							justify-content: space-between;
							align-items: center;
							p {
								position: relative;
								font-size: fonts.$font-size-big;
								line-height: 19.2px;
								margin: 0px;
							}
							.hide-buttons {
								font-weight: 400;
								display: flex;
								.menu-button {
									font-size: fonts.$font-size-h4;
									margin: 0px;
									padding: 0px;
									text-decoration: none;
									color: colors.$color-gray-slate;
								}
							}
						}
						.divider {
							width: 81px;
							padding: 0px;
							height: 3px;
							background: colors.$gradient-blue;
							border-radius: 2px;
							margin: 0px;
						}
						.menu {
							margin-top: spacing.$spacing-1_75x;
						}
					}
				}
			}
			.main-content.profile {
				width: 100%;
			}
			.main-content {
				padding: 0px spacing.$spacing-x spacing.$spacing-x spacing.$spacing-x !important;

				.content {
					.header-card {
						display: flex;
						align-items: center;
						.menu-button {
							display: block;
							width: fit-content;
							height: 28px;
							padding: spacing.$spacing-half-x spacing.$spacing-x;
							font-size: fonts.$font-size-small;
							font-family: fonts.$primary-font-family;
							line-height: 19.2px;
							text-align: center;
							align-self: center;
							box-shadow: none;
							border: 1px solid colors.$color-gray-slate;
							color: black;
							margin-bottom: 0;
							i {
								margin-right: spacing.$spacing-half-x;
							}
						}
						.count-products {
							margin: 0px;
							top: 0px;
							padding: 0px;
						}
					}

					.title-content {
						display: flex;
						height: auto;
						margin-left: spacing.$spacing-x;
						margin-top: spacing.$spacing-3x !important;
						h2 {
							font-size: fonts.$font-size-base !important;
							color: colors.$color-gray-slate;
							line-height: 22.4px !important;
							margin: auto;
							margin-left: spacing.$spacing-half-x !important;
						}
						i {
							font-size: fonts.$font-size-h4 !important;
						}
					}
					.profile-card {
						padding: spacing.$spacing-2x spacing.$spacing-1_5x spacing.$spacing-2x
							spacing.$spacing-1_5x;
						width: 100%;
						margin-top: spacing.$spacing-x;
						.profile-title {
							font-size: fonts.$font-size-bigger;
							line-height: 24.51px;
							margin: 0px;
						}
					}
					.ingredient-card {
						padding: spacing.$spacing-2x spacing.$spacing-1_5x spacing.$spacing-2x
							spacing.$spacing-1_5x !important;
						width: 100%;
						box-shadow: shadows.$shadow-medium;
						margin-top: spacing.$spacing-x;
					}
					.favorite-card {
						width: 100%;
						box-shadow: shadows.$shadow-medium;
						padding: spacing.$spacing-2x spacing.$spacing-1_5x spacing.$spacing-2x
							spacing.$spacing-1_5x !important;
						margin-top: spacing.$spacing-x;
					}
					.brand-info-card {
						padding: spacing.$spacing-2x spacing.$spacing-1_5x spacing.$spacing-2x
							spacing.$spacing-1_5x !important;
						box-shadow: shadows.$shadow-medium;
						margin-top: spacing.$spacing-x;
						width: 100%;
						.title-container {
							position: relative;
							word-break: normal;
							display: flex;
							flex-direction: column;
							align-items: start;
							gap: 8px;
							h3 {
								margin: 0px;
								font-size: fonts.$font-size-h5;
								display: inline;
							}
						}
						.app-status {
							margin-top: 16px;
							:first-child {
								font-size: fonts.$font-size-base;
								line-height: 22.4px;
							}
							:nth-child(2) {
								font-size: fonts.$font-size-base;
							}
						}
						.divider {
							border-bottom: 2px solid colors.$color-background-gray;
							margin-top: spacing.$spacing-x;
						}
						.questions {
							display: flex;
							margin-top: spacing.$spacing-4x;
							i {
								font-size: fonts.$font-size-big;
							}
							.info-questions-container {
								word-break: break-all;
								span {
									font-size: fonts.$font-size-base;
								}
								:nth-child(2) {
									font-size: fonts.$font-size-base;
								}
							}
						}
						.description-form {
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
						}
					}
					.product-info-card {
						width: 100%;
						padding: spacing.$spacing-2x spacing.$spacing-1_5x;
						.product-main-description-container {
							display: none;
						}
						.product-main-description-container-mobile {
							display: flex;
							flex-direction: column;
							gap: 8px;
							.options-container {
								display: flex;
								align-content: center;
								gap: 8px;
								font-size: fonts.$font-size-base-mobile;
								align-items: center;
								span {
									margin: 0px;
								}
								i {
									font-size: fonts.$font-size-h5-mobile;
								}
								button {
									margin: 0px;
									border-radius: border-radius.$radius-big;
									border: 1.5px solid colors.$color-tertiary-dark;
									padding: 0px spacing.$spacing-2x;
									box-shadow: none;
									color: colors.$color-tertiary-dark;
									font-size: fonts.$font-size-small;
								}
								.rounded-button.primary {
									background-color: colors.$color-tertiary-dark;
									color: colors.$color-text-white;
								}
							}
							h3 {
								font-size: fonts.$font-size-h5-mobile;
								font-family: fonts.$secondary-font-family;
								font-weight: fonts.$font-weight-bold;
								margin: 0px;
							}
							.product-image {
								margin: auto;
								flex: 0 0 235px;
								width: 259px;
								height: 235px;
								border-radius: border-radius.$radius-brand-image;
								box-shadow: shadows.$shadow-medium;

								.image-container {
									position: relative;
									img {
										border-radius: border-radius.$radius-brand-image;
										max-height: 230px;
										width: 100%;
										margin: auto;
									}
									i {
										position: absolute;
										right: 18px;
										top: 18px;
										color: colors.$color-gray-slate-border-input;
										font-size: fonts.$font-size-h5;
									}
								}
							}
							span {
								font-size: fonts.$font-size-base-mobile;
								line-height: 19.2px;
								color: black;
								margin-top: 8px;
							}
							.questions-mobile {
								margin-top: 0px;
								margin-bottom: spacing.$spacing-2x;
								background-color: colors.$color-secondary-lightest;
								display: flex;
								align-items: center;
								border-radius: 12px;
								padding: spacing.$spacing-x spacing.$spacing-2x;
								i {
									font-size: fonts.$font-size-bigger-mobile;
									color: colors.$color-primary;
									margin-right: 4px;
								}
								.info-questions-container {
									word-break: normal;
									span {
										font-family: fonts.$primary-font-family;
										font-weight: fonts.$font-weight-regular;
										font-size: fonts.$font-size-big-mobile;
									}
									:nth-child(2) {
										font-size: fonts.$font-size-big-mobile;
										font-family: fonts.$secondary-font-family;
										font-weight: fonts.$font-weight-bold;
										a {
											color: colors.$color-black;
										}
									}
								}
							}
						}
						.button-show {
							font-size: fonts.$font-size-small;
						}
					}
					.brand-onboard-page-container {
						width: 100%;
						margin-top: spacing.$spacing-x;
					}
				}
			}
		}
	}
	#target {
		margin-top: spacing.$spacing-3x !important;
	}
}
