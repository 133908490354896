@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/breakpoints.scss';

.category-nav-overlay {
	overflow: hidden;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	border-radius: 0 0 border-radius.$radius-medium border-radius.$radius-medium;
	box-shadow: shadows.$shadow-medium;
	z-index: 98;
	position: absolute;
	background-color: colors.$color-background-white;

	& .overlay-footer {
		width: 100%;
		height: 67px;
		background: colors.$color-background-secondary-lightest;
		margin-top: spacing.$spacing-4x;
	}

	&.cards-case {
		background: linear-gradient(
			to top,
			colors.$color-background-white 57%,
			colors.$color-background-secondary-lightest 43%
		);
		padding-bottom: spacing.$spacing-4x;

		& .overlay-footer {
			display: none;
		}
	}
	&.cards-case.large-blue {
		background: linear-gradient(
			to top,
			colors.$color-background-white 66.2%,
			colors.$color-background-secondary-lightest 33.8%
		);
	}
	&.nexty-case {
		height: auto;
	}
	& .navbar-card-elements-container {
		display: flex;
		justify-content: space-evenly;
		margin-top: spacing.$spacing-4x;

		width: 100%;
		& > div {
			max-width: 232px;
		}

		& .card-container {
			cursor: pointer;

			& .image-container {
				width: 100%;
				height: fit-content;

				& .card-image {
					width: 232px;
					height: 232px;
				}
			}

			& .description-container {
				margin-top: spacing.$spacing-2x;

				& .description {
					color: colors.$color-text-black;
					font-family: fonts.$primary-font-family;
					font-size: fonts.$font-size-big;
					font-style: normal;
					font-weight: fonts.$font-weight-regular;
					line-height: 25.6px;
					letter-spacing: fonts.$letter-spacing-std;
					margin-bottom: 0px;
				}
			}

			& .link-container {
				margin-top: spacing.$spacing-3x;

				& .action-link-contianer {
					transition: transform 1s ease;
					display: flex;
					align-items: center;

					& > span {
						height: auto;
						width: fit-content;
						border-bottom: none;
						text-decoration: underline;
						text-underline-offset: 6px;
					}

					& > i {
						margin-left: spacing.$spacing-half-x;
						font-size: 22px;
						margin-top: -17px;
					}

					&:hover {
						& > i {
							transform: translateX(4px);
						}
					}
				}
			}
		}
	}

	& .category-navbar-container {
		display: flex;
		width: 100%;
		padding: spacing.$spacing-3x 1% 0px 1%;
		justify-content: center;
		flex-direction: column;

		& .overlay-body {
			background: colors.$color-background-white;
		}
		.business-case.category-navbar-card-item {
			display: grid;
			grid-template-columns: repeat(5, auto);
			grid-template-rows: repeat(2, auto);
			column-gap: 56px;
			row-gap: 32px;
			width: 100%;
			justify-content: center;
			:first-child.category-navbar-item-container {
				width: 100%;
			}
		}
		& .pantry-case {
			&.category-navbar-card-item {
				display: grid;
				grid-template-columns: repeat(5, auto);
				grid-template-rows: repeat(2, auto);
				column-gap: 32px;
				width: 100%;
				justify-content: center;

				& > div {
					height: fit-content;

					&:nth-child(1),
					&:nth-child(2) {
						height: fit-content;
						grid-column: span 1 / 2;
					}

					&:nth-child(1) {
						grid-row: 1;
					}

					&:nth-child(2) {
						grid-row: 1;
						margin-top: spacing.$spacing-6x;
					}

					&:nth-child(n + 3) {
						grid-column: span 1;
						grid-row: 1;
					}
				}
			}
		}

		& .other-case {
			&.category-navbar-card-item {
				column-gap: 72px;
			}
		}
		& .beverages-case {
			&.category-navbar-card-item {
				column-gap: 32px;
			}
		}
		& .fresh-frozen-case {
			&.category-navbar-card-item {
				column-gap: 32px;
			}
		}
		& .supplements-vitamins-case {
			&.category-navbar-card-item {
				column-gap: 32px;
			}
		}
		& .health-beauty-case {
			&.category-navbar-card-item {
				column-gap: 32px;
			}
		}

		& .category-navbar-card-item {
			justify-content: center;
			width: 100%;
			display: flex;

			& .category-navbar-item-container {
				display: flex;
				flex-direction: column;
				gap: 8px;
				box-sizing: border-box;

				&:not(.no-underline) {
					& > a {
						&:first-child {
							transition: transform 1s ease;
							display: flex;
							align-items: center;

							& > span {
								height: auto;
								width: fit-content;
								border-bottom: none;
								text-decoration: underline;
								text-underline-offset: 6px;
							}

							& > i {
								margin-top: -8px;
								width: 22px;
								height: 22px;
								font-size: 22px;
								margin-left: spacing.$spacing-half-x;
							}

							&:hover {
								border-bottom: none;

								& > i {
									transform: translateX(4px);
								}
							}
						}
					}
				}

				& > div {
					&:first-child {
						transition: transform 1s ease;
						display: flex;
						align-items: center;

						& > span {
							height: auto;
							width: fit-content;
							border-bottom: none;
						}

						& > i {
							margin-top: -8px;
							width: 22px;
							height: 22px;
							font-size: 22px;
							margin-left: spacing.$spacing-half-x;
						}

						&:hover {
							border-bottom: none;

							& > i {
								transform: translateX(4px);
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 500px) and (max-width: 992px) {
	.category-nav-overlay {
		&.cards-case.large-blue {
			background: linear-gradient(
				to top,
				colors.$color-background-white 73.7%,
				colors.$color-background-secondary-lightest 26.3%
			);
		}
	}
}

@media (max-width: 1330px) {
	.category-nav-overlay {
		& .navbar-card-elements-container {
			& .card-container {
				width: 100%;
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1270px) {
	.category-nav-overlay {
		& .navbar-card-elements-container {
			justify-content: space-evenly;
		}
	}
}

@media (min-width: 993px) and (max-width: 1150px) {
	.category-nav-overlay {
		.category-navbar-container {
			.business-case.category-navbar-card-item {
				:first-child.category-navbar-item-container {
					width: 72%;
				}
			}
		}
	}
}
@media (min-width: 1151px) and (max-width: 1190px) {
	.category-nav-overlay {
		.category-navbar-container {
			.business-case.category-navbar-card-item {
				:first-child.category-navbar-item-container {
					width: 89%;
				}
			}
		}
	}
}
@media (min-width: 1195px) and (max-width: 1281px) {
	.category-nav-overlay {
		.category-navbar-container {
			.business-case.category-navbar-card-item {
				:first-child.category-navbar-item-container {
					width: 78%;
				}
			}
		}
	}
}
@media (min-width: 1282px) and (max-width: 1381px) {
	.category-nav-overlay {
		.category-navbar-container {
			.business-case.category-navbar-card-item {
				:first-child.category-navbar-item-container {
					width: 109%;
				}
			}
		}
	}
}

@media (min-width: 1290px) and (max-width: 1310px) {
	.category-nav-overlay {
		.category-navbar-container {
			.business-case.category-navbar-card-item {
				:nth-child(2).category-navbar-item-container {
					width: 108%;
				}
			}
		}
	}
}

@media (min-width: 1250px) and (max-width: 1289px) {
	.category-nav-overlay {
		.category-navbar-container {
			.business-case.category-navbar-card-item {
				:nth-child(2).category-navbar-item-container {
					width: 92%;
				}
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1020px) {
	.category-nav-overlay {
		.category-navbar-container {
			.business-case.category-navbar-card-item {
				:nth-child(3).category-navbar-item-container {
					width: 82%;
				}
			}
		}
	}
}

@media (min-width: 1030px) and (max-width: 1220px) {
	.category-nav-overlay {
		.category-navbar-container {
			.business-case.category-navbar-card-item {
				:nth-child(4).category-navbar-item-container {
					width: 82%;
				}
			}
		}
	}
}

@media (min-width: 1052px) and (max-width: 1210px) {
	.category-nav-overlay {
		.category-navbar-container {
			.business-case.category-navbar-card-item {
				:nth-child(6).category-navbar-item-container {
					width: 72%;
				}
			}
		}
	}
}

@media (max-width: breakpoints.$tablet-breakpoint) {
	.category-nav-overlay {
		&.cards-case {
			background: linear-gradient(
				to top,
				colors.$color-background-white 64%,
				colors.$color-background-secondary-lightest 36%
			);
			height: auto;
		}

		& .navbar-card-elements-container {
			margin: spacing.$spacing-3x 0px 0px 0px;
			display: flex;
			justify-content: space-evenly;
			& > div {
				max-width: 150px;
			}

			& .card-container {
				& .image-container {
					width: 100%;
					height: fit-content;

					& .card-image {
						width: 150px;
						height: 150px;
					}
				}

				& .description-container {
					& .description {
						font-size: fonts.$font-size-base-mobile;
						line-height: 19px;
						letter-spacing: 0.0075em;
					}
				}

				& .link-container {
					margin-top: spacing.$spacing-2x;

					& .action-link-contianer {
						& > span {
							font-size: fonts.$font-size-base;
							line-height: 22px;
						}

						& > i {
							margin-top: -12px;
							font-size: fonts.$font-size-h5-mobile;
						}
					}
				}
			}
		}

		& .category-navbar-container {
			padding: spacing.$spacing-3x 0px 0px 0px;

			& .category-navbar-card-item {
				& .category-navbar-item-container {
					column-gap: 0px;

					& > div {
						&:first-child {
							& > i {
								font-size: fonts.$font-size-h5-mobile;
								margin-top: -5px;
							}
						}
					}

					& .action-link-contianer {
						& .action-link {
							&.big {
								font-size: fonts.$font-size-base;
								line-height: 22.4px;
							}
						}
					}
					&:not(.no-underline) {
						& > div {
							&:first-child {
								& > i {
									margin-top: -8px;
									width: 18px;
									height: 18px;
									font-size: 18px;
								}
							}
						}
					}
				}
			}
			& .business-case {
				&.category-navbar-card-item {
					display: grid;
					padding-right: 1%;
					padding-left: 1%;
					grid-template-columns: repeat(3, auto);
					grid-template-rows: repeat(3, auto);
					column-gap: 16px;
					row-gap: 32px;
					width: 100%;
					justify-content: center;
				}
			}
			& .pantry-case {
				height: auto;
				padding: 0px 33px;

				&.category-navbar-card-item {
					grid-template-columns: repeat(3, auto);
					grid-template-rows: repeat(2, auto);
					row-gap: 16px;
					justify-content: space-around;

					& > div {
						height: fit-content;

						&:nth-child(1),
						&:nth-child(2) {
							grid-column: span 1 / 2;
						}

						&:nth-child(5) {
							grid-column: span 1 / 2;
							grid-row: 2;
						}

						&:nth-child(3) {
							grid-column: 2;
							grid-row: 1;
						}

						&:nth-child(6) {
							grid-column: 2;
							grid-row: 2;
						}

						&:nth-child(4) {
							grid-column: 3;
							grid-row: 1;
						}

						&:nth-child(7) {
							grid-column: 3;
							grid-row: 2;
						}
					}
				}
			}

			& .health-beauty-case {
				padding: 0px 57px;

				&.category-navbar-card-item {
					display: grid;
					grid-template-columns: repeat(4, auto);
					grid-template-rows: repeat(1, auto);
					justify-content: space-around;
				}
			}

			& .fresh-frozen-case {
				height: auto;
				padding: 0px 51px;

				&.category-navbar-card-item {
					display: inline-grid;
					grid-template-columns: 236px auto auto;
					grid-template-rows: repeat(2, auto);
					row-gap: 16px;
					justify-content: space-between;

					& > div {
						height: fit-content;
					}
				}
			}

			& .beverages-case {
				height: auto;
				padding: 0px 54px;

				&.category-navbar-card-item {
					display: inline-grid;
					grid-template-columns: repeat(3, auto);
					grid-template-rows: repeat(2, auto);
					row-gap: 16px;
					justify-content: space-between;
				}
			}

			& .supplements-vitamins-case {
				height: auto;
				padding: 0px 50px;

				&.category-navbar-card-item {
					display: inline-grid;
					grid-template-columns: 235px repeat(2, auto);
					grid-template-rows: repeat(2, auto);
					column-gap: 24px;
					row-gap: 16px;
					justify-content: space-between;

					& > div {
						height: fit-content;

						&:nth-child(3) {
							grid-column: 1;
							grid-row: 2;
						}

						&:nth-child(4) {
							grid-column: 2;
							grid-row: 2;
						}
					}
				}
			}

			& .other-case {
				&.category-navbar-card-item {
					gap: 56px;
				}
			}
		}
	}

	@media (max-width: 793px) {
		.category-nav-overlay {
			& .category-navbar-container {
				& .pantry-case {
					&.category-navbar-card-item {
						& .category-navbar-item-container {
							&:nth-child(4) {
								& .action-link-contianer {
									&:first-child {
										& > span {
											width: 76%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 795px) {
		.category-nav-overlay {
			& .category-navbar-container {
				& .fresh-frozen-case {
					&.category-navbar-card-item {
						& .category-navbar-item-container {
							&:nth-child(2) {
								& .action-link-contianer {
									&:first-child {
										& > span {
											width: 60%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@media (max-width: 779px) {
		.category-nav-overlay {
			& .category-navbar-container {
				& .fresh-frozen-case {
					&.category-navbar-card-item {
						& .category-navbar-item-container {
							&:nth-child(2) {
								& .action-link-contianer {
									&:first-child {
										& > span {
											width: 51%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@media (max-width: 765px) {
		.category-nav-overlay {
			& .category-navbar-container {
				& .fresh-frozen-case {
					&.category-navbar-card-item {
						& .category-navbar-item-container {
							&:nth-child(2) {
								& .action-link-contianer {
									&:first-child {
										& > span {
											width: 52%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@media (max-width: 760px) {
		.category-nav-overlay {
			& .category-navbar-container {
				& .fresh-frozen-case {
					&.category-navbar-card-item {
						& .category-navbar-item-container {
							&:nth-child(2) {
								& .action-link-contianer {
									&:first-child {
										& > span {
											width: 55%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 749px) {
		.category-nav-overlay {
			& .category-navbar-container {
				& .fresh-frozen-case {
					&.category-navbar-card-item {
						& .category-navbar-item-container {
							&:nth-child(2) {
								& .action-link-contianer {
									&:first-child {
										& > span {
											width: 57%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@media (max-width: 784px) {
		.category-nav-overlay {
			& .category-navbar-container {
				& .pantry-case {
					&.category-navbar-card-item {
						& .category-navbar-item-container {
							&:nth-child(4) {
								& .action-link-contianer {
									&:first-child {
										& > span {
											width: 72%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@media (max-width: 774px) {
		.category-nav-overlay {
			& .category-navbar-container {
				& .pantry-case {
					&.category-navbar-card-item {
						& .category-navbar-item-container {
							&:nth-child(4) {
								& .action-link-contianer {
									&:first-child {
										& > span {
											width: 59%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@media (max-width: 760px) {
		.category-nav-overlay {
			& .category-navbar-container {
				& .pantry-case {
					&.category-navbar-card-item {
						& .category-navbar-item-container {
							&:nth-child(4) {
								& .action-link-contianer {
									&:first-child {
										& > span {
											width: 44%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1165px) {
	.category-nav-overlay {
		& .category-navbar-container {
			& .beverages-case {
				&.category-navbar-card-item {
					gap: 4px;
				}
			}
		}
	}

	@media (max-width: 1070px) {
		.category-nav-overlay {
			& .category-navbar-container {
				& .beverages-case {
					&.category-navbar-card-item {
						gap: 0;

						& .action-link-contianer {
							& .action-link {
								&.big {
									font-size: 15px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1430px) {
	.category-nav-overlay {
		& .category-navbar-container {
			& .fresh-frozen-case {
				&.category-navbar-card-item {
					display: grid;
					grid-template-columns: repeat(4, auto);
					grid-template-rows: repeat(2, auto);
					width: 100%;
					justify-content: center;

					& > div {
						height: fit-content;

						&:nth-child(2),
						&:nth-child(3),
						&:nth-child(6) {
							grid-column: 2;
						}

						&:nth-child(1) {
							grid-row: 1;
						}

						&:nth-child(4) {
							grid-column: 3;
							grid-row: 1;
						}

						&:nth-child(5) {
							grid-row: 1;
							grid-column: 4;
						}

						&:nth-child(6) {
							margin-top: -80px;
						}

						&:nth-child(3) {
							margin-top: -105px;
						}
					}
				}
			}
		}
	}

	@media (max-width: 1094px) {
		.category-nav-overlay {
			& .category-navbar-container {
				& .fresh-frozen-case {
					&.category-navbar-card-item {
						& .action-link-contianer {
							& .action-link {
								&.big {
									font-size: 15px;
								}
							}
						}
					}
				}
			}
		}
	}
	@media (min-width: 1200px) {
		.category-nav-overlay {
			& .category-navbar-container {
				& .fresh-frozen-case {
					&.category-navbar-card-item {
						& > div {
							&:nth-child(6) {
								margin-top: -50px;
							}

							&:nth-child(3) {
								margin-top: -87px;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1292px) {
	.category-nav-overlay {
		& .category-navbar-container {
			& .supplements-vitamins-case {
				&.category-navbar-card-item {
					display: grid;
					grid-template-columns: repeat(4, auto);
					grid-template-rows: repeat(2, auto);
					width: 100%;
					justify-content: space-around;

					& > div {
						height: fit-content;

						&:nth-child(1),
						&:nth-child(2) {
							grid-column: span 1 / 2;
						}

						&:nth-child(1) {
							grid-row: 1;
						}

						&:nth-child(3) {
							grid-column: 2;
							grid-row: 1;
						}

						&:nth-child(4) {
							grid-column: 3;
							grid-row: 1;
							margin-top: 5px;
						}

						&:nth-child(2) {
							margin-top: -62px;
						}

						&:nth-child(5) {
							grid-column: 4;
							grid-row: 1;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1125px) {
	.category-nav-overlay {
		& .category-navbar-container {
			& .pantry-case {
				&.category-navbar-card-item {
					& .category-navbar-item-container {
						gap: 4px;

						& .action-link-contianer {
							& .action-link {
								&.big {
									font-size: 15px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1466px) {
	.category-nav-overlay {
		& .category-navbar-container {
			& .pantry-case {
				&.category-navbar-card-item {
					& .category-navbar-item-container {
						&:nth-child(4) {
							& .action-link-contianer {
								&:first-child {
									& > span {
										width: 75%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1440px) {
	.category-nav-overlay {
		& .category-navbar-container {
			& .pantry-case {
				&.category-navbar-card-item {
					& .category-navbar-item-container {
						&:nth-child(4) {
							& .action-link-contianer {
								&:first-child {
									& > span {
										width: 60%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1431px) {
	.category-nav-overlay {
		& .category-navbar-container {
			& .pantry-case {
				&.category-navbar-card-item {
					& .category-navbar-item-container {
						&:nth-child(4) {
							& .action-link-contianer {
								&:first-child {
									& > span {
										width: 60%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1429px) {
	.category-nav-overlay {
		& .category-navbar-container {
			& .pantry-case {
				&.category-navbar-card-item {
					& .category-navbar-item-container {
						&:nth-child(4) {
							& .action-link-contianer {
								&:first-child {
									& > span {
										width: 50%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1351px) {
	.category-nav-overlay {
		& .category-navbar-container {
			& .pantry-case {
				&.category-navbar-card-item {
					& .category-navbar-item-container {
						&:nth-child(6) {
							& .action-link-contianer {
								&:first-child {
									& > span {
										width: 60%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1062px) {
	.category-nav-overlay {
		& .category-navbar-container {
			& .pantry-case {
				&.category-navbar-card-item {
					& .category-navbar-item-container {
						&:nth-child(6) {
							& .action-link-contianer {
								&:first-child {
									& > span {
										width: 70%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 993px) and (max-width: 1270px) {
	.category-nav-overlay {
		& .navbar-card-elements-container {
			& .card-container {
				& .description-container {
					& .description {
						font-size: fonts.$font-size-big;
					}
				}
			}
		}

		& .category-navbar-container {
			& .beverages-case {
				&.category-navbar-card-item {
					gap: 16px;
				}
			}

			& .fresh-frozen-case {
				&.category-navbar-card-item {
					gap: 16px;
				}
			}
		}
	}
}

@media (max-width: breakpoints.$mobile-breakpoint) {
	.category-nav-overlay {
		display: none;
	}
}
