$spacing-x: 8px;
$spacing-half-x: $spacing-x / 2;
$spacing-1_25x: $spacing-x * 1.25;
$spacing-1_5x: $spacing-x * 1.5;
$spacing-1_75x: $spacing-x * 1.75;
$spacing-3_5x: $spacing-x * 3.5;
$spacing-2x: $spacing-x * 2;
$spacing-3x: $spacing-x * 3;
$spacing-4x: $spacing-x * 4;
$spacing-5x: $spacing-x * 5;
$spacing-6x: $spacing-x * 6;
$spacing-7x: $spacing-x * 7;
$spacing-8x: $spacing-x * 8;
$spacing-9x: $spacing-x * 9;
$spacing-10x: $spacing-x * 10;
$spacing-12x: $spacing-x * 12;
$spacing-15x: $spacing-x * 15;
$spacing-20x: $spacing-x * 20;
