@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';
.form-title-container {
	background-color: colors.$color-secondary-lightest;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 12px;
	padding: spacing.$spacing-2x;
	.form-title {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		color: colors.$color-primary;
		font-size: fonts.$font-size-h5;
		margin: 0px;
		padding: 0px;
		line-height: 27.24px;
	}
	.read-only-edit {
		display: flex;
		align-items: center;
		font-size: fonts.$font-size-big;
		color: colors.$color-gray-slate;
		i {
			color: colors.$color-primary;
			font-size: fonts.$font-size-h4;
		}
		p {
			margin: 0px;
			margin-right: 12px;
			font-size: fonts.$font-size-big;
		}
		.not-form {
			display: none;
		}
	}
	.form-edit {
		display: flex;
		gap: 12px;
		cursor: pointer;
		.edit-buttons.save {
			display: flex;
			align-items: center;
			gap: spacing.$spacing-half-x;
			p {
				margin: auto;
				padding: 0px;
				align-self: center;
				border-bottom: 0.7px solid colors.$color-gray-slate;
				color: colors.$color-gray-slate;
				font-size: fonts.$font-size-big;
			}
			i {
				margin: auto;
				font-size: fonts.$font-size-h5;
				color: colors.$color-gray-slate;
			}
		}
		.edit-buttons.save.invalid {
			display: flex;
			p {
				margin: auto;
				padding: 0px;
				align-self: center;
				border-bottom: 0.7px solid colors.$color-gray-medium;
				color: colors.$color-gray-medium;
				font-size: fonts.$font-size-big;
			}
			i {
				margin: auto;
				font-size: fonts.$font-size-h5;
				color: colors.$color-gray-medium;
			}
		}
		.edit-buttons.edit {
			display: flex;
			gap: 8px;
			p {
				margin: auto;
				padding: 0px;
				align-self: center;
				font-size: fonts.$font-size-big;
				color: colors.$color-gray-slate;
			}
			i {
				margin: auto;
				font-size: fonts.$font-size-h5;
				color: colors.$color-gray-slate;
			}
		}
		i {
			color: colors.$color-primary;
			font-size: fonts.$font-size-h4;
		}
	}
}
.form-title-container.warning {
	background-color: colors.$color-error-light;
	display: flex;
	height: auto;
	span {
		display: flex;
		color: colors.$expo-west-red;
		align-items: center;
		gap: 4px;
		p {
			display: flex;
			align-items: center;
			color: colors.$expo-west-red;
			gap: 4px;
			margin: 0px;
			position: relative;
			top: 2px;
			font-family: fonts.$primary-font-family;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-big;
			line-height: 28.8px;
			i {
				font-family: fonts.$primary-font-family;
				font-weight: fonts.$font-weight-regular;
				position: relative;
				font-size: 22px;
				top: -2px;
				margin: auto;
			}
		}
	}
	.read-only-edit {
		i {
			color: colors.$expo-west-red;
		}
		display: flex;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.form-title-container {
		padding: spacing.$spacing-x spacing.$spacing-2x spacing.$spacing-x spacing.$spacing-2x;
		.form-title {
			font-size: fonts.$font-size-big;
			margin: 0px;
			padding: 0px;
			line-height: 25.6px;
		}
		.read-only-edit {
			p {
				font-size: fonts.$font-size-base;
			}
		}
		.form-edit {
			display: flex;
			.edit-buttons.save {
				display: flex;
				p {
					margin: auto;
					padding: 0px;
					align-self: center;
					border-bottom: 0.7px solid colors.$color-gray-slate;
					font-size: fonts.$font-size-base;
				}
				i {
					margin: auto;
					font-size: fonts.$font-size-h5;
				}
			}
			.edit-buttons.edit {
				p {
					font-size: fonts.$font-size-base;
				}
				i {
					font-size: fonts.$font-size-h5;
				}
			}
		}
	}
	.form-title-container.warning {
		span {
			gap: 8px;
			p {
				display: flex;
				align-items: center;
				gap: 4px;
				top: 0px;
				i {
					top: 0px;
					font-size: fonts.$font-size-h5;
				}
			}
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.form-title-container {
		padding: spacing.$spacing-x;
		.form-title {
			font-size: fonts.$font-size-big;
			margin: 0px;
			padding: 0px;
			line-height: 25.6px;
			text-align: center;
		}
		.read-only-edit {
			i {
				font-size: fonts.$font-size-h5;
			}
			p {
				font-size: fonts.$font-size-small;
				font-family: fonts.$tertiary-font-family;
				margin-right: 0px;
			}
		}
		.form-edit {
			display: flex;
			gap: 0;
			.edit-buttons.save {
				display: flex;
				p {
					margin: auto;
					padding: 0px;
					align-self: center;
					border-bottom: 0.7px solid colors.$color-gray-slate;
					font-size: fonts.$font-size-base;
				}
				i {
					margin: auto;
					font-size: fonts.$font-size-h5;
				}
			}
			.edit-buttons.edit {
				gap: spacing.$spacing-half-x;
				p {
					margin: 0px;
					font-size: fonts.$font-size-base;
				}
				i {
					margin: auto 0px;
					font-size: fonts.$font-size-bigger;
				}
			}
			i {
				font-size: fonts.$font-size-h5;
			}
		}
	}
	.form-title-container.break {
		display: flex;
		flex-direction: column;
		height: auto;
		.read-only-edit {
			display: flex;
			align-self: center;
		}
		.form-edit {
			display: flex;
			align-self: center;
		}
	}
}
