@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/spacing.scss';
.brand-onboard-footer {
	padding-bottom: spacing.$spacing-5x;
	& .onboard-footer-title-container {
		& .footer-title {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h4;
			line-height: 33px;
			letter-spacing: 0.0075em;
			color: colors.$color-primary-dark;
		}
	}
	& .links-container {
		margin-top: spacing.$spacing-2x;
		& > .list-container {
			margin: 0px;
			padding: 0px;
			list-style-type: none;
			& > li {
				position: relative;
				margin-left: spacing.$spacing-5x;
				font-family: fonts.$primary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-h4;
				line-height: 38px;
				letter-spacing: 0.0075em;
				color: colors.$color-text-black;
				& ::before {
					content: '•';
					position: absolute;
					left: -1.5rem;
					font-size: 0.8em;
					color: colors.$color-text-black;
				}
				& > a {
					font-family: fonts.$secondary-font-family;
					font-weight: fonts.$font-weight-bold;
					color: colors.$color-primary;
					text-decoration: underline;
				}
			}
		}
	}
}
@media (max-width: breakpoints.$tablet-breakpoint) {
	.brand-onboard-footer {
		& .onboard-footer-title-container {
			& .footer-title {
				font-size: fonts.$font-size-h5;
				line-height: 27.24px;
			}
		}
		& .links-container {
			& > .list-container {
				& > li {
					font-size: fonts.$font-size-bigger;
					line-height: 29px;
				}
			}
		}
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.brand-onboard-footer {
		padding: 0px spacing.$spacing-2x;
		& .onboard-footer-title-container {
			& .footer-title {
				font-size: fonts.$font-size-h5-mobile;
				line-height: 24.51px;
			}
		}
		& .links-container {
			& > .list-container {
				& > li {
					font-size: fonts.$font-size-big-mobile;
					line-height: 23px;
				}
			}
		}
	}
}
@media (max-width: 500px) {
	.brand-onboard-footer {
		padding: 0px spacing.$spacing-x;
		& .links-container {
			& > .list-container {
				& > li {
					margin-left: spacing.$spacing-2x;
					& ::before {
						left: -10px;
						font-size: 0.8em;
					}
				}
			}
		}
	}
}
