@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/shadows.scss';

.skeleton-wrapper {
	display: flex;
	border-radius: border-radius.$radius-medium;
	background-color: colors.$color-background-white;
	box-shadow: shadows.$shadow-medium;
	flex-direction: column;
	justify-content: flex-start;
	text-align: start;
	padding: spacing.$spacing-x spacing.$spacing-2x spacing.$spacing-1_25x spacing.$spacing-2x;
	gap: 8px;
	margin-top: spacing.$spacing-half-x;
	@keyframes loading-skeleton {
		0% {
			background-color: colors.$color-background-gray;
		}

		50% {
			background-color: colors.$color-background-light-gray;
		}

		100% {
			background-color: colors.$color-background-gray;
		}
	}
	.skeleton-line {
		height: 26px;
		background-color: colors.$color-background-gray;
		display: inline-flex;
		line-height: 1;
		position: relative;
		overflow: hidden;
		border-radius: border-radius.$radius-medium;
		animation-name: loading-skeleton;
		animation-direction: normal;
		animation-duration: 2s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
	}

	& .long {
		width: 222px;
	}
	& .medium {
		width: 196px;
	}
	& .short {
		width: 140px;
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.skeleton-wrapper {
		margin-top: spacing.$spacing-1_5x;
		width: 100vw;
		margin-left: -16px;
		border-radius: 0px 0px border-radius.$radius-medium border-radius.$radius-medium;
		height: 80vh;
		overflow: scroll;
		.skeleton-line {
			margin-left: spacing.$spacing-1_25x;
		}
		.skeleton-header {
			margin-left: spacing.$spacing-1_25x;
			font-size: fonts.$font-size-big-mobile;
		}
	}
}
