@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/breakpoints.scss';
.user-menu-item {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	width: 100%;
	height: 40px;
	background: colors.$color-background-white;
	order: 2;
	flex-grow: 0;
	cursor: pointer;
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	&:hover {
		background: colors.$color-background-white;
	}
}
.user-menu-item-text {
	display: flex;
	font-family: fonts.$primary-font-family;
	font-style: normal;
	font-weight: 400;
	font-size: fonts.$font-size-base;
	align-items: center;
	text-align: left;
	color: rgba(33, 37, 41, 0.85);
	order: 1;
	flex-grow: 0;
	width: 100%;
	& .icon {
		color: colors.$color-first-tier;
		font-size: fonts.$font-size-h5;
	}
	& .user-icon-edge-case {
		width: 32px;
		height: 48px;
		margin-left: 0px;
		margin-right: spacing.$spacing-x;
	}
	& .beacon-hub {
		margin-right: spacing.$spacing-x;
		width: 25px;
	}
	& .user-item-text-container {
		display: flex;
		flex-direction: column;
		& .sub-text {
			font-size: fonts.$font-size-small;
			font-weight: 400;
		}
	}
}

@media (max-width: breakpoints.$tablet-breakpoint) {
	.user-menu-item-text {
		& .user-icon-edge-case {
			width: 28px;
			height: 32px;
			margin-left: -3px;
			margin-right: spacing.$spacing-half-x;
		}
	}
}
