@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/shadows.scss';

.bubble-status {
	border-radius: border-radius.$radius-big;
	height: 24px;
	color: colors.$color-text-gray-slate-light;
	box-sizing: border-box;
	display: inline-flex;
	font-size: fonts.$font-size-small;
	font-family: fonts.$tertiary-font-family;
	font-weight: fonts.$font-weight-semibold;
	line-height: 19.2px;
	padding: 0px spacing.$spacing-2x;
	text-align: center;
	align-items: center;
	margin-right: spacing.$spacing-x;
	&.normal {
		&.blue {
			color: colors.$color-primary;
			border: 1.5px solid colors.$color-primary;
		}
		&.green {
			color: colors.$color-tertiary-dark;
			border: 1.5px solid colors.$color-tertiary;
		}
		&.gray {
			color: colors.$color-gray-slate;
			border: 1.5px solid colors.$color-gray-slate;
		}
		&.red {
			color: colors.$expo-west-red;
			border: 1.5px solid colors.$expo-west-red;
		}
	}
	&.filled {
		&.blue {
			background: colors.$color-primary;
			color: colors.$color-white;
			border: 1.5px solid colors.$color-primary;
		}
		&.green {
			background: colors.$color-tertiary-dark;
			color: colors.$color-white;
			border: 1.5px solid colors.$color-tertiary-dark;
		}
		&.gray {
			background-color: colors.$color-background-slate;
			color: colors.$color-white;
			border: 1.5px solid colors.$color-background-slate;
		}
		&.red {
			background-color: colors.$expo-west-red;
			color: colors.$color-white;
			border: 1.5px solid colors.$expo-west-red;
		}
	}
}
