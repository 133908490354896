@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/spacing.scss';
.spins-metrics-container {
	& .metrics-title-container {
		& .metrics-title {
			font-family: fonts.$tertiary-font-family;
			font-size: fonts.$font-size-big;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			line-height: 28.8px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-gray-slate;
		}
	}
	& .metrics-text-container {
		margin-bottom: spacing.$spacing-3x;
		width: 900px;
		& .metrics-text {
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-base;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			line-height: 22.4px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
		}
	}
	& .median-text-container {
		& .median-text {
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-base;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			line-height: 22.4px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
		}
		& .median-text-title {
			font-family: fonts.$tertiary-font-family;
			font-size: fonts.$font-size-base;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			line-height: 22.4px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
		}
	}
	& .spins-separator {
		width: 148px;
		border: 1px solid colors.$color-background-gray;
		margin-bottom: spacing.$spacing-2x;
		&:not(:first-child) {
			margin-top: spacing.$spacing-2x;
		}
	}
	& .metrics-not-available-container {
		& .metrics-not-available-text {
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-base;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			line-height: 22.4px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
		}
	}
	& .metrics-items-container {
		& .spin-metrics-item-container {
			& .metric-element-container {
				display: flex;
				& .spin-metrics-icon {
					font-size: fonts.$font-size-h4;
					color: colors.$color-secondary;
					margin-top: 1.5px;
				}
				& .metric-text-container {
					margin-left: spacing.$spacing-half-x;
					& .metric-title {
						font-family: fonts.$secondary-font-family;
						font-size: fonts.$font-size-base;
						font-style: normal;
						font-weight: fonts.$font-weight-bold;
						line-height: 22.4px;
						letter-spacing: fonts.$letter-spacing-std;
						color: colors.$color-text-black;
					}
					& .metric-prev-text {
						font-family: fonts.$primary-font-family;
						font-size: fonts.$font-size-base;
						font-style: normal;
						font-weight: fonts.$font-weight-regular;
						line-height: 22.4px;
						letter-spacing: fonts.$letter-spacing-std;
						color: colors.$color-text-black;
					}
					& .metric-value {
						font-family: fonts.$tertiary-font-family;
						font-size: fonts.$font-size-base;
						font-style: normal;
						font-weight: fonts.$font-weight-semibold;
						line-height: 22.4px;
						letter-spacing: fonts.$letter-spacing-std;
						color: colors.$color-text-black;
						& .not-available {
							font-family: fonts.$primary-font-family;
							font-weight: fonts.$font-weight-regular;
						}
					}
					& .metric-next-text {
						font-family: fonts.$tertiary-font-family;
						font-size: fonts.$font-size-base;
						font-style: normal;
						font-weight: fonts.$font-weight-semibold;
						line-height: 22.4px;
						letter-spacing: fonts.$letter-spacing-std;
						color: colors.$color-text-black;
					}
					& .details {
						font-family: fonts.$primary-font-family;
						font-size: fonts.$font-size-base;
						font-style: normal;
						font-weight: fonts.$font-weight-regular;
						line-height: 22.4px;
						letter-spacing: fonts.$letter-spacing-std;
						color: colors.$color-gray-slate;
						cursor: pointer;
						margin-left: spacing.$spacing-x;
						width: 85px;
						border-bottom: 1px solid colors.$color-gray-slate;
					}
				}
			}
			& .metric-details-container {
				margin-top: spacing.$spacing-2x;
				& .detail-title {
					font-family: fonts.$primary-font-family;
					font-size: fonts.$font-size-base;
					font-style: normal;
					font-weight: fonts.$font-weight-regular;
					line-height: 22.4px;
					letter-spacing: fonts.$letter-spacing-std;
					color: colors.$color-gray-slate;
				}
				& .detail-item {
					margin-left: spacing.$spacing-x;
					display: flex;
					& .item-text {
						font-family: fonts.$primary-font-family;
						font-size: fonts.$font-size-base;
						font-style: normal;
						font-weight: fonts.$font-weight-regular;
						line-height: 22.4px;
						letter-spacing: fonts.$letter-spacing-std;
						color: colors.$color-gray-slate;
						margin-left: -4px;
					}
					& .dot-container {
						& .dot {
							margin-top: 1px;
							color: colors.$color-gray-slate;
							font-size: fonts.$font-size-small;
						}
					}
				}
			}
		}
	}
	& .based-on-container {
		width: 590px;
		& .based-on-text {
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-base;
			font-style: italic;
			font-weight: fonts.$font-weight-regular;
			line-height: 22.4px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-gray-slate;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.spins-metrics-container {
		& .metrics-title-container {
			& .metrics-title {
				font-size: fonts.$font-size-base;
				line-height: 22.4px;
			}
		}
		& .metrics-text-container {
			width: 100%;
			& .metrics-text {
				font-size: fonts.$font-size-small;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
		}
		& .median-text-container {
			& .median-text-title {
				font-size: fonts.$font-size-small;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
			& .median-text {
				font-size: fonts.$font-size-small;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
		}
		& .metrics-not-available-container {
			& .metrics-not-available-text {
				font-size: fonts.$font-size-small;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
		}
		& .based-on-container {
			width: 100%;
			& .based-on-text {
				font-size: fonts.$font-size-small;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
		}
		& .metrics-items-container {
			& .spin-metrics-item-container {
				& .metric-element-container {
					& .spin-metrics-icon {
						font-size: fonts.$font-size-h5;
						margin-top: 0px;
					}
					& .metric-text-container {
						& .metric-title {
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							letter-spacing: 0.09px;
						}
						& .details {
							margin-left: spacing.$spacing-half-x;
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							letter-spacing: 0.09px;
						}
						& .metric-value {
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							letter-spacing: 0.09px;
						}
						& .metric-prev-text {
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							letter-spacing: 0.09px;
						}
						& .metric-next-text {
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							letter-spacing: 0.09px;
						}
					}
				}
				& .metric-details-container {
					& .detail-title {
						font-size: fonts.$font-size-small;
						line-height: 19.2px;
						letter-spacing: 0.09px;
					}
					& .detail-item {
						& .item-text {
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							letter-spacing: 0.09px;
						}
						& .dot-container {
							& .dot {
								margin-top: 0px;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.spins-metrics-container {
		& .metrics-title-container {
			& .metrics-title {
				font-size: fonts.$font-size-big-mobile;
				line-height: 22.4px;
			}
		}
		& .metrics-text-container {
			width: 100%;
			& .metrics-text {
				font-size: fonts.$font-size-small;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
		}
		& .median-text-container {
			& .median-text-title {
				font-size: fonts.$font-size-small;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
			& .median-text {
				font-size: fonts.$font-size-small;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
		}
		& .metrics-not-available-container {
			& .metrics-not-available-text {
				font-size: fonts.$font-size-small;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
		}
		& .based-on-container {
			width: 100%;
			& .based-on-text {
				font-size: fonts.$font-size-small;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
		}
		& .metrics-items-container {
			& .spin-metrics-item-container {
				& .metric-element-container {
					& .icon-container {
						display: flex;
						align-items: center;
						& .spin-metrics-icon {
							font-size: fonts.$font-size-h5;
							margin-top: 0px;
						}
					}
					& .metric-text-container {
						& .metric-title {
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							letter-spacing: 0.09px;
						}
						& .details {
							margin-left: spacing.$spacing-half-x;
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							letter-spacing: 0.09px;
						}
						& .metric-value {
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							letter-spacing: 0.09px;
						}
						& .metric-prev-text {
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							letter-spacing: 0.09px;
						}
						& .metric-next-text {
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							letter-spacing: 0.09px;
						}
					}
				}
				& .metric-details-container {
					& .detail-title {
						font-size: fonts.$font-size-small;
						line-height: 19.2px;
						letter-spacing: 0.09px;
					}
					& .detail-item {
						& .item-text {
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							letter-spacing: 0.09px;
						}
						& .dot-container {
							& .dot {
								margin-top: 0px;
							}
						}
					}
				}
			}
		}
	}
}
