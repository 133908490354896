@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
.beacon-only-brand-container {
	& .beacon-only-brand-header-text-container {
		& .beacon-only-brand-header-text {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-h5;
			line-height: 32px;
			letter-spacing: 0.0075em;
			color: colors.$color-text-black;
		}
		& > a {
			text-decoration: underline;
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h5;
			line-height: 32px;
			letter-spacing: 0.0075em;
			color: colors.$color-text-black;
		}
	}
	& .beacon-only-timeline-content-container {
		margin: spacing.$spacing-6x spacing.$spacing-5x spacing.$spacing-5x spacing.$spacing-6x;
	}
	& .number {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: 39.47px;
		line-height: 53.75px;
		letter-spacing: 0.0075em;
	}
	& .circle-check {
		font-size: 47.5px;
	}
	& .card-text-header {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-h5;
		line-height: 32px;
		letter-spacing: 0.0075em;
		color: colors.$color-primary;
		&.card-mt-2 {
			display: flex;
			margin-top: spacing.$spacing-2x;
		}
	}
	& .card-link-header {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-h5;
		line-height: 32px;
		letter-spacing: 0.0075em;
		color: colors.$color-primary;
		text-decoration: underline;
	}
	& .card-text-content {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-h5;
		line-height: 32px;
		letter-spacing: 0.0075em;
		color: colors.$color-text-black;
	}
	& .card-email-content {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-h5;
		line-height: 32px;
		letter-spacing: 0.0075em;
		color: colors.$color-text-black;
		text-decoration: underline;
	}
	& .bold-card-text-content {
		font-family: fonts.$tertiary-font-family;
		font-weight: fonts.$font-weight-semibold;
		font-size: fonts.$font-size-h5;
		font-style: italic;
		line-height: 32px;
		letter-spacing: 0.0075em;
	}
	& .third-card-header-container {
		margin-left: spacing.$spacing-2x;
	}
	& .third-card-content-container {
		margin-left: spacing.$spacing-3x;
	}
	& .spins-img {
		margin-left: spacing.$spacing-3x;
		width: 167px;
		height: 141px;
	}
	& .upload-img {
		width: 168px;
		height: 175px;
	}
	& .fourth-card-header-container {
		margin-top: spacing.$spacing-3x;
		margin-left: spacing.$spacing-2x;
		height: 96px;
	}
	& .favorites-img {
		width: 248px;
		height: 197px;
	}
	& .first-card-content-container {
		width: 100%;
	}
	& .first-card-header-container {
		margin-top: spacing.$spacing-3x;
	}
}



@media (max-width: 1248px) {
	.beacon-only-brand-container {
		& .beacon-only-brand-header-text-container {
			& .beacon-only-brand-header-text {
				font-size: fonts.$font-size-base;
				line-height: 22.4px;
			}
			& > a {
				font-size: fonts.$font-size-base;
				line-height: 22.4px;
			}
		}
		& .number {
			font-size: 23.4px;
			line-height: 32px;
		}
		& .circle-check {
			font-size: 33.7px;
		}
		& .card-text-header {
			font-size: fonts.$font-size-base;
			line-height: 22px;
		}
		& .card-link-header {
			font-size: fonts.$font-size-base;
			line-height: 22px;
		}
		& .card-text-content {
			font-size: fonts.$font-size-small;
			line-height: 19.2px;
			display: flex;
		}
		& .card-email-content {
			font-size: fonts.$font-size-small;
			line-height: 19.2px;
			display: flex;
		}
		& .bold-card-text-content {
			font-size: fonts.$font-size-small;
			line-height: 19.2px;
		}
		& .spins-img {
			margin-top: spacing.$spacing-8x;
			margin-left: 0px;
			width: 99.2px;
			height: 83.69px;
		}
		& .upload-img {
			width: 99.5px;
			height: 103.46px;
		}
		& .favorites-img {
			margin-top: -12px;
			width: 146.62px;
			height: 116.58px;
		}
		& .first-card-header-container {
			margin-top: spacing.$spacing-8x;
		}
		& .third-card-header-container {
			margin-top: spacing.$spacing-7x;
			margin-left: spacing.$spacing-x;
		}
		& .third-card-content-container {
			margin-left: 0px;
		}
		& .fourth-card-header-container {
			margin-top: spacing.$spacing-x;
			margin-left: spacing.$spacing-x;
			height: 96px;
		}
		& .five-card-header-container {
			margin-top: -22px;
		}
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.beacon-only-brand-container {
		& .first-card-header-container {
			width: 100%;
			margin-top: -8px;
		}
		& .first-card-content-container {
			width: 100%;
		}
		& .second-card-header-container {
			width: 100%;
		}
		& .second-card-content-container {
			width: 100%;
		}
		& .third-card-header-container {
			margin-left: 0px;
			margin-top: spacing.$spacing-x;
		}
		& .fourth-card-header-container {
			width: 100%;
			height: auto;
			margin-left: 0px;
		}
		& .fourth-card-content-container {
			width: 100%;
		}
		& .beacon-only-timeline-content-container {
			margin: spacing.$spacing-2x spacing.$spacing-5x spacing.$spacing-2x 0px;
		}
		& .card-text-header {
			display: inline;
		}
		& .card-link-header {
			display: inline;
		}
		& .card-text-content {
			display: inline;
		}
		& .card-email-content {
			display: inline;
		}
		& .spins-img {
			margin-top: 0px;
		}
		& .favorites-img {
			margin-top: -18px;
		}
		& .five-card-header-container {
			margin-top: spacing.$spacing-x;
		}
	}
}
@media (max-width: 500px) {
	.beacon-only-brand-container {
		& .beacon-only-brand-header-text-container {
			& > a {
				word-break: break-all;
			}
		}
		& .card-link-header {
			word-break: break-all;
		}
		& .card-email-content {
			word-break: break-all;
		}
		& .five-card-header-container {
			width: 217px;
		}
		& .five-card-content-container {
			width: 226px;
		}
		& .favorites-img {
			margin-top: -30px;
		}
	}
}
