@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/shadows.scss';
.engagement-table-container {
	display: flex;
	flex-direction: column;
	width: 423px;
	background: colors.$color-background-white;
	box-shadow: shadows.$shadow-lower;
	border-radius: border-radius.$radius-big;
	padding: spacing.$spacing-1_5x spacing.$spacing-2x spacing.$spacing-half-x spacing.$spacing-2x;
	height: fit-content;
	& .table-title-container {
		& .table-title {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-bigger;
			line-height: 25px;
			letter-spacing: 0.0075em;
			color: colors.$color-primary;
		}
	}
	& .table-content-container {
		margin-top: spacing.$spacing-2x;
		display: flex;
		flex-direction: column;
		gap: 8px;
		& .table-row {
			height: 29px;
			border-radius: border-radius.$radius-small;
			&:nth-child(even) {
				background: colors.$color-secondary-lightest;
			}
			display: flex;
			gap: 4px;
			padding: 0px spacing.$spacing-1_5x 0px spacing.$spacing-half-x;
			align-items: center;
			& .table-position {
				font-family: fonts.$secondary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-semibold;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				letter-spacing: 0.0075em;
				color: colors.$color-text-black;
			}
			& .table-row-text {
				font-family: fonts.$primary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				letter-spacing: 0.0075em;
				color: colors.$color-text-black;
				width: 255px;
				text-overflow: ellipsis;
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
			}
			& .table-row-value {
				text-align: right;
				font-family: fonts.$tertiary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-semibold;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				letter-spacing: 0.0075em;
				color: colors.$color-text-black;
				width: 37px;
			}
			& .table-row-metric {
				font-family: fonts.$primary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				letter-spacing: 0.0075em;
				color: colors.$color-text-black;
				text-wrap: nowrap;
			}
		}
	}
	& .expand-table-container {
		display: flex;
		justify-content: center;
		margin-top: spacing.$spacing-half-x;
		align-items: center;
		& .expand-table-text {
			cursor: pointer;
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-base;
			line-height: 22px;
			letter-spacing: 0.0075em;
			color: colors.$color-primary-dark;
		}
		& > i {
			cursor: pointer;
			font-size: fonts.$font-size-h4;
			color: colors.$color-primary-dark;
		}
	}
}
