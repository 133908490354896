@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/shadows.scss';
.template-filter-container {
	margin-top: spacing.$spacing-half-x;
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 2px;
	align-items: flex-start;
	z-index: 1;
	background: colors.$color-background-white;
	padding: spacing.$spacing-half-x;
	border-radius: border-radius.$radius-default;
	box-shadow: shadows.$shadow-medium;
	& .filter-template-item-container {
		width: 100%;
		display: flex;
		height: 28px;
		align-items: center;
		padding: spacing.$spacing-half-x spacing.$spacing-2x spacing.$spacing-half-x spacing.$spacing-2x;
		cursor: pointer;
		& .filter-item-text {
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-small;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			line-height: 19.2px;
			letter-spacing: 0.09px;
			color: colors.$color-text-black;
			width: 125px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
		&:hover {
			border-radius: border-radius.$radius-brand-image;
			background-color: colors.$color-background-gray;
		}
		& .ri-check-line {
			margin-left: auto;
			font-size: fonts.$font-size-small;
			color: colors.$color-text-black;
		}
		&.current {
			background-color: colors.$color-background-gray;
			border-radius: border-radius.$radius-brand-image;
		}
	}
}
@media (min-width: 0) and (max-width: breakpoints.$mobile-breakpoint) {
	.template-filter-container {
		margin-top: spacing.$spacing-x;
		right: 0px;
	}
}
