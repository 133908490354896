@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';
.select-container {
	text-align: left;
	border: 1px solid #ccc;
	position: relative;
	cursor: pointer;
	max-width: 365px;
	width: 100%;
	box-shadow: shadows.$shadow-lower;
	border-radius: border-radius.$radius-small;
	.dropdown-input {
		font-size: fonts.$font-size-base;
		font-family: fonts.$primary-font-family;
		font-weight: fonts.$font-weight-regular;
		padding: spacing.$spacing-1_25x spacing.$spacing-2x;
		display: flex;
		flex-direction: row !important;
		align-items: center;
		justify-content: space-between;
		user-select: none;
		gap: 7px;
		.dropdown-selected-value {
			color: colors.$color-text-gray-slate-light;
		}
		.dropdown-selected-value.placeholder {
			color: colors.$color-text-gray-slate-light;
		}
	}
	.dropdown-menu {
		box-shadow: shadows.$shadow-medium;
		width: 100%;
		padding: spacing.$spacing-2x spacing.$spacing-x;
		position: absolute;
		border-radius: 0px 0px border-radius.$radius-medium border-radius.$radius-medium;
		overflow: auto;
		z-index: 99;
		max-height: 212px;
		margin-top: 1px;
		display: flex !important;
		flex-direction: column !important;
		gap: 4px !important;
		.dropdown-item {
			font-size: fonts.$font-size-base;
			cursor: pointer;
			padding: spacing.$spacing-half-x spacing.$spacing-2x;
		}
		.dropdown-item.selected {
			padding: spacing.$spacing-half-x spacing.$spacing-2x;
			border-radius: border-radius.$radius-select-item;
			background-color: colors.$color-background-gray;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.dropdown-item:hover {
			padding: spacing.$spacing-half-x spacing.$spacing-2x;
			border-radius: border-radius.$radius-select-item;
			background-color: colors.$color-background-gray;
			color: colors.$color-black;
		}
	}
}
.select-container.active {
	border: 1px solid colors.$input-border;
	.dropdown-input {
		.dropdown-selected-value {
			color: colors.$color-black;
		}
	}
}
