@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';

.product-improvements-form {
	margin-top: spacing.$spacing-2x;
	p {
		margin: 0px;
		margin-top: spacing.$spacing-1_5x;
		a {
			color: inherit;
			text-decoration: none;
		}
	}
	table {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0px;
		th {
			width: 100%;
			flex: 1;
			max-width: 365px;
		}
		tr {
			margin-bottom: spacing.$spacing-1_5x;
			display: flex;
			justify-content: space-between;
			td {
				flex: 1;
				max-width: 365px;
				line-height: 22.4px;
				color: colors.$color-text-black;
				font-size: fonts.$font-size-base;
				font-family: fonts.$primary-font-family;
				font-weight: fonts.$font-weight-regular;
			}
			td:not(:last-child) {
				margin-right: spacing.$spacing-x;
			}
			td:first-child {
				font-weight: fonts.$font-weight-semibold;
				font-family: fonts.$tertiary-font-family;
			}
		}
	}
}

.improvements-container {
	display: flex;
	flex-direction: column;
	gap: 16px;
	.improvement-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
		.improvement-element {
			display: flex;
			flex-direction: column;
			gap: 8px;

			p {
				margin: 0px;
			}
		}
		.improvement-element:first-child {
			font-weight: fonts.$font-weight-semibold;
			font-family: fonts.$tertiary-font-family;
		}
		.separator {
			height: 4px;
			width: 100%;
		}
	}
}
