@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/border-radius.scss';

.product-list-skeleton-container {
	@keyframes loading-skeleton {
		0% {
			background-color: colors.$color-background-gray;
		}

		50% {
			background-color: colors.$color-background-light-gray;
		}

		100% {
			background-color: colors.$color-background-gray;
		}
	}

	.p-skeleton {
		background-color: colors.$color-background-gray;
		display: inline-flex;
		line-height: 1;
		position: relative;
		overflow: hidden;
		z-index: 1;
		border-radius: border-radius.$radius-medium;
		animation-name: loading-skeleton;
		animation-direction: normal;
		animation-duration: 2s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
	}

	.p-skeleton-title {
		width: 220px;
		height: 24px;
		margin-top: spacing.$spacing-4x;
		display: block;
	}

	.p-skeleton-breadcrumb {
		width: 300px;
		height: 24px;
		margin-top: spacing.$spacing-3x;
		display: block;
	}

	.p-skeleton-body-for-filter {
		margin-top: spacing.$spacing-2x !important;
	}

	.p-skeleton-body {
		grid-template-columns: auto 1fr;
		display: grid;
		margin-top: spacing.$spacing-5x;

		.p-skeleton-filter {
			width: 280px;
			height: 850px;
			margin-right: spacing.$spacing-6x;
		}

		.p-skeleton-filter-bubble {
			width: 100%;
			display: flex;
			justify-content: right;

			.p-skeleton-filter-m {
				width: 149px;
				height: 24px;
			}

			.p-skeleton-sort {
				height: 24px;
				width: 210px;
			}
		}

		.p-skeleton-product-list {
			display: grid;
			grid-template-rows: repeat(3, minmax(auto, 1fr));
			width: 100%;
			row-gap: spacing.$spacing-3x;
			column-gap: spacing.$spacing-3x;
			&.first-group {
				margin-top: spacing.$spacing-2x;
			}

			.p-skeleton-product-card {
				height: 339px;
				width: 100%;
				position: relative;

				.p-skeleton-product-card-title {
					position: absolute;
					left: 16px;
					bottom: 16px;
					height: 21px;
					width: 50%;
					background-color: colors.$color-gray-medium;
					border-radius: border-radius.$radius-medium;
				}
			}
		}
	}
}
@media (min-width: breakpoints.$large-screen-breakpoint) {
	.product-list-skeleton-container {
		.p-skeleton-product-list {
			grid-template-columns: repeat(4, minmax(auto, 1fr));
		}
		.p-skeleton-body-for-filter {
			grid-template-columns: 1fr;
		}
	}
}

@media (min-width: 500px) and (max-width: breakpoints.$tablet-breakpoint) {
	.product-list-skeleton-container {
		.p-skeleton-product-list {
			grid-template-columns: repeat(3, minmax(auto, 1fr));
		}
		.p-skeleton-body-for-filter {
			grid-template-columns: 1fr;
		}
	}
}

@media (min-width: 0px) and (max-width: 500px) {
	.product-list-skeleton-container {
		.p-skeleton-product-list {
			grid-template-columns: repeat(2, minmax(auto, 1fr));
		}
		.p-skeleton-body-for-filter {
			grid-template-columns: 1fr;
		}
	}
}

@media (max-width: breakpoints.$tablet-breakpoint) {
	.product-list-skeleton-container {
		padding: 0px spacing.$spacing-half-x spacing.$spacing-3x spacing.$spacing-x;

		.p-skeleton-title {
			width: 97px !important;
			margin-top: spacing.$spacing-2x;
		}

		.p-skeleton-body {
			grid-template-columns: 1fr;
			margin-top: spacing.$spacing-x !important;

			.p-skeleton-filter-bubble {
				justify-content: space-between;

				.p-skeleton-sort {
					width: 97px !important;
				}
			}
			&.first-group {
				margin-top: spacing.$spacing-3x;
			}

			.p-skeleton-product-card {
				height: 220px !important;
			}
		}
	}
}

@media (max-width: breakpoints.$mobile-breakpoint) {
	.product-list-skeleton-container {
		padding: 0px spacing.$spacing-half-x spacing.$spacing-3x spacing.$spacing-x;
		.p-skeleton-filter-m {
			width: 80px !important;
		}
	}
}
@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: breakpoints.$large-screen-breakpoint) {
	.product-list-skeleton-container {
		.p-skeleton-product-list {
			grid-template-columns: repeat(3, minmax(auto, 1fr));
		}
		.p-skeleton-body-for-filter {
			grid-template-columns: 1fr;
		}
	}
}
