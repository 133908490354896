@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';

.market-ready-overlay {
	background: colors.$color-background-white;
	border-radius: border-radius.$radius-medium;
	padding: spacing.$spacing-5x;
	position: relative;
	height: 425px;
	overflow: hidden;
	.logo {
		img {
			width: 145px;
			height: auto;
		}
	}
	.body {
		margin-top: spacing.$spacing-3x;
		z-index: 9999;
		p {
			font-size: fonts.$font-size-big;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			line-height: 26px;
			letter-spacing: 0.0075em;
			font-family: fonts.$primary-font-family;
		}
	}
	.learn-more {
		margin-top: spacing.$spacing-3x;
		a {
			color: colors.$color-primary;
			text-decoration: underline;
			font-size: fonts.$font-size-big;
			font-family: fonts.$secondary-font-family;
			letter-spacing: 0.25px;
			line-height: 32px;
			font-weight: fonts.$font-weight-bold;
		}
	}
	.market-ready-close {
		position: absolute;
		top: 18px;
		right: 18px;
		color: colors.$color-black;
		font-size: fonts.$font-size-bigger;
		cursor: pointer;
	}
	.back-pattern {
		margin-top: -164px;
		width: 452px;
		margin-left: 100px;
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.market-ready-overlay {
		margin-top: 95px;
		height: 384px;
		padding: spacing.$spacing-4x spacing.$spacing-3x;
		.body {
			margin-top: spacing.$spacing-3x;
			height: 212px;
			p {
				font-size: fonts.$font-size-small;
				line-height: 19px;
			}
		}
		.learn-more {
			margin-top: spacing.$spacing-2x;
			a {
				font-size: fonts.$font-size-small;
			}
		}
		.back-pattern {
			margin-top: -144px;
			width: 410px;
			margin-left: 74px;
		}
	}
}
