@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/spacing.scss';
@use '../../styles/tokens/breakpoints.scss';

.browse-by-category {
	height: auto;
	background: linear-gradient(353.46deg, #5fb6e0 4.68%, #99dbeb 48.6%, #c0f3ff 94.39%);
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: spacing.$spacing-3x;

	& .title-container {
		margin-top: spacing.$spacing-3x;
		display: flex;
		align-items: center;
		cursor: pointer;

		& .title {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h3;
			line-height: 44px;
			letter-spacing: 0.0075em;
			color: colors.$color-primary;
		}

		& > i {
			margin-left: spacing.$spacing-x;
			font-size: fonts.$font-size-h3;
			color: colors.$color-primary;
		}

		&:hover {
			& > i {
				transform: translateX(4px);
			}
		}
	}

	& .categories-container {
		display: flex;
		gap: 51px;
		margin-top: spacing.$spacing-3x;
		flex-wrap: wrap;
		width: 100%;
		justify-content: center;
		align-items: center;

		& .category-item {
			cursor: pointer;
			height: 226px;

			& .category-img-container {
				width: 151px;
				height: 151px;
				border-radius: 50%;
				overflow: hidden;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: colors.$color-background-white;

				& .browse-by-img {
					&[class*='all'] {
						object-fit: cover;
						width: 75%;
						height: 75%;
					}

					&[class*='food'] {
						object-fit: cover;
						width: 65%;
						height: 80%;
					}

					&[class*='health'] {
						object-fit: cover;
						width: 109px;
						height: 80px;
					}

					&.other {
						width: 84px;
						height: 109px;
						object-fit: cover;
					}

					&[class*='vitam'] {
						width: 80px;
						height: 107px;
						object-fit: cover;
					}
				}
			}

			&:hover {
				& .category-img-container {
					& .browse-by-img {
						transition: transform 0.3s ease;
						transform: scale(1.1);
					}
				}
			}

			& .category-name-container {
				margin-top: spacing.$spacing-2x;
				display: flex;
				justify-content: center;

				& .category-name {
					font-family: fonts.$tertiary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-semibold;
					font-size: fonts.$font-size-bigger;
					line-height: 29px;
					letter-spacing: 0.0075em;
					color: colors.$color-text-black;
				}
			}

			&:last-child {
				& .category-name-container {
					& .category-name {
						text-align: center;
						width: 187px;
					}
				}
			}
		}
	}
	& > a {
		text-decoration: none;
		color: unset;
		&:hover {
			color: unset;
			text-decoration: none;
		}
	}
}

@media (max-width: breakpoints.$tablet-breakpoint) {
	.browse-by-category {
		padding-bottom: spacing.$spacing-6x;

		& .title-container {
			& .title {
				font-size: fonts.$font-size-h5;
				line-height: 27px;
			}

			& > i {
				font-size: fonts.$font-size-h4;
			}
		}

		& .categories-container {
			width: 60%;

			& .category-item {
				height: 164px;

				& .category-name-container {
					& .category-name {
						font-size: fonts.$font-size-big;
						line-height: 29px;
					}
				}

				& .category-img-container {
					width: 120px;
					height: 120px;

					& .browse-by-img {
						&[class*='all'] {
							object-fit: cover;
							width: 87px;
							height: 87px;
						}

						&[class*='food'] {
							object-fit: cover;
							width: 72px;
							height: 87px;
						}

						&[class*='health'] {
							object-fit: cover;
							width: 89px;
							height: 70px;
						}

						&.other {
							object-fit: cover;
							width: 68px;
							height: 88px;
						}

						&[class*='vitam'] {
							object-fit: cover;
							width: 62px;
							height: 85px;
						}
					}
				}

				&:last-child {
					& .category-name-container {
						& .category-name {
							text-align: center;
							width: 144px;
						}
					}
				}
			}
		}
	}

	@media (max-width: 816px) {
		.browse-by-category {
			& .categories-container {
				width: 75%;
			}
		}
	}
}

@media (max-width: breakpoints.$mobile-breakpoint) {
	.browse-by-category {
		& .title-container {
			& .title {
				font-size: fonts.$font-size-bigger-mobile;
				line-height: 22px;
			}

			& > i {
				font-size: fonts.$font-size-h4;
			}
		}

		& .categories-container {
			gap: 24px;
			width: 75%;
			padding: 0px spacing.$spacing-1_5x;

			& .category-item {
				& .category-name-container {
					& .category-name {
						font-size: fonts.$font-size-big-mobile;
						line-height: 22.4px;
					}
				}

				&:last-child {
					& .category-name-container {
						& .category-name {
							text-align: center;
							width: 136px;
						}
					}
				}
			}
		}

		@media (max-width: 500px) {
			.browse-by-category {
				& .categories-container {
					padding: 0px 18px;
					width: 100%;
				}
			}
		}
	}
}

@media (min-width: 1600px) {
	.browse-by-category {
		& .categories-container {
			gap: 55px;
		}
	}
}

@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1240px) {
	.browse-by-category {
		& .categories-container {
			gap: 60px;
			width: 60%;
		}
	}
}
