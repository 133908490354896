@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';

.distribution-tab {
	padding-top: spacing.$spacing-3x;
	margin-bottom: spacing.$spacing-2x;
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.distribution-tab {
		padding-top: spacing.$spacing-2x;
		margin-bottom: spacing.$spacing-2x;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.profile-distribution {
		.distribution-tab {
			padding-top: spacing.$spacing-2x;
			margin-bottom: spacing.$spacing-2x;
			.distribution-title-container {
				padding: spacing.$spacing-x;
				display: flex;
				flex-direction: column;
				height: auto;
				.distribution-title {
					font-size: fonts.$font-size-big;
					margin: 0px;
					padding: 0px;
					line-height: 27.24px;
				}
				.distribution-edit {
					display: flex;
					width: auto;
					gap: 0px;
					align-self: center;
					.edit-buttons.save {
						display: flex;
						width: 110px;
						height: 29px;
						p {
							font-size: fonts.$font-size-small;
							margin: auto;
							padding: 0px;
							align-self: center;
							border-bottom: 0.7px solid colors.$color-gray-slate;
						}
						i {
							margin: auto;
							font-size: fonts.$font-size-h5;
						}
					}
					.edit-buttons.edit {
						width: 89px;
						height: 20px;
						display: flex;
						margin: auto;
						margin-left: 0px;
						margin-right: 0px;
						gap: 4px;
						i {
							position: relative;
							top: -3px;
							font-size: fonts.$font-size-bigger;
						}
						p {
							font-size: fonts.$font-size-small;
							line-height: 19.2px;
							margin: auto;
							padding: 0px;
							width: 67px;
						}
					}
				}
			}
		}
	}
}
