@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';
.text-input-container {
	display: flex !important;
	flex-direction: column !important;
	gap: 4px !important;
	.main-container {
		max-width: 365px;
		border: none;
		box-shadow: shadows.$shadow-lower;
		border-radius: border-radius.$radius-small;
		display: flex;
		flex-direction: row;
		.input-form-container {
			border-radius: border-radius.$radius-small;
			display: flex;
			flex-direction: row !important;
			padding: spacing.$spacing-1_25x spacing.$spacing-2x;
			border: 1px solid colors.$color-gray-slate-border-input;
			align-items: center;
			gap: 8px;
			width: 100%;
			.label-icon-start {
				display: flex;
				align-items: center;
				flex-direction: row;
				div {
					display: none;
				}
			}
			input,
			textarea {
				background-color: transparent;
				font-size: fonts.$font-size-base;
				font-family: fonts.$primary-font-family;
				font-weight: fonts.$font-weight-regular;
				max-width: 350px;
				width: 100%;
				border: none;
				line-height: 24px;
			}

			textarea {
				border: none;
				overflow-y: scroll;
				outline: none;
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
				resize: none;
				max-height: 190px;
			}

			input::placeholder {
				background-color: transparent;
				color: colors.$color-gray-slate-border-input;
			}
			input:focus-visible {
				border: 0px;
				outline: none;
			}
			input:focus {
				outline: none;
			}
			.label-icon-end {
				display: flex;
				flex-direction: row !important;
			}
		}
		.input-form-container.disabled {
			background-color: colors.$color-background-gray;
			border: 1px solid colors.$color-gray-slate-border-input !important;
			input::placeholder {
				color: colors.$input-disabled-color;
			}
		}
		.input-form-container.error {
			border: 1px solid colors.$input-error;
			input::placeholder {
				color: colors.$input-disabled-color;
			}
			i {
				color: colors.$input-error;
			}
		}
		.input-form-container.success {
			border: 1px solid colors.$input-border;
		}
	}
	.main-container.default:hover {
		outline: 3px solid colors.$input-hover;
	}
	.error-message {
		color: colors.$input-error;
		div {
			font-size: 13px;
			line-height: 23px;
		}
	}
	.helper-text {
		font-size: fonts.$font-size-small;
		line-height: 22px;
		color: colors.$color-gray-slate;
	}
}
.text-input-container.with-start-text {
	.main-container {
		.input-form-container {
			.label-icon-start {
				div {
					display: inline;
					max-width: 124px;
					font-size: fonts.$font-size-base;
				}
			}
			input {
				width: auto;
			}

			input::placeholder {
				background-color: transparent;
			}
			input:focus-visible {
				border: 0px;
				outline: none;
			}
			input:focus {
				outline: none;
			}
			.label-icon-end {
				display: flex;
				flex-direction: row !important;
			}
		}
		.input-form-container.disabled {
			background-color: colors.$color-background-gray;
			input::placeholder {
				color: colors.$input-disabled-color;
			}
		}
		.input-form-container.error {
			border: 1px solid colors.$input-error;
			input::placeholder {
				color: colors.$input-disabled-color;
			}
			i {
				color: colors.$input-error;
			}
		}
		.input-form-container.success {
			border: 1px solid colors.$input-border;
		}
	}
	.main-container.default:hover {
		outline: 3px solid colors.$input-hover;
	}
	.error-message {
		color: colors.$input-error;
		div {
			font-size: 13px;
			line-height: 23px;
		}
	}
}
