@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/border-radius.scss';
.filter-container {
	height: auto;
	min-width: 0;
	max-width: 100%;
	position: relative;
	width: 100%;
	padding-bottom: spacing.$spacing-x;
	& .main-filter-container {
		display: flex;
		width: 100%;
		& .main-filter-name {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-big;
			line-height: 20px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
			margin: spacing.$spacing-4x 0px spacing.$spacing-4x 0px;
		}
		& .main-menu-close {
			color: colors.$color-background-slate;
			top: 30px;
			position: absolute;
			right: 0px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border: none;
			background-color: unset;
		}
		& .main-menu-open {
			color: colors.$color-background-slate;
			top: 30px;
			position: absolute;
			right: 0px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border: none;
			background-color: unset;
		}
		& .icon-wrapper {
			margin-left: auto;
		}
		& .ri-lock-line {
			display: flex;
			position: relative;
			margin-top: 30px;
			font-size: fonts.$font-size-big;
			cursor: pointer;
			color: colors.$color-gray-slate;
		}
	}
	.filter-content {
		display: flex;
		flex-direction: column;
		width: 108%;
		height: auto;
		max-height: 520px;
		margin: -20px 0px 0px -11px;
		overflow-x: hidden;
		&.cat-margin-wrapper {
			margin: auto;
			&:not(.scrollable) {
				width: 100%;
			}
		}
	}
	& .category-wrapper {
		height: auto;
		& .main-filter-name {
			margin-bottom: 0px;
		}
	}
}
.scrollable {
	width: 100%;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		background: rgba(0, 0, 0, 0.1);
		width: 8px;
		border-radius: border-radius.$radius-small;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.3);
		border-radius: border-radius.$radius-small;
	}
}
.bottom-border {
	border-bottom: 1.5px solid colors.$color-gray;
}
.filter-tooltip-container {
	display: flex;
	flex-direction: row;
	& .tooltip-icon {
		cursor: pointer;
		width: 13.33px;
		height: 13.33px;
		margin: 0px 0px 0px 6.33px;
		color: colors.$color-text-gray-slate-light;
	}
	& .active {
		color: colors.$color-primary;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.filter-tooltip-container {
		& .tooltip-icon {
			margin-top: 4px;
		}
	}
	.filter-container .main-filter-container .main-menu-open {
		top: 27px;
	}
	.filter-container .main-filter-container .main-menu-close {
		top: 26px;
	}
	.filters-container {
		width: 100%;
		background: white;
		display: flex;
		flex-direction: column;
		margin-left: 5%;
		& .filter-container {
			width: 320px;
		}
		& > div:nth-child(1) {
			& > div:nth-child(1) {
				& > div {
					& > span.main-filter-name {
						margin: 18px 0px spacing.$spacing-3x 0px;
					}
					& .main-menu-open,
					& .main-menu-close {
						top: 14px;
					}
				}
			}
		}

		& > div:nth-child(2) {
			& > div:nth-child(1) {
				& > div {
					& > span.main-filter-name {
						margin: spacing.$spacing-half-x 0px spacing.$spacing-3x 0px;
					}
					& .main-menu-open,
					& .main-menu-close {
						top: 0px;
					}
				}
			}
		}
	}

	.filter-container {
		width: 90% !important;
		& .main-filter-container {
			width: 100%;
			& .main-filter-name {
				margin: spacing.$spacing-4x 0px spacing.$spacing-3x 0px;
			}
			& .ri-lock-line {
				margin-top: 20px;
			}
		}
	}
	.filter-container.collapsed-filter:nth-child(1) {
		.row {
			.main-filter-container {
				.main-filter-name {
					margin: spacing.$spacing-4x 0px spacing.$spacing-3x 0px;
				}
				.main-menu-open,
				& .main-menu-close {
					top: 27px;
				}
			}
		}
	}
	.filter-container.collapsed-filter:nth-child(2) {
		.row {
			.main-filter-container {
				.main-filter-name {
					margin: spacing.$spacing-4x 0px spacing.$spacing-3x 0px;
				}
				.main-menu-open,
				& .main-menu-close {
					top: 27px;
				}
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$tablet-breakpoint) {
	.filter-container {
		& .scrollable {
			&::-webkit-scrollbar {
				width: 4px;
			}
		}
	}
}
