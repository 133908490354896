/* Font family */
$primary-font-family: 'Open Sans';
$secondary-font-family: 'Open Sans full-bold';
$tertiary-font-family: 'Open Sans semi-bold';
$font-family-default: 'Open Sans', sans-serif;

/* Font weight */
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$text-underline: underline;
$letter-spacing-std: 0.12px;

/* Font size default */
$font-size-base: 14px;
$font-size-smallest: 14px - 6px;
$font-size-smaller: $font-size-base - 4px;
$font-size-small: $font-size-base - 2px;
$font-size-big: $font-size-base + 2px;
$font-size-bigger: $font-size-base + 4px;
$font-size-h6: $font-size-bigger;
$font-size-h5: $font-size-base + 6px;
$font-size-h4: $font-size-base + 10px;
$font-size-h3: $font-size-base + 18px;
$font-size-h2: $font-size-base + 36px;
$font-size-h1: $font-size-base + 50px;

/* Font size mobile */
$font-size-base-mobile: 12px;
$font-size-smaller-mobile: $font-size-base - 5px;
$font-size-small-mobile: 10px;
$font-size-big-mobile: 14px;
$font-size-bigger-mobile: 16px;
$font-size-h6-mobile: 16px;
$font-size-h5-mobile: 18px;
$font-size-h4-mobile: $font-size-base + 8px;
$font-size-h3-mobile: $font-size-base + 14px;
$font-size-h2-mobile: $font-size-base + 24px;
$font-size-h1-mobile: 48px;
$font-size-hg-btn-inside-caurosel: 9px;
$font-size-onboard-metric-value: 32px;
$font-size-arrow-big: $font-size-base + 16px;
$font-size-arrow-small: $font-size-base + 8px;
