@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';

.sales-information-container {
	display: flex;
	flex-direction: column;
	height: auto;
	margin: 8px auto auto 2%;

	& .sales-information-title {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-base;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
		font-style: normal;
		line-height: 22px;
		margin-bottom: 16px;
	}

	& .sales-information-comingsoon {
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-base;
		line-height: 22px;
	}
	& .sales-information-value {
		font-family: fonts.$primary-font-family;
		margin-left: spacing.$spacing-half-x;
	}
}
