@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';

@keyframes expo-east-cards {
	0% {
		top: 0px;
	}
	30% {
		top: -50px;
	}
	50% {
		top: -50px;
	}
	80% {
		top: 0px;
	}
	100% {
		top: 0px;
	}
}

.expo-east-container {
	width: 100%;
	height: 418px;
	background: colors.$color-background-white;
	border-radius: border-radius.$radius-medium;
	overflow: hidden;
	position: relative;
	.back-pattern {
		margin-top: -125px;
		width: 318px;
	}
	.east-title {
		padding: spacing.$spacing-3x;
		text-align: center;
		font-size: fonts.$font-size-h5;
		background: colors.$gradient-blue;
		color: colors.$color-white;
		font-family: fonts.$tertiary-font-family;
		letter-spacing: 0.0075em;
		line-height: 27px;
	}

	.east-body {
		padding: spacing.$spacing-3x;

		.body-title {
			width: 94%;
			font-size: fonts.$font-size-h5;
			font-family: fonts.$primary-font-family;
			letter-spacing: 0.25px;
			line-height: 32px;
			font-weight: fonts.$font-weight-regular;
			font-style: normal;
			display: flex;
			align-items: center;

			i {
				color: colors.$color-primary;
			}
			.title-text {
				margin-left: spacing.$spacing-x;
				a {
					outline: none;
					color: colors.$color-primary;
					text-decoration: none;
					font-family: fonts.$secondary-font-family;
					& :not(i) {
						text-decoration: underline;
					}
					& > i {
						font-size: fonts.$font-size-h5;
					}
				}
			}
		}

		.map-container {
			margin-top: spacing.$spacing-3x;
			display: flex;
			overflow: hidden;
			height: 200px;
			.cards {
				width: 160px;
				margin-left: spacing.$spacing-3x;
				overflow: hidden;
				position: relative;
				div {
					animation-name: expo-east-cards;
					animation-direction: normal;
					animation-duration: 4000ms;
					animation-timing-function: ease-in-out;
					animation-iteration-count: infinite;
					position: absolute;
				}
				svg {
					width: 73px;
					height: 100px;
				}
			}
			& > :nth-child(2) {
				margin-left: 77px;
				z-index: 9999;
			}
			.curve-arrow {
				position: absolute;
				margin-left: 174px;
				margin-top: 44px;
				z-index: 999999;
			}
		}
	}

	.cursor {
		position: absolute;
		margin-top: -220px;
		margin-left: 177px;
	}
	.expo-close {
		position: absolute;
		top: 18px;
		right: 18px;
		color: colors.$color-white;
		font-size: fonts.$font-size-bigger;
		cursor: pointer;
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.expo-east-container {
		height: 334px;
		margin-top: 110px;
		.east-title {
			padding: 20px;
			font-size: fonts.$font-size-base;
		}

		.east-body {
			padding: spacing.$spacing-3x spacing.$spacing-2x;
			.body-title {
				font-size: fonts.$font-size-small;
				line-height: 16px;
				& .title-text {
					a {
						& > i {
							position: absolute;
							font-size: fonts.$font-size-bigger-mobile;
						}
						& > span {
							margin-left: 18px;
						}
					}
				}
			}
			.map-container {
				height: 150px;
				.cards {
					width: 120px;
					margin-left: 0;
					svg {
						width: 54px;
						height: 73px;
					}
				}
				& > :nth-child(2) {
					width: 160px;
					height: 115px;
					margin: 0 auto 0 auto;
				}
				.curve-arrow {
					width: 55px;
					height: 20px;
					margin-left: 104px;
				}
			}
		}
		.cursor {
			width: 20px;
			margin-left: 120px;
			margin-top: -178px;
		}
		.back-pattern {
			margin-top: -66px;
			width: 191px;
		}
	}
}
@media (min-width: 0px) and (max-width: 331px) {
	.expo-east-container {
		.east-body {
			.body-title {
				& .title-text {
					a {
						& > i {
							position: relative;
							font-size: fonts.$font-size-bigger-mobile;
						}
						& > span {
							margin-left: 0px;
						}
					}
				}
			}
		}
	}
}
