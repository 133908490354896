@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/spacing.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/shadows.scss';

.dashboard-container {
	display: flex;
	flex-direction: column;

	& .dashboard-title-container {
		margin-top: spacing.$spacing-7x;

		& .beacon-discovery-text {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h4;
			line-height: 33px;
			letter-spacing: 0.0075em;
			color: colors.$color-primary;
		}

		& .engagement-text {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h4;
			line-height: 33px;
			letter-spacing: 0.0075em;
			color: colors.$color-text-black;
			margin-left: spacing.$spacing-x;
		}
	}

	& .separator {
		width: 100%;
		height: 2px;
		background-color: colors.$color-background-gray;
		margin-top: spacing.$spacing-x;
	}

	& .dashboard-content-container {
		margin-top: spacing.$spacing-7x;
		width: 100%;
		padding: spacing.$spacing-3x spacing.$spacing-10x spacing.$spacing-7x spacing.$spacing-10x;
		border-radius: border-radius.$radius-medium;
		box-shadow: shadows.$shadow-medium;
		background: radial-gradient(
				circle at 50% 50%,
				rgba(151, 255, 209, 0.4) 0%,
				rgba(255, 255, 255, 0) 100%
			),
			radial-gradient(
				circle at 97.85% 98.02%,
				rgba(4, 172, 212, 0.2) 0%,
				rgba(255, 255, 255, 0) 100%
			);

		& .subtitle-container {
			display: flex;
			align-items: center;
			& .dashboard-subtitle {
				& > span {
					font-family: fonts.$secondary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-bold;
					font-size: fonts.$font-size-h5;
					line-height: 27px;
					letter-spacing: 0.0075em;
					color: colors.$color-primary;
				}
			}
			& .dashboard-button-container {
				margin-left: auto;
				& > button {
					margin: auto;
					padding: spacing.$spacing-x spacing.$spacing-3x;
					font-family: fonts.$secondary-font-family;
					font-weight: fonts.$font-weight-bold;
					& > i {
						font-size: 22px;
						font-weight: 500;
					}
					&:hover {
						& > i {
							transform: translateX(4px);
						}
					}
				}
			}
		}

		& .dashboard-who-is-on-container {
			display: flex;
			flex-direction: column;
			margin-top: spacing.$spacing-8x;
			& .who-is-on-beacon-container {
				& .who-is-on-title {
					font-family: fonts.$secondary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-bold;
					font-size: fonts.$font-size-h5;
					line-height: 27px;
					letter-spacing: 0.0075em;
					color: colors.$color-primary;
				}
			}
		}
		& .charts-container {
			display: flex;
			gap: 16px;
			margin-top: spacing.$spacing-3x;
			justify-content: space-between;
			flex-wrap: wrap;
			& > div {
				&:nth-child(1) {
					margin-right: spacing.$spacing-2x;
				}
			}
		}
	}
}
@media (max-width: 1122px) {
	.dashboard-container {
		& .dashboard-content-container {
			& .subtitle-container {
				& .dashboard-subtitle {
					width: 389px;
				}
			}
		}
	}
}
@media (max-width: 1414px) {
	.dashboard-container {
		& .dashboard-content-container {
			& .charts-container {
				justify-content: unset;
				& > div {
					&:nth-child(1) {
						margin-right: 0px;
						flex: 1 1 100%;
						& .kpi-container {
							max-width: 216px;
						}
					}
				}
			}
		}
	}
}
@media (max-width: 1150px) {
	.dashboard-container {
		& .dashboard-content-container {
			padding: spacing.$spacing-1_5x spacing.$spacing-1_5x spacing.$spacing-5x spacing.$spacing-1_5x;
			& .charts-container {
				gap: 24px;
				& > div {
					&:nth-child(1) {
						flex: auto;
					}
					&:nth-child(2) {
						margin-left: auto;
					}
					&:nth-child(3) {
						margin-left: auto;
					}
				}
			}
			& .subtitle-container {
				align-items: normal;
				& .dashboard-subtitle {
					& > span {
						line-height: 32px;
					}
				}
			}
		}
	}
}
@media (max-width: breakpoints.$tablet-breakpoint) {
	.dashboard-container {
		& .dashboard-title-container {
			margin-top: spacing.$spacing-5x;
			& .beacon-discovery-text {
				font-size: fonts.$font-size-h5;
				line-height: 27px;
			}

			& .engagement-text {
				font-size: fonts.$font-size-h5;
				line-height: 27px;
			}
		}
		& .dashboard-content-container {
			margin-top: spacing.$spacing-3x;
			& .charts-container {
				margin-top: 14px;
			}
			& .dashboard-who-is-on-container {
				margin-top: spacing.$spacing-5x;
			}
		}
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.dashboard-container {
		& .dashboard-title-container {
			& .beacon-discovery-text {
				font-size: fonts.$font-size-h5-mobile;
				line-height: 24px;
			}

			& .engagement-text {
				font-size: fonts.$font-size-h5-mobile;
				line-height: 24px;
			}
		}
		padding: 0px spacing.$spacing-2x;
		& .dashboard-content-container {
			& .subtitle-container {
				flex-direction: column;
				justify-content: center;
				align-items: center;
				& .dashboard-subtitle {
					width: 100%;
					text-align: center;
					& > span {
						line-height: 27px;
					}
				}
				& .dashboard-button-container {
					margin-left: unset;
					margin-top: spacing.$spacing-2x;
					& > button {
						line-height: 22px;
						font-size: fonts.$font-size-base;
					}
				}
			}
			overflow-x: scroll;
			& .charts-container {
				flex-direction: column;
				gap: 24px;
				align-items: center;
				margin-top: spacing.$spacing-3x;
				& > div {
					&:nth-child(2) {
						margin-left: unset;
					}
					&:nth-child(3) {
						margin-left: unset;
					}
				}
			}
			& .dashboard-who-is-on-container {
				& .who-is-on-beacon-container {
					& .who-is-on-title {
						line-height: 27px;
					}
				}
			}
		}
		& .chart-container {
			width: fit-content;
			padding-right: spacing.$spacing-2x;
		}
	}
}
@media (max-width: 500px) {
	.dashboard-container {
		& .dashboard-title-container {
			& .beacon-discovery-text {
				text-wrap: nowrap;
			}
		}
		& .dashboard-content-container {
			& .subtitle-container {
				& .dashboard-subtitle {
					& > span {
						font-size: fonts.$font-size-bigger-mobile;
					}
				}
				& .dashboard-button-container {
					& > button {
						text-wrap: nowrap;
					}
				}
			}
			& .charts-container {
				align-items: flex-start;
			}
			& .dashboard-who-is-on-container {
				& .who-is-on-beacon-container {
					& .who-is-on-title {
						text-wrap: nowrap;
					}
				}
			}
		}
	}
}
