@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
.onboard-title-container {
	& .title-container {
		width: 90%;
		margin-top: 0px;
		& .bold-title {
			font-family: fonts.$secondary-font-family;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h4;
			line-height: 33px;
			letter-spacing: 0.0075em;
			color: colors.$color-primary;
		}
		& .title {
			color: colors.$color-text-black;
			font-family: fonts.$primary-font-family;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-h4;
			line-height: 33px;
			letter-spacing: 0.0075em;
		}
	}
	& .buttons-container {
		display: flex;
		margin-top: spacing.$spacing-2x;
		flex-wrap: wrap;
		gap: 16px;
		& > a {
			& > button {
				margin: 0px;
				& > i {
					font-size: 22px;
					font-weight: 500;
				}
				&:hover {
					& > i {
						transform: translateX(4px);
					}
				}
			}
		}
	}

	& .new-title-container {
		margin-top: 0px;
		& .bold-title {
			font-family: fonts.$secondary-font-family;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h4;
			line-height: 33px;
			letter-spacing: 0.0075em;
			color: colors.$color-primary;
		}
		& .title {
			color: colors.$color-text-black;
			font-family: fonts.$primary-font-family;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-h4;
			line-height: 33px;
			letter-spacing: 0.0075em;
		}
	}
	& .buttons-container {
		display: flex;
		margin-top: spacing.$spacing-2x;
		flex-wrap: wrap;
		gap: 16px;
		& > a {
			& > button {
				margin: 0px;
				& > i {
					font-size: 22px;
					font-weight: 500;
				}
				&:hover {
					& > i {
						transform: translateX(4px);
					}
				}
			}
		}
	}
}
@media (max-width: breakpoints.$tablet-breakpoint) {
	.onboard-title-container {
		& .title-container {
			width: 100%;
			& .bold-title {
				font-size: fonts.$font-size-bigger;
			}
			& .title {
				font-size: fonts.$font-size-bigger;
			}
		}
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.onboard-title-container {
		padding: 0px spacing.$spacing-2x;
		& .title-container {
			& .bold-title {
				line-height: 23px;
				font-size: fonts.$font-size-big-mobile;
			}
			& .title {
				line-height: 23px;
				font-size: fonts.$font-size-big-mobile;
			}
		}
		& .buttons-container {
			& > a {
				& > button {
					width: 100%;
					min-width: 295px;
					text-wrap: nowrap;
					gap: 2px;
					padding: spacing.$spacing-half-x 20px;
					& > i {
						font-size: fonts.$font-size-h5-mobile;
					}
				}
			}
		}
	}
}
@media (max-width: 500px) {
	.onboard-title-container {
		padding: 0px spacing.$spacing-x;
		& .buttons-container {
			margin-top: spacing.$spacing-1_5x;
			& > a {
				& > button {
					width: 295px;
				}
			}
		}
	}
}
