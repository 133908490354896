@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/shadows.scss';
.loading-bar {
	height: 12px;
	background: colors.$gradient-beacon;
	box-shadow: shadows.$progress-bar-shadow;
	&.fade-out {
		animation: fadeOut 1s forwards;
	}

	@keyframes fadeOut {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}
