@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';
.filter-error-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto;
	& div {
		text-align: center;
		margin-top: 69px;
		.error-img {
			width: 347px;
			height: 492px;
			&.search {
				margin-top: spacing.$spacing-5x;
				width: 487px;
				height: 422px;
			}
		}
		.error-title {
			margin-top: 80px;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-h4;
			line-height: 18px;
			font-family: fonts.$tertiary-font-family;
			color: colors.$color-text-black;
		}
		.error-content {
			margin-top: 32px;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-h5;
			line-height: 26px;
			font-family: fonts.$primary-font-family;
			color: colors.$color-text-black;
			&.search {
				font-size: fonts.$font-size-big;
			}
		}
		.error-btn {
			border: 2px solid colors.$color-first-tier;
			box-shadow: shadows.$shadow-low;
			border-radius: border-radius.$radius-biggest;
			letter-spacing: fonts.$letter-spacing-std;
			background: colors.$color-background-white;
			margin-top: 24px;
			padding: 6px 32px;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-big;
			line-height: 28px;
			color: colors.$color-primary;
			font-family: fonts.$tertiary-font-family;
		}
		.error-btn:hover {
			background: colors.$color-primary-light;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.filter-error-container {
		margin-bottom: spacing.$spacing-20x;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.filter-error-container {
		align-items: flex-start;
		padding-bottom: 55px;
		& div {
			&.search {
				margin-top: auto;
			}
			& .error-img {
				width: 118px;
				height: 175px;
				&.search {
					margin-top: spacing.$spacing-3x;
					width: 202px;
					height: 175px;
				}
			}
			& .error-title {
				margin-top: 32px;
				font-weight: fonts.$font-weight-semibold;
				font-size: fonts.$font-size-base-mobile;
				line-height: 18px;
				font-family: fonts.$tertiary-font-family;
			}
			& .error-content {
				margin-top: 12px;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-base-mobile;
				line-height: 18px;
				&.search {
					margin: spacing.$spacing-1_5x spacing.$spacing-3x 0px spacing.$spacing-3x;
					font-size: fonts.$font-size-base-mobile;
				}
			}
			& .error-btn {
				margin-top: spacing.$spacing-3x;
				padding: spacing.$spacing-half-x spacing.$spacing-3x;
				font-size: fonts.$font-size-base-mobile;
				line-height: 20px;
			}
		}
	}
}
