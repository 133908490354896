@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/shadows.scss';
.kpi-container {
	position: relative;
	min-width: 216px;
	background: colors.$color-white;
	box-shadow: shadows.$shadow-lower;
	border-radius: border-radius.$radius-big;
	overflow-x: visible;
	padding: spacing.$spacing-1_5x 0px 33px spacing.$spacing-2x;
	height: 216px;
	& .kpi-title-container {
		display: flex;
		& .kpi-title {
			width: 149px;
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-bigger;
			line-height: 25px;
			letter-spacing: 0.0075em;
			color: colors.$color-primary;
			margin-top: spacing.$spacing-1_25x;
		}
		& .icon-container {
			position: absolute;
			right: 0;
			left: 175px;
			display: flex;
			width: 64px;
			height: 64px;
			align-items: center;
			justify-content: center;
			background: colors.$color-secondary-lighter;
			border-radius: border-radius.$radius-medium;
			& > i {
				font-size: fonts.$font-size-h3;
				color: colors.$color-secondary;
			}
		}
	}
	& .value-container {
		display: flex;
		margin: spacing.$spacing-x auto auto 29px;
		& .value {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h1-mobile;
			line-height: 77px;
			letter-spacing: 0.0075em;
			color: colors.$color-text-black;
		}
	}
	& .metric-container {
		display: flex;
		margin-left: 29px;
		& .metric {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-big;
			line-height: 26px;
			letter-spacing: 0.0075em;
			color: colors.$color-gray-slate;
		}
	}
}
