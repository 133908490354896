$color-primary: rgb(0, 113, 168);
$color-primary-dark: rgb(34, 72, 127);
$color-primary-light: rgb(222, 238, 248);
$color-secondary: rgba(0, 165, 205);
$color-secondary-medium: rgb(0, 148, 185);
$color-secondary-light: rgb(153, 219, 235);
$color-secondary-lighter: rgb(192, 237, 248);
$color-secondary-lightest: rgb(230, 246, 250);
$color-background-secondary-lightest: rgb(230, 246, 250);
$color-third-light: rgb(230, 244, 249);
$color-tertiary: rgb(63, 177, 126);
$color-tertiary-dark: rgb(44, 124, 88);
$color-tertiary-light: rgb(121, 200, 165);
$color-black: rgb(0, 0, 0);
$color-background-black: rgb(0, 0, 0);
$color-text-black: rgb(0, 0, 0);
$color-gray-darkest: rgb(66, 70, 73, 1);
$color-text-gray-darkest: rgb(66, 70, 73, 1);
$color-gray-darker: rgb(77, 81, 84);
$color-overlay-dark: rgba(0, 0, 0, 0.6);
$color-background-gray-darker: rgb(77, 81, 84);
$color-gray-slate: rgb(92, 102, 111);
$color-background-slate: rgb(92, 102, 111);
$color-gray-slate-border-input: rgb(141, 148, 154);
$color-gray-slate-light: rgb(141, 146, 154);
$color-gray-slate-lighter: rgb(141, 158, 154);
$color-text-gray-slate-light: rgb(141, 146, 154);
$color-gray-medium: rgb(190, 194, 197);
$color-gray: rgb(234, 234, 234);
$color-background-gray: rgb(234, 234, 234);
$color-gray-light: rgba(249, 249, 250);
$color-background-gray-medium: rgba(240, 240, 240);
$color-background-light-gray: rgba(249, 249, 250);
$color-white: rgb(255, 255, 255);
$color-background-white: rgb(255, 255, 255);
$color-text-white: rgb(255, 255, 255);
$color-transparent: transparent;
$color-info: rgb(1, 208, 234);
$color-info-light: rgb(242, 248, 251);
$color-first-tier-light: rgb(242, 248, 251);
$color-success: rgb(52, 211, 157);
$color-success-light: rgb(245, 251, 249);
$color-second-tier-light: rgb(245, 251, 249);
$color-warning: rgb(255, 193, 45);
$color-warning-light: rgba(255, 250, 242);
$color-third-tier-light: rgba(255, 250, 242);
$color-error: rgb(255, 0, 15);
$color-error-light: rgb(253, 237, 238);
$color-howgood-very-bad: rgb(247, 139, 146);
$color-howgood-bad: rgb(255, 182, 141);
$color-howgood-mid: rgb(247, 215, 139);
$color-howgood-good: rgb(206, 230, 157);
$color-howgood-very-good: rgb(155, 193, 176);
$color-first-tier: #0071a8;
$color-second-tier: #2d805c;
$color-third-tier: #fc9200;
$color-third-tier-2: #cc7700;
$color-how-good-bar: #749516;
$color-how-great-bar: #3f9591;
$color-how-best-bar: #66a164;
$color-how-good-text: #7c790d;
$color-how-great-text: #3f9591;
$color-how-best-text: #66a164;
$color-tag-certification: rgba(45, 128, 92, 1);
$color-brand-links: rgba(0, 130, 181, 1);
$gradient-blue: linear-gradient(90deg, #0172a9 0%, #03aed5 100%);
$gradient-blue-2: linear-gradient(90deg, #0172a9 0%, #03aed5 32.29%, #03aed5 67.71%, #0172a9 100%);
$gradient-green: linear-gradient(90deg, #00add8 -30.39%, #3fb17e -27%, #9aca3c 78.27%);
$gradient-beacon: linear-gradient(
	90deg,
	#0071a8 0%,
	#0071a8 0.17%,
	#0075ab 4.49%,
	#0082b5 11.52%,
	#0096c5 20.34%,
	#00add8 28.42%,
	#3fb17e 67.9%,
	#9aca3c 100%
);
$gradient-traffic: linear-gradient(
	90deg,
	#f23e4a 0.18%,
	#ff8641 26.45%,
	#ffc74b 51.67%,
	#ffc94b 51.68%,
	#aed65b 77.42%,
	#458179 101.07%
);
$app-background-gradient: linear-gradient(
	90deg,
	#0172a9 0%,
	#03aed5 32.29%,
	#03aed5 67.71%,
	#0172a9 100%
);
$gradient-highlighted: linear-gradient(180deg, #baeffc 0%, rgba(217, 217, 217, 0) 100%);
$good-estimate: #fef876;
$great-estimate: #6bd6d6;
$best-estimate: #c2e950;
$company-label-color: #20102b;
$estimate-color-background: #f5f5f5;
$spinner-color: #7986cb;
$loading-skeleton-color: #f7f7f8;
$loading-skeleton-gradient: linear-gradient(90deg, #ebebeb, #f5f5f5, #ebebeb);
$east-animation-gredient1: linear-gradient(90deg, #0172a9 0%, #03aed5 100%);
$east-animation-gredient2: linear-gradient(90deg, #03a1cc 0%, #0172a9 28.65%, #03aed5 100%);
$east-animation-gredient3: linear-gradient(90deg, #03acd4 0%, #0172a9 50.52%, #03acd4 100%);
$east-animation-gredient4: linear-gradient(90deg, #03acd4 0%, #0172a9 72.4%, #03acd4 100%);
$east-animation-gredient5: linear-gradient(90deg, #03aed5 0%, #0172a9 100%);
$loading-pill-color: #f7f7f8;
$separator-loading-templates-color: #00000033;
$gradient-expo-west: linear-gradient(90deg, #9f00b0 0%, #cb5200 56.5%, #ee7300 100%);
$expo-west-red: rgb(207, 51, 53);
$expo-west-orange: rgb(195, 67, 10);
$west-animation-gradient1: linear-gradient(90deg, #9f00b0 0%, #cb5200 56.5%, #ee7300 100%);
$west-animation-gradient2: linear-gradient(90deg, #9f00b0 22%, #cb5200 79%, #ee7300 100%);
$west-animation-gradient3: linear-gradient(90deg, #ee7300 0%, #9f00b0 38%, #cb5200 100%);
$west-animation-gradient4: linear-gradient(90deg, #ee7300 18.5%, #9f00b0 53%, #cb5200 100%);
$west-animation-gradient5: linear-gradient(90deg, #ee7300 23.5%, #9f00b0 69%, #cb5200 100%);
$west-animation-gradient6: linear-gradient(90deg, #ee7300 35%, #9f00b0 81%, #cb5200 100%);
$west-animation-gradient7: linear-gradient(90deg, #cb5200 1.5%, #ee7300 54.5%, #9f00b0 100%);
$west-animation-gradient8: linear-gradient(90deg, #9f00b0 0%, #cb5200 22%, #ee7300 68.5%);
$west-animation-gradient9: linear-gradient(90deg, #9f00b0 0%, #cb5200 56.5%, #ee7300 100%);
$stepper-header-background: linear-gradient(
	90deg,
	rgba(205, 242, 237, 1) 0%,
	rgba(225, 244, 247, 1) 52%,
	rgba(233, 246, 249, 1) 100%
);
$stepper-body-background: linear-gradient(
	180deg,
	rgba(255, 255, 255, 1) 0%,
	rgba(236, 246, 247, 1) 100%
);
$profile-template-background: linear-gradient(
	180deg,
	rgba(255, 255, 255, 1) 0%,
	rgba(243, 249, 249, 1) 100%
);
$page-container-template-background: rgba(243, 249, 249, 1);
$table-stripe-background: #f7f7f8;
$not-labeled-us: rgba(238, 245, 255, 1);
$save-template-sticky-background: linear-gradient(
		60.21deg,
		rgba(103, 225, 255, 0.25) 18.46%,
		rgba(255, 255, 255, 0) 82.05%
	),
	#e6f6fa;
$newtopia-event-tag-gradient: linear-gradient(90deg, #f23ea8 0%, #f891ce 100%);
$newtopia-animation-gradient1: linear-gradient(90deg, #f23ea8 0%, #f891ce 100%);
$newtopia-animation-gradient2: linear-gradient(90deg, #f23ea8 0%, #f891ce 28.65%, #f23ea8 100%);
$newtopia-animation-gradient3: linear-gradient(90deg, #f23ea8 0%, #f891ce 50.52%, #f23ea8 100%);
$newtopia-animation-gradient4: linear-gradient(90deg, #f23ea8 0%, #f891ce 72.4%, #f23ea8 100%);
$newtopia-animation-gradient5: linear-gradient(90deg, #f23ea8 0%, #f891ce 100%);
$animated-newtopia-label: linear-gradient(
	to right,
	#f23ea8,
	#f891ce,
	rgba(248, 145, 206, 0.5),
	#f23ea8,
	rgba(248, 145, 206, 0.5)
);
$gradient-back-to-top-button: linear-gradient(90deg, #0172a9 0%, #03aed5 100%);
$onboard-page-metric-gradient: linear-gradient(90deg, #0172a9 0%, #03aed5 100%);
$certifications-gradient: linear-gradient(90deg, #00add8 -30.39%, #3fb17e -27%, #9aca3c 78.27%);
$need-changes-background: #00b2e2;
$input-disabled-color: rgb(155, 149, 159);
$input-error: rgb(219, 55, 31);
$input-hover: rgb(204, 233, 242);
$input-border: rgb(0, 143, 190);
$newtopia-highlight-navbar: rgba(169, 3, 101, 1);
$login-background-color: linear-gradient(90deg, #64fe92, #37d8c2, #1dbecf);
$login-main-card-background: linear-gradient(180deg, #ffffff 40.5%, rgba(255, 255, 255, 0.4) 100%);
$background-hover-ingredients-button: rgb(239, 240, 241);
$border-hover-ingredients-button: solid 1px rgb(231, 232, 234);
