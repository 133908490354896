@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/spacing.scss';
.loading-ingredients-template-container {
	overflow: hidden;
	z-index: 0;
	@keyframes loading-skeleton {
		0% {
			background-color: colors.$color-background-gray;
		}

		50% {
			background-color: colors.$color-background-light-gray;
		}

		100% {
			background-color: colors.$color-background-gray;
		}
	}
	& .templates-skeleton {
		background-color: colors.$color-background-gray;
		display: inline-flex;
		line-height: 1;
		position: relative;
		overflow: hidden;
		z-index: 1;
		border-radius: border-radius.$radius-medium;
		animation-name: loading-skeleton;
		animation-direction: normal;
		animation-duration: 2s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
	}
	width: 100%;
	margin: auto;
	& .loading-ingredients-template-header {
		display: grid;
		width: 80%;
		margin: 50px auto 52px 10%;
		grid-template-columns: auto auto auto;
		& > div {
			&:first-child {
				grid-column-start: 1;
				grid-column-end: 3;
				max-width: 428px;
				width: 100%;
				height: 27px;
				margin: 0px 0px 19px 0px;
			}
			&:nth-child(2) {
				width: 71px;
				height: 24px;
				margin: 0px spacing.$spacing-5x 19px auto;
			}
			&:nth-child(3) {
				grid-column-start: 1;
				grid-column-end: 3;
				width: 100%;
				max-width: 747px;
				height: 24px;
				margin: 0px 0px spacing.$spacing-4x 0px;
			}
			&:last-child {
				grid-column-start: 1;
				grid-column-end: 3;
				max-width: 595px;
				width: 100%;
				height: 24px;
			}
		}
		& .loading-pill {
			width: 100%;
			border-radius: border-radius.$radius-biggest;
			background: colors.$loading-pill-color;
		}
	}
	& .loading-cards-container {
		display: flex;
		width: 90%;
		margin: auto auto auto 4%;
		gap: 24px;
		& .loaing-template-card {
			width: 100%;
			height: 335px;
			background: colors.$loading-pill-color;
			border-radius: border-radius.$radius-medium;
		}
	}
	& .loading-ingredients-template-footer {
		display: flex;
		margin: 41px auto auto 4%;
		flex-direction: column;
		& > div {
			&:first-child {
				max-width: 846px;
				width: 100%;
				height: 41px;
				margin: auto;
			}
			&:nth-child(2) {
				max-width: 483px;
				width: 100%;
				height: 24px;
				margin: 44px 0px 0px 0px;
			}
			&:last-child {
				max-width: 191px;
				width: 100%;
				height: 38px;
				margin: 44px 164px 0px auto;
			}
		}
		& .loading-pill {
			width: 100%;
			border-radius: border-radius.$radius-biggest;
			background: colors.$loading-pill-color;
		}
	}
}
@media (min-width: 0px) and (max-width: 500px) {
	.loading-ingredients-template-container {
		&.profile-wrapper {
			margin: auto auto auto spacing.$spacing-x;
		}
		& .loading-ingredients-template-header {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin: 21px auto auto auto;
			& > div {
				&:first-child {
					width: 95%;
					height: 24px;
					margin: 0px 0px 29px 0px;
				}
				&:nth-child(2),
				&:nth-child(3) {
					width: 97%;
					height: 24px;
				}
				&:nth-child(2) {
					margin: 0px 0px spacing.$spacing-x 0px;
				}
				&:nth-child(3) {
					margin: 0px 0px 28px 0px;
				}
				&:nth-child(4) {
					width: 90%;
					height: 24px;
					margin: 0px 0px spacing.$spacing-half-x 0px;
				}
				&:last-child {
					width: 88%;
					height: 24px;
				}
			}
		}
		& .loading-cards-container.profile-cards {
			display: flex;
			flex-direction: column;
			margin: spacing.$spacing-2x auto;
			overflow: hidden;
			width: fit-content;
		}
		& .dots-conteiner.profile-dots {
			display: none;
		}
		& .loading-cards-container {
			margin: 28px auto auto 0px;
			overflow: hidden;
			width: fit-content;
			& .loaing-template-card {
				width: 224px;
				height: 354px;
				background: colors.$loading-pill-color;
				border-radius: border-radius.$radius-medium;
			}
			& > div {
				&:nth-child(2),
				&:nth-child(3) {
					height: 317px;
				}
			}
		}
		& .dots-conteiner {
			display: flex;
			justify-content: center;
			gap: 8px;
			margin: spacing.$spacing-2x auto spacing.$spacing-5x 0px;
			& > div {
				width: 8px;
				height: 8px;
				background-color: colors.$color-gray;
				border-radius: border-radius.$radius-circle;
			}
			& :first-child {
				background-color: colors.$color-gray-slate-light;
			}
		}
		& .open-detail-comparison-container {
			display: flex;
			justify-content: center;
			margin: auto auto auto auto;
			& > div {
				width: 100%;
				height: 34px;
			}
		}
		& .seperator-container {
			display: flex;
			justify-content: center;
			margin: spacing.$spacing-4x 0px 0px 0px;
			& .separator {
				width: 100%;
				height: 2px;
				background-color: colors.$separator-loading-templates-color;
			}
		}
		& .loading-ingredients-template-footer {
			margin: 29px auto auto auto;
			padding-bottom: 88px;
			& > div {
				&:first-child {
					width: 100%;
					height: 24px;
				}
				&:nth-child(2) {
					max-width: 90px;
					width: 100%;
					height: 24px;
					margin: spacing.$spacing-half-x 0px 0px 0px;
				}
				&:last-child {
					max-width: 110px;
					width: 100%;
					height: 28px;
					margin: spacing.$spacing-2x 0px 0px auto;
				}
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.loading-ingredients-template-container {
		& .loading-ingredients-template-header {
			& > div {
				&:nth-child(4) {
					display: none;
				}
			}
		}
		& .loading-ingredients-template-footer {
			& > div {
				&:last-child {
					margin: 44px 0px 0px auto;
				}
			}
		}
	}
}
@media (min-width: 500px) and (max-width: breakpoints.$mobile-breakpoint) {
	.loading-ingredients-template-container {
		&.profile-wrapper {
			margin: auto auto auto spacing.$spacing-x;
		}
		& .loading-ingredients-template-header {
			display: flex;
			flex-direction: column;
			margin: 21px auto auto 0px;
			width: 100%;
			& > div {
				&:first-child {
					width: 95%;
					height: 24px;
					margin: 0px 0px 29px 0px;
					max-width: 95%;
				}
				&:nth-child(2),
				&:nth-child(3) {
					width: 97%;
					height: 24px;
				}
				&:nth-child(2) {
					margin: 0px 0px spacing.$spacing-x 0px;
				}
				&:nth-child(3) {
					margin: 0px 0px 28px 0px;
				}
				&:nth-child(4) {
					width: 90%;
					height: 24px;
					margin: 0px 0px spacing.$spacing-half-x 0px;
				}
				&:last-child {
					width: 88%;
					height: 24px;
				}
			}
		}
		& .loading-cards-container.profile-cards {
			display: flex;
			flex-direction: column;
			margin: spacing.$spacing-2x auto;
			overflow: hidden;
			width: fit-content;
		}
		& .dots-conteiner.profile-dots {
			display: none;
		}
		& .loading-cards-container {
			margin: 28px auto auto 0px;
			overflow: hidden;
			width: fit-content;
			& .loaing-template-card {
				width: 100%;
				min-width: 300px;
				height: 354px;
				background: colors.$loading-pill-color;
				border-radius: border-radius.$radius-medium;
			}
			& > div {
				&:nth-child(2),
				&:nth-child(3) {
					height: 317px;
				}
			}
		}
		& .dots-conteiner {
			display: flex;
			justify-content: center;
			gap: 8px;
			margin: spacing.$spacing-2x auto spacing.$spacing-5x 0px;
			& > div {
				width: 8px;
				height: 8px;
				background-color: colors.$color-gray;
				border-radius: border-radius.$radius-circle;
			}
			& :first-child {
				background-color: colors.$color-gray-slate-light;
			}
		}
		& .open-detail-comparison-container {
			display: flex;
			justify-content: center;
			margin: auto auto auto auto;
			& > div {
				width: 100%;
				height: 34px;
			}
		}
		& .seperator-container {
			display: flex;
			justify-content: center;
			margin: spacing.$spacing-4x 0px 0px 0px;
			& .separator {
				width: 100%;
				height: 2px;
				background-color: colors.$separator-loading-templates-color;
			}
		}
		& .loading-ingredients-template-footer {
			margin: 29px auto auto auto;
			padding-bottom: 88px;
			& > div {
				&:first-child {
					width: 100%;
					height: 24px;
				}
				&:nth-child(2) {
					max-width: 90px;
					width: 100%;
					height: 24px;
					margin: spacing.$spacing-half-x 0px 0px 0px;
				}
				&:last-child {
					max-width: 110px;
					width: 100%;
					height: 28px;
					margin: spacing.$spacing-2x 0px 0px auto;
				}
			}
		}
	}
}
