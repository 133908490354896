@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';

.tooltip-form-container {
	position: relative;
	display: inline-block !important;
}
.tooltip {
	font-size: fonts.$font-size-small;
	padding: spacing.$spacing-half-x spacing.$spacing-x;
	background-color: colors.$color-background-slate;
	color: colors.$color-white;
	border-radius: border-radius.$radius-small;
	a {
		color: colors.$color-white;
	}
}
