$shadow-lower: 0px 1px 6px rgba(0, 0, 0, 0.1);
$shadow-low: 0px 2px 4px rgba(0, 0, 0, 0.25);
$shadow-medium: 0px 2px 8px rgba(0, 0, 0, 0.2);
$shadow-high: 0px 4px 8px rgba(0, 0, 0, 0.25);
$shadow-higher: 0px 6px 10px rgba(0, 0, 0, 0.25);
$shadow-highest: 0px 8px 12px rgba(0, 0, 0, 0.25);
$shadow-high-inner: inset 0px 4px 8px rgba(0, 0, 0, 0.25);
$shadow-bigger: 0px 20px 15px -20px rgba(0, 0, 0, 0.25);
$shadow-brand-image: 0px 4px 20px rgba(0, 0, 0, 0.25);
$blue-template-card-shadow: 0px 2px 12px rgba(0, 113, 168, 0.6);
$green-template-card-shadow: 0px 2px 12px rgba(63, 177, 126, 0.6);
$yellow-template-card-shadow: 0px 2px 12px rgba(252, 146, 0, 0.6);
$progress-bar-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
$save-sticky-template-shadow: 0px -2px 8px rgba(0, 0, 0, 0.25);
