@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/spacing.scss';

.list-providers-container {
	padding: spacing.$spacing-2x 0px spacing.$spacing-4x 0px;
	width: 100%;
	.section-title {
		color: colors.$color-text-black;
		font-family: fonts.$secondary-font-family;
		font-size: fonts.$font-size-h5;
		font-weight: fonts.$font-weight-bold;
		line-height: 27.24px;
		letter-spacing: 0.0075em;
		margin: 0px;
	}
	.providers-container {
		display: flex;
		flex-direction: column;
		margin-top: spacing.$spacing-10x;
		.providers-count {
			color: colors.$color-gray-slate-border-input;
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-small;
			font-weight: fonts.$font-weight-regular;
			line-height: 19.2px;
			letter-spacing: 0.0075em;
			margin-bottom: spacing.$spacing-1_25x;
		}
		.provider-section {
			.category-title {
				background-color: colors.$color-secondary-lightest;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-radius: 12px;
				padding: spacing.$spacing-3_5x spacing.$spacing-2x;
				margin-bottom: spacing.$spacing-3x;
				.title {
					font-family: fonts.$secondary-font-family;
					font-weight: fonts.$font-weight-bold;
					color: colors.$color-primary;
					font-size: fonts.$font-size-h3;
					margin: 0px;
					padding: 0px;
					line-height: 32px;
					letter-spacing: 0.0075em;
				}
			}
			.brands-container {
				margin-bottom: spacing.$spacing-5x;
				display: grid;
				column-gap: spacing.$spacing-6x;
				row-gap: spacing.$spacing-x;
				max-width: 97%;
				.group {
					-webkit-column-count: 4;
					column-count: 4;
					.brand {
						min-width: 235px;
						max-width: 235px;
						row-gap: spacing.$spacing-x;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						text-decoration: none !important;
						.text {
							font-family: fonts.$primary-font-family;
							font-size: fonts.$font-size-big;
							font-weight: fonts.$font-weight-regular;
							line-height: 25.6px;
							letter-spacing: 0.0075em;
							color: colors.$color-text-black;
						}
					}
				}
			}
			&:last-child {
				.brands-container {
					margin-bottom: 0;
				}
			}
		}
	}
}
// TABLET
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.list-providers-container {
		padding: spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-3x spacing.$spacing-2x;
		.section-title {
			font-size: fonts.$font-size-base;
			line-height: 22px;
			letter-spacing: 0.0075em;
		}
		.providers-container {
			margin-top: spacing.$spacing-x;
			.providers-count {
				margin-left: auto;
				font-size: fonts.$font-size-smaller;
				margin-bottom: spacing.$spacing-3_5x;
			}
			.provider-section {
				.category-title {
					padding: spacing.$spacing-x spacing.$spacing-2x;
					margin-bottom: spacing.$spacing-2x;
					.title {
						font-size: fonts.$font-size-h3;
						line-height: 43.58px;
						letter-spacing: 0.0075em;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.brands-container {
					column-gap: spacing.$spacing-2x;
					.group {
						-webkit-column-count: 3;
						column-count: 3;
						.brand {
							max-width: 219px;
							min-width: 219px;
							.text {
								font-size: fonts.$font-size-big;
								line-height: 25.6px;
								letter-spacing: 0.0075em;
							}
						}
					}
				}
			}
		}
	}
}

// LARGE MOBILE
@media (min-width: 700px) and (max-width: breakpoints.$mobile-breakpoint) {
	.list-providers-container {
		.providers-container {
			.provider-section {
				.brands-container {
					justify-content: space-between !important;
					.group {
						column-count: 2;
						.brand {
							max-width: 226px !important;
							min-width: 226px !important;
						}
					}
				}
			}
		}
	}
}

// MOBILE
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.list-providers-container {
		padding: spacing.$spacing-3x spacing.$spacing-2x;
		.section-title {
			font-size: fonts.$font-size-base;
			line-height: 22px;
			letter-spacing: 0.0075em;
		}
		.providers-container {
			margin-top: spacing.$spacing-x;
			.providers-count {
				margin-left: auto;
				font-size: fonts.$font-size-smaller;
			}
			.provider-section {
				.category-title {
					padding: spacing.$spacing-x spacing.$spacing-2x;
					margin-bottom: spacing.$spacing-2x;
					.title {
						font-size: fonts.$font-size-h4;
						line-height: 32.68px;
						letter-spacing: 0.0075em;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.brands-container {
					max-width: 100%;
					margin-bottom: spacing.$spacing-3x;
					column-gap: spacing.$spacing-2x;
					.group {
						column-count: 2;
						.brand {
							max-width: 135px;
							min-width: 135px;
							.text {
								font-size: fonts.$font-size-big;
								line-height: 25.6px;
								letter-spacing: 0.0075em;
							}
						}
					}
				}
			}
		}
	}
}
