@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.badges {
	padding: 0;
	display: flex;
	gap: 8px;
	margin-bottom: spacing.$spacing-3x;
	& .nexty-badge {
		margin: auto 0px auto 0px;
		& img {
			width: 163px;
			height: auto;
		}
	}
	& .market-ready {
		margin: auto 0px auto 0px;
		& img {
			width: 150px;
			height: auto;
		}
	}
}
.badges-both {
	padding: 0;
	display: flex;
	gap: 8px;
	margin-bottom: spacing.$spacing-3x;
	& .market-ready {
		margin: auto 0px auto 0px;
		& img {
			width: 150px;
			height: auto;
		}
	}
	& .nexty-badge {
		margin: auto 0px auto 0px;
		& img {
			width: 163px;
			height: auto;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.badges {
		& .market-ready {
			& img {
				width: 107px;
				height: auto;
			}
		}
		& .nexty-badge {
			& img {
				width: 79px;
				height: auto;
			}
		}
	}
	.badges-both {
		& .market-ready {
			& img {
				width: 100%;
				height: 48px;
			}
		}
		& .nexty-badge {
			& img {
				width: 100%;
				height: 63px;
			}
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.badges-both {
		& .market-ready {
			margin: auto auto auto 0px;
			& img {
				width: 100%;
				height: 48px;
			}
		}
		& .nexty-badge {
			margin: auto 0px auto auto;
			& img {
				width: 100%;
				height: 63px;
			}
		}
	}
	.badges {
		& .market-ready {
			margin: auto auto auto auto;
			& img {
				width: 100%;
				height: 48px;
			}
		}
		& .nexty-badge {
			margin: auto auto auto auto;
			& img {
				width: 100%;
				height: 63px;
			}
		}
	}
}
