@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';

.unsubscribe-container {
	padding: spacing.$spacing-10x spacing.$spacing-10x 0px spacing.$spacing-10x;
	height: auto;

	> .head-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		> .title-unsubscribe {
			font-family: fonts.$secondary-font-family;
			font-size: fonts.$font-size-h3;
			font-weight: fonts.$font-weight-bold;
			line-height: 43px;
			letter-spacing: 0.0075em;
			color: colors.$color-primary;
			margin-top: spacing.$spacing-5x;
			margin-bottom: 0px;
			text-align: center;
		}

		> img {
			width: 100%;
			max-width: 842px;
		}
	}

	> .options-container {
		margin-top: spacing.$spacing-7x;

		> .subheader-text {
			font-family: fonts.$secondary-font-family;
			font-size: fonts.$font-size-h5;
			font-weight: fonts.$font-weight-bold;
			line-height: 27px;
			letter-spacing: 0.0075em;
			margin-bottom: spacing.$spacing-3x;
		}

		> .other-container {
			display: flex;
			align-items: center;

			> .radio-container {
				margin-bottom: 0px;
			}

			> .form-control {
				margin-left: spacing.$spacing-3x;
				width: fit-content;
				min-width: 365px;
			}
		}

		.radio-container {
			display: block;
			position: relative;
			padding-left: spacing.$spacing-4x;
			margin-bottom: spacing.$spacing-3x;
			cursor: pointer;
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-bigger;
			font-weight: fonts.$font-weight-regular;
			line-height: 28px;
			letter-spacing: 0.0075em;
			text-align: left;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				height: 0;
				width: 0;

				&:checked {
					~ .checkmark:after {
						background: colors.$color-primary;
					}
				}
			}

			.checkmark {
				position: absolute;
				top: spacing.$spacing-half-x;
				left: 0;
				height: 20px;
				width: 20px;
				background-color: colors.$color-primary;
				border-radius: 50%;

				&:after {
					display: block;
					content: '';
					position: absolute;
					top: spacing.$spacing-half-x;
					left: spacing.$spacing-half-x;
					width: spacing.$spacing-1_5x;
					height: spacing.$spacing-1_5x;
					border-radius: 50%;
					background: white;
				}
			}
		}
	}

	> .actions-container {
		display: flex;
		flex-direction: column;
		margin-top: spacing.$spacing-4x;

		> .rounded-button {
			margin: 0;
		}

		> .footer-text {
			margin-top: spacing.$spacing-2x;
			margin-bottom: 0;
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-bigger;
			font-weight: fonts.$font-weight-regular;
			line-height: 28px;
			letter-spacing: 0.0075em;

			a {
				text-decoration: underline;
				color: black;
				cursor: pointer;
			}
		}
	}
}

@media (min-width: 743px) and (max-width: breakpoints.$desktop-breakpoint) {
	.unsubscribe-container {
		padding: spacing.$spacing-5x spacing.$spacing-3x 0px spacing.$spacing-3x;

		> .head-container {
			> .title-unsubscribe {
				font-size: fonts.$font-size-h4;
				line-height: fonts.$font-size-h3;
			}

			> img {
				max-width: 568px;
			}
		}

		> .options-container {
			margin-top: spacing.$spacing-4x;

			> .subheader-text {
				font-size: fonts.$font-size-h6;
				line-height: 24px;
			}

			.radio-container {
				font-size: fonts.$font-size-big;
				line-height: 22px;
				.checkmark {
					top: 2px;
				}
			}
		}

		> .actions-container {
			> .footer-text {
				font-size: fonts.$font-size-big;
				line-height: 25px;
			}
		}
	}
}

@media (min-width: 525px) and (max-width: breakpoints.$mobile-breakpoint) {
	.unsubscribe-container {
		padding: spacing.$spacing-5x spacing.$spacing-2x 0px spacing.$spacing-2x;

		> .head-container {
			> .title-unsubscribe {
				font-size: fonts.$font-size-bigger;
				line-height: fonts.$font-size-h4;
			}

			> img {
				max-width: 458px;
			}
		}

		> .options-container {
			margin-top: spacing.$spacing-3x;

			> .subheader-text {
				font-size: fonts.$font-size-big;
				line-height: 22px;
			}

			.radio-container {
				font-size: fonts.$font-size-base;
				line-height: 22px;
				.checkmark {
					top: 2px;
				}
			}
		}

		> .actions-container {
			margin-top: spacing.$spacing-3x;
			> .footer-text {
				font-size: fonts.$font-size-base;
				line-height: 22px;
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 524px) {
	.unsubscribe-container {
		padding: spacing.$spacing-5x spacing.$spacing-2x 0px spacing.$spacing-2x;

		> .head-container {
			> .title-unsubscribe {
				font-size: fonts.$font-size-bigger;
				line-height: fonts.$font-size-h4;
			}

			> img {
				max-width: 288px;
			}
		}

		> .options-container {
			margin-top: spacing.$spacing-3x;

			> .subheader-text {
				font-size: fonts.$font-size-big;
				line-height: 22px;
			}

			.radio-container {
				font-size: fonts.$font-size-base;
				line-height: 22px;
				margin-bottom: spacing.$spacing-2x;
				.checkmark {
					top: 2px;
				}
			}

			> .other-container {
				display: flex;
				align-items: start;
				flex-direction: column;

				> .form-control {
					margin-top: spacing.$spacing-x;
					margin-left: 0;
					width: fit-content;
					min-width: 100%;
				}
			}
		}

		> .actions-container {
			margin-top: spacing.$spacing-3x;
			> .footer-text {
				font-size: fonts.$font-size-base;
				line-height: 22px;
			}
		}
	}
}
