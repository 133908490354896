@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.certifications-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 24px auto auto auto;
	& .top-separator {
		width: 100%;
		height: 1px;
		background-color: colors.$color-background-gray;
		box-shadow: shadows.$shadow-lower;
	}
	& .bottom-separator {
		width: 100%;
		height: 1px;
		background-color: colors.$color-background-gray;
		box-shadow: shadows.$shadow-lower;
		margin-top: 56px;
	}
	& .certifications-header {
		margin-top: 72px;
		& > h5 {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h5;
			line-height: 27px;
			color: colors.$color-text-black;
			letter-spacing: fonts.$letter-spacing-std;
			order: 0;
			flex-grow: 0;
		}
	}
	& .line {
		margin-top: 8px;
		width: 188px !important;
		height: 0;
		border-radius: border-radius.$radius-default;
		border: 2px solid colors.$color-transparent;
		background: colors.$gradient-beacon border-box;
	}
	& .certifications-card-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		width: 100%;
		margin-top: 32px;
		& > div {
			height: 316px;
			width: 85vw;
			margin-top: 0px;
		}
		& .certification-card {
			width: 280px;
			height: 254px;
			display: flex;
			flex-direction: column;
			border: 2px solid colors.$color-secondary-light;
			align-items: center;
			gap: 16px;
			isolation: isolate;
			box-shadow: shadows.$shadow-medium;
			border-radius: border-radius.$radius-medium;
			&.front-view {
				background: colors.$color-secondary-lightest;
				justify-content: center;
				cursor: pointer;
			}
			&.back-view {
				background: colors.$color-background-white;
				justify-content: center;
				cursor: pointer;
			}
			& .certification-front {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 100%;
				margin-top: 15px;
				& .certification-name {
					margin: 16px auto 0px auto;
					font-family: fonts.$tertiary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-bold;
					font-size: fonts.$font-size-base;
					line-height: 22px;
					display: flex;
					align-items: center;
					text-align: center;
					color: colors.$color-text-black;
					letter-spacing: fonts.$letter-spacing-std;
				}
				& .certification-image-container {
					& > img {
						height: 100px;
						width: fit-content;
					}
				}
			}
			& .certification-footer {
				display: flex;
				justify-content: end;
				align-items: flex-end;
				width: 100%;
				margin-top: 10.5px;
				& .certification-more-info {
					font-family: fonts.$primary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-regular;
					font-size: fonts.$font-size-base;
					line-height: 22px;
					text-align: center;
					letter-spacing: fonts.$letter-spacing-std;
					color: colors.$color-gray-slate;
					margin: auto 38px 0px auto;
					cursor: pointer;
					position: absolute;
					top: 86%;
					width: fit-content;
					&:hover {
						border-bottom: 1px solid colors.$color-gray-slate;
					}
					& i {
						font-size: 18px;
						width: 18px;
						height: 18px;
						position: absolute;
						margin: 1px 18px 0px 4px;
					}
				}
			}
			& .certification-back {
				display: flex;
				flex-direction: column;
				width: 100%;
				padding: 0px 16px 0px 16px;
				& .certification-name {
					font-family: fonts.$secondary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-bold;
					font-size: fonts.$font-size-base;
					line-height: 22px;
					letter-spacing: fonts.$letter-spacing-std;
					color: colors.$color-text-black;
				}
				& .certification-description {
					font-family: fonts.$primary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-regular;
					font-size: fonts.$font-size-small;
					line-height: 19px;
					letter-spacing: fonts.$letter-spacing-std;
					color: colors.$color-text-black;
					margin: 4px 0px 8px 0px;
				}
				& .certifiers-container {
					display: flex;
					flex-direction: column;
					& .certifiers-title {
						font-family: fonts.$secondary-font-family;
						font-style: normal;
						font-weight: fonts.$font-weight-bold;
						font-size: fonts.$font-size-small;
						line-height: 19px;
						letter-spacing: fonts.$letter-spacing-std;
						color: colors.$color-text-black;
						&::after {
							content: ':';
							font-family: fonts.$secondary-font-family;
							font-style: normal;
							font-weight: fonts.$font-weight-bold;
							font-size: fonts.$font-size-small;
							line-height: 19px;
							letter-spacing: fonts.$letter-spacing-std;
							color: colors.$color-text-black;
							margin-left: 0.5px;
						}
					}
					& .certifiers-list {
						display: flex;
						flex-direction: column;
						margin-top: 2%;
						& .certifier-item {
							font-family: fonts.$primary-font-family;
							font-style: normal;
							font-weight: fonts.$font-weight-regular;
							font-size: fonts.$font-size-small;
							line-height: 19px;
							letter-spacing: fonts.$letter-spacing-std;
							color: colors.$color-text-black;
							border-bottom: 1px solid colors.$color-text-black;
							width: fit-content;
							margin-bottom: 4px;
						}
					}
				}
				& a:hover {
					text-decoration: none;
				}
			}
		}
		& .swiper-pagination-bullets {
			display: block;
			bottom: 0px;
			& .swiper-pagination-bullet {
				width: 12px;
				height: 12px;
				background: colors.$color-background-gray;
				opacity: 1;
				&:hover {
					background: colors.$color-gray-medium;
				}
			}

			& .swiper-pagination-bullet-active {
				background: #8d949a;
			}
		}
		& .swiper-button-next {
			display: flex;
			justify-content: center;
			align-items: center;
			background: colors.$color-gray;
			border-radius: 40px;
			width: 26px;
			height: 26px;
			right: 0px;
			top: 56%;
			&::after {
				content: '';
				background-image: url('../../../assets/images/arrow-right-s.svg');
				background-position: center;
				background-size: 24px 24px;
				width: 24px;
				height: 20px;
			}
			&:hover {
				box-shadow: shadows.$shadow-low;
			}
			&:active {
				box-shadow: shadows.$shadow-high-inner;
			}
			&.swiper-button-lock {
				display: none;
			}
		}
		& .swiper-button-prev {
			display: flex;
			justify-content: center;
			align-items: center;
			background: colors.$color-gray;
			border-radius: 40px;
			width: 26px;
			height: 26px;
			left: 0px;
			top: 56%;
			&:hover {
				box-shadow: shadows.$shadow-low;
			}
			&:active {
				box-shadow: shadows.$shadow-high-inner;
			}
			&::after {
				content: '';
				background-image: url('../../../assets/images/arrow-left-s.svg');
				background-position: center;
				background-size: 24px 24px;
				width: 24px;
				height: 20px;
			}
			&.swiper-button-lock {
				display: none;
			}
		}
		& .swiper-slide {
			text-align: unset;
		}
	}
	& .certifications-card-container-no-swiper {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		width: 100%;
		margin-top: 32px;
		& > div {
			width: 280px;
			height: 254px;
			margin-top: 0px;
			&:not(:first-child) {
				margin-left: 24px;
			}
		}
		& .certification-card {
			width: 280px;
			height: 254px;
			display: flex;
			flex-direction: column;
			border: 2px solid colors.$color-secondary-light;
			align-items: center;
			gap: 16px;
			isolation: isolate;
			box-shadow: shadows.$shadow-medium;
			border-radius: border-radius.$radius-medium;
			&.front-view {
				background: colors.$color-secondary-lightest;
				justify-content: center;
				cursor: pointer;
			}
			&.back-view {
				background: colors.$color-background-white;
				justify-content: center;
				cursor: pointer;
			}
			& .certification-front {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 100%;
				margin-top: 15px;
				& .certification-name {
					margin: 16px auto 0px auto;
					font-family: fonts.$tertiary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-bold;
					font-size: fonts.$font-size-base;
					line-height: 22px;
					display: flex;
					align-items: center;
					text-align: center;
					color: colors.$color-text-black;
					letter-spacing: fonts.$letter-spacing-std;
				}
				& .certification-image-container {
					& > img {
						height: 100px;
						min-width: 100%;
					}
				}
			}
			& .certification-footer {
				display: flex;
				justify-content: end;
				align-items: flex-end;
				width: 100%;
				margin-top: 10.5px;
				& .certification-more-info {
					font-family: fonts.$primary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-regular;
					font-size: fonts.$font-size-base;
					line-height: 22px;
					text-align: center;
					letter-spacing: fonts.$letter-spacing-std;
					color: colors.$color-gray-slate;
					margin: auto 38px 0px auto;
					cursor: pointer;
					position: absolute;
					top: 86%;
					width: fit-content;
					&:hover {
						border-bottom: 1px solid colors.$color-gray-slate;
					}
					& i {
						font-size: 18px;
						width: 18px;
						height: 18px;
						position: absolute;
						margin: 1px 18px 0px 4px;
					}
				}
			}
			& .certification-back {
				display: flex;
				flex-direction: column;
				width: 100%;
				padding: 0px 16px 0px 16px;
				& .certification-name {
					font-family: fonts.$secondary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-bold;
					font-size: fonts.$font-size-base;
					line-height: 22px;
					letter-spacing: fonts.$letter-spacing-std;
					color: colors.$color-text-black;
				}
				& .certification-description {
					font-family: fonts.$primary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-regular;
					font-size: fonts.$font-size-small;
					line-height: 19px;
					letter-spacing: fonts.$letter-spacing-std;
					color: colors.$color-text-black;
					margin: 4px 0px 8px 0px;
				}
				& .certifiers-container {
					display: flex;
					flex-direction: column;
					& .certifiers-title {
						font-family: fonts.$secondary-font-family;
						font-style: normal;
						font-weight: fonts.$font-weight-bold;
						font-size: fonts.$font-size-small;
						line-height: 19px;
						letter-spacing: fonts.$letter-spacing-std;
						color: colors.$color-text-black;
						&::after {
							content: ':';
							font-family: fonts.$secondary-font-family;
							font-style: normal;
							font-weight: fonts.$font-weight-bold;
							font-size: fonts.$font-size-small;
							line-height: 19px;
							letter-spacing: fonts.$letter-spacing-std;
							color: colors.$color-text-black;
							margin-left: 0.5px;
						}
					}
					& .certifiers-list {
						display: flex;
						flex-direction: column;
						margin-top: 2%;
						& .certifier-item {
							font-family: fonts.$primary-font-family;
							font-style: normal;
							font-weight: fonts.$font-weight-regular;
							font-size: fonts.$font-size-small;
							line-height: 19px;
							letter-spacing: fonts.$letter-spacing-std;
							color: colors.$color-text-black;
							border-bottom: 1px solid colors.$color-text-black;
							width: fit-content;
							margin-bottom: 4px;
						}
					}
				}
				& a:hover {
					text-decoration: none;
				}
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.certifications-container {
		& .bottom-separator {
			margin-top: 24px;
		}
		& .certifications-header {
			& > h5 {
				font-size: fonts.$font-size-bigger-mobile;
			}
		}
	}
	.certifications-container {
		& .certifications-card-container {
			& > div {
				width: 290px;
			}
			& .certification-card {
				& .certification-back {
					& .certification-name {
						font-size: fonts.$font-size-small;
						line-height: 19px;
						display: flex;
						align-items: flex-end;
					}
					& .certification-description {
						font-size: fonts.$font-size-small;
						line-height: 19px;
						display: flex;
						justify-content: start;
						text-align: start;
					}
					& .certifiers-container {
						& .certifiers-title {
							display: flex;
							align-items: flex-end;
						}
					}
				}
			}
			& .swiper-pagination-bullets {
				width: 80%;
				margin-left: 10%;
			}
			& .swiper-button-next {
				top: 343px;
				background-color: unset;
				&:active {
					box-shadow: none;
				}
				&:hover {
					box-shadow: none;
				}
			}
			& .swiper-button-prev {
				top: 343px;
				background-color: unset;
				&:active {
					box-shadow: none;
				}
				&:hover {
					box-shadow: none;
				}
			}
		}
	}
	.certifications {
		margin: 0px !important;
		width: 100% !important;
		padding: 0 !important;
		.cert-title {
			display: none !important;
		}
		& .cert-title {
			margin-left: 3.5% !important;
		}
		.cert-title-mob {
			margin: 0 spacing.$spacing-2x !important;
			display: block !important;
			font-family: fonts.$secondary-font-family !important;
			font-weight: fonts.$font-weight-bold !important;
			font-size: fonts.$font-size-bigger-mobile !important;
			line-height: 34px !important;
			letter-spacing: 0.4px !important;
			text-transform: uppercase !important;
			color: #212529 !important;
		}
		.product-certifications {
			.certification-container {
				border-top: 1px solid rgb(185, 181, 181);
				padding: 0 spacing.$spacing-2x !important;
				.cert-img {
					width: 48px !important;
					height: 48px !important;
					margin-top: spacing.$spacing-2x !important;
					margin-bottom: spacing.$spacing-2x !important;
				}
				.cert-content {
					height: 100% !important;
					.name {
						font-family: fonts.$tertiary-font-family !important;
						font-size: fonts.$font-size-big-mobile !important;
						color: #212529 !important;
						padding: 5px !important;
						margin-left: spacing.$spacing-2x !important;
						line-height: 19px !important;
						margin-top: 25px !important;
					}
					.cert-toggle {
						top: -10px !important;
						width: 30px !important;
						height: 30px !important;
						margin-bottom: 5px !important;
						margin-top: 35px !important;
					}
					.active {
						font-family: fonts.$primary-font-family;
						font-style: normal;
						font-weight: fonts.$font-weight-regular;
						font-size: fonts.$font-size-base-mobile;
						line-height: 20px;
						letter-spacing: 0.25px;
						color: colors.$color-text-black;
						height: fit-content !important;
						margin-left: -40px !important;
						margin-top: 21px !important;
						margin-bottom: spacing.$spacing-2x !important;
					}
					.description {
						& .learn-more {
							margin-top: spacing.$spacing-2x !important;
							margin-bottom: spacing.$spacing-2x !important;
						}
					}
				}
			}
		}
	}
	.certifications-container .certifications-card-container .swiper-pagination-bullets {
		bottom: -30px;
		& .swiper-pagination-bullet {
			width: 8px;
			height: 8px;
		}
	}
	.certifications-container .certifications-card-container {
		position: relative;
		margin: spacing.$spacing-4x 0;
	}
	.certifications-container .certifications-card-container .swiper {
		position: static;
	}
	.certifications-header {
		padding: 0 spacing.$spacing-2x;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.certifications-container {
		padding: 0 16px;
		& .certifications-card-container {
			position: relative;
			margin: 32px 0;
			& > div {
				width: 590px;
			}
			& .certifications-swiper {
				& .swiper-wrapper {
					justify-content: center;
				}
			}

			& .swiper-pagination-bullets {
				display: block;
				bottom: -30px;
			}
			& .swiper {
				position: static;
			}
			& .swiper-pagination-bullet-active {
				background: colors.$color-gray-slate-light;
			}
		}
		& .certifications-header {
			& > h5 {
				font-size: fonts.$font-size-bigger-mobile;
			}
		}
		& .certification-card {
			& .certification-back {
				& .certification-description {
					text-align: left;
				}
				& .certifiers-title {
					text-align: left;
				}
			}
		}
	}
}
