@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
.welcome-container {
	display: flex;
	width: 100%;
	& .welcome {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 171px auto auto 11.5%;
		flex: 2.3;
		& .welcome-title {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h3;
			line-height: 44px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
			display: flex;
			width: 100%;
			justify-content: start;
			text-align: start;
		}
		& .meet-standars {
			display: flex;
			width: 100%;
			justify-content: start;
			text-align: start;
			margin: 55px auto auto auto;
			font-family: fonts.$tertiary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-big;
			line-height: 26px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
		}
		& .standars-explanation {
			display: flex;
			width: 100%;
			justify-content: start;
			text-align: start;
			margin: 37px auto auto auto;
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-big;
			line-height: 26px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
		}
	}
	& .welcome-img-container {
		width: 418px;
		height: 484px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		flex: 2;
		margin-top: 86px;
		& .step-text {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-base;
			line-height: 22px;
			text-align: right;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-gray-slate-light;
			margin: 55px 29% 94px auto;
		}
		& .img-container {
			margin: 0px 86px auto 70px;
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.welcome-container {
		flex-direction: column;
		& .welcome {
			flex-direction: column;
			margin: 40px auto auto auto;
			justify-content: center;
			text-align: center;
			align-items: center;
			& .welcome-title {
				text-align: start;
				align-items: start;
				font-size: fonts.$font-size-bigger;
				line-height: 25px;
			}
			& .meet-standars {
				margin: 24px auto auto auto;
				text-align: justify;
				align-items: center;
				font-size: fonts.$font-size-base-mobile;
				line-height: 20px;
			}
			& .standars-explanation {
				font-size: fonts.$font-size-base-mobile;
				line-height: 20px;
				margin: 25px auto 40px auto;
				text-align: justify;
			}
		}
		& .welcome-img-container {
			flex-direction: column;
			margin: auto;
			justify-content: center;
			align-items: center;
			text-align: center;
			width: fit-content;
			& .img-container {
				& > img {
					width: 220px;
					height: 255px;
				}
				margin: auto;
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.welcome-container {
		margin-top: 46px;
		& .welcome {
			margin: 45px auto auto 0px;
			& .meet-standars {
				margin-top: 21px;
			}
			& .standars-explanation {
				margin: 16px auto auto auto;
			}
		}
		& .welcome-img-container {
			margin-top: -30px;
			margin-left: 40px;
			& .img-container {
				& > img {
					width: 334px;
					height: 385px;
				}
				margin: 0px;
			}
			& .step-text {
				margin: 0px 28% 32px auto;
			}
		}
	}
}
@media (min-width: 1700px) {
	.welcome-container {
		& .welcome-img-container {
			& .step-text {
				margin: 55px 32% 94px auto;
			}
		}
	}
}
