@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.category-filter-container {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-left: spacing.$spacing-x;
	&.current-category-case {
		margin-left: 0px;
	}
	& .category-elements-container {
		display: flex;
		flex-direction: column;
		gap: 8px;
		& .category-element-container {
			display: flex;
			align-items: center;
			cursor: pointer;
			gap: 4px;
			& .filter-name {
				color: colors.$color-text-black;
				font-family: fonts.$tertiary-font-family;
				font-size: fonts.$font-size-base;
				font-style: normal;
				font-weight: fonts.$font-weight-semibold;
				line-height: 22.4px;
				letter-spacing: 0.105px;
			}
			& > i {
				font-size: fonts.$font-size-h5;
				color: colors.$color-gray-slate;
			}
			&:hover {
				& .filter-name {
					text-decoration: underline;
				}
				& > i {
					transform: translateX(4px);
				}
			}
		}
	}
	& .selected-category-filter-container {
		display: flex;
		flex-direction: column;
		& .current-category-container {
			& .drop-element-container {
				display: flex;
				flex-direction: column;
				margin-left: spacing.$spacing-2x;
				margin-top: spacing.$spacing-half-x;
				gap: 4px;
				& .drop-menu-item:not(:has(+ .check-box-children)),
				& .drop-menu-item:not(:has(~ .check-box-children)) {
					margin-left: -16px;
				}
				& .drop-menu-item:has(+ .check-box-children),
				& .drop-menu-item:has(~ .check-box-children) {
					& > input[type='checkbox'] {
						+ label {
							font-family: fonts.$tertiary-font-family;
							font-weight: fonts.$font-weight-semibold;
							&::before {
								top: 0px;
								margin-right: spacing.$spacing-half-x;
							}
						}
					}
				}
			}
			& .selected-element-container {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 4px;
				margin-top: spacing.$spacing-x;
				margin-left: 7px;
				& > i {
					color: colors.$color-gray-slate;
					font-size: fonts.$font-size-h5;
				}
				& .current-category {
					color: colors.$color-text-black;
					font-family: fonts.$secondary-font-family;
					font-size: fonts.$font-size-base;
					font-style: normal;
					font-weight: fonts.$font-weight-bold;
					line-height: 22.4px;
					letter-spacing: 0.105px;
					text-decoration: underline;
				}
			}
		}
		& .selected-filter-items {
			display: flex;
			flex-direction: column;
			gap: 8px;
			margin-top: spacing.$spacing-half-x;
			margin-left: spacing.$spacing-1_25x;
			& .category-element-container {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 4px;
				& .filter-name {
					color: colors.$color-text-black;
					font-family: fonts.$tertiary-font-family;
					font-size: fonts.$font-size-base;
					font-style: normal;
					font-weight: fonts.$font-weight-semibold;
					line-height: 22.4px;
					letter-spacing: 0.105px;
				}
				& > i {
					font-size: fonts.$font-size-h5;
					color: colors.$color-gray-slate;
				}
				&:hover {
					& .filter-name {
						text-decoration: underline;
					}
					& > i {
						transform: translateX(4px);
					}
				}
			}
			& > div:nth-child(9) {
				display: flex;
				align-items: center;
				& > span {
					width: 69%;
				}
			}
		}
	}
}
@media (max-width: breakpoints.$tablet-breakpoint) {
	.category-filter-container {
		& .selected-category-filter-container {
			& .selected-filter-items {
				& > div:nth-child(9) {
					& > span {
						width: fit-content;
					}
				}
			}
		}
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.category-filter-container {
		margin-top: -18px;
		margin-bottom: spacing.$spacing-x;
		& .selected-category-filter-container {
			& .selected-filter-items {
				& > div:nth-child(9) {
					& > span {
						width: fit-content;
					}
				}
			}
		}
	}
}
