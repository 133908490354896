@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/shadows.scss';
.onboardign-template-container {
	margin: auto auto auto spacing.$spacing-5x;
	& .show-detail-onboarding {
		display: flex;
		width: 100%;
		justify-content: center;
		& .show-detail {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 4px;
			width: 846px;
			padding: spacing.$spacing-x spacing.$spacing-4x;
			border-radius: border-radius.$radius-biggest;
			border: 1px solid colors.$color-gray;
			background: colors.$color-background-white;
			box-shadow: shadows.$shadow-high;
			&:first-child {
				font-family: fonts.$tertiary-font-family;
				font-size: fonts.$font-size-base;
				font-style: normal;
				font-weight: fonts.$font-weight-semibold;
				line-height: 26px;
				letter-spacing: fonts.$letter-spacing-std;
				color: colors.$color-gray-slate;
			}
		}
	}
	& .onboarding-template-comparison-list {
		margin-top: spacing.$spacing-5x;
		& .button-panel-onboarding {
			display: flex;
			align-items: center;
			& :first-child {
				margin-left: 0px;
			}
			& .separator {
				margin: auto spacing.$spacing-2x auto spacing.$spacing-2x;
				width: 1px;
				height: 27px;
				border: 1px solid colors.$color-gray;
			}
		}
	}
	& .separator-container {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: spacing.$spacing-3x;
		& .separator {
			width: 100%;
			border: 1px solid rgba(0, 0, 0, 0.2);
			height: 1px;
		}
	}
}
@media (min-width: 0) and (max-width: breakpoints.$mobile-breakpoint) {
	.onboardign-template-container {
		margin: auto;
		& .show-detail-onboarding {
			margin-top: spacing.$spacing-3x;
			& .show-detail {
				&:first-child {
					font-size: fonts.$font-size-base-mobile;
					line-height: 26px;
				}
				& > img {
					width: 8px;
					height: 8px;
				}
			}
		}
		& .detail-slider {
			margin-top: spacing.$spacing-2x;
			& .swiper {
				& > div {
					&.swiper-wrapper {
						margin-left: -24px;
						& > div {
							background-color: transparent;
							&:nth-child(2) {
								margin-left: -11px;
							}
							&:nth-child(3) {
								width: fit-content;
								margin-left: 2px;
							}
							width: 205px;
						}
					}
				}
			}
		}
		& .table-container {
			overflow: scroll;
			.blue,
			.green,
			.red {
				font-size: fonts.$font-size-base-mobile;
				line-height: 20px;
				letter-spacing: 0.09px;
			}
			& .table {
				& td {
					font-size: fonts.$font-size-base-mobile;
					line-height: 20px;
					letter-spacing: 0.09px;
					overflow: scroll;
				}
			}
			&.blue {
				& .table-striped {
					& tbody {
						& tr:nth-of-type(even) {
							background-color: colors.$table-stripe-background;
						}
						& tr:nth-of-type(odd) {
							background-color: colors.$color-background-white;
						}
					}
				}
			}
			&.green {
				& .table-striped {
					& tbody {
						& tr:nth-of-type(even) {
							background-color: colors.$color-second-tier-light;
						}
						& tr:nth-of-type(odd) {
							background-color: colors.$color-background-white;
						}
					}
				}
			}
			&.yellow {
				& .table-striped {
					& tbody {
						& tr:nth-of-type(even) {
							background-color: colors.$color-third-tier-light;
						}
						& tr:nth-of-type(odd) {
							background-color: colors.$color-background-white;
						}
					}
				}
			}
		}
		& .banned-ingredients {
			& .banned-ingredients-item {
				& .banned-ingredients-item-title {
					font-size: fonts.$font-size-base-mobile;
					line-height: 20px;
					letter-spacing: 0.09px;
				}
				& .banned-list-profile {
					& .banned-ingredient-item {
						font-size: fonts.$font-size-base-mobile;
						line-height: 20px;
						letter-spacing: 0.09px;
					}
				}
			}
		}
		& .view-template-comparison-profile {
			& .show-specific-profile {
				& .check-label {
					font-size: fonts.$font-size-base-mobile;
					line-height: 20px;
					letter-spacing: 0.09px;
				}
				& input[type='checkbox'] + label::before {
					width: 8px;
					height: 8px;
				}
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.onboardign-template-container {
		margin: auto auto auto auto;
		& .onboarding-template-comparison-list {
			& .button-panel-onboarding {
				gap: 8px;
				width: 100%;
				& > div {
					& .rounded-button {
						width: 100%;
					}
					& .square-button {
						margin-left: -8px;
					}
				}
			}
		}
		& .show-detail-onboarding {
			& .show-detail {
				width: 588px;
			}
		}
	}
}
