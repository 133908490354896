@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.filter-menu {
	width: 278px;
	height: 100%;
	margin-right: 35px;
}
.tablet-container {
	position: absolute;
	z-index: 1000;
	background: colors.$color-background-white;
	left: 0px;
	top: 89px;
	width: 320px;
	overflow-x: hidden;
	height: 100%;
	box-shadow: shadows.$shadow-medium;
	border-radius: 0px 16px 0px 0px;
	padding: 26px 16px 0px 16px;
	max-height: 88vh;
	overflow-y: scroll;
	-moz-animation: leftToRigth 0.5s ease-out;
	-webkit-animation: leftToRigth 0.5s ease-out;
	animation: leftToRigth 0.5s ease-out;
	&::-webkit-scrollbar {
		background: rgba(0, 0, 0, 0.1);
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.3);
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.filter-menu {
		background-color: colors.$color-background-white;
		height: auto;
		width: 100%;
		margin-top: spacing.$spacing-x;
		padding-bottom: 13px;
		-moz-animation: leftToRigth 0.5s ease-in-out;
		-webkit-animation: leftToRigth 0.5s ease-in-out;
		animation: leftToRigth 0.5s ease-in-out;
	}
}
