@use '../../styles/tokens/spacing.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/breakpoints.scss';
.lock-overlay-container {
	width: 100%;
	height: 373px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-radius: border-radius.$radius-medium;

	& .title-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 78px;
		background: colors.$gradient-blue;
		& .lock-title {
			font-family: fonts.$secondary-font-family;
			font-size: fonts.$font-size-h5;
			color: colors.$color-text-white;
			font-style: normal;
			line-height: normal;
			font-weight: fonts.$font-weight-bold;
			letter-spacing: 0.15px;
		}
	}
	& .body-container {
		display: flex;
		flex-direction: column;
		background: colors.$color-background-white;
		height: 295px;
		& .img-container {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: spacing.$spacing-3x;
		}
		& .description-container {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			width: 100%;
			height: 92px;
			margin: spacing.$spacing-3x auto 0 auto;
			padding: 0px 30px;
			& > i {
				color: colors.$color-primary;
				font-size: fonts.$font-size-h4;
			}
			& .description-content {
				width: 519px;
				& .description {
					color: colors.$color-text-black;
					font-family: fonts.$primary-font-family;
					font-size: fonts.$font-size-h5;
					font-style: normal;
					font-weight: fonts.$font-weight-regular;
					line-height: 32px;
					letter-spacing: 0.25px;
				}
			}
		}
		& .back-pattern {
			margin-top: -93px;
			width: 327px;
			height: 127px;
		}
		& .buttons-container {
			display: flex;
			width: 234px;
			gap: 8px;
			justify-content: flex-end;
			margin: spacing.$spacing-4x spacing.$spacing-4x 0px auto;
			& .rounded-button {
				margin: 0;
			}
		}
	}
}
.lock-popup-close {
	top: 40px !important; /*Use important to override library styles*/
	color: colors.$color-white;
	font-size: fonts.$font-size-h4;
	cursor: pointer;
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.lock-popup-close {
		top: 177px !important; /*Use important to override library styles*/
		color: colors.$color-white;
		font-size: fonts.$font-size-h4;
		cursor: pointer;
	}
	.lock-overlay-container {
		height: 384px;
		margin-top: 142px;
		& .title-container {
			height: 64px;
			& .lock-title {
				font-size: fonts.$font-size-big-mobile;
				line-height: 22.4px;
				letter-spacing: 0.105px;
			}
		}
		& .body-container {
			height: 289px;
			border-radius: 0 0 border-radius.$radius-medium border-radius.$radius-medium;
			& .description-container {
				& .description-content {
					& .description {
						font-size: fonts.$font-size-base-mobile;
						line-height: 19.2px;
					}
				}
			}
			& .back-pattern {
				display: none;
			}
		}
	}
	@media (max-width: 382px) {
		.lock-overlay-container {
			& .body-container {
				& .description-container {
					padding: 0px 15px;
				}
				& .img-container {
					& > img {
						height: 38px;
						width: 80%;
					}
				}
			}
		}
	}
}
