@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.template-cards {
	width: 100%;
	margin: 48px auto 40px auto;
	& :not(:first-child) {
		margin-left: 1.5%;
	}
}
.ingredients-loading {
	display: flex;
	justify-content: center;
	align-items: center;
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.template-cards {
		margin-left: 0px;
	}
}
@media (min-width: 795px) and (max-width: breakpoints.$tablet-breakpoint) {
	.template-cards {
		margin: spacing.$spacing-4x auto spacing.$spacing-4x 0px;
		width: 95%;
		& :not(:first-child) {
			margin-left: 0px;
			margin-top: -7px;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: 795px) {
	.template-cards {
		margin: spacing.$spacing-4x auto spacing.$spacing-4x 0px;
		width: 98%;
		& :not(:first-child) {
			margin-left: 0px;
			margin-top: -7px;
		}
	}
}
