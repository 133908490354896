@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';

.toast-body * {
	color: colors.$color-black;
	&.toast-title {
		font-family: fonts.$secondary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-h5;
		line-height: 27px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-black;
	}
	&.toast-text {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-base;
		line-height: 22px;
		letter-spacing: fonts.$letter-spacing-std;
	}
	&.toast-actions {
		display: flex;
		align-items: center;
		justify-content: start;
		width: 100%;
	}
}
.toast-success {
	background: colors.$color-success-light !important;
	& .Toastify__progress-bar--animated {
		background-color: colors.$color-success !important;
	}
}
.toast-info {
	background: colors.$color-info-light !important;
	& .Toastify__progress-bar--animated {
		background-color: colors.$color-info !important;
	}
}
.toast-warning {
	background: colors.$color-warning-light !important;
	& .Toastify__progress-bar--animated {
		background-color: colors.$color-warning !important;
	}
}
.toast-error {
	background: colors.$color-error-light !important;
	& .Toastify__progress-bar--animated {
		background-color: colors.$color-error !important;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.toast-body * {
		&.toast-title {
			font-size: fonts.$font-size-bigger;
			line-height: 25px;
		}
		&.toast-text {
			font-size: fonts.$font-size-small;
			line-height: 19px;
		}
	}
}
