@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/opacity.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/shadows.scss';

.menu {
	display: flex;
	flex-direction: column;
	width: 252px;
	position: relative;
	gap: 8px;
	padding: 0px;

	> :first-child {
		border-radius: 0px 15px 0px 0px;
	}
	> :last-child {
		border-radius: 0px 0px 15px 0px;
	}
	.tab-container.title-submenu {
		height: 53px;
	}
	.tab-container.title-submenu.active {
		height: auto;
		.submenu.show {
			position: relative;
		}
	}
	.tab-container.active {
		border-bottom: 2px solid;
		border-color: colors.$color-primary;
		box-shadow: shadows.$shadow-medium;
		.tab-text {
			color: colors.$color-primary;
			font-family: fonts.$tertiary-font-family;
			display: flex;
			align-items: center;
		}
	}
	.tab-container {
		cursor: pointer;
		background-color: colors.$color-white;
		padding: 12px 12px 12px 12px;
		margin: 0px;
		display: flex;
		flex-direction: column;
		.tab-text {
			font-weight: 400;
			font-size: 18px;
			line-height: 28.8px;
			color: colors.$color-gray-slate;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		&:not(.active) {
			&:hover {
				background: colors.$color-gray-light;
				.tab-text {
					color: colors.$color-primary;
				}
			}
		}
		i {
			margin-left: spacing.$spacing-x;
			font-size: fonts.$font-size-h4;
		}
	}
	.submenu {
		display: none;
	}
	.submenu.show {
		display: block;
		width: 272px;
		position: relative;
		left: -10px;
		background-color: colors.$color-background-light-gray;
		top: 0px;
		.menu {
			gap: 2px;
			margin: 0px;
			.tab-container {
				cursor: pointer;
				position: relative;
				left: 10px;
				border-radius: 0px;
				background-color: colors.$color-background-gray-medium;
				.tab-text {
					color: colors.$color-gray-slate;
				}
				&:not(.active-sub) {
					&:hover {
						background: colors.$color-gray-light;
						.tab-text {
							color: colors.$color-primary;
						}
					}
				}
			}
			.tab-container.active-sub {
				font-family: fonts.$tertiary-font-family;
				font-weight: fonts.$font-weight-semibold;
				background-color: colors.$color-background-gray;
				.tab-text {
					color: colors.$color-text-gray-darkest;
				}
			}
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.menu {
		display: flex;
		flex-direction: column;
		gap: 8px;
		top: 30px;
		position: relative;

		.tab-container {
			padding: 12px;
			margin: 0px;
		}
		.tab-container.active {
			box-shadow: shadows.$shadow-medium;
		}

		.submenu {
			background-color: colors.$color-white !important;
			.menu {
				top: 0px;
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.menu {
		display: flex;
		flex-direction: column;
		gap: 4px;
		top: 0px;
		position: relative;
		width: 252px;
		> :first-child {
			border-radius: 0px 15px 0px 0px;
		}
		> :last-child {
			border-radius: 0px 0px 15px 0px;
		}
		.tab-container {
			background-color: colors.$color-background-white;
			padding: 12px;
			margin: 0px;
		}
	}
}
