@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/breakpoints.scss';

.secondary-label-form {
	font-family: fonts.$secondary-font-family;
	font-weight: fonts.$font-weight-bold;
	font-size: fonts.$font-size-bigger;
	margin: 0px;
	line-height: 28px;
	color: colors.$color-gray-slate;
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.secondary-label-form {
		font-size: fonts.$font-size-big;
		margin: 0px;
		line-height: 25.6px;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.secondary-label-form {
		font-size: fonts.$font-size-big;
		margin: 0px;
		line-height: 25.6px;
	}
}
