@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/breakpoints.scss';
.header-container {
	display: flex;
	align-items: center;
	gap: 16px;
	background-color: colors.$color-secondary-lightest;
	height: auto;
	padding: spacing.$spacing-x spacing.$spacing-half-x spacing.$spacing-x spacing.$spacing-half-x;
	border-radius: border-radius.$radius-default;
	& .icon-container {
		& > i {
			font-size: 40px;
			color: colors.$color-primary-dark;
		}
	}
	& .onboard-content-container {
		width: 93%;
		& .header-title-container {
			& .header-title {
				font-family: fonts.$secondary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-h5;
				line-height: 32px;
				letter-spacing: 0.0075em;
				color: colors.$color-primary;
			}
			& .big-header-title {
				font-family: fonts.$secondary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-h5;
				line-height: 32px;
				letter-spacing: 0.0075em;
				color: colors.$color-primary;
			}
		}
	}
}
@media (max-width: breakpoints.$tablet-breakpoint) {
	.header-container {
		& .onboard-content-container {
			& .header-title-container {
				& .header-title {
					font-size: fonts.$font-size-base;
					line-height: 22.4px;
				}
				& .big-header-title {
					letter-spacing: 0.0075em;
					line-height: 27.24px;
					font-size: fonts.$font-size-h5;
				}
			}
		}
	}
}
@media (max-width: 500px) {
	.header-container {
		gap: 8px;
		& .onboard-content-container {
			width: 80%;
			& .header-title-container {
				& .big-header-title {
					font-size: fonts.$font-size-base;
					line-height: 22.4px;
					letter-spacing: 0.0075em;
				}
			}
		}
	}
}
