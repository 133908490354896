@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/spacing.scss';
.ingredients-template-header {
	margin-top: 64px;
	margin-left: 189px;
	& .title-wrapper {
		display: flex;
		& .personalization-title {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h3;
			line-height: 44px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
		}
		& .step {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-base;
			line-height: 22px;
			text-align: right;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-gray-slate-light;
			margin-left: auto;
			margin-right: 189px;
		}
	}
	& .store-standars {
		display: flex;
		margin-top: 8px;
		& div {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-big;
			line-height: 26px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
			display: inline-block;
		}
		& .template-onboarding-learn-more-container {
			margin-left: 3px;
			& .action-link-contianer {
				display: flex;
				gap: 4px;
				& .action-link {
					font-style: normal;
					width: fit-content;
					letter-spacing: fonts.$letter-spacing-std;
				}
				& > i {
					margin-top: -1px;
					font-size: 22px;
					width: 22px;
					height: 22px;
				}
			}
		}
	}
	& .choose-template {
		margin-top: 24px;
		& span {
			font-family: fonts.$tertiary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-big;
			line-height: 26px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: 1200px) {
	.onboarding-ingredients-template {
		margin: unset;
		& .ingredients-template-header {
			margin-left: 0px;
			& .title-wrapper {
				& .step {
					margin-right: 13.5%;
				}
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.onboarding-ingredients-template {
		margin: unset;
		& .ingredients-template-header {
			margin-left: 0px;
			margin-top: spacing.$spacing-4x;
			& .store-standars {
				display: flex;
				flex-direction: column;
				margin-top: spacing.$spacing-3x;
				width: 100%;
				& > div {
					font-size: fonts.$font-size-base-mobile;
					line-height: 19.2px;
					letter-spacing: 0.09px;
				}
				& .template-onboarding-learn-more-container {
					& .action-link-contianer {
						& > i {
							font-size: fonts.$font-size-h5-mobile;
							width: 18px;
							height: 18px;
							margin-top: -3px;
						}
					}
				}
			}
			& .choose-template {
				display: flex;
				flex-direction: column;
				width: 100%;
				& > span {
					font-size: fonts.$font-size-base-mobile;
					line-height: 20px;
					letter-spacing: 0.09px;
				}
			}
		}
		& .title-wrapper {
			width: 100%;
			& .personalization-title {
				font-size: 18px;
				line-height: normal;
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.onboarding-ingredients-template {
		& .ingredients-template-header {
			margin-top: 31px;
			& .title-wrapper {
				& .personalization-title {
					line-height: normal;
					letter-spacing: 0.24px;
				}
			}
			& .store-standars {
				margin-top: spacing.$spacing-2x;
			}
			& .choose-template {
				margin-top: spacing.$spacing-x;
				& > span {
					line-height: 28.8px;
				}
			}
		}
	}
}
