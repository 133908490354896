@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/border-radius.scss';
.p-skeleton-product-favorite-list {
	display: grid;
	grid-template-columns: repeat(4, minmax(auto, 1fr));
	width: 100%;
	row-gap: spacing.$spacing-3x;
	column-gap: spacing.$spacing-3x;
	z-index: 0;
	&.first-group {
		margin-top: spacing.$spacing-2x;
	}

	& .p-skeleton-product-card {
		height: 339px;
		width: 100%;
		position: relative;

		& .p-skeleton-product-card-title {
			position: absolute;
			left: 16px;
			bottom: 16px;
			height: 21px;
			width: 50%;
			background-color: colors.$color-gray-medium;
			border-radius: border-radius.$radius-medium;
		}
	}
}

@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1050px) {
	.p-skeleton-product-favorite-list {
		grid-template-columns: repeat(2, minmax(auto, 1fr));
	}
}

@media (min-width: 490px) and (max-width: breakpoints.$tablet-breakpoint) {
	.p-skeleton-product-favorite-list {
		grid-template-columns: repeat(3, minmax(auto, 1fr));
	}
}
@media (min-width: breakpoints.$large-screen-breakpoint) {
	.p-skeleton-product-favorite-list {
		grid-template-columns: repeat(5, minmax(auto, 1fr));
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.p-skeleton-product-favorite-list {
		grid-template-columns: repeat(2, minmax(auto, 1fr));
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.p-skeleton-product-favorite-list {
		grid-template-columns: repeat(3, minmax(auto, 1fr));
	}
}
@media (min-width: 1051px) and (max-width: 1200px) {
	.p-skeleton-product-favorite-list {
		grid-template-columns: repeat(3, minmax(auto, 1fr));
	}
}
