@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/spacing.scss';
.booth-number {
	display: flex;
	width: 116px;
	margin: auto spacing.$spacing-x auto 0px;
	font-family: fonts.$tertiary-font-family;
	font-style: normal;
	font-weight: fonts.$font-weight-semibold;
	font-size: fonts.$font-size-big;
	line-height: 29px;
	& > i {
		font-size: 22px;
		margin-right: spacing.$spacing-half-x;
		font-family: fonts.$primary-font-family;
		font-weight: fonts.$font-weight-regular;
	}
	&.primary {
		color: colors.$color-primary;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.booth-number {
		margin: 0;
		font-size: fonts.$font-size-base;
		& > i {
			font-size: fonts.$font-size-bigger-mobile;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.booth-number {
		margin: 0;
	}
}
