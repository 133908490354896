@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.ingredients-container {
	.title {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-h5;
		line-height: 27px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
		background: colors.$color-background-white !important;
	}
	.ingredient-line {
		margin-top: 8px;
		width: 188px;
		height: 0;
		border-radius: border-radius.$radius-default;
		border: 2px solid colors.$color-transparent;
		background: colors.$gradient-beacon border-box;
	}
	.ingredients {
		margin-top: 24px;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-base;
		line-height: 19px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
		& > b {
			font-family: fonts.$secondary-font-family;
		}
	}
	.view_nutrition {
		margin-top: 16px;
		text-decoration: fonts.$text-underline;
		text-underline-offset: 4px;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-base;
		line-height: 22px;
		letter-spacing: fonts.$letter-spacing-std;
		color: #000000;
		cursor: pointer;
	}
}
.nutrition-fact-modal-container {
	.modal-content {
		border: none;
		border: 2px solid colors.$color-gray;
		border-radius: border-radius.$radius-medium;
		background-color: colors.$color-background-white;
		max-width: 95%;
	}
	.modal-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		height: 90vh;
		width: 100%;
		& img {
			object-fit: scale-down;
			margin-left: 15%;
			width: 72%;
			max-width: 600px;
			height: auto;
		}
	}
	.zoom-icon {
		display: flex;
		justify-content: end;
		font-size: fonts.$font-size-h3-mobile;
		margin: -78px 73px 0px auto;
		padding-bottom: 50px;
	}

	.close_icon {
		position: absolute;
		top: 0px;
		right: -128px;
		font-size: fonts.$font-size-h4;
		width: 40px;
		height: 40px;
		text-align: center;
		align-items: center;
		background-color: colors.$color-background-white;
		border-radius: border-radius.$radius-circle;
		color: colors.$color-text-black;
		cursor: pointer;
	}
	.iiz__btn {
		display: none;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.nutrition-fact-modal-container {
		.close_icon {
			right: 16px;
			top: 20%;
			position: fixed;
		}
		.modal-dialog {
			width: 100vw;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.modal-item {
			height: auto !important;
			img {
				margin-left: auto;
				width: 100%;
			}
		}
		.modal-content {
			width: 100% !important;
			left: auto !important;
			margin: auto spacing.$spacing-2x !important;
		}
		.zoom-icon {
			display: none !important;
		}
	}
	.ingredients-container {
		padding: 0 spacing.$spacing-2x !important;
		margin-top: 60px !important;
		.title {
			font-size: 16px !important;
		}
		.ingredient-line {
			width: 157px !important;
		}
		.ingredients,
		.view_nutrition {
			font-size: 12px !important;
			margin-top: 20px;
		}
	}
	.product-information {
		padding: 0 spacing.$spacing-2x;
	}
	.product-back {
		a {
			font-size: 25px !important;
		}
	}
	.breadcrumb-contianer {
		.breadcrumb-link {
			font-size: 25px !important;
		}
	}
	/*********************************/
	.product-image-container {
		.product-image {
			display: block !important;
			width: 100vw !important;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.ingredients-container {
		margin-top: 60px;
		padding: 0 16px;
		.title {
			font-size: fonts.$font-size-bigger-mobile;
		}

		.ingredient-line {
			width: 157px;
		}

		.ingredients,
		.view_nutrition {
			font-size: 14px;
			margin-top: 20px;
		}
	}
}
