@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/breakpoints.scss';

.timeline-container {
	margin-top: spacing.$spacing-4x;
	display: flex;
	flex-direction: column;
	align-items: center;

	&.first-time {
		& .timeline-step {
			display: flex;
			justify-content: center;
			width: 100%;
			&:nth-child(1) {
				& .timeline-step {
					height: 380px;
				}
			}
			&:nth-child(2) {
				& .timeline-step {
					height: 390px;
				}
			}
			&:nth-child(3) {
				& .timeline-step {
					height: 420px;
				}
			}
			&:nth-child(4) {
				width: 100%;
				& .timeline-step {
					height: 360px;
				}
				& .time-container {
					width: 100%;
					& .time-heading-container {
						width: 100%;
						& .timeline-icon {
							&::after {
								display: none;
							}
						}
						& .timeline-content {
							height: auto;
						}
					}
				}
			}
		}

		& .time-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;

			& .time-heading-container {
				display: flex;
				justify-content: space-between;
				width: 100%;
				position: relative;

				& .timeline-content {
					flex: 1;
					display: flex;
					height: 400px;

					&.left {
						padding-right: spacing.$spacing-2x;
					}

					&.right {
						justify-content: flex-start;
						padding-left: spacing.$spacing-2x;
					}
				}

				& .timeline-icon {
					width: 74px;
					height: 74px;
					border-radius: border-radius.$radius-circle;
					background-color: colors.$color-primary-dark;
					color: colors.$color-text-white;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 1;
					flex-shrink: 0;
					position: relative;

					&::after {
						position: absolute;
						z-index: -1;
						content: '';
						width: 10px;
						background-color: colors.$color-primary-dark;
						height: 346px;
						top: 74px;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}
		}
	}
	&.beacon-only {
		& .timeline-step {
			display: flex;
			justify-content: center;
			width: 100%;
			&:nth-child(4) {
				& .time-container {
					& .time-heading-container {
						& .timeline-icon {
							&::after {
								display: none;
							}
						}
						& .timeline-content {
							height: auto;
						}
					}
				}
			}
		}

		& .time-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;

			& .time-heading-container {
				display: flex;
				justify-content: space-between;
				width: 100%;
				position: relative;

				& .timeline-content {
					flex: 1;
					display: flex;
					height: 400px;

					&.left {
						justify-content: flex-end;
						padding-right: spacing.$spacing-2x;
					}

					&.right {
						justify-content: flex-start;
						padding-left: spacing.$spacing-2x;
					}
				}

				& .timeline-icon {
					width: 74px;
					height: 74px;
					border-radius: border-radius.$radius-circle;
					background-color: colors.$color-primary-dark;
					color: colors.$color-text-white;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 1;
					flex-shrink: 0;
					position: relative;

					&::after {
						position: absolute;
						z-index: -1;
						content: '';
						width: 10px;
						background-color: colors.$color-primary-dark;
						height: 326px;
						top: 74px;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}
		}
	}
	&.need-changes {
		display: flex;
		width: 70%;
		margin-top: 0px;
		& .timeline-step {
			display: flex;
			justify-content: center;
			width: 100%;
			&:nth-child(1) {
				width: 100%;
				& .time-container {
					width: 100%;
					& .time-heading-container {
						width: 100%;
						& .timeline-icon {
							&::after {
								height: 350px;
							}
						}
						& .timeline-content {
							width: 100%;
							height: 424px;
						}
					}
				}
			}
			&:nth-child(2) {
				width: 100%;
				& .time-container {
					width: 100%;
					& .time-heading-container {
						width: 100%;
						& .timeline-icon {
							&::after {
								height: 232px;
							}
						}
						& .timeline-content {
							height: 342px;
							width: 100%;
						}
					}
				}
			}
		}

		& .time-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			& .time-heading-container {
				display: flex;
				justify-content: space-between;
				position: relative;
				& .timeline-content {
					display: flex;
					height: 400px;

					&.left {
						display: none;
						justify-content: flex-end;
						padding-right: spacing.$spacing-2x;
					}

					&.right {
						justify-content: flex-start;
						padding-left: spacing.$spacing-2x;
					}
				}

				& .timeline-icon {
					width: 74px;
					height: 74px;
					border-radius: border-radius.$radius-circle;
					background-color: colors.$color-primary-dark;
					color: colors.$color-text-white;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 1;
					flex-shrink: 0;
					position: relative;

					&::after {
						position: absolute;
						z-index: -1;
						content: '';
						width: 10px;
						background-color: colors.$color-primary-dark;
						height: 326px;
						top: 74px;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}
		}
	}
}

@media (min-width: 1249px) and (max-width: 1500px) {
	.timeline-container {
		display: flex;
		flex-direction: column;
		align-items: center;

		&.first-time {
			& .timeline-step {
				display: flex;
				justify-content: center;
				width: 100%;
				&:nth-child(1) {
					& .timeline-step {
						height: 430px;
					}
				}
				&:nth-child(2) {
					& .timeline-step {
						height: 430px;
					}
				}
				&:nth-child(3) {
					& .timeline-step {
						height: 430px;
					}
				}
				&:nth-child(4) {
					width: 100%;
					& .timeline-step {
						height: 390px;
					}
					& .time-container {
						width: 100%;
						& .time-heading-container {
							width: 100%;
							& .timeline-icon {
								&::after {
									display: none;
								}
							}
							& .timeline-content {
								height: auto;
							}
						}
					}
				}
			}
		}
	}
	.timeline-container {
		&.first-time {
			& .time-container {
				& .time-heading-container {
					& .timeline-content {
						height: 235.75px;
						width: 45%;
					}

					& .timeline-icon {
						width: 74px;
						height: 74px;
						&::after {
							height: 390px;
							top: 43.75px;
							width: 10px;
						}
					}
				}
			}
		}
		&.need-changes {
			& .time-container {
				& .time-heading-container {
					& .timeline-content {
						height: 293px;
					}

					& .timeline-icon {
						&::after {
							height: 249.25px;
							top: 43.75px;
							width: 10px;
						}
					}
				}
			}
			& .timeline-step {
				&:nth-child(1) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 279.25px;
									width: 10px;
								}
							}
							& .timeline-content {
								height: 323px;
							}
						}
					}
				}
				&:nth-child(2) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 247px;
									width: 10px;
								}
							}
							& .timeline-content {
								height: 296px;
							}
						}
					}
				}
			}
		}
		&.beacon-only {
			& .time-container {
				& .time-heading-container {
					& .timeline-content {
						height: 235.75px;
						width: 45%;
					}

					& .timeline-icon {
						width: 43.75px;
						height: 43.75px;
						&::after {
							height: 192px;
							top: 43.75px;
							width: 5px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1248px) {
	.timeline-container {
		width: 100%;
		&.first-time {
			& .timeline-step {
				&:nth-child(1) {
					& .timeline-step {
						height: auto;
					}
				}
				&:nth-child(2) {
					& .timeline-step {
						height: auto;
					}
				}
				&:nth-child(3) {
					& .timeline-step {
						height: 260px;
					}
				}
				&:nth-child(4) {
					width: 100%;
					& .timeline-step {
						height: 270px;
					}
				}
			}
			& .time-container {
				& .time-heading-container {
					& .timeline-content {
						height: 235.75px;
						width: 297px;
						display: block;
					}

					& .timeline-icon {
						width: 43.75px;
						height: 43.75px;
						&::after {
							height: 240px;
							top: 43.75px;
							width: 5px;
						}
					}
				}
			}
		}
		&.need-changes {
			max-width: 450px;
			width: 100%;
			& .time-container {
				& .time-heading-container {
					& .timeline-content {
						height: 293px;
					}

					& .timeline-icon {
						width: 43.75px;
						height: 43.75px;
						&::after {
							height: 249.25px;
							top: 43.75px;
							width: 5px;
						}
					}
				}
			}
			& .timeline-step {
				&:nth-child(1) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 249.25px;
									width: 5px;
								}
							}
							& .timeline-content {
								height: 293px;
							}
						}
					}
				}
				&:nth-child(2) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 147px;
									width: 5px;
								}
							}
							& .timeline-content {
								height: 196px;
							}
						}
					}
				}
			}
		}
		&.beacon-only {
			& .time-container {
				& .time-heading-container {
					& .timeline-content {
						height: 235.75px;
					}

					& .timeline-icon {
						width: 43.75px;
						height: 43.75px;
						&::after {
							height: 192px;
							top: 43.75px;
							width: 5px;
						}
					}
				}
			}
		}
	}
}
@media (max-width: 1158px) {
	.timeline-container {
		&.first-time {
			& .time-container {
				& .time-heading-container {
					& .timeline-icon {
						&::after {
							height: 320px;
							top: 43.75px;
						}
					}
				}
			}
			& .timeline-step {
				&:nth-child(2) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 249.25px;
									width: 5px;
								}
							}
							& .timeline-content {
								height: 293px;
							}
						}
					}
					& .timeline-step {
						& .time-container {
							& .time-heading-container {
								& .timeline-icon {
									&::after {
										height: 249.25px;
										width: 5px;
									}
								}
								& .timeline-content {
									height: 293px;
								}
							}
						}
						height: 280px;
					}
				}
				&:nth-child(3) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 310.25px;
									width: 5px;
								}
							}
							& .timeline-content {
								height: 293px;
							}
						}
					}
					& .timeline-step {
						height: 340px;
					}
				}
			}
		}
		&.beacon-only {
			& .timeline-step {
				&:nth-child(1) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 249.25px;
									width: 5px;
								}
							}
							& .timeline-content {
								height: 293px;
							}
						}
					}
					& .timeline-step {
						height: 180px;
					}
				}
				&:nth-child(2) {
					& .timeline-step {
						height: 280px;
					}
				}
				&:nth-child(3) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 329.25px;
									width: 5px;
								}
							}
							& .timeline-content {
								height: 293px;
							}
						}
					}
					& .timeline-step {
						height: 340px;
					}
				}
			}
		}
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.timeline-container {
		&.need-changes {
			width: 100%;
			& .timeline-step {
				&:nth-child(1) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 395px;
								}
							}
							& .timeline-content {
								height: 238.75px;
							}
						}
					}
				}
				&:nth-child(2) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 165px;
								}
							}
							& .timeline-content {
								height: 208.75px;
							}
						}
					}
				}
			}
		}
		&.first-time {
			& .time-container {
				& .timeline-content {
					&.left {
						display: none;
					}
				}
			}

			& .timeline-step {
				display: flex;
				justify-content: center;
				width: 100%;
				&:nth-child(1) {
					& .timeline-step {
						height: 400px;
					}
					& .timeline-icon {
						&::after {
							height: 469.25px;
						}
					}
				}
				&:nth-child(2) {
					& .timeline-step {
						height: 280px;
					}
				}
				&:nth-child(3) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 440.25px;
									width: 5px;
								}
							}
						}
					}
					& .timeline-step {
						height: 400px;
					}
				}
				&:nth-child(4) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									display: block;
									height: 177px;
								}
							}
							& .timeline-content {
								height: auto;
							}
						}
					}
				}
			}
		}

		&.beacon-only {
			& .time-container {
				& .timeline-content {
					&.left {
						display: none;
					}
				}
			}
			&:first-child {
				& > div {
					&:first-child {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 82px;
								}
							}
							& .timeline-content {
								height: 125.75px;
							}
						}
					}
					&:nth-child(2) {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 235.25px;
								}
							}
							& .timeline-content {
								height: 279px;
							}
						}
					}
					&:nth-child(3) {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 311px;
								}
							}
							& .timeline-content {
								height: 354.75px;
							}
						}
					}
				}
			}
			& .timeline-step {
				&:nth-child(4) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									display: block;
									height: 207px;
								}
							}
							& .timeline-content {
								height: auto;
							}
						}
					}
				}
			}
		}
	}
	@media (max-width: 635px) {
		.timeline-container {
			&.first-time {
				& .timeline-step {
					&:nth-child(4) {
						& .time-container {
							& .time-heading-container {
								& .timeline-icon {
									&::after {
										height: 181px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (max-width: 500px) {
	.timeline-container {
		&.first-time {
			& .timeline-step {
				display: flex;
				justify-content: center;
				width: 100%;
				&:nth-child(1) {
					& .timeline-step {
						height: 570px;
					}
					& .timeline-icon {
						&::after {
							height: 532px;
						}
					}
				}
				&:nth-child(2) {
					&.time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 440.25px;
								}
							}
						}
					}

					& .timeline-step {
						height: 290px;
					}
				}
				&:nth-child(3) {
					& .timeline-step {
						height: 460px;
					}
					& .timeline-icon {
						&::after {
							height: 469.25px;
						}
					}
				}
				&:nth-child(4) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 222.25px;
								}
							}
						}
					}
				}
			}
		}
		&.need-changes {
			& .timeline-step {
				&:nth-child(1) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 289px;
								}
							}
							& .timeline-content {
								height: 332.75px;
							}
						}
					}
				}
				&:nth-child(2) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 206px;
								}
							}
							& .timeline-content {
								height: auto;
							}
						}
					}
				}
			}
		}
		&.beacon-only {
			& .timeline-step {
				&:nth-child(1) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 249.25px;
									width: 5px;
								}
							}
							& .timeline-content {
								height: 293px;
							}
						}
					}
					& .timeline-step {
						height: 180px;
					}
				}
				&:nth-child(2) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 340.25px;
									width: 5px;
								}
							}
						}
					}
					& .timeline-step {
						height: 320px;
					}
				}
				&:nth-child(3) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 440.25px;
									width: 5px;
								}
							}
						}
					}
					& .timeline-step {
						height: 480px;
					}
				}
				&:nth-child(4) {
					& .time-container {
						& .time-heading-container {
							& .timeline-icon {
								&::after {
									height: 240.25px;
									width: 5px;
								}
							}
						}
					}
					& .timeline-step {
						height: 300px;
					}
				}
			}
		}
	}
}
