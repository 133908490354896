@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/spacing.scss';
@use '../../styles/tokens/breakpoints.scss';

.top-to-btm {
	position: fixed;
	bottom: 95px;
	right: 25px;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	align-items: center;

	& > span {
		margin-top: spacing.$spacing-half-x;
		background-color: white;
		border-radius: border-radius.$radius-small;
		color: colors.$color-primary;
		text-align: center;
		padding: 2px;
		font-family: fonts.$primary-font-family;
		font-size: fonts.$font-size-big;
		font-weight: 400;
		line-height: 25.6px;
		letter-spacing: 0.0075em;
	}
}

.border-gradient-container {
	--b: 2px;
	color: #313149;
	display: inline-block;
	width: 32px;
	aspect-ratio: 1;
	position: relative;
	z-index: 0;
	cursor: pointer;
	border-radius: 50%;
	box-shadow: shadows.$shadow-low;
	&:before {
		content: '';
		position: absolute;
		z-index: 0;
		inset: 0;
		background: var(--c, colors.$gradient-back-to-top-button);
		padding: 2px;
		border-radius: 50%;
		-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
		mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
	}
	&:hover {
		box-shadow: shadows.$shadow-high;
	}

	&:active {
		box-shadow: none;
		& > .icon-style {
			box-shadow: shadows.$shadow-high-inner;
		}
	}
}

.icon-style {
	border-radius: border-radius.$radius-circle;
	height: 32px;
	width: 32px;
	display: flex;
	background: colors.$color-secondary-lightest;
	color: colors.$color-primary;
	font-size: fonts.$font-size-h4;
	border: 2px solid;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	animation: movebtn 3s ease-in-out infinite;
	transition: all 0.2s ease-in-out;
}

@media (max-width: breakpoints.$mobile-breakpoint) {
	.top-to-btm {
		& > span {
			font-family: fonts.$tertiary-font-family;
			font-size: fonts.$font-size-smallest;
			font-weight: 600;
			line-height: 10px;
			letter-spacing: 0.0075em;
		}
	}
}
