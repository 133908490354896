@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/opacity.scss';
@use '../../../styles/tokens/shadows.scss';
.search-bar-container {
	display: flex;
	align-items: center;
	width: 100%;
	margin-right: spacing.$spacing-2x;
	& .search-bar {
		display: flex;
		max-width: 700px;
		width: 100%;
		height: 38px;
		justify-content: space-between;
		align-items: center;
		padding: spacing.$spacing-x spacing.$spacing-5x spacing.$spacing-x spacing.$spacing-2x;
		border-radius: border-radius.$radius-bigger;
		border: unset;
		opacity: opacity.$opacity-60;
		background: colors.$color-background-white;
		cursor: pointer;
		font-family: fonts.$primary-font-family;
		font-size: fonts.$font-size-big;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		line-height: 25.6px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
		text-overflow: ellipsis;
		&.typing {
			padding: spacing.$spacing-x spacing.$spacing-10x spacing.$spacing-x spacing.$spacing-2x;
			box-shadow: shadows.$shadow-low;
		}
		&::placeholder {
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-big;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			line-height: 25.6px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-gray-darkest;
		}
		&:focus {
			outline: none;
			opacity: opacity.$opacity-100;
			&::placeholder {
				color: colors.$color-text-gray-slate-light;
			}
		}
		&:hover {
			&:not(:focus) {
				opacity: opacity.$opacity-100;
				&::placeholder {
					color: colors.$color-text-black;
				}
			}
		}
	}
	& .icons-container {
		display: flex;
		margin-left: -72px;
		&.empty-wrapper {
			margin-left: -40px;
		}
		& .search-icon {
			display: flex;
			align-items: center;
			font-size: fonts.$font-size-h4;
			width: 24px;
			height: 24px;
			opacity: 0.99;
			cursor: pointer;
		}
		& .clean-icon {
			display: flex;
			align-items: center;
			cursor: pointer;
			font-size: fonts.$font-size-h4;
			width: 24px;
			height: 24px;
			margin-right: spacing.$spacing-x;
			color: colors.$color-gray-medium;
			opacity: 0.99;
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.search-bar-container {
		margin-right: spacing.$spacing-x;
		& .search-bar {
			max-width: 450px;
			height: 31px;
			padding: spacing.$spacing-x spacing.$spacing-5x spacing.$spacing-x spacing.$spacing-1_25x;
			&.typing {
				padding: spacing.$spacing-x spacing.$spacing-8x spacing.$spacing-x spacing.$spacing-1_25x;
			}
			&::placeholder {
				margin-top: 2px;
			}
		}
		& .icons-container {
			margin-left: -60px;
			&.empty-wrapper {
				margin-left: -32px;
			}
			& .search-icon {
				width: 20px;
				height: 20px;
				font-size: fonts.$font-size-h5;
			}
			& .clean-icon {
				width: 20px;
				height: 20px;
				font-size: fonts.$font-size-h5;
			}
		}
	}
}
@media (min-width: 0) and (max-width: breakpoints.$mobile-breakpoint) {
	.search-bar-container {
		margin-right: 0px;
		& .search-bar {
			max-width: unset;
			width: 100%;
			height: 28px;
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-big-mobile;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			line-height: 22.4px;
			letter-spacing: 0.105px;
			padding: spacing.$spacing-x spacing.$spacing-5x spacing.$spacing-x spacing.$spacing-1_25x;
			opacity: opacity.$opacity-100;
			&::placeholder {
				font-family: fonts.$primary-font-family;
				font-size: fonts.$font-size-big-mobile;
				font-style: normal;
				font-weight: fonts.$font-weight-regular;
				line-height: 22.4px;
				letter-spacing: 0.105px;
			}
			&.typing {
				padding: spacing.$spacing-x spacing.$spacing-8x spacing.$spacing-x spacing.$spacing-1_25x;
			}
		}
		& .icons-container {
			margin-left: -60px;
			&.empty-wrapper {
				margin-left: -32px;
			}
			& .search-icon {
				width: 20px;
				height: 20px;
				font-size: fonts.$font-size-h5;
			}
			& .clean-icon {
				width: 20px;
				height: 20px;
				font-size: fonts.$font-size-h5;
			}
		}
	}
}
