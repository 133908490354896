@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/shadows.scss';
.search-prediction-container {
	display: flex;
	border-radius: border-radius.$radius-medium;
	background: colors.$color-background-white;
	box-shadow: shadows.$shadow-medium;
	flex-direction: column;
	justify-content: flex-start;
	text-align: start;
	padding: spacing.$spacing-x spacing.$spacing-2x spacing.$spacing-1_25x spacing.$spacing-2x;
	gap: 8px;
	margin-top: spacing.$spacing-half-x;
	& .suggestion-item-container {
		cursor: pointer;
		& .matching-case {
			color: colors.$color-text-black;
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-big;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			line-height: 25.6px;
			letter-spacing: fonts.$letter-spacing-std;
		}

		& .rest-case {
			color: colors.$color-text-black;
			font-family: fonts.$tertiary-font-family;
			font-size: fonts.$font-size-big;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			line-height: 25.6px;
			letter-spacing: fonts.$letter-spacing-std;
		}
		& .default-case {
			color: colors.$color-text-black;
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-big;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			line-height: 25.6px;
			letter-spacing: fonts.$letter-spacing-std;
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.search-prediction-container {
		margin-top: spacing.$spacing-1_5x;
		margin-left: -16px;
		width: 105%;
		border-radius: 0px 0px border-radius.$radius-medium border-radius.$radius-medium;
		height: 80vh;
		overflow: scroll;
		& .suggestion-item-container {
			& .matching-case {
				font-size: fonts.$font-size-big-mobile;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
			& .rest-case {
				font-size: fonts.$font-size-big-mobile;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
			& .default-case {
				font-size: fonts.$font-size-big-mobile;
				line-height: 19.2px;
				letter-spacing: 0.09px;
			}
		}
	}
	@media (max-width: 700px) {
		.search-prediction-container {
			width: 105%;
		}
	}
	@media (max-width: 600px) {
		.search-prediction-container {
			width: 106%;
		}
	}
	@media (max-width: 500px) {
		.search-prediction-container {
			width: 107%;
		}
	}
	@media (max-width: 450px) {
		.search-prediction-container {
			width: 108%;
		}
	}
	@media (max-width: 380px) {
		.search-prediction-container {
			width: 109%;
		}
	}
	@media (max-width: 345px) {
		.search-prediction-container {
			width: 110%;
		}
	}
}
