$radius-default: 8px;
$radius-small: $radius-default / 2;
$radius-mid: $radius-default + 4px;
$radius-medium: $radius-default * 2;
$radius-big: $radius-default * 3;
$radius-bigger: $radius-default * 4;
$radius-biggest: $radius-default * 5;
$radius-circle: 100%;
$radius-semi-circle: 50%;
$radius-brand-image: 20px;
$radius-select-item: 20px;
