@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/fonts.scss';
.how-its-made-container {
	display: flex;
	flex-direction: column;
	height: auto;
	margin: 8px auto auto 2%;
	& .how-its-made-block {
		margin-top: 16px;
	}
	& .how-its-made-label {
		font-weight: fonts.$font-weight-bold;
		font-family: fonts.$secondary-font-family;
		font-size: fonts.$font-size-base;
	}
	& .how-its-made-title {
		font-style: normal;
		line-height: 22px;
	}
}
