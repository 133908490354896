@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/spacing.scss';

.distribution-container {
	display: flex;
	flex-direction: column;
	height: auto;
	margin: spacing.$spacing-x 2% auto 2%;

	& .distribution-block {
		margin-top: spacing.$spacing-2x;
	}

	& .distribution-title {
		font-family: fonts.$secondary-font-family;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-base;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
		font-style: normal;
		line-height: 22px;
		margin-right: spacing.$spacing-half-x;
	}

	& .distribution-table-container {
		height: auto;
		margin-top: spacing.$spacing-2x;
		width: 450px;

		& table {
			width: 100%;

			& tr {
				width: 100%;
				flex-direction: row;
				border-bottom: 1px solid #eaeaea;
				height: 39px;

				& td {
					margin: auto spacing.$spacing-4x;
					font-family: fonts.$primary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-regular;
					font-size: fonts.$font-size-base;
					line-height: 22px;
					color: colors.$color-text-black;
					letter-spacing: fonts.$letter-spacing-std;
					text-align: start;
					justify-content: start;
					padding-left: spacing.$spacing-2x;
				}

				& td:nth-child(2) {
					padding-left: spacing.$spacing-2x;
					width: 75%;
				}
				& .not-distribution {
					width: 35%;
				}
			}

			& tr:nth-child(even) {
				background: colors.$color-background-secondary-lightest;
			}
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.distribution-container {
		& .distribution-table-container {
			width: 100%;
			& table {
				& tr {
					& td:nth-child(2) {
						width: 75%;
					}
					& .not-distribution {
						width: 50%;
					}
				}
			}
		}
	}
}
