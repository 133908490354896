@use '../tokens/opacity.scss';
@use '../tokens/colors.scss';
// LeftToRigth
@keyframes leftToRigth {
	from {
		opacity: opacity.$opacity-0;
		transform: translateX(-300px);
		-webkit-transform: translateX(-300px);
		-moz-transform: translateX(-300px);
		-ms-transform: translateX(-300px);
		-o-transform: translateX(-300px);
	}
	to {
		opacity: opacity.$opacity-100;
	}
}
@-webkit-keyframes leftToRigth {
	from {
		opacity: opacity.$opacity-0;
		transform: translateX(-300px);
		-webkit-transform: translateX(-300px);
		-moz-transform: translateX(-300px);
		-ms-transform: translateX(-300px);
		-o-transform: translateX(-300px);
	}
	to {
		opacity: opacity.$opacity-100;
	}
}
@-moz-keyframes leftToRigth {
	from {
		opacity: opacity.$opacity-0;
		transform: translateX(-300px);
		-webkit-transform: translateX(-300px);
		-moz-transform: translateX(-300px);
		-ms-transform: translateX(-300px);
		-o-transform: translateX(-300px);
	}
	to {
		opacity: opacity.$opacity-100;
	}
}
// FadeIn
@keyframes fadeIn {
	0% {
		opacity: opacity.$opacity-0;
	}
	100% {
		opacity: opacity.$opacity-100;
	}
}
@-moz-keyframes fadeIn {
	0% {
		opacity: opacity.$opacity-0;
	}
	100% {
		opacity: opacity.$opacity-100;
	}
}
@-webkit-keyframes fadeIn {
	0% {
		opacity: opacity.$opacity-0;
	}
	100% {
		opacity: opacity.$opacity-100;
	}
}
//FaceIn
@keyframes faceIn {
	0% {
		max-height: 0;
		opacity: opacity.$opacity-0;
	}
	100% {
		max-height: 500px;
		opacity: opacity.$opacity-100;
	}
}
@-moz-keyframes faceIn {
	0% {
		max-height: 0;
		opacity: opacity.$opacity-0;
	}
	100% {
		max-height: 500px;
		opacity: opacity.$opacity-100;
	}
}
@-webkit-keyframes faceIn {
	0% {
		max-height: 0;
		opacity: opacity.$opacity-0;
	}
	100% {
		max-height: 500px;
		opacity: opacity.$opacity-100;
	}
}
@keyframes fadeOut {
	from {
		opacity: opacity.$opacity-100;
	}
	to {
		opacity: opacity.$opacity-0;
		transform: translateX(-300px);
	}
}
@-moz-keyframes fadeOut {
	from {
		opacity: opacity.$opacity-100;
	}
	to {
		opacity: opacity.$opacity-0;
		transform: translateX(-300px);
	}
}
@-webkit-keyframes fadeOut {
	from {
		opacity: opacity.$opacity-100;
	}
	to {
		opacity: opacity.$opacity-0;
		transform: translateX(-300px);
	}
}
//Spiner
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-o-keyframes spin {
	from {
		-o-transform: rotate(0deg);
	}
	to {
		-o-transform: rotate(360deg);
	}
}

@-ms-keyframes spin {
	from {
		-ms-transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
	}
}

@-webkit-keyframes gradient {
	0%,
	83% {
		background: colors.$east-animation-gredient1;
	}
	87.25% {
		background: colors.$east-animation-gredient2;
	}
	91.5% {
		background: colors.$east-animation-gredient3;
	}
	95.75% {
		background: colors.$east-animation-gredient4;
	}
	99% {
		background: colors.$east-animation-gredient5;
	}
	100% {
		background: colors.$east-animation-gredient1;
	}
}
@-moz-keyframes gradient {
	0%,
	83% {
		background: colors.$east-animation-gredient1;
	}
	87.25% {
		background: colors.$east-animation-gredient2;
	}
	91.5% {
		background: colors.$east-animation-gredient3;
	}
	95.75% {
		background: colors.$east-animation-gredient4;
	}
	99% {
		background: colors.$east-animation-gredient5;
	}
	100% {
		background: colors.$east-animation-gredient1;
	}
}
@keyframes gradient {
	0%,
	83% {
		background: colors.$east-animation-gredient1;
	}
	87.25% {
		background: colors.$east-animation-gredient2;
	}
	91.5% {
		background: colors.$east-animation-gredient3;
	}
	95.75% {
		background: colors.$east-animation-gredient4;
	}
	99% {
		background: colors.$east-animation-gredient5;
	}
	100% {
		background: colors.$east-animation-gredient1;
	}
}
@-webkit-keyframes west-gradient {
	0%,
	10% {
		background: colors.$west-animation-gradient1;
	}
	20% {
		background: colors.$west-animation-gradient2;
	}
	30% {
		background: colors.$west-animation-gradient3;
	}
	40% {
		background: colors.$west-animation-gradient4;
	}
	50% {
		background: colors.$west-animation-gradient5;
	}
	60% {
		background: colors.$west-animation-gradient6;
	}
	70% {
		background: colors.$west-animation-gradient7;
	}
	80% {
		background: colors.$west-animation-gradient8;
	}
	90% {
		background: colors.$west-animation-gradient9;
	}
	100% {
		background: colors.$west-animation-gradient1;
	}
}
@-moz-keyframes west-gradient {
	0%,
	10% {
		background: colors.$west-animation-gradient1;
	}
	20% {
		background: colors.$west-animation-gradient2;
	}
	30% {
		background: colors.$west-animation-gradient3;
	}
	40% {
		background: colors.$west-animation-gradient4;
	}
	50% {
		background: colors.$west-animation-gradient5;
	}
	60% {
		background: colors.$west-animation-gradient6;
	}
	70% {
		background: colors.$west-animation-gradient7;
	}
	80% {
		background: colors.$west-animation-gradient8;
	}
	90% {
		background: colors.$west-animation-gradient9;
	}
	100% {
		background: colors.$west-animation-gradient1;
	}
}
@keyframes west-gradient {
	0%,
	10% {
		background: colors.$west-animation-gradient1;
	}
	20% {
		background: colors.$west-animation-gradient2;
	}
	30% {
		background: colors.$west-animation-gradient3;
	}
	40% {
		background: colors.$west-animation-gradient4;
	}
	50% {
		background: colors.$west-animation-gradient5;
	}
	60% {
		background: colors.$west-animation-gradient6;
	}
	70% {
		background: colors.$west-animation-gradient7;
	}
	80% {
		background: colors.$west-animation-gradient8;
	}
	90% {
		background: colors.$west-animation-gradient9;
	}
	100% {
		background: colors.$west-animation-gradient1;
	}
}
@keyframes newtopia-gradient {
	0%,
	83% {
		background: colors.$newtopia-animation-gradient1;
	}
	87.25% {
		background: colors.$newtopia-animation-gradient2;
	}
	91.5% {
		background: colors.$newtopia-animation-gradient3;
	}
	95.75% {
		background: colors.$newtopia-animation-gradient4;
	}
	99% {
		background: colors.$newtopia-animation-gradient5;
	}
	100% {
		background: colors.$newtopia-animation-gradient1;
	}
}
@keyframes newtopia-animated {
	0% {
		background-position: 100% 0;
	}
	83.33% {
		background-position: 0% 0;
	}
	100% {
		background-position: 0% 0;
	}
}
