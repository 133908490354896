@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/breakpoints.scss';

.checkbox-container {
	display: flex;
	justify-content: start;
	align-items: center;
	flex-direction: row;

	&.square {
		.checkbox {
			display: block;
			position: relative;
			margin-bottom: 0;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				height: 0;
				width: 0;

				&:checked {
					~ .checkmark {
						background-color: colors.$color-primary !important;
						&::after {
							display: block;
						}
					}
				}

				&:disabled {
					~ .checkmark {
						background-color: gray;
						border: solid 2px gray;
						&::after {
							display: block;
						}
					}
					~  .selected{
						background-color: gray !important;
						&::after {
							color:red;
							display: block !important;
						}
					}
					~  .not-selected{
						background-color: white  !important;
						&::after {
						
							display: none !important;
						}
					}
				}
			}

			.checkmark {
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				top: 0;
				left: 0;
				height: 18px;
				width: 18px;
				background-color: white;
				border: solid 2px colors.$color-primary;
				border-radius: 2px;
				flex-shrink: 0;
				margin-right: 7px;
				&:after {
					content: '';
					display: none;
					width: 5px;
					height: 10px;
					border: solid white;
					border-width: 0 2px 2px 0;
					transform: rotate(45deg);
				}
			}
		}

		& > .checkbox-text {
			font-family: fonts.$primary-font-family;
			font-size: fonts.$font-size-base;
			font-weight: fonts.$font-weight-regular;
			line-height: 22.4px;
			letter-spacing: 0.0075em;
			color: colors.$color-gray-slate;
			cursor: pointer;
		}
	}

	&.toggle {
		&.checkbox {
			display: block;
			position: relative;
			margin-bottom: 0;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;

			&:checked {
				~ .checkmark {
					background-color: colors.$color-primary;
					&::after {
						display: block;
						-webkit-transform: translateX(9px);
						-ms-transform: translateX(9px);
						transform: translateX(9px);
						background-color: white;
					}
				}
			}

			&:disabled {
				~ .checkmark {
					background-color: gray;
					border: solid 2px gray;
					&::after {
						display: block;
						background-color: white;
					}
				}
			}
		}

		.checkmark {
			display: block;
			position: relative;
			top: 0;
			left: 0;
			height: 14px;
			width: 22px;
			background-color: white;
			border: solid 2px colors.$color-primary;
			border-radius: 40px;
			flex-shrink: 0;

			&:after {
				display: block;
				position: absolute;
				content: '';
				height: 6px;
				width: 6px;
				left: 2px;
				bottom: 2px;
				border-radius: 50%;
				background-color: colors.$color-primary;
				-webkit-transition: 0.6s;
				transition: 0.6s;
			}
		}
	}
}
