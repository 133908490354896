@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';
@use '../../../../styles/tokens/border-radius.scss';

.contact-information-container {
	display: flex;
	margin: spacing.$spacing-x auto 0px 2%;
	flex-direction: column;
	gap: 24px;
	max-width: 840px;
	width: 100%;

	& .separator {
		margin: auto;

		&.last-item {
			margin-bottom: spacing.$spacing-2x;
		}
	}

	& .brand-contact-container {
		& .title-container {
			& .title {
				font-family: fonts.$secondary-font-family;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-base;
				line-height: 22.4px;
				letter-spacing: 0.0075em;
			}
		}
	}

	& .request-sample-container {
		& .title-container {
			& .title {
				font-family: fonts.$secondary-font-family;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-base;
				line-height: 22.4px;
				letter-spacing: 0.0075em;
			}
		}
	}

	& .broker-container {
		& .title-container {
			& .title {
				font-family: fonts.$secondary-font-family;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-base;
				line-height: 22.4px;
				letter-spacing: 0.0075em;
			}
		}
	}

	& .brand-content-container {
		margin-top: spacing.$spacing-x;
	}

	& .request-sample-content-container {
		margin-top: spacing.$spacing-x;
	}

	& .broker-list-container {
		margin-top: spacing.$spacing-x;

		& > div {
			&:nth-child(even) {
				border-top: 1px solid colors.$color-background-secondary-lightest;
				background-color: colors.$color-background-secondary-lightest;
			}
		}
	}

	& .contact-information-item-container {
		display: flex;
		width: 100%;
		padding: spacing.$spacing-1_5x 0px spacing.$spacing-1_5x spacing.$spacing-4x;

		& .contact-name-container {
			display: flex;
			align-items: center;
			width: 172px;
			gap: 8px;

			& > i {
				font-size: fonts.$font-size-h4;
				color: colors.$color-secondary;
			}
			& .contact-name {
				font-family: fonts.$primary-font-family;
				font-weight: fonts.$font-weight-regular;
				font-style: normal;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				letter-spacing: 0.0075em;
				color: colors.$color-text-black;
				text-overflow: ellipsis;
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
			}
		}

		& .contact-email-container {
			display: flex;
			align-items: center;
			width: 429px;
			gap: 8px;

			& > i {
				font-size: fonts.$font-size-h4;
				color: colors.$color-secondary;
			}

			& .contact-email {
				font-family: fonts.$primary-font-family;
				font-weight: fonts.$font-weight-regular;
				font-style: normal;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				letter-spacing: 0.0075em;
				text-decoration: underline;
				text-overflow: ellipsis;
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				& > a {
					color: colors.$color-text-black;
				}
			}
		}

		& .contact-phone-container {
			display: flex;
			align-items: center;
			width: 158px;
			gap: 8px;

			& > i {
				font-size: fonts.$font-size-h4;
				color: colors.$color-secondary;
			}

			& .contact-phone {
				font-family: fonts.$primary-font-family;
				font-weight: fonts.$font-weight-regular;
				font-style: normal;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				letter-spacing: 0.0075em;
				color: colors.$color-text-black;
				text-overflow: ellipsis;
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
			}
		}
	}
}

@media (min-width: 601px) and (max-width: breakpoints.$tablet-breakpoint) {
	.contact-information-container {
		width: 91%;
		margin: spacing.$spacing-x auto 0px 32px;

		& .contact-information-item-container {
			flex-wrap: wrap;
			row-gap: 8px;
		}
	}

	@media (max-width: 839px) {
		.contact-information-container {
			& .separator {
				margin: spacing.$spacing-half-x auto;
			}
			& .brand-content-container {
				margin-top: spacing.$spacing-x;
			}

			& .request-sample-content-container {
				margin-top: spacing.$spacing-x;
			}

			& .broker-list-container {
				margin-top: spacing.$spacing-x;
			}
		}
	}
	@media (max-width: 720px) {
		.contact-information-container {
			width: 89%;
			gap: 8px;
			& .brand-content-container {
				margin-top: spacing.$spacing-x;
			}

			& .request-sample-content-container {
				margin-top: spacing.$spacing-x;
			}

			& .broker-list-container {
				margin-top: spacing.$spacing-x;
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 600px) {
	.contact-information-container {
		margin: auto;
		max-width: unset;
		width: 100%;

		& .separator {
			display: none;
		}

		& .contact-information-item-container {
			flex-direction: column;
			height: auto;
			width: 100%;
			min-width: 291px;
			padding-left: 0px;
			background-color: colors.$color-background-white;
			box-shadow: shadows.$shadow-medium;
			border-radius: border-radius.$radius-default;
			padding: spacing.$spacing-x spacing.$spacing-x spacing.$spacing-x spacing.$spacing-x;
			gap: 4px;
			display: flex;
			min-height: 94px;
			& .contact-name-container {
				width: auto;
				display: flex;
				align-items: center;
				height: 24px;
				& > i {
					font-size: fonts.$font-size-h5;
					position: relative;
				}

				& .contact-name {
					font-family: fonts.$tertiary-font-family;
					font-weight: fonts.$font-weight-semibold;
					line-height: 22.4px;
					text-overflow: unset;
					overflow-wrap: break-word;
					width: 100%;
					word-break: break-word;
					-webkit-line-clamp: unset;
				}
			}

			& .contact-email-container {
				order: 3;
				display: flex;
				align-items: center;
				width: auto;
				& > i {
					font-size: fonts.$font-size-h5;
					position: relative;
				}

				& .contact-email {
					width: 100%;
					line-height: 22.5px;
					text-overflow: unset;
					overflow-wrap: break-word;
					word-break: break-word;
					-webkit-line-clamp: unset;
					& > a {
						color: colors.$color-gray-slate;
					}
				}
			}

			& .contact-phone-container {
				order: 2;
				width: auto;
				display: flex;
				align-items: center;
				height: 23px;
				& > i {
					font-size: fonts.$font-size-h5;
					position: relative;
				}
				& .contact-phone {
					width: 100%;
					line-height: 22.4px;
					text-overflow: unset;
					overflow-wrap: break-word;
					word-break: break-word;
					-webkit-line-clamp: unset;
				}
			}
		}
		& .broker-list-container {
			display: flex;
			flex-direction: column;
			gap: 8px;
			& > div {
				&:nth-child(even) {
					border-top: none;
					background-color: colors.$color-background-white;
				}
			}
		}
	}
}
