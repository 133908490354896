@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/spacing.scss';

.home-page-onboard-container {
	background-color: colors.$color-secondary-lighter;
	height: 291px;
	margin-top: spacing.$spacing-8x;
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: spacing.$spacing-3x;
	& .home-onboard-title-container {
		& .onboard-title {
			color: colors.$color-first-tier;
			font-family: fonts.$secondary-font-family;
			font-size: fonts.$font-size-h3;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			letter-spacing: 0.0075em;
			line-height: 43.58px;
		}
	}
	& .home-page-metric-container {
		display: flex;
		margin-top: spacing.$spacing-3x;
		gap: 88px;
		height: 112px;
		& .home-onboard-metric-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 8px;
			& .metric-value {
				font-family: fonts.$secondary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-h1-mobile;
				line-height: 65.37px;
				letter-spacing: 0.0075em;
				background: colors.$onboard-page-metric-gradient;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
			}
			& .metric-name {
				font-family: fonts.$primary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-h4;
				line-height: 38.4px;
				letter-spacing: 0.0075em;
				color: colors.$color-text-black;
			}
		}
	}
	& .button-container {
		margin-top: spacing.$spacing-3x;
		& > button {
			width: 153px;
			height: 39px;
			padding: spacing.$spacing-x spacing.$spacing-3x;
			margin: 0px;
			&:hover {
				& > i {
					transform: translateX(4px);
				}
			}
			& > i {
				font-size: fonts.$font-size-big;
			}
		}
	}
}
@media (min-width: 744px) and (max-width: breakpoints.$tablet-breakpoint) {
	.home-page-onboard-container {
		height: 240px;
		margin-top: spacing.$spacing-4x;
		& .home-onboard-title-container {
			& .onboard-title {
				font-size: fonts.$font-size-h5;
				line-height: 27.24px;
			}
		}
		& .home-page-metric-container {
			gap: 32px;
			& .home-onboard-metric-container {
				height: 78px;
				& .metric-value {
					font-size: fonts.$font-size-h3;
					line-height: 43.58px;
				}
				& .metric-name {
					font-size: fonts.$font-size-big;
					line-height: 25.6px;
				}
			}
		}
		& .button-container {
			& > button {
				width: fit-content;
				height: 39px;
				padding: spacing.$spacing-half-x spacing.$spacing-3_5x;
			}
		}
	}
}
@media (min-width: 500px) and (max-width: 744px) {
	.home-page-onboard-container {
		height: 240px;
		margin-top: spacing.$spacing-4x;
		& .home-onboard-title-container {
			& .onboard-title {
				font-size: fonts.$font-size-h4-mobile;
				line-height: 27px;
			}
		}
		& .home-page-metric-container {
			gap: 24px;
			height: auto;
			& .home-onboard-metric-container {
				& .metric-value {
					font-size: fonts.$font-size-h3;
					line-height: 44px;
				}
				& .metric-name {
					font-size: fonts.$font-size-big;
					line-height: 26px;
				}
			}
		}
		& .button-container {
			& > button {
				width: fit-content;
				padding: spacing.$spacing-half-x spacing.$spacing-3_5x;
			}
		}
	}
}
@media (max-width: 500px) {
	.home-page-onboard-container {
		height: auto;
		margin-top: 0px;
		& .home-onboard-title-container {
			text-align: center;
			& .onboard-title {
				font-size: fonts.$font-size-bigger-mobile;
				line-height: 21.79px;
				width: 100%;
				min-width: 283px;
			}
		}
		& .home-page-metric-container {
			flex-direction: column;
			gap: 24px;
			height: auto;
			.home-onboard-metric-container {
				height: 78px;
				.metric-value {
					font-size: fonts.$font-size-onboard-metric-value;
					line-height: 44px;
				}
				.metric-name {
					font-size: fonts.$font-size-bigger-mobile;
					line-height: 26px;
				}
			}
		}
		& .button-container {
			& > button {
				height: 28px;
				font-size: fonts.$font-size-base;
			}
		}
	}
}
