@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/shadows.scss';
.chart-container {
	margin-top: spacing.$spacing-3x;
	& .chart-card-container {
		display: flex;
		width: 100%;
		max-width: 811px;
		flex-direction: column;
		background: colors.$color-background-white;
		border-radius: border-radius.$radius-big;
		box-shadow: shadows.$shadow-lower;
		padding: spacing.$spacing-2x spacing.$spacing-2x spacing.$spacing-1_5x spacing.$spacing-2x;
		& .chart-card-title-container {
			& .chart-title {
				font-family: fonts.$secondary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-bigger;
				line-height: 25px;
				letter-spacing: 0.0075em;
				color: colors.$color-primary;
			}
		}
		& .chart-card-text-container {
			margin-top: spacing.$spacing-half-x;
			& .chart-text {
				font-family: fonts.$primary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				letter-spacing: 0.0075em;
				color: colors.$color-gray-slate;
			}
		}
		& .chart-content {
			display: flex;
			justify-content: center;
			width: 678px;
			height: 158px;
			margin: auto;
		}
		& .chart-card-metric-container {
			display: flex;
			justify-content: center;
			& .chart-metric {
				font-family: fonts.$secondary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-base;
				line-height: 22px;
				letter-spacing: 0.0075em;
				color: colors.$color-gray-slate;
			}
		}
	}
}
