@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/shadows.scss';
.onboarding-template-cards-contianer {
	display: flex;
	margin: auto auto auto 30px;
	& > div {
		display: flex;
		width: 100%;
	}
}
@media (min-width: 0) and (max-width: breakpoints.$mobile-breakpoint) {
	.template-cards {
		& :not(:first-child) {
			margin-left: unset;
		}
		margin: spacing.$spacing-5x auto auto auto;
		& .slider-template-cards {
			width: 100%;
			& .swiper {
				overflow: visible;
				& > div {
					&.swiper-wrapper {
						& > div {
							flex-shrink: unset;
						}
					}
				}
				& .onboarding-card {
					width: 224px;
					height: 334px;
					border-radius: border-radius.$radius-medium;
					box-shadow: shadows.$shadow-medium;
					&:not(.default-card) {
						&:not(.active) {
							& .card-title-container {
								margin-top: spacing.$spacing-5x;
								justify-content: center;
							}
						}
					}
					&.active {
						&:not(.default-card) {
							& .card-title-container {
								justify-content: center;
								margin-top: spacing.$spacing-5x;
							}
						}
						&.default-card {
							& .card-title-container {
								justify-content: center;
								margin-top: spacing.$spacing-x;
							}
						}
					}
					& .card-title-container {
						& .template-card-title {
							font-family: fonts.$secondary-font-family;
							font-style: normal;
							font-weight: fonts.$font-weight-bold;
							font-size: fonts.$font-size-base-mobile;
							line-height: 16px;
							letter-spacing: 0.09px;
						}
					}
					& .banned-container {
						display: flex;
						flex-direction: column;
						& .banned-ingredients-number {
							font-family: fonts.$secondary-font-family;
							font-style: normal;
							font-weight: fonts.$font-weight-bold;
							font-size: fonts.$font-size-h4;
							line-height: normal;
							letter-spacing: 0.18px;
						}
						& .banned-ingredients-label {
							font-family: fonts.$primary-font-family;
							font-style: normal;
							font-weight: fonts.$font-weight-regular;
							font-size: fonts.$font-size-small-mobile;
							line-height: 16px;
							letter-spacing: 0.075px;
						}
					}
					& .description-container {
						display: flex;
						flex-direction: column;
						width: 192px;
						margin: spacing.$spacing-2x auto auto spacing.$spacing-2x;
						text-align: center;
						& .all-products-text {
							font-family: fonts.$secondary-font-family;
							font-style: normal;
							font-weight: fonts.$font-weight-bold;
							font-size: fonts.$font-size-hg-btn-inside-caurosel;
							line-height: 14px;
							letter-spacing: 0.068px;
							color: colors.$color-text-black;
						}
						& .description {
							font-family: fonts.$primary-font-family;
							font-size: fonts.$font-size-hg-btn-inside-caurosel;
							line-height: 14px;
							letter-spacing: 0.068px;
							color: colors.$color-text-black;
							font-weight: fonts.$font-weight-regular;
						}
					}
					& .template-list-container {
						display: flex;
						flex-direction: column;
						margin: spacing.$spacing-2x auto auto spacing.$spacing-3x;
						& .top-banned-container {
							display: flex;
							flex-direction: column;
							text-align: start;
							width: 172px;
							& .top-banned-text {
								font-family: fonts.$tertiary-font-family;
								font-style: normal;
								font-weight: fonts.$font-weight-semibold;
								font-size: fonts.$font-size-small-mobile;
								line-height: 16px;
								letter-spacing: 0.075px;
								color: colors.$color-text-black;
							}
						}
						& .list-container {
							display: flex;
							flex-direction: column;
							text-align: start;
							width: 190px;
							& .list-item {
								display: flex;
							}
							& .ingredient {
								font-family: fonts.$primary-font-family;
								font-style: normal;
								font-weight: fonts.$font-weight-regular;
								font-size: fonts.$font-size-small-mobile;
								line-height: 18px;
								letter-spacing: 0.075px;
								color: colors.$color-text-black;
							}
							& .ex-icon {
								margin: 6px 2px 0px 0px;
								width: 8px;
								height: 8px;
							}
						}
					}
					& .show-more-container {
						display: flex;
						& .show-more {
							font-family: fonts.$tertiary-font-family;
							font-style: normal;
							font-weight: fonts.$font-weight-bold;
							font-size: fonts.$font-size-small-mobile;
							line-height: 16px;
							letter-spacing: 0.075px;
						}
					}
					&.active {
						&.default-card {
							height: 364px;
						}
						background: colors.$color-info-light;
						border: 2px solid colors.$color-primary;
						& :before {
							content: '';
							position: absolute;
							top: 5px;
							left: 196px;
							transform: translate(50%, -50%);
							width: 24px;
							height: 24px;
							border-radius: border-radius.$radius-circle;
							background: colors.$color-primary url(../../../assets/images/checkmark.png) no-repeat
								center;
						}
						& .card-title-container {
							& .template-card-title {
								color: colors.$color-primary;
							}
						}
						& .show-more-container {
							& .show-more {
								color: colors.$color-primary;
							}
						}
						& .default-template-label-container {
							& .default-template-label {
								color: colors.$color-primary;
							}
						}
					}

					& .default-template-label-container {
						display: flex;
						justify-content: flex-start;
						margin: spacing.$spacing-2x auto auto spacing.$spacing-2x;
						& .default-template-label {
							color: colors.$color-gray-darker;
							font-family: fonts.$primary-font-family;
							font-style: normal;
							font-size: fonts.$font-size-hg-btn-inside-caurosel;
							line-height: 12px;
							letter-spacing: 0.068px;
						}
					}
				}
			}
			& .swiper-pagination {
				position: relative;
				margin-top: spacing.$spacing-2x;
			}
			& .swiper-pagination-bullets {
				display: block;
			}
		}
	}
}
@media (min-width: 795px) and (max-width: 849px) {
	.onboarding-template-cards-contianer {
		margin: auto auto auto auto;
		gap: spacing.$spacing-4x;
		& > div {
			&:first-child {
				margin-top: -7px;
			}
			width: 100%;
			height: 296px;
			min-width: 224px;
			& .template-card {
				&.active {
					&:not(.default-template-card) {
						:before {
							content: '';
							position: absolute;
							top: -30px;
							right: 3px;
							transform: translate(50%, -50%);
							width: 24px;
							height: 24px;
							border-radius: border-radius.$radius-circle;
							background: colors.$color-primary url(../../../assets/images/checkmark.png) no-repeat
								center;
						}
					}
				}
				& .ingredients-template-list {
					margin: spacing.$spacing-x auto spacing.$spacing-x spacing.$spacing-3x;
				}
				& .description {
					margin: spacing.$spacing-1_5x auto auto auto;
				}
				& .default-template-label {
					font-size: fonts.$font-size-hg-btn-inside-caurosel;
					line-height: 14.4px;
					letter-spacing: 0.068px;
				}
				&.default-template-card {
					& .template-card-title {
						font-size: fonts.$font-size-base-mobile;
						line-height: 19.2px;
						letter-spacing: 0.09px;
						margin-top: 1px;
					}
				}
				&:not(.default-template-card) {
					& .template-card-title {
						font-size: fonts.$font-size-base-mobile;
						line-height: 19.2px;
						letter-spacing: 0.09px;
						margin-top: spacing.$spacing-4x;
					}
					& .show-more {
						margin-top: spacing.$spacing-x;
					}
				}
				& .banned-wrapper {
					margin-top: spacing.$spacing-half-x;
					& .banned-ingredients-number {
						font-size: 28px;
						line-height: normal;
						letter-spacing: 0.21px;
					}
					& .banned-ingredients-label {
						margin-top: -4px;
						font-size: fonts.$font-size-smaller;
						line-height: 16px;
						letter-spacing: 0.075px;
					}
				}
				& .description {
					font-size: fonts.$font-size-hg-btn-inside-caurosel;
					line-height: 14.4px;
					letter-spacing: 0.068px;
					margin-top: spacing.$spacing-1_5x;
					padding: 0px spacing.$spacing-2x 0px spacing.$spacing-2x;
					text-align: center;
				}
				& .ingredients-template-list {
					margin: spacing.$spacing-x auto auto spacing.$spacing-3x;
					& > div {
						& > svg {
							margin-top: 2px;
							width: 10px;
							height: 10px;
						}
					}

					& .top-banned-text {
						font-size: fonts.$font-size-smaller;
						line-height: 16px;
						letter-spacing: 0.075px;
					}
					& .ingredient {
						font-size: fonts.$font-size-smaller;
						line-height: 18px;
						letter-spacing: 0.075px;
						margin-left: 2px;
					}
				}
				& .show-more-container {
					display: flex;
					margin: auto;
					align-items: center;
					justify-content: center;
					& .show-more {
						font-size: fonts.$font-size-small-mobile;
						line-height: 16px;
						letter-spacing: 0.075px;
						justify-content: center;
						text-align: center;
					}
				}
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: 795px) {
	.onboarding-template-cards-contianer {
		margin: auto auto auto auto;
		gap: 2%;
		& > div {
			&:first-child {
				margin-top: -7px;
			}
			width: 100%;
			height: 296px;
			min-width: 224px;
			& .template-card {
				&.active {
					&:not(.default-template-card) {
						:before {
							content: '';
							position: absolute;
							top: -30px;
							right: 3px;
							transform: translate(50%, -50%);
							width: 24px;
							height: 24px;
							border-radius: border-radius.$radius-circle;
							background: colors.$color-primary url(../../../assets/images/checkmark.png) no-repeat
								center;
						}
					}
				}
				& .ingredients-template-list {
					margin: spacing.$spacing-x auto spacing.$spacing-3x spacing.$spacing-3x;
				}
				& .description {
					margin: spacing.$spacing-x auto auto auto;
				}
				& .ingredients-template-list {
					margin: spacing.$spacing-x auto spacing.$spacing-x spacing.$spacing-3x;
				}
				& .description {
					margin: spacing.$spacing-1_5x auto auto auto;
				}
				& .default-template-label {
					font-size: fonts.$font-size-hg-btn-inside-caurosel;
					line-height: 14.4px;
					letter-spacing: 0.068px;
				}
				&.default-template-card {
					& .template-card-title {
						font-size: fonts.$font-size-base-mobile;
						line-height: 19.2px;
						letter-spacing: 0.09px;
						margin-top: 1px;
					}
				}
				&:not(.default-template-card) {
					& .template-card-title {
						font-size: fonts.$font-size-base-mobile;
						line-height: 19.2px;
						letter-spacing: 0.09px;
						margin-top: spacing.$spacing-4x;
					}
					& .show-more {
						margin-top: spacing.$spacing-2x;
					}
				}
				& .banned-wrapper {
					margin-top: spacing.$spacing-half-x;
					& .banned-ingredients-number {
						font-size: 28px;
						line-height: normal;
						letter-spacing: 0.21px;
					}
					& .banned-ingredients-label {
						font-size: fonts.$font-size-smaller;
						line-height: 16px;
						letter-spacing: 0.075px;
					}
				}
				& .description {
					font-size: fonts.$font-size-hg-btn-inside-caurosel;
					line-height: 14.4px;
					letter-spacing: 0.068px;
					margin-top: spacing.$spacing-1_5x;
					padding: 0px 0px 0px 0px;
					width: 194px;
					text-align: center;
				}
				& .ingredients-template-list {
					margin: spacing.$spacing-x auto auto spacing.$spacing-3x;
					& > div {
						& > svg {
							margin-top: 1px;
							width: 10px;
							height: 10px;
						}
					}
					& .top-banned-text {
						font-size: fonts.$font-size-smaller;
						line-height: 16px;
						letter-spacing: 0.075px;
					}
					& .ingredient {
						font-size: fonts.$font-size-smaller;
						line-height: 18px;
						letter-spacing: 0.075px;
						margin-left: 2px;
					}
				}
				& .show-more-container {
					display: flex;
					margin: auto;
					align-items: center;
					justify-content: center;
					& .show-more {
						font-size: fonts.$font-size-small-mobile;
						line-height: 16px;
						letter-spacing: 0.075px;
						justify-content: center;
						text-align: center;
					}
				}
			}
		}
	}
}
@media (min-width: 850px) and (max-width: breakpoints.$tablet-breakpoint) {
	.onboarding-template-cards-contianer {
		margin: auto auto auto auto;
		gap: spacing.$spacing-4x;
		& > div {
			&:first-child {
				margin-top: -7px;
			}
			width: 100%;
			height: 296px;
			min-width: 224px;
			& .template-card {
				&.active {
					&:not(.default-template-card) {
						:before {
							content: '';
							position: absolute;
							top: -30px;
							right: 3px;
							transform: translate(50%, -50%);
							width: 24px;
							height: 24px;
							border-radius: border-radius.$radius-circle;
							background: colors.$color-primary url(../../../assets/images/checkmark.png) no-repeat
								center;
						}
					}
				}
				& .ingredients-template-list {
					margin: spacing.$spacing-x auto spacing.$spacing-3x 17%;
				}
				& .description {
					margin: spacing.$spacing-x auto auto auto;
				}
				& .ingredients-template-list {
					margin: spacing.$spacing-x auto spacing.$spacing-x 14%;
				}
				& .description {
					margin: spacing.$spacing-1_5x auto auto auto;
				}
				& .default-template-label {
					font-size: fonts.$font-size-hg-btn-inside-caurosel;
					line-height: 14.4px;
					letter-spacing: 0.068px;
				}
				&.default-template-card {
					& .template-card-title {
						font-size: fonts.$font-size-base-mobile;
						line-height: 19.2px;
						letter-spacing: 0.09px;
						margin-top: 1px;
					}
				}
				&:not(.default-template-card) {
					& .template-card-title {
						font-size: fonts.$font-size-base-mobile;
						line-height: 19.2px;
						letter-spacing: 0.09px;
						margin-top: spacing.$spacing-4x;
					}
					& .show-more {
						margin-top: spacing.$spacing-2x;
					}
				}
				& .banned-wrapper {
					margin-top: spacing.$spacing-half-x;
					& .banned-ingredients-number {
						font-size: 28px;
						line-height: normal;
						letter-spacing: 0.21px;
					}
					& .banned-ingredients-label {
						margin-top: -4px;
						font-size: fonts.$font-size-smaller;
						line-height: 16px;
						letter-spacing: 0.075px;
					}
				}
				& .description {
					font-size: fonts.$font-size-hg-btn-inside-caurosel;
					line-height: 14.4px;
					letter-spacing: 0.068px;
					margin-top: spacing.$spacing-1_5x;
					padding: 0px 0px 0px 0px;
					width: 194px;
					text-align: center;
				}
				& .ingredients-template-list {
					margin: spacing.$spacing-x auto auto spacing.$spacing-3x;
					& > div {
						& > svg {
							margin-top: 2px;
							width: 10px;
							height: 10px;
						}
					}

					& .top-banned-text {
						font-size: fonts.$font-size-smaller;
						line-height: 16px;
						letter-spacing: 0.075px;
					}
					& .ingredient {
						font-size: fonts.$font-size-smaller;
						line-height: 18px;
						letter-spacing: 0.075px;
						margin-left: 2px;
					}
				}
				& .show-more-container {
					display: flex;
					margin: auto;
					align-items: center;
					justify-content: center;
					& .show-more {
						font-size: fonts.$font-size-small-mobile;
						line-height: 16px;
						letter-spacing: 0.075px;
						justify-content: center;
						text-align: center;
					}
				}
			}
		}
	}
}
@media (min-width: breakpoints.$tablet-breakpoint) and (max-width: 1200px) {
	.onboarding-template-cards-contianer {
		margin: auto auto auto auto;
	}
}
