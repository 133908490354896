@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/shadows.scss';

.brand-onboard-content-container {
	& .brand-onboard-title-container {
		& .title {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h4;
			line-height: 33px;
			letter-spacing: 0.0075em;
			color: colors.$color-primary-dark;
		}
	}
	& .cards-container {
		display: flex;
		gap: 22px;
		margin-top: spacing.$spacing-1_5x;
		flex-wrap: nowrap;
		padding: 0px spacing.$spacing-half-x;
		justify-content: space-between;
		& .onboard-card-container {
			display: flex;
			border-radius: border-radius.$radius-default;
			box-shadow: shadows.$shadow-medium;
			height: 335px;
			flex-direction: column;

			& .image-container {
				width: 100%;
				height: 143px;
				&.first-time {
					background: colors.$color-tertiary;
					background-image: url('../../../assets/images/brand-onboard/desktop/first-time.svg');
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					border-radius: border-radius.$radius-default border-radius.$radius-default 0px 0px;
				}
				&.need-changes {
					background: colors.$need-changes-background;
					background-image: url('../../../assets/images/brand-onboard/desktop/need-changes.svg');
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					border-radius: border-radius.$radius-default border-radius.$radius-default 0px 0px;
				}
				&.beacon-only-brand {
					background: colors.$color-primary;
					background-image: url('../../../assets/images/brand-onboard/desktop/beacon-only-brand.svg');
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					border-radius: border-radius.$radius-default border-radius.$radius-default 0px 0px;
				}
			}
			& .information-container {
				margin: spacing.$spacing-3x spacing.$spacing-4x;
				& > .button-container {
					margin-top: spacing.$spacing-1_5x;
					& > button {
						width: 121px;
						height: 28px;
						padding: spacing.$spacing-half-x 20px;
						margin: 0px;
						font-size: fonts.$font-size-small;
						line-height: 19px;
						text-wrap: nowrap;
						gap: 2px;
						& > i {
							font-size: fonts.$font-size-bigger;
							margin-top: 2px;
							font-weight: 500;
						}
						&:hover {
							& > i {
								transform: translateX(2px);
							}
						}
					}
				}
				& .name-container {
					& .name {
						font-family: fonts.$secondary-font-family;
						font-style: normal;
						font-weight: fonts.$font-weight-bold;
						font-size: fonts.$font-size-h5;
						line-height: 27px;
						letter-spacing: 0.0075em;
						color: colors.$color-text-black;
					}
				}
				& .description-container {
					margin-top: spacing.$spacing-1_5x;
					& .description {
						font-family: fonts.$primary-font-family;
						font-style: normal;
						font-weight: fonts.$font-weight-regular;
						font-size: fonts.$font-size-small;
						line-height: 19px;
						letter-spacing: 0.0075em;
						color: colors.$color-text-black;
						display: block;
						height: 39px;
					}
				}
			}
		}
	}
	& .onboard-accordion-container {
		margin-top: spacing.$spacing-7x;
		& .accordion-item-container {
			& .accordion-title-container {
				display: flex;
				align-items: center;
				& > i {
					margin-left: auto;
					margin-right: 20px;
					font-size: fonts.$font-size-h5;
					cursor: pointer;
				}
				& .accordion-title {
					margin-left: spacing.$spacing-4x;
					font-family: fonts.$primary-font-family;
					font-style: normal;
					font-weight: fonts.$font-weight-regular;
					font-size: fonts.$font-size-bigger;
					line-height: 29px;
					letter-spacing: 0.0075em;
					color: colors.$color-text-black;
					cursor: pointer;
					&.active {
						font-family: fonts.$secondary-font-family;
						font-weight: fonts.$font-weight-bold;
						color: colors.$color-primary;
					}
				}
			}
			& .separator {
				width: 100%;
				height: 1px;
				background-color: colors.$color-background-gray;
				margin: spacing.$spacing-2x 0px;
			}
			& .accordion-content {
				margin-top: spacing.$spacing-2x;
				max-width: 100%;
			}
		}
		& .clickable {
			cursor: pointer;
		}
	}
}
@media (min-width: 1600px) {
	.brand-onboard-content-container {
		& .cards-container {
			justify-content: space-between;
		}
	}
}
@media (min-width: 993px) and (max-width: 1436px) {
	.brand-onboard-content-container {
		& .cards-container {
			justify-content: space-between;
		}
	}
}
@media (min-width: 993px) and (max-width: 1200px) {
	.brand-onboard-content-container {
		& .onboard-accordion-container {
			max-width: 900px;
			& .accordion-item-container {
				& .accordion-title-container {
					& .accordion-title {
						margin-left: 0px;
					}
				}
				& .accordion-content {
					margin-left: 0px;
				}
			}
		}
	}
}
@media (min-width: 993px) and (max-width: 1380px) {
	.brand-onboard-content-container {
		& .cards-container {
			& .onboard-card-container {
				width: 100%;
				height: 304px;
				& .image-container {
					width: 100%;
					height: 114px;
				}
				& .information-container {
					margin: spacing.$spacing-2x;
					& .name-container {
						height: 66px;
						& .name {
							font-size: fonts.$font-size-big;
							line-height: 21.79px;
						}
					}
					& .description-container {
						& .description {
							font-size: fonts.$font-size-small-mobile;
							line-height: 16px;
						}
					}
				}
			}
		}
	}
}
@media (min-width: 993px) and (max-width: 1052px) {
	.brand-onboard-content-container {
		& .cards-container {
			gap: 8px;
		}
	}
}
@media (max-width: breakpoints.$tablet-breakpoint) {
	.brand-onboard-content-container {
		& .brand-onboard-title-container {
			& .title {
				font-size: fonts.$font-size-h5;
			}
		}
		& .cards-container {
			gap: 12px;
			flex-wrap: wrap;
			& .onboard-card-container {
				width: 288px;
				height: 282px;
				& .image-container {
					width: 100%;
					height: 114px;
				}
				& .information-container {
					height: 168px;
					margin: 0px;
					padding: spacing.$spacing-2x;
					& > .button-container {
						margin-top: spacing.$spacing-2x;
					}
					& .name-container {
						height: 44px;
						& .name {
							display: flex;
							font-size: fonts.$font-size-big;
							line-height: 21.79px;
						}
					}
					& .description-container {
						margin-top: spacing.$spacing-2x;
						height: 32px;
						& .description {
							display: flex;
							font-size: fonts.$font-size-smaller;
							line-height: 16px;
						}
					}
				}
			}
		}
		& .onboard-accordion-container {
			& .accordion-item-container {
				& .accordion-title-container {
					& .accordion-title {
						margin-left: spacing.$spacing-2x;
						font-size: fonts.$font-size-base;
						line-height: 22.4px;
					}
					& > i {
						margin-right: 0px;
					}
					}
				}
			}
	}
	@media (max-width: 927px) {
		.brand-onboard-content-container {
			& .cards-container {
				justify-content: center;
			}
		}
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.brand-onboard-content-container {
		padding: 0px spacing.$spacing-2x;
		& .brand-onboard-title-container {
			& .title {
				font-size: fonts.$font-size-h5-mobile;
				line-height: 24.51px;
			}
		}
		& .onboard-accordion-container {
			& .accordion-item-container {
				& .accordion-title-container {
					& .accordion-title {
						font-size: fonts.$font-size-bigger-mobile;
						line-height: 25.6px;
					}
				}
				& .accordion-content {
					margin-top: spacing.$spacing-x;
				}
			}
		}
	}
}
@media (max-width: 500px) {
	.brand-onboard-content-container {
		padding: 0px spacing.$spacing-x;
		& .cards-container {
			gap: 12px;
			& .onboard-card-container {
				width: 288px;
				& .image-container {
					width: 100%;
					&.first-time {
						background-image: url('../../../assets/images/brand-onboard/mobile/first-time.svg');
					}
					&.need-changes {
						background-image: url('../../../assets/images/brand-onboard/mobile/need-changes.svg');
					}
					&.beacon-only-brand {
						background-image: url('../../../assets/images/brand-onboard/mobile/beacon-only-brand.svg');
					}
				}
			}
		}
		& .onboard-accordion-container {
			& .accordion-item-container {
				& .accordion-title-container {
					& .accordion-title {
						margin-right: spacing.$spacing-2x;
						margin-left: spacing.$spacing-4x;
					}
					& >i {
						margin-right: spacing.$spacing-x;
					}
				}
			}
		}
	}
}
