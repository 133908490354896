@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/shadows.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.square-button {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 6px 8px;
	gap: 8px;
	background: colors.$color-gray-light;
	border-radius: border-radius.$radius-small 0px 0px border-radius.$radius-small;
	font-family: fonts.$tertiary-font-family;
	font-style: normal;
	font-weight: fonts.$font-weight-semibold;
	font-size: fonts.$font-size-small;
	line-height: 20px;
	letter-spacing: fonts.$letter-spacing-std;
	border: none;
	color: colors.$color-text-gray-slate-light;
	&.active {
		&.blue {
			color: colors.$color-primary;
			background: #f2f8fa;
		}
		&.green {
			color: colors.$color-second-tier;
			background: colors.$color-success-light;
		}
		&.yellow {
			color: colors.$color-third-tier-2;
			background: colors.$color-warning-light;
		}
	}
}
.rounded-button {
	align-items: center;
	border-radius: 20px;
	background: #f7f7f8;
	border: 1px solid #e7e8ea;
	color: colors.$color-text-gray-slate-light;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	font-family: fonts.$tertiary-font-family;
	font-size: fonts.$font-size-small;
	font-style: normal;
	font-weight: fonts.$font-weight-semibold;
	gap: 8px;
	justify-content: center;
	letter-spacing: fonts.$letter-spacing-std;
	line-height: 20px;
	order: 0;
	padding: 6px 16px;
	text-align: center;
	flex-grow: 0;
	margin: 8px;
	width: fit-content;
	&.active {
		&.blue {
			color: colors.$color-primary;
			background: #e6f1f6;
			border: 1.5px solid colors.$color-primary;
		}
		&.green {
			color: colors.$color-second-tier;
			background: #ecf7f2;
			border: 1.5px solid colors.$color-tertiary;
		}
		&.yellow {
			color: colors.$color-third-tier-2;
			background: #fff4e6;
			border: 1.5px solid colors.$color-third-tier;
		}
	}
	&.primary {
		align-items: center;
		background: colors.$color-primary;
		border: none;
		border-radius: border-radius.$radius-biggest;
		box-shadow: shadows.$shadow-low;
		color: colors.$color-text-white;
		display: flex;
		flex-direction: row;
		font-family: fonts.$tertiary-font-family;
		font-size: fonts.$font-size-base;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		gap: 4px;
		justify-content: center;
		letter-spacing: fonts.$letter-spacing-std;
		line-height: 22px;
		padding: 8px 32px;
		text-align: center;
		&:hover {
			background: colors.$color-primary-dark;
			box-shadow: shadows.$shadow-high;
		}
		&:active {
			background: colors.$color-primary-dark;
			box-shadow: shadows.$shadow-high-inner;
		}
		&:disabled {
			background: colors.$color-gray-medium;
			&:hover {
				background: colors.$color-gray-medium;
				box-shadow: shadows.$shadow-low;
			}
			&:active {
				background: colors.$color-gray-medium;
				box-shadow: shadows.$shadow-low;
			}
		}
	}
	&.secondary {
		align-items: center;
		background: colors.$color-background-white;
		border-radius: border-radius.$radius-biggest;
		border: 2px solid colors.$color-primary;
		box-shadow: shadows.$shadow-low;
		box-sizing: border-box;
		color: colors.$color-primary;
		display: flex;
		flex-direction: row;
		font-family: fonts.$tertiary-font-family;
		font-size: fonts.$font-size-base;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		gap: 4px;
		justify-content: center;
		letter-spacing: fonts.$letter-spacing-std;
		line-height: 22px;
		padding: 8px 32px;
		text-align: center;
		&:hover {
			background: colors.$color-primary-light;
			box-shadow: shadows.$shadow-high;
		}
		&:active {
			background: colors.$color-primary-light;
			box-shadow: shadows.$shadow-high-inner;
		}
		&:disabled {
			border: 2px solid colors.$color-gray-medium;
			box-shadow: shadows.$shadow-low;
			color: colors.$color-gray-medium;
			&:hover {
				background: colors.$color-background-white;
				box-shadow: shadows.$shadow-low;
			}
			&:active {
				background: colors.$color-background-white;
				box-shadow: shadows.$shadow-low;
			}
		}
	}
	&.tertiary {
		align-items: center;
		background: colors.$color-transparent;
		border: none;
		color: colors.$color-primary;
		display: flex;
		flex-direction: row;
		font-family: fonts.$tertiary-font-family;
		font-size: fonts.$font-size-base;
		font-style: normal;
		font-weight: fonts.$font-weight-semibold;
		gap: 4px;
		justify-content: center;
		letter-spacing: fonts.$letter-spacing-std;
		line-height: 22px;
		padding: 8px 32px;
		text-align: center;
		&:hover {
			background: colors.$color-background-white;
			box-shadow: shadows.$shadow-low;
			border-radius: border-radius.$radius-biggest;
		}
		&:active {
			background: colors.$color-background-white;
			box-shadow: shadows.$shadow-high-inner;
		}
		&:disabled {
			background: colors.$color-transparent;
			border: none;
			color: colors.$color-gray-medium;
			&:hover {
				background: colors.$color-transparent;
				box-shadow: none;
			}
			&:active {
				background: colors.$color-transparent;
				box-shadow: none;
			}
		}
	}
	&.icon-only {
		width: 38px;
		height: 38px;
		border-radius: 50%;
		padding: 0px;
	}
}
button:focus {
	outline: none !important;
}
.how-good-btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 8px;
	gap: 10px;
	max-width: 195px;
	width: auto;
	height: 21px;
	border-radius: border-radius.$radius-medium;
	border: none;
	font-family: fonts.$primary-font-family;
	font-style: normal;
	font-weight: fonts.$font-weight-regular;
	font-size: fonts.$font-size-small;
	line-height: 14px;
	color: colors.$color-text-black;
	&.red-score {
		background: colors.$color-howgood-very-bad;
	}
	&.ligth-orange-score {
		background: colors.$color-howgood-bad;
	}
	&.yellow-score {
		background: colors.$color-howgood-mid;
	}
	&.green-score {
		background: colors.$color-howgood-good;
	}
	&.dark-green-score {
		background: colors.$color-howgood-very-good;
	}
	&.good-estimate {
		background: colors.$good-estimate;
	}
	&.great-estimate {
		background: colors.$great-estimate;
	}
	&.best-estimate {
		background: colors.$best-estimate;
	}
	&.none-estimate {
		background: colors.$color-background-gray;
	}
}
.show-all-filters-btn {
	align-items: center;
	border-radius: 20px;
	border: 1px solid colors.$color-gray-slate-light;
	box-sizing: border-box;
	color: colors.$color-text-black;
	display: flex;
	flex-direction: row;
	flex-grow: 0;
	font-family: fonts.$primary-font-family;
	font-size: fonts.$font-size-small;
	font-style: normal;
	font-weight: fonts.$font-weight-regular;
	gap: 6px;
	height: 28px;
	letter-spacing: fonts.$letter-spacing-std;
	line-height: 20px;
	padding: 4px 12px 4px 16px;
	width: fit-content;
	background: colors.$color-background-white;
}
.sort-by-btn {
	font-family: fonts.$primary-font-family;
	font-weight: fonts.$font-weight-regular;
	font-size: fonts.$font-size-small;
	background: colors.$color-background-white;
	font-style: normal;
	line-height: 19px;
	display: flex;
	align-items: center;
	letter-spacing: 0.0075em;
	color: #000000;
	box-sizing: border-box;
	padding: 4px 12px;
	gap: 6px;
	border: 1px solid colors.$color-gray-slate-light;
	border-radius: 20px;
	z-index: 200;
	width: 209px;
	justify-content: center;
	&::before {
		content: 'Sort by:';
		color: colors.$color-gray-slate;
	}
	& img {
		margin-left: 4px;
	}
	&.left-align {
		justify-content: flex-start;
		& > i {
			margin-left: auto;
		}
	}
	&:hover {
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.rounded-button {
		&.primary {
			padding: 4px 28px;
			gap: 4px;
			width: fit-content;
			font-size: fonts.$font-size-small;
			line-height: 19px;
			&:hover {
				background: colors.$color-primary;
				box-shadow: shadows.$shadow-low;
			}
			&:active {
				box-shadow: shadows.$shadow-high-inner;
			}
		}
		&.secondary {
			padding: 4px 28px;
			gap: 4px;
			width: fit-content;
			font-size: fonts.$font-size-small;
			line-height: 19px;
			&:hover {
				background: colors.$color-background-white;
				box-shadow: shadows.$shadow-low;
			}
			&:active {
				box-shadow: shadows.$shadow-high-inner;
			}
		}
		&.tertiary {
			padding: 4px 28px;
			gap: 4px;
			width: fit-content;
			font-size: fonts.$font-size-small;
			line-height: 19px;
			&:hover {
				background: colors.$color-transparent;
				box-shadow: none;
			}
			&:active {
				box-shadow: shadows.$shadow-high-inner;
			}
		}
		&.icon-only {
			width: 28px !important;
			height: 28px;
			border-radius: 50%;
			padding: 0px;
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.how-good-btn {
		margin-bottom: spacing.$spacing-half-x;
		font-size: fonts.$font-size-small-mobile;
	}
	.rounded-button {
		width: 100%;
	}
	.sort-by-btn {
		width: 100%;
		&::before {
			content: 'Sort By';
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.how-good-btn {
		width: 108px;
		height: 16px;
		font-size: fonts.$font-size-hg-btn-inside-caurosel;
		& .product-score {
			font-size: fonts.$font-size-small-mobile;
			margin-right: -25px;
			margin-left: -25px;
			&.best-hg-wrapper {
				margin-left: -27px;
			}
		}
		& .world-icon {
			font-size: fonts.$font-size-small-mobile;
			margin-bottom: 0px;
			margin-left: -5px;
		}
	}
}
/** Safari */
@media (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) and (stroke-color: transparent) {
		@media screen and ((min-width: 977px) and (max-width: 992px)) {
			.how-good-btn {
				width: 138px;
				height: 16px;
			}
		}
	}
}
