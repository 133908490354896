@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';

.sai-container {
	background-color: colors.$color-secondary-lightest;
	border-radius: 12px;
	padding: spacing.$spacing-2x;
	display: flex;
	justify-content: space-between;
}
