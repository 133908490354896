@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';

.brand-tab {
	padding-top: spacing.$spacing-3x;
	margin-bottom: spacing.$spacing-2x;
}
.processing-info-container {
	display: inline-flex !important;
	flex-direction: row !important;
	flex-wrap: wrap !important;
	word-break: break-all !important;
	gap: 0px !important;
	.title-technique {
		display: contents;
		word-break: normal !important;
		font-family: fonts.$tertiary-font-family;
		font-weight: fonts.$font-weight-semibold;
	}
	.description-technique {
		display: contents !important;
		word-break: normal !important;
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.brand-tab {
		padding-top: spacing.$spacing-2x;
		margin-bottom: spacing.$spacing-2x;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.brand-tab {
		padding-top: spacing.$spacing-2x;
		margin-bottom: spacing.$spacing-2x;
	}
}
