@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.filter-control-container {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: spacing.$spacing-x;
	& .items-length {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-small;
		line-height: 20px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-gray-slate;
		margin-left: 1%;
		width: fit-content;
		&.length-wrapper {
			margin-top: 5px;
		}
		&.length-alingment {
			margin-left: auto;
			margin-right: 38px;
		}
	}
	& .clear-all {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-smaller;
		line-height: 16px;
		text-align: center;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
		margin-top: 2px;
		margin-left: 1%;
		cursor: pointer;
		border-bottom: 1px solid colors.$color-text-black;
		width: fit-content;
		height: fit-content;
		&.length-wrapper {
			margin-top: 7px;
		}
	}
	& .sort-products {
		width: fit-content;
		margin-left: auto;
		z-index: 2;
	}
	&.expanded-wrapper {
		margin-top: spacing.$spacing-4x;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.filter-control-container {
		display: flex;
		margin: spacing.$spacing-4x 0px 0px 2%;
		width: 97%;
		& .sort-products {
			width: fit-content;
			margin: unset;
			margin-right: spacing.$spacing-x;
		}
		& .items-length {
			margin-left: auto;
			&.length-alingment {
				margin: auto spacing.$spacing-half-x auto auto;
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.products-wrapper {
		margin-top: 0px;
	}
	.filter-control-container {
		display: flex;
		margin: 8px 0px 0px spacing.$spacing-2x;
		width: 92%;
		& .sort-products {
			width: fit-content;
			margin: unset;
		}
		& .items-length {
			margin-left: auto;
			&.length-alingment {
				margin-left: auto;
				margin-right: 4px;
			}
		}
		&.search-wrapper {
			margin-top: 0px;
		}
		&.expanded-wrapper {
			margin-top: spacing.$spacing-x;
		}
	}
	@media screen and (min-width: 500px) {
		.filter-control-container {
			width: 97%;
		}
	}
	@media screen and (min-width: 630px) {
		.filter-control-container {
			width: 94%;
		}
	}
}
