@use './styles/tokens/opacity.scss';
@use './styles/tokens/fonts.scss';
@import './styles/typography/typography.scss';
@import './styles/keyframes/keyframes.scss';
* {
	box-sizing: border-box;
}

body {
	min-height: 100vh;
	font-family: fonts.$primary-font-family;
}
@font-face {
	font-family: fonts.$primary-font-family;
	src: url('assets/fonts/OpenSans-VariableFont_wdth\,wght.ttf');
	font-weight: fonts.$font-weight-regular;
}
@font-face {
	font-family: fonts.$secondary-font-family;
	src: url('assets/fonts/OpenSans-VariableFont_wdth\,wght.ttf');
	font-weight: fonts.$font-weight-bold;
}
@font-face {
	font-family: fonts.$tertiary-font-family;
	src: url('assets/fonts/OpenSans-VariableFont_wdth\,wght.ttf');
	font-weight: fonts.$font-weight-semibold;
}
.app-overlay {
	opacity: opacity.$opacity-90;
	filter: brightness(1);
}
.col {
	padding: 0 !important;
}
