@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';
.form-container {
	margin-top: spacing.$spacing-3x;
	max-width: 887px;
	display: flex;
	.row-form {
		width: 100%;
		display: flex;
		max-width: 887px;
		justify-content: space-between;
		gap: 16px;
		> :first-child {
			flex: 1 1 365px;
			max-width: 365px;
		}
		> :nth-child(2) {
			flex: 1 1 365px;
			max-width: 365px;
		}
		.column-form {
			display: flex;
			flex-direction: column;
			gap: 16px;
			.brand-logo-container {
				label {
					font-family: fonts.$tertiary-font-family;
					font-weight: fonts.$font-weight-semibold;
					font-size: fonts.$font-size-base;
				}
				.brand-image {
					flex: 1;
					.image-container {
						position: relative;
						width: 259px;
						padding: spacing.$spacing-2x;
						box-shadow: shadows.$shadow-brand-image;
						border-radius: border-radius.$radius-brand-image;
						height: 235px;

						& > img {
							object-fit: contain;
							width: 100%;
							height: 201px;
						}
						i {
							position: absolute;
							left: 223px;
							top: 11.5px;
							font-size: fonts.$font-size-h4;
							color: colors.$color-text-gray-slate-light;
						}
					}
				}
			}
			.mission-container {
				div {
					display: flex;
					align-items: center;
					flex-direction: row;
					justify-content: space-between;
					p {
						flex: 1 1;
						margin: 0px;
						margin-right: spacing.$spacing-1_5x;
					}
					button {
						border: none;
						border-bottom: 1px solid colors.$color-primary;
						background-color: transparent;
						padding: 0px;
						color: colors.$color-primary;
					}
				}
			}
			.story-container {
				div {
					display: flex;
					align-items: center;
					flex-direction: row;
					justify-content: space-between;
					p {
						margin: 0px;
						margin-right: spacing.$spacing-1_5x;
						flex: 1 1;
					}
					button {
						border: none;
						border-bottom: 1px solid colors.$color-primary;
						background-color: transparent;
						padding: 0px;
						color: colors.$color-primary;
					}
				}
			}
			.description-container {
				div {
					display: flex;
					align-items: center;
					flex-direction: row;
					justify-content: space-between;
					p {
						margin: 0px;
						margin-right: spacing.$spacing-1_5x;
					}
					button {
						border: none;
						border-bottom: 1px solid colors.$color-primary;
						background-color: transparent;
						padding: 0px;
						color: colors.$color-primary;
					}
				}
			}
			.blank-space {
				height: 266.9px;
			}
			div {
				display: flex;
				flex-direction: column;
				gap: 8px;
				.blank-field {
					border-bottom: 1px solid colors.$color-gray-slate;
					margin-top: 17px;
					max-width: 60%;
					height: 12px;
				}
				.gray-text {
					font-family: fonts.$primary-font-family;
					font-size: fonts.$font-size-small;
					font-weight: fonts.$font-weight-regular;
					line-height: 22.4px;
					letter-spacing: 0.0075em;
					color: colors.$color-gray-slate;
				}
				.toggle-button {
					margin: 0px;
					padding: 0px;
					font-size: fonts.$font-size-h4;
					color: colors.$color-text-gray-slate-light;
					box-shadow: none;
				}
				.checkbox-container {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 2px;
					i {
						font-size: fonts.$font-size-h4;
						color: colors.$color-text-gray-slate-light;
					}
					p {
						margin: 0px;
					}
				}
			}
			p {
				margin: 0px;
				color: colors.$color-gray-slate;
			}
		}
		.column-form.brokers {
			.broker-fields {
				display: flex;
				gap: 16px;
			}
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.form-container {
		margin-top: spacing.$spacing-3x;
		.row-form {
			display: flex;
			gap: 16px;
			width: 100%;
			justify-content: space-between;
			> :first-child {
				flex: 1 1 348px;
				max-width: 348px;
			}
			> :nth-child(2) {
				flex: 1 1 348px;
				max-width: 348px;
			}
			.column-form {
				.description-container {
					div {
						button {
							font-size: fonts.$font-size-base;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.form-container {
		margin-top: spacing.$spacing-3x;
		display: flex;

		.row-form {
			margin: auto;
			margin-left: 0px;
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 16px;
			.column-form {
				.blank-space {
					height: 0px;
				}
				.brand-logo-container {
					gap: 0px;
					label {
						font-family: fonts.$tertiary-font-family;
						font-weight: fonts.$font-weight-semibold;
						font-size: fonts.$font-size-base;
					}
					.brand-image {
						flex: 1;
						margin: auto;
						margin-top: 8px;
						.image-container {
							position: relative;
							width: 259px;
							padding: spacing.$spacing-2x;
							box-shadow: shadows.$shadow-brand-image;
							border-radius: border-radius.$radius-brand-image;
							height: 235px;

							& > img {
								object-fit: contain;
								width: 100%;
								height: 201px;
								margin: auto;
							}
							i {
								position: absolute;
								left: 223px;
								top: 11.5px;
								font-size: fonts.$font-size-h4;
								color: colors.$color-text-gray-slate-light;
							}
						}
					}
				}
				display: flex;
				flex-direction: column;
				gap: 16px;
				width: 100%;
				max-width: 100%;
				flex: 1;
				div {
					gap: 16px;
					p {
						word-break: break-word;
					}
				}
			}
		}
	}
}
