@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';
.actions {
	height: 39px;
	width: 100%;
	margin-top: spacing.$spacing-2x;
	margin-bottom: spacing.$spacing-x;
	display: flex;
	gap: 12px;
	button {
		height: 39px;
		font-size: fonts.$font-size-small;
		margin: 0px;
	}
}
.error-data {
	margin-bottom: spacing.$spacing-4x;
	color: colors.$input-error;
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.actions {
		height: auto;
		width: 100%;
		margin-top: spacing.$spacing-2x;
		margin-bottom: spacing.$spacing-2x;
		display: flex;
		flex-direction: column;
		gap: 12px;
		button {
			height: 28px;
			font-size: fonts.$font-size-small;
			margin: 0px;
		}
	}
}
