@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/opacity.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/spacing.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/shadows.scss';

.product-about-form {
	margin-top: spacing.$spacing-2x;
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.product-about-form {
		margin-top: 0px;
	}
}
