@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/opacity.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/spacing.scss';
@use '../../styles/tokens/breakpoints.scss';
.warning-overlay-container {
	display: flex;
	margin: auto;
	width: 613px;
	height: 325px;
	flex-direction: column;
	z-index: 100000;
	.warning-title-container {
		position: relative;
		background-image: colors.$onboard-page-metric-gradient;
		border-radius: border-radius.$radius-medium border-radius.$radius-medium 0px 0px;
		height: 78px;
		width: 100%;
		display: flex;
		p {
			font-family: fonts.$secondary-font-family;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h5;
			color: colors.$color-text-white;
			margin: auto;
		}
		button {
			position: absolute;
			margin: 0px;
			top: 10px;
			right: 10px;
			color: colors.$color-text-white;
			i {
				font-size: fonts.$font-size-h5;
			}
		}
		button:hover {
			background: none;
			box-shadow: none;
			border-radius: 0px;
		}
	}
	.warning-body-container {
		background-color: colors.$color-background-white;
		border-radius: 0px 0px border-radius.$radius-medium border-radius.$radius-medium;
		padding: spacing.$spacing-4x;
		.warning-description-container {
			max-width: 549px;
			display: flex;
			flex-direction: column;
			gap: 24px;
			.description-title {
				font-family: fonts.$secondary-font-family;
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-h5;
				color: colors.$color-primary-dark;
				line-height: 32px;
			}
			.description-body {
				font-size: 20px;
			}
		}
		.warning-buttons-container {
			display: flex;
			justify-content: end;
			gap: 12px;
			button {
				margin: 0px;
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.warning-overlay-container {
		width: 320px;
		height: 294px;
		.warning-title-container {
			height: 63px;
			width: 320px;
			p {
				font-weight: fonts.$font-weight-bold;
				font-size: fonts.$font-size-base;
				color: colors.$color-text-white;
				margin: auto;
			}
			button {
				position: absolute;
				margin: 0px;
				top: 10px;
				right: 5px;
				color: colors.$color-text-white;
				i {
					font-size: fonts.$font-size-h4;
				}
			}
		}
		.warning-body-container {
			padding: spacing.$spacing-3x spacing.$spacing-2x;
			height: 231px;
			.warning-description-container {
				width: 291px;
				display: flex;
				flex-direction: column;
				gap: 24px;
				.description-title {
					font-family: fonts.$secondary-font-family;
					font-weight: fonts.$font-weight-bold;
					font-size: fonts.$font-size-base-mobile;
					line-height: 19.2px;
				}
				.description-body {
					font-size: fonts.$font-size-base-mobile;
				}
			}
			.warning-buttons-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 12px;
				button {
					padding: spacing.$spacing-half-x spacing.$spacing-3_5x;
					line-height: 19.2px;
					margin: 0px;
					font-size: fonts.$font-size-base-mobile;
					max-height: 28px;
				}
				:first-child{
					border: solid 1px colors.$color-primary;
				}
			}
		}
	}
}
