@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.hide-filter-container {
	display: flex;
	width: 100%;
	border-bottom: 2px solid colors.$color-gray;
	& .filter-text {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-small;
		line-height: 19px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
		margin-bottom: 8px;
	}
	& .hide-filter {
		cursor: pointer;
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-small;
		line-height: 20px;
		text-align: center;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-gray-slate-light;
		order: 0;
		flex-grow: 0;
		margin: 0px 0px 8px auto;
		display: flex;
		& .back-icon {
			margin: 0px 3px 2px 0px;
			font-size: fonts.$font-size-big;
		}
		& .hide-filter-text {
			position: relative;
			&::before {
				content: '';
				position: absolute;
				width: 64px;
				height: 0;
				left: 0;
				bottom: 3px;
				border-bottom: 1px solid colors.$color-text-gray-slate-light;
			}
		}
	}
	.close-filter {
		margin-left: auto;
		margin-right: 2px;
		& > img {
			width: 10.61px;
			height: 10.61px;
		}
		& > i {
			font-size: fonts.$font-size-h5;
			color: colors.$color-text-black;
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.hide-filter-container {
		margin-left: 5%;
		width: 90% !important;
		border: none !important;
		& .filter-text {
			margin: spacing.$spacing-1_5x 0px 9px 0px;
			font-size: fonts.$font-size-bigger-mobile;
		}
		& .filter-text::before {
			content: '';
			position: absolute;
			width: 21.5%;
			height: 0;
			left: 17px;
			top: 130px;
			bottom: spacing.$spacing-half-x;
			border-bottom: 3px solid;
			border-image-slice: 1;
			border-width: 3px;
			border-image-source: linear-gradient(90deg, #0172a9 0%, #03aed5 100%);
			border-radius: 50px;
		}
		& .close-filter {
			margin: spacing.$spacing-1_25x 2px 0px auto;
			& > img {
				width: 10.61px;
				height: 10.61px;
			}
			& > i {
				font-size: fonts.$font-size-h5;
				color: colors.$color-text-black;
			}
		}
	}
	.line {
		width: auto !important;
		& :last-child {
			margin-bottom: 13px !important;
		}
	}
}
