@use '../../../../styles/tokens/border-radius.scss';
@use '../../../../styles/tokens/colors.scss';
@use '../../../../styles/tokens/shadows.scss';
@use '../../../../styles/tokens/fonts.scss';
@use '../../../../styles/tokens/breakpoints.scss';
@use '../../../../styles/tokens/spacing.scss';
.template-card {
	box-sizing: border-box;
	width: 100%;
	height: auto;
	box-shadow: shadows.$shadow-medium;
	border-radius: border-radius.$radius-medium;
	flex-grow: 1;
	text-align: center;
	cursor: pointer;
	background: colors.$color-white;
	&.active {
		background: colors.$color-info-light;
		border: 2px solid colors.$color-primary;
		& .default-template-label {
			color: colors.$color-primary;
		}
		&.default-template-card {
			& :before {
				content: '';
				position: absolute;
				top: -14px;
				right: 3px;
				transform: translate(50%, -50%);
				width: 24px;
				height: 24px;
				border-radius: border-radius.$radius-circle;
				background: colors.$color-primary url(../../../../assets/images/checkmark.png) no-repeat
					center;
			}
		}
		&:not(.default-template-card) {
			& :before {
				content: '';
				position: absolute;
				top: -45px;
				right: 3px;
				transform: translate(50%, -50%);
				width: 24px;
				height: 24px;
				border-radius: border-radius.$radius-circle;
				background: colors.$color-primary url(../../../../assets/images/checkmark.png) no-repeat
					center;
			}
		}
		& .template-card-title {
			color: colors.$color-primary;
			margin-top: spacing.$spacing-2x;
			&:not(.default-template) {
				margin-top: spacing.$spacing-6x;
			}
		}
		& .show-more {
			color: colors.$color-primary;
		}
	}
	& .template-card-title {
		font-family: fonts.$secondary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-bold;
		font-size: fonts.$font-size-base;
		line-height: 22px;
		text-align: center;
		letter-spacing: fonts.$letter-spacing-std;
		margin-top: spacing.$spacing-2x;
		&:not(.default-template) {
			margin-top: spacing.$spacing-6x;
		}
	}
	.banned-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		& .banned-ingredients-number {
			width: 100%;
			height: 38px;
			font-family: fonts.$tertiary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: 36px;
			line-height: 44px;
			text-align: center;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
			justify-content: center;
			margin-top: 8px;
		}
		& .banned-ingredients-label {
			width: 100%;
			height: 20px;
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-small;
			line-height: 20px;
			text-align: center;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
		}
	}
	& .description {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-base;
		line-height: 22px;
		text-align: center;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-text-black;
		margin-top: 24px;
		width: 100%;
		padding: 0px 15% 0px 15%;
		& .all-products-text {
			font-family: fonts.$secondary-font-family;
		}
	}
	& .ingredients-template-list {
		margin: 40px 0px 0px 40px;
		display: flex;
		flex-direction: column;
		text-align: left;
		& .full {
			height: auto;
		}
		& .collapsed {
			height: 110px;
		}
		& .top-banned-text {
			font-family: fonts.$tertiary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-small;
			line-height: 22px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
		}
		& .ingredient {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-small;
			line-height: 22px;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-gray-slate;
			justify-content: left;
			margin-left: 6.82px;
		}
	}
	& .show-more-container {
		margin: spacing.$spacing-half-x auto 44px 47px;
		display: flex;
		align-items: start;
		& .show-more {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-small;
			line-height: 16px;
			letter-spacing: fonts.$letter-spacing-std;
			cursor: pointer;
		}
	}
	& .default-template-label {
		font-family: fonts.$primary-font-family;
		font-style: normal;
		font-weight: fonts.$font-weight-regular;
		font-size: fonts.$font-size-small;
		line-height: 16px;
		letter-spacing: fonts.$letter-spacing-std;
		color: colors.$color-gray-darker;
		text-align: left;
		display: flex;
		margin: 16px 0px 0px 16px;
	}
}
.ingredients-template-card {
	display: flex;
	flex-direction: row;
	& > div {
		display: flex;
		width: 100%;
	}
}
.slider-content {
	width: 100%;
	&.slick-arrow {
		display: none;
	}
	& .slick-slider {
		margin-left: -27px;
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.ingredients-template-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		& > div {
			display: flex;
			width: 255px;
			margin-bottom: spacing.$spacing-2x;
		}
		& .template-card {
			padding: 0px spacing.$spacing-x;
			width: 275px;
			& .default-template-label {
				font-size: fonts.$font-size-smaller;
			}
			& .template-card-title {
				font-size: fonts.$font-size-small;
			}
			& .banned-wrapper {
				& .banned-ingredients-number {
					font-size: fonts.$font-size-h4;
				}
				& .banned-ingredients-label {
					font-size: fonts.$font-size-smaller;
				}
			}
			& .description {
				font-size: fonts.$font-size-smaller;
				line-height: 16px;
				padding: 0px;
			}
			& .top-banned-text {
				font-size: fonts.$font-size-smaller;
			}
			& .ingredient {
				font-size: fonts.$font-size-smaller;
			}
			& .ingredients-template-list {
				line-height: 0.8px;
				margin: spacing.$spacing-x 0 0;
			}
			& .show-more {
				font-size: fonts.$font-size-smaller;
			}
			&.blue {
				&.active {
					& :before {
						right: -6px;
					}
				}
				& :before {
					right: -6px;
				}
			}
			&.yellow {
				&.active {
					& :before {
						right: -6px;
					}
				}
				& :before {
					right: -6px;
				}
			}
			&.green {
				&.active {
					& :before {
						right: -6px;
					}
				}
				& :before {
					right: -6px;
				}
			}
		}
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.ingredients-template-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		& > div {
			display: flex;
			width: 255px;
			margin-bottom: 16px;
		}
		& .template-card {
			padding: 0px 10px;
			& .default-template-label {
				font-size: fonts.$font-size-smaller;
			}
			& .template-card-title {
				font-size: fonts.$font-size-small;
			}
			& .banned-wrapper {
				& .banned-ingredients-number {
					font-size: fonts.$font-size-h4;
				}
				& .banned-ingredients-label {
					font-size: fonts.$font-size-smaller;
				}
			}
			& .description {
				font-size: fonts.$font-size-smaller;
				line-height: 16px;
				padding: 0px;
			}
			& .top-banned-text {
				font-size: fonts.$font-size-smaller;
			}
			& .ingredient {
				font-size: fonts.$font-size-smaller;
			}
			& .ingredients-template-list {
				line-height: 0.8px;
				margin: 8px 0 0;
			}
			& .show-more {
				font-size: fonts.$font-size-smaller;
			}
		}
	}
}
