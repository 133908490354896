@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/opacity.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/spacing.scss';
@use '../../styles/tokens/breakpoints.scss';
.ingredients-template {
	& .template-profile-learn-more-container {
		& .action-link-contianer {
			display: flex;
			gap: 4px;
			& .action-link {
				font-style: normal;
				width: fit-content;
				letter-spacing: fonts.$letter-spacing-std;
			}
			& > i {
				font-size: 22px;
				margin-top: -4px;
				width: 22px;
				height: 22px;
			}
		}
	}
}

.in-profile {
	padding: 100px 250px;
	box-shadow: shadows.$shadow-medium;
	border-radius: border-radius.$radius-medium;
	border: 1px solid colors.$color-gray;
	position: relative;
	background-color: colors.$color-background-white;
}

.edit-btn-template {
	position: absolute;
	top: 24px;
	right: 24px;
	line-height: 26px;
	font-size: fonts.$font-size-big;
	font-weight: fonts.$font-weight-semibold;
	color: #8d949a;
	font-style: normal;
	height: 26px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	display: flex;
	& .gear-icon {
		font-size: fonts.$font-size-h4;
		margin-right: spacing.$spacing-half-x;
	}
}

.save-btn {
	justify-content: end;
	background: colors.$color-first-tier;
	font-weight: fonts.$font-weight-semibold;
	font-size: fonts.$font-size-big;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.0075em;
	border: none;
	padding: 6px 32px;
	color: colors.$color-white;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	border-radius: 40px;
	margin-left: auto;
	margin-top: 24px;
}

button.show {
	display: block;
}

button.hide {
	display: none;
}
.template-title-show {
	font-family: fonts.$secondary-font-family;
	font-style: normal;
	font-weight: fonts.$font-weight-bold;
	font-size: fonts.$font-size-h4;
	line-height: fonts.$font-size-h3;
	letter-spacing: fonts.$letter-spacing-std;
	color: colors.$color-black;
	margin: 50px 0 spacing.$spacing-x 0;
}

.template-title-show span.card-name {
	color: colors.$color-primary;
}

.Toastify {
	.toast-body {
		padding: 5px 5px 5px 28px;
		h5 {
			margin-bottom: 0;
		}
		.toat-text {
			font-size: 14px;
		}
	}
	.Toastify__progress-bar {
		height: 3px;
	}
	.Toastify__toast {
		border-radius: 8px;
	}
}
.template-cards-profile {
	width: 100%;
	margin: 30px auto 40px auto;
}
.ingredients-template {
	margin: spacing.$spacing-2x;
}
@media (max-width: 768px) {
	.in-profile {
		padding: 40px 16px;
	}
	.template-title-show {
		font-family: fonts.$secondary-font-family;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 24.51px;
		letter-spacing: 0.75%;
		color: colors.$color-black;
	}

	.edit-btn-template {
		position: absolute;
		top: 12px;
		right: 8px;
		height: 16px;
		line-height: 16px;
		font-size: fonts.$font-size-small-mobile;
		font-weight: fonts.$font-weight-semibold;
		color: colors.$color-gray-slate-lighter;
		font-style: normal;
		cursor: pointer;
		& .gear-icon {
			font-size: fonts.$font-size-h6-mobile;
		}
	}
	.ingredients-template {
		.col {
			padding-left: 5px;
			padding-right: 5px;
		}

		.save-btn {
			font-size: 12px;
			line-height: 20px;
		}
		& .template-header {
			margin: auto auto auto auto;
		}
		& .detailed-template-profile {
			margin: auto spacing.$spacing-2x auto spacing.$spacing-2x;
		}
	}
	.Toastify {
		.toast-body {
			padding: 5px 5px 5px 9px;
			h5 {
				margin-bottom: 0;
				font-size: 18px;
			}
			.toat-text {
				font-size: 12px;
			}
		}
		.Toastify__toast-icon {
			width: 13.3px;
			height: 13.3px;
		}
		.Toastify__toast {
			padding: 0 8px;
			border-radius: 8px;
		}
		.Toastify__toast-container--top-right {
			top: 20px;
			right: 10px;
		}
		.Toastify__toast-container {
			left: auto;
		}
	}
	.template-cards-profile {
		width: 100%;
	}
}
@media (max-width: 743px) {
	.template-cards-profile {
		width: 93%;
		margin: spacing.$spacing-2x spacing.$spacing-2x 40px spacing.$spacing-2x;
	}
}
@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.ingredients-template {
		& .template-title-show {
			margin-top: spacing.$spacing-5x;
			line-height: normal;
			font-size: fonts.$font-size-h5;
			letter-spacing: 0.15px;
		}
		& .detailed-template-profile {
			margin: auto auto auto spacing.$spacing-2x;
		}
		& .template-cards-profile {
			margin-top: spacing.$spacing-3x;
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.ingredients-template {
		& .template-header {
			margin: auto auto auto spacing.$spacing-2x;
			& .template-title-show {
				line-height: normal;
				letter-spacing: 0.135px;
				margin-top: spacing.$spacing-3x;
			}
			& .template-profile-learn-more-container {
				& .action-link-contianer {
					& > i {
						font-size: fonts.$font-size-h5-mobile;
						width: 18px;
						height: 18px;
						margin-top: -3px;
					}
				}
			}
		}
	}
}
