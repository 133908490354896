@use '../../styles/tokens/spacing.scss';
@use '../../styles/tokens/breakpoints.scss';
.brand-onboard-page-container {
	overflow-x: hidden;
	&.public-brand-onboard-page {
		margin: spacing.$spacing-8x spacing.$spacing-8x;
		& .title-container {
			margin-top: 0px;
		}
	}
	& .title-container {
		margin-top: spacing.$spacing-7x;
	}
	& .content-container {
		margin-top: spacing.$spacing-7x;
	}
	& .footer-container {
		margin-top: spacing.$spacing-7x;
	}

}


.brand-onboard-page-container.new {
	overflow-x: hidden;
	&.public-brand-onboard-page {
		margin: spacing.$spacing-8x spacing.$spacing-8x;
		& .title-container {
			margin-top: 0px;
		}
	}
	& .new-title-container {
		margin-top: spacing.$spacing-2x;
	}
	& .new-content-container {
		margin-top: spacing.$spacing-7x;
	}
	& .new-footer-container {
		margin-top: spacing.$spacing-7x;
	}
}

@media (max-width: breakpoints.$tablet-breakpoint) {
	.brand-onboard-page-container {
		&.public-brand-onboard-page {
			margin: 42px spacing.$spacing-3x 0px spacing.$spacing-2x;
			& .title-container {
				margin-top: 0px;
			}
		}
	}
}
@media (max-width: breakpoints.$mobile-breakpoint) {
	.brand-onboard-page-container {
		&.public-brand-onboard-page {
			margin: spacing.$spacing-3x spacing.$spacing-2x 0px spacing.$spacing-2x;
			& .title-container {
				margin-top: 0px;
			}
		}
		& .title-container {
			margin-top: spacing.$spacing-x;
		}
		& .content-container {
			margin-top: spacing.$spacing-3x;
		}
		& .footer-container {
			margin-top: spacing.$spacing-3x;
		}
	}
}
@media (max-width: 500px) {
	.brand-onboard-page-container {
		&.public-brand-onboard-page {
			margin: spacing.$spacing-3x spacing.$spacing-x 0px spacing.$spacing-x;
			& .title-container {
				margin-top: 0px;
			}
		}
	}
}
