@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
@use '../../../styles/tokens/border-radius.scss';
@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/spacing.scss';
.company-details-container {
	width: 610px;
	background-color: colors.$color-background-white;
	border-radius: border-radius.$radius-medium;
	& .company-details {
		display: flex;
		flex-direction: column;
		width: 100%;
		& .modal-title {
			font-family: fonts.$secondary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-bold;
			font-size: fonts.$font-size-h4;
			line-height: 60px;
			color: colors.$color-text-black;
			letter-spacing: fonts.$letter-spacing-std;
			margin: 17px auto auto 151px;
		}
		& .welcome-message-container {
			margin: 4px auto auto 79px;
			display: flex;
			order: 0;
			flex-grow: 0;
			text-align: justify;
			width: 450px;
			& > p {
				font-family: fonts.$primary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-big;
				line-height: 26px;
				letter-spacing: fonts.$letter-spacing-std;
				color: colors.$color-background-slate;
			}
		}
		& .form-container {
			display: flex;
			width: 454px;
			margin: auto auto auto 79px;
			flex-direction: column;
			padding-bottom: 60px;
			& .options-container {
				display: flex;
				& .col-sm {
					padding: 0 !important;
					& > * {
						width: 216px;
					}
				}
				& > div:last-child {
					margin-left: 20px;
				}
			}
			& .col {
				margin-top: 24px;
			}
			& > div {
				margin-top: 24px;
			}
		}
		& .button-container {
			display: flex;
			justify-content: center;
			width: 100%;
		}
		& .powered-by-container {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			& .powered-by-text {
				font-family: fonts.$primary-font-family;
				font-style: normal;
				font-weight: fonts.$font-weight-regular;
				font-size: fonts.$font-size-big;
				line-height: 26px;
				margin: 0 23px 23px 0;
				color: colors.$color-text-black;
			}
			& .iris-text {
				font-family: fonts.$tertiary-font-family;
			}
		}
		& .line-icon {
			font-size: fonts.$font-size-bigger;
		}
		& .form-company-label {
			font-family: fonts.$tertiary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-base;
			line-height: 24px;
			letter-spacing: 0.5px;
			color: colors.$company-label-color;
		}
		& .requiere-label {
			font-family: fonts.$tertiary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-base;
			line-height: 24px;
			letter-spacing: 0.5px;
			color: colors.$color-error;
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.company-details-container {
		width: 100%;
		& .company-details {
			& .modal-title {
				margin: auto;
			}
			& .welcome-message-container {
				margin: spacing.$spacing-half-x spacing.$spacing-2x 0 spacing.$spacing-2x;
				width: 90%;
			}
			& .form-container {
				margin: 0 spacing.$spacing-2x 0 spacing.$spacing-2x;
				width: 90%;
				& .options-container {
					display: flex;
					& .col-sm {
						padding: 0 !important;
						& > * {
							width: 100%;
						}
					}
					& > div:last-child {
						margin-left: spacing.$spacing-x;
					}
				}
			}
		}
	}
}
