@use '../../../styles/tokens/breakpoints.scss';
@use '../../../styles/tokens/colors.scss';
@use '../../../styles/tokens/fonts.scss';
.brand-attribute-item {
	display: flex;
	flex-direction: column;
	& .brand-attribute-name-container {
		display: flex;
		& .attribute-icon {
			color: colors.$color-secondary;
			padding-top: 5px;
		}
		& .tiktok-icon {
			width: 16px;
			height: 16px;
		}
		& .brand-attribute-name {
			font-family: fonts.$tertiary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-semibold;
			font-size: fonts.$font-size-small;
			line-height: 19px;
			display: flex;
			align-items: center;
			letter-spacing: fonts.$letter-spacing-std;
			color: colors.$color-text-black;
			margin-left: 6px;
		}
	}
	& .brand-attribute-content {
		& .text-content {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-small;
			line-height: 19px;
			display: flex;
			align-items: center;
			letter-spacing: fonts.$letter-spacing-std;
			margin: -4px 0px 0px 22px;
			color: colors.$color-background-slate;
		}
		& .attribute-link {
			font-family: fonts.$primary-font-family;
			font-style: normal;
			font-weight: fonts.$font-weight-regular;
			font-size: fonts.$font-size-small;
			line-height: 19px;
			display: flex;
			align-items: center;
			letter-spacing: fonts.$letter-spacing-std;
			margin: -4px 0px 0px 22px;
			color: colors.$color-background-slate;
			border-bottom: 1px solid colors.$color-background-slate;
			width: fit-content;
			&:hover {
				text-decoration: none;
			}
		}
	}
}
@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.brand-attribute-item {
		& .brand-attribute-name-container {
			& .brand-attribute-name {
				font-size: fonts.$font-size-smaller;
				line-height: 16px;
			}
		}
		& .brand-attribute-content {
			& .text-content {
				font-size: fonts.$font-size-smaller;
				line-height: 16px;
			}
			& .attribute-link {
				font-size: fonts.$font-size-smaller;
				line-height: 16px;
			}
		}
	}
}
