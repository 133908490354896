@use '../../styles/tokens/border-radius.scss';
@use '../../styles/tokens/colors.scss';
@use '../../styles/tokens/shadows.scss';
@use '../../styles/tokens/fonts.scss';
@use '../../styles/tokens/breakpoints.scss';
@use '../../styles/tokens/spacing.scss';

.authentication {
	& .buttons-section {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 100%;

		& :nth-child(even) {
			margin-left: 36px;
		}

		& .rounded-button {
			margin-top: 0px;
			margin-bottom: 0px;
		}

		&.anonymous-container {
			gap: 16px;

			& .rounded-button {
				margin: 0px;
			}

			& :first-child {
				width: 102px;
				padding: spacing.$spacing-x 0px;
			}

			& :nth-child(2) {
				width: 116px;
				padding: spacing.$spacing-x 0px;
			}
		}
	}

	& .user-icon {
		width: 30px;
		height: 30px;
		background: colors.$color-transparent;
		border-radius: border-radius.$radius-circle;
		margin-left: auto;

		& .active {
			background-color: colors.$color-background-white;
			border-radius: border-radius.$radius-circle;
			margin-left: auto;
			display: block;
		}

		&:not(.active) {
			background: transparent;
		}
	}

	& .user-icon {
		&:not(.active) {
			&:hover {
				background-color: colors.$color-background-white;
				animation: fadeIn 0.8s linear forwards;
			}
		}
	}

	& .ri-user-line {
		font-size: fonts.$font-size-h5;
		cursor: pointer;
	}
	& .ri-user-fill {
		font-size: fonts.$font-size-h5;
		cursor: pointer;
	}
	& .blue {
		color: #017caf;
	}

	& .white {
		&:not(.active) {
			color: colors.$color-white;
		}
	}

	& .cta-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: spacing.$spacing-4x;

		& .rounded-button {
			margin: 0px;
			text-decoration: underline;
		}
	}
}

@media (min-width: 0px) and (max-width: breakpoints.$mobile-breakpoint) {
	.authentication {
		& .user-icon {
			display: flex;
			align-items: center;
			border: none;
			background: colors.$color-transparent;
			margin-left: auto;

			&:not(.active) {
				&:hover {
					background-color: transparent;
					animation: none;
				}
			}

			& .active {
				&::before {
					color: #017caf;
				}
			}
		}

		& .ri-menu-line {
			height: 30px;
			width: 30px;
			font-size: fonts.$font-size-h5;
		}

		& .user-icon:hover {
			background-color: colors.$color-transparent;
			animation: none;
		}

		& .buttons-section {
			&.anonymous-container {
				& :first-child {
					display: none;
				}

				& :nth-child(2) {
					width: 101px;
					padding: spacing.$spacing-x 0px;
					height: 28px;
				}
			}
		}
		& .cta-container {
			margin-top: spacing.$spacing-3x;
		}
	}
}

@media (min-width: breakpoints.$mobile-breakpoint) and (max-width: breakpoints.$tablet-breakpoint) {
	.authentication {
		& .user-icon {
			margin-right: spacing.$spacing-2x;
		}

		& .buttons-section {
			&.anonymous-container {
				& :first-child {
					width: 88px;
					padding: spacing.$spacing-x 0px;
					height: 28px;
				}

				& :nth-child(2) {
					width: 101px;
					padding: spacing.$spacing-x 0px;
					height: 28px;
				}
			}
		}
	}
}
